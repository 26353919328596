import React, { useState, useContext } from "react";
import Layout from "../../Components/Layout";
import MainMenu from "../../Components/MainMenu";
import ExitCard from "../../Components/ListPageCard/ExitCard";
import Table from "../../Components/TableComponent";
// import { PE_TABLE_QUERY } from '../../Queries/Pe';

import MonthPickerComponent from "../../Utils/MonthPickerComponent";
import xls from "../../images/xls/png/Group 47.png";
import FileIcon from "../../images/file.png";
import FileIconBlur from "../../images/fileblur.png";
// import ExcelPopup from "../../Components/ExcelPopup";
import line from "../../images/line.png";
import moment from "moment";

import RefineSearch from "../../Components/RefineSearchComponent";
import FilterTag from "../../Components/Tag";
// import chatPopupIcon from '../../images/chatPopupIcon.png'
// import ChatPopup from '../../Components/ChatPopup'
import ExportPopup from "../../Components/ExportPopup";
import { exitExportData } from "../../Components/ExportPopup/ExportData";

import SavedFilterContent from "../../Components/SavedFilter";
import SavedFilterICON from "../../Components/SavedFilter/SavedFilterICON";
import { exportPDF } from "../../Config/config";
import ExitRefineSearch from "../../Components/RefineSearch/ExitRefineSearch";
import Constants from "../../Constants";
import { useLocation } from "react-router";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { UserContext } from "../../Contexts/UserContext";
import { Switch, Tooltip } from "antd";
import ExcelDownloader from "../../Components/shared/ExcelDownloader";
import { TableDataContext } from "../../Contexts/TableDataContext";
import ToggleDealsViewButton from "../../Components/ToggleDealsView";
/**
 * The function `Exists` is a JavaScript function that renders a page for displaying PE/VC exit data
 * and includes various components such as filters, a table, and a refine search section.
 * @returns JSX code, which represents the UI elements of a web page.
 */
function Exists() {
  // const [save, setSave] = useState(false);
  const {
    save,
    setSave,
    // setMonthPickerValue,monthPickerValue,
    showMonthPicker,
    groupId,
    //  setShowMontnPicker
    dealsView,
    setDealsView,
    switchValue,
    setSwitchValue,
  } = useContext(PeFilterContext);
  const { globalSearchResult } = useContext(TableDataContext);
  const { isStudent, isDirectoryUser, isTrialExport } = useContext(UserContext);
  const [showPopup, setShowPopup] = useState(false);
  const [date, setDate] = useState([
    moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
    moment().endOf("month").format("YYYY-MM-DD"),
  ]);

  const savedList = () => {
    setSave(!save);
  };

  const location = useLocation();

  const { pathname } = useLocation();

  const { refineSearchFilters } = useContext(PeFilterContext);
  const isFromGlobalSearch = () =>
    refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false;

  const dealsViewHandler = (checked) => {
    setSwitchValue(checked);
    if (checked) {
      setDealsView(true);
    } else setDealsView(false);
  };

  return (
    <Layout>
      <div className="flex min-h-screen h-screen flex-col">
        {/* Top nav*/}
        {isFromGlobalSearch() ? (
          ""
        ) : (
          <div className="hidden lg:block">
            {" "}
            <ExitCard />{" "}
          </div>
        )}
        {/* Bottom section */}
        <div className="flex min-h-0 flex-1 overflow-hidden">
          {/* Narrow sidebar*/}
          {/* Main area */}
          <main
            className={
              isFromGlobalSearch()
                ? "mt-[3rem] min-w-0 flex-1 border-t border-gray-200 lg:flex"
                : "min-w-0 flex-1 border-t border-gray-200 lg:flex"
            }
          >
            {/* Primary column */}
            <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
              {/* Your content */}
              <div className="mr-[4.25rem] flex justify-between lg:items-center xl:items-center  md:items-center items-baseline">
                <FilterTag />
                <ToggleDealsViewButton />
              </div>
              <div className="pl-3 pr-4 lg:pr-4 md:pr-5 ">
                <div className="scrollbar ">
                  <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between  ">
                    {/* <div className='px-3'> */}
                    <MainMenu active={2} menu="exit-pe-vc" />
                    {isFromGlobalSearch() ? (
                      globalSearchResult?.company.exit_count !== 0 ? (
                        <div
                          className={
                            "md:flex justify-between items-center px-2 py-2 space-x-4 " +
                            (showMonthPicker != 0 ? "" : "flex")
                          }
                        >
                          <label htmlFor="" className="text-[#666666] ">
                            <div className={showMonthPicker != 0 ? "" : "flex"}>
                              <MonthPickerComponent />
                            </div>
                          </label>
                          <div
                            className={
                              showMonthPicker != 0
                                ? "hidden md:flex space-x-3"
                                : "flex space-x-3"
                            }
                          >
                            {isStudent == "true" ? (
                              <></>
                            ) : (
                              <>
                                <button>
                                  {isTrialExport == "false" ? (
                                    <ExcelDownloader
                                      url={exportPDF}
                                      fileName={
                                        Constants.exportNames.pe_company_exit
                                      }
                                      data={{ type: "pe_vc_exit" }}
                                      children={
                                        <img
                                          src={xls}
                                          className="max-h-[24px] mt-1 max-w-[21px]"
                                          alt=""
                                        />
                                      }
                                    />
                                  ) : (
                                    <img
                                      src={xls}
                                      className="max-h-[24px] mt-1 max-w-[21px]"
                                      alt=""
                                      onClick={() => setShowPopup(!showPopup)}
                                    />
                                  )}
                                  {showPopup && (
                                    <ExportPopup
                                      onClose={() => setShowPopup(false)}
                                      modules="company"
                                      fileName={
                                        Constants.exportNames.pe_company_exit
                                      }
                                      url={exportPDF}
                                      module_type="pe"
                                      exportOptions={exitExportData}
                                      type="pe_vc_exit"
                                    />
                                  )}
                                  {/* {showPopup && (
                              <div>
                                <ExcelPopup
                                  menu="exits"
                                  subMenu="pe_vc_exit"
                                  showPop={showPopup}
                                  setShowPop={setShowPopup}
                                />{" "}
                              </div>
                            )} */}
                                </button>

                                <img
                                  alt=""
                                  className="h-[24px] w-[2px] mt-1 "
                                  src={line}
                                />
                              </>
                            )}

                            <SavedFilterICON
                              save={save}
                              FileIcon={FileIcon}
                              FileIconBlur={FileIconBlur}
                              savedList={savedList}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )
                    ) : (
                      <div
                        className={
                          "md:flex justify-between items-center px-2 py-2 space-x-4 " +
                          (showMonthPicker != 0 ? "" : "flex")
                        }
                      >
                        <label htmlFor="" className="text-[#666666] ">
                          <div className={showMonthPicker != 0 ? "" : "flex"}>
                            <MonthPickerComponent />
                          </div>
                        </label>
                        <div
                          className={
                            showMonthPicker != 0
                              ? "hidden md:flex space-x-3"
                              : "flex space-x-3"
                          }
                        >
                          {isStudent == "true" ? (
                            <></>
                          ) : (
                            <>
                              <button>
                                {isTrialExport == "false" ? (
                                  <ExcelDownloader
                                    url={exportPDF}
                                    fileName={
                                      Constants.exportNames.pe_company_exit
                                    }
                                    data={{ type: "pe_vc_exit" }}
                                    children={
                                      <img
                                        src={xls}
                                        className="max-h-[24px] mt-1 max-w-[21px]"
                                        alt=""
                                      />
                                    }
                                  />
                                ) : (
                                  <img
                                    src={xls}
                                    className="max-h-[24px] mt-1 max-w-[21px]"
                                    alt=""
                                    onClick={() => setShowPopup(!showPopup)}
                                  />
                                )}
                                {showPopup && (
                                  <ExportPopup
                                    onClose={() => setShowPopup(false)}
                                    modules="company"
                                    fileName={
                                      Constants.exportNames.pe_company_exit
                                    }
                                    url={exportPDF}
                                    module_type="pe"
                                    exportOptions={exitExportData}
                                    type="pe_vc_exit"
                                  />
                                )}
                                {/* {showPopup && (
                              <div>
                                <ExcelPopup
                                  menu="exits"
                                  subMenu="pe_vc_exit"
                                  showPop={showPopup}
                                  setShowPop={setShowPopup}
                                />{" "}
                              </div>
                            )} */}
                              </button>

                              <img
                                alt=""
                                className="h-[24px] w-[2px] mt-1 "
                                src={line}
                              />
                            </>
                          )}

                          <SavedFilterICON
                            save={save}
                            FileIcon={FileIcon}
                            FileIconBlur={FileIconBlur}
                            savedList={savedList}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {save ? (
                    <SavedFilterContent
                      primaryTypeMasterId={Constants.primaryMasterIds.peId}
                      subTypeMasterId={
                        Constants.primaryMasterSubIds.pe_vc_exits_id
                      }
                      typeId={Constants.moduleIds.companiesId}
                      show={save}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="overflow-y-auto">
                <Table
                  menu="exit-pe-vc"
                  sub_type_master_id={Constants.subCategoryIds.pe_exits_id}
                />
              </div>
              {/* </div> */}
            </section>
            {/* Secondary column (hidden on smaller screens) */}
            <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
              <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                {/* Your content */}
                <RefineSearch>
                  <ExitRefineSearch
                    typeId={Constants.primaryMasterSubIds.pe_vc_exits_id}
                    customPathname={location.pathname}
                  />
                </RefineSearch>
              </div>
            </aside>
          </main>
        </div>
      </div>
    </Layout>
  );
}

export default Exists;
