import React, { useState, useEffect, useContext, useMemo, useCallback, useRef } from "react";
import chartDark from "../../images/ReportsImages/chartDark.png";
import chartLite from "../../images/ReportsImages/chartLite.png";
import statistics from "../../images/ReportsImages/statistics.png";
import pieChart from '../../images/ReportsImages/pie-chart.svg'
import pieChartActive from '../../images/ReportsImages/pie-chart-active.svg'
import tableDark from "../../images/ReportsImages/tableDark .png";
import tableLite from "../../images/ReportsImages/calenderlite.png";
import XlsBlur from "../../images/ReportsImages/xls-blur.png";
import Xls from "../../images/ReportsImages/xls.png";
// import tableLite from '../../images/ReportsImages/calenderlite.png'
import TableTabTable from "./TableTabTable";
import ReportHeader from "./ReportsHeader";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import MultiSelectEntity from "../RefineSearch/MultiSelectEntity";
import { Badge, Popover, Tooltip } from "antd";
import { useCubeQuery } from "@cubejs-client/react";
import {
  getFilterQuery,
  getMasterDataQuery,
  initialDateRange,
} from "../../Queries/CubeQueries";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import TagSearch from "./TagSearch";
import MonthRangePicker, { FyPeriodRangePicker, PeriodRangePicker } from "./MonthPicker";
import { orderBy } from "lodash";
import { toast } from "react-toastify";
import SelectInput from "../shared/SelectInput";
import PE from '../../images/reportsProducts/pe.png'
import MA from '../../images/reportsProducts/ma.png'
import RE from '../../images/reportsProducts/pe-re.png'
import PEInActive from '../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import REInactive from '../../images/RE.png';
import MAInactive from '../../images/M & A.png';
import { ReportsChartMixed } from "../../Pages/Charts/dashboard/deals/ReportsChartMixed";
import { BarChart } from "../../Pages/Charts/dashboard/deals/ReportsChartMixed2";
import { PieChart } from "../../Pages/Charts/dashboard/deals/PieCharts";

import ToggleAndOrSwitch from "../../Components/RefineSearchComponents/ToggleAndOrSwitch";
import Loader from '../../Components/Loader';
import { utils, writeFileXLSX } from 'xlsx';
import Archive from './Archive'
import axios from "axios";
import { getDateApi } from "../../Config/config";
import { cubejsApi } from "../..";
import { UserContext } from "../../Contexts/UserContext";
import moment from "moment";
// import {allowedModulesList} from useModule
import useModule from "../../hooks/useModule";
import Constants from "../../Constants";

/**
 * The `ProductTab` component is a button that displays an image and label, and can be selected or
 * disabled.
 * The `ProductTab` component is returning a button element with an image and a label. The
 * button has an `onClick` event handler, a `role` attribute set to "tab", and a `disabled` attribute
 * that is set based on the `disabled` prop passed to the component. The button also has a dynamic
 * class name that includes styles for hover effects and a border if the `selected
 */
const ProductTab = ({ selected, label, imgSrc, onClick ,disabled}) => {
  return (  
    <button
      onClick={onClick}
      role="tab"  
      disabled={!disabled}    
      className={`flex h-9 px-4 items-center hover:bg-slate-50 ${selected === true ? "border-b-2 border-b-primaryColor" : ""} ${disabled ? 'cursor-pointer': 'cursor-not-allowed'}`}>
      <img alt={label} src={imgSrc} className={`w-[28px] h-[24px]`}/>
      <h3 className={`ml-1 ${!disabled ? 'text-[#cccccc]' :'text-[#333333]'}`}>{label}</h3>
    </button>      
  );
};

/** The above code is a React component called "Filter". It is a dropdown filter component that displays
a label, count badge, and arrow icon. When the component is clicked, it opens a popover with a list
of options to select from. The selected options are passed to the "handleChange" function. The
component also has the ability to be disabled, which prevents it from being clicked and displays a
disabled style. The position of the popover can be set to either "bottom" or "top". */
const Filter = ({
  label,
  handleChange,
  options,
  disabled,
  count,
  position = "bottom",
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (disabled === true) {
      setOpen(false);
    }
  }, [disabled]);

  return (
    <Popover
      open={open}
      onOpenChange={disabled === true ? null : setOpen}
      trigger="click"
      placement={position}
      autoAdjustOverflow={false}
      destroyTooltipOnHide={true}
      className={`${disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
        }`}
      content={
        <div className="max-h-[250px] overflow-y-auto">
          <MultiSelectEntity
            handleEntityChange={handleChange}
            primaryEntity={options}
          />
        </div>
      }
    >
      <div className="flex items-center whitespace-nowrap">
        {label}
        {count > 0 ? (
          <Badge
            className="ml-0.5"
            count={count}
            showZero={false}
            color="#BC8B20"
          />
        ) : null}
        {open ? (
          <MdKeyboardArrowUp size={24} className="ml-1" />
        ) : (
          <MdKeyboardArrowDown size={24} className="ml-1" />
        )}
      </div>
    </Popover>
  );
};

export const PiechartIconActive =()=>{
  return <button><img src={pieChartActive} className="w-[26px] h-[28px]"/></button>
} 
export const PiechartIcon =()=>{
  return <button><img src={pieChart} className="w-[26px] h-[28px]"/></button>
} 
export const InvestorTypeData = [
  {
    id: "C",
    name: "Co-Investment",
    isChecked: false,
  },
  {
    id: "F",
    name: "Foreign",
    isChecked: false,
  },
  {
    id: "I",
    name: "India-dedicated",
    isChecked: false,
  },
];

export const FilterTypeData = [
  {
    id: 1,
    name: "Single",
    isChecked: true,
  },
  {
    id: 2,
    name: "Multiple",
    isChecked: false,
  },
];

export const DealRangeData = [
  {
    id: 1,
    name: "0-5",
    isChecked: false,
  },
  {
    id: 2,
    name: "5-10",
    isChecked: false,
  },
  {
    id: 3,
    name: "10-15",
    isChecked: false,
  },
  {
    id: 4,
    name: "15-25",
    isChecked: false,
  },
  {
    id: 5,
    name: "25-50",
    isChecked: false,
  },
  {
    id: 6,
    name: "50-100",
    isChecked: false,
  },
  {
    id: 7,
    name: "100-200",
    isChecked: false,
  },
  {
    id: 8,
    name: "200+",
    isChecked: false,
  },
];

export const PeriodTypeOptions = [
  {
    value: "CY",
    label: "CY",
  },
  {
    value: "FY",
    label: "FY",
  },
];

export const PeriodOptions = [
  {
    label: "Yearly",
    value: "yearly",
  },
  {
    label: "Half Yearly",
    value: "halfYearly",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

function ReportsMain() {
  const [openTab, setOpenTab] = React.useState(3);
  const [activeTab, setActiveTab] = React.useState(1);
  const [selectedProduct, setSelectedProduct] = useState('PE');
  const [select, setSelected] = useState(0);
  const [show, setShow] = useState(false);
  const {
    Industries,
    Stages,
    Rounds,
    Regions,
    dealTypeFilterResponse,
    FundSector,
  } = useContext(PeFilterContext);
  const [IndustriesOptions, setIndustriesOptions] = useState(Industries);
  const [SectorOptions, setSectorOptions] = useState(FundSector);
  const [DealTypeOptions, setDealTypeOptions] = useState(
    dealTypeFilterResponse?.ma ?? []
  );
  const [StageOptions, setStageOptions] = useState(Stages);
  const [RoundOptions, setRoundOptions] = useState(Rounds);
  const [InvestorTypeOptions, setInvestorTypeOptions] =
    useState(InvestorTypeData);
  const [locationOptions, setLocationOptions] = useState(Regions);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedViewBy, setSelectedViewBy] = useState("periodWise");
  const [selectedPeriodType, setselectedPeriodType] = useState("CY");
  const [selectedViewOption, setselectedViewOption] = useState("yearly");
  const [selectedFilterViewOption, setSelectedFilterViewOption] = useState("");
  const [reportsFilter, setReportsFilter] = useState(() => {
    const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
    const allowedModulesList = JSON.stringify(allowedModulesStr) ?? [];

    let product = ""
    if (allowedModulesList.includes("pe")) {
      product = 'PE'
    } else if (allowedModulesList.includes("ma")) {
      product = 'MA'
    } else if (allowedModulesList.includes("re")) {
      product = 'PE-RE'
    }

    return { product }

  });
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [chartType, setChartType] = useState('');
  const [showChartType,setShowChartType] =useState(false)
  const [isRenderingInitial, setIsRenderingInitial] = useState(true);
  const [DealRangeOptions, setDealRangeOptions] = useState(DealRangeData);
  const [isDownloading,setIsDownloading] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [resultSet, setResultSet] = useState(null);
  const [exportFileName, setExportFileName] = useState({periodType:selectedPeriodType,periodOptions:selectedViewOption})
  const {allowedModulesList} = useModule()

  const tableRef = useRef(null);
  const { getToken, logout } = useContext(UserContext);
  const {isStudent,isTrialExport} = useContext(UserContext)


  useEffect(() => {
    setIndustriesOptions(Industries);
    setStageOptions(Stages);
    setRoundOptions(Rounds);
    setInvestorTypeOptions(InvestorTypeData);
    setLocationOptions(Regions);
    setDealTypeOptions(dealTypeFilterResponse?.ma);
    setSectorOptions(FundSector);
  }, [Industries, Stages, Rounds, Regions, dealTypeFilterResponse, FundSector]);


  const cubeApiCall = async (query) => {
    setIsLoading(true);
    const yearTypeOptions = {
      yearly: "Y",
      halfYearly: "HY",
      quarterly: "QY",
      monthly: "MY"
    }

    let preparedQuery = {...query}

    const request = {
      "year_type": preparedQuery?.period,
      "frequency": yearTypeOptions[preparedQuery?.viewOption],
      "from_date": preparedQuery?.dateRange?.at(0),
      "to_date": preparedQuery?.dateRange?.at(1),
    }
    console.log("request-q",request);
    const preparedDateResponse = await axios(getDateApi, {
      data: request,
      method:"POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      }
    })

    if (preparedDateResponse.status === 200 && preparedDateResponse.data?.success === true) {
      const data = preparedDateResponse.data;
      preparedQuery = {...preparedQuery,dateRange: [data?.from_date, data?.end_date]}
    }

    cubejsApi.load(getFilterQuery(preparedQuery)).then(result=>{
      setIsLoading(false);
      setResultSet(result)
    })
  };

  useEffect(() => {
    cubeApiCall(reportsFilter)
  }, [reportsFilter])

  useEffect(()=>{
    if(allowedModulesList.includes("pe")){
    setSelectedProduct('PE')}
    else if(allowedModulesList.includes("ma")){
      setSelectedProduct('MA')
    }
    else if(allowedModulesList.includes("re")){
      setSelectedProduct('PE-RE')
    }
  },[allowedModulesList])


  // const { resultSet, isLoading } = useCubeQuery(prepareQuery(), {
  //   resetResultSetOnChange: true,
  // });

  const masterData = useCubeQuery(getMasterDataQuery(), {
    resetResultSetOnChange: false,
  });
  const pvConfig = useMemo(() => {
    const isFilterSelected = resultSet?.query()?.filters?.length > 1;
    if (resultSet?.query()?.dimensions?.length === 0) {
      return {
        fillMissingDates: true,
        x: [
          `${resultSet?.query()?.timeDimensions[0]?.dimension}.${resultSet?.query()?.timeDimensions[0]?.granularity
          }`,
        ],
        y: ["measures"],
      };
    }

    if (
      reportsFilter?.viewBy === "periodWise" ||
      reportsFilter?.viewBy == null
    ) {
      let xVal = [];
      let yVal = [];

      if (
        resultSet?.query()?.dimensions?.length === 1 ||
        reportsFilter?.viewBy == null
      ) {
        return {
          fillMissingDates: true,
          x: [`${resultSet?.query()?.dimensions?.at(0)}`],
          y: ["measures"],
        };
      }

      if (reportsFilter?.viewOption === "yearly") {
        return {
          fillMissingDates: true,
          x: [`${resultSet?.query()?.dimensions?.at(1)}`],
          y: [`${resultSet?.query()?.dimensions?.at(0)}`, "measures"],
        };
      }

      if (resultSet?.query()?.dimensions?.at(2) !== undefined) {
        xVal = [resultSet?.query()?.dimensions?.at(2)];
        yVal = [
          resultSet?.query()?.dimensions?.at(0),
          resultSet?.query()?.dimensions?.at(1),
          "measures",
        ];
      }else if(!isFilterSelected){
        xVal = resultSet?.query()?.dimensions;
        yVal = [
          "measures",
        ];
      } else {
        xVal = [];
        yVal = [
          resultSet?.query()?.dimensions?.at(0),
          resultSet?.query()?.dimensions?.at(1),
          "measures",
        ];
      }

      return {
        fillMissingDates: true,
        x: xVal,
        y: yVal,
      };
    }
    return {
      fillMissingDates: true,
      x: resultSet?.query()?.dimensions,
      y: [
        `${resultSet?.query()?.timeDimensions[0]?.dimension}.${resultSet?.query()?.timeDimensions[0]?.granularity
        }`,
        "measures",
      ],
    };
  }, [resultSet, reportsFilter]);

  //DealFlat.industry
  console.log('from bar chart resultSet',  pvConfig)

  // console.log(chain(resultSet?.rawData()).groupBy('DealFlat.date.year').value(), "resultSet?.rawData()");

  const tableData = useMemo(()=>resultSet?.tablePivot(pvConfig) ?? [],[pvConfig, resultSet]);
  const tableColumns = useMemo(() => {
    let columns = resultSet?.tableColumns(pvConfig) ?? [];
    let formatedCol = [];
console.log("columns-098",columns);
    const isFilterSelected = resultSet?.query()?.filters?.length > 1;

    if (
      (reportsFilter?.viewBy === "periodWise" ||
        reportsFilter?.viewBy == null) &&
      (reportsFilter?.viewOption === "quarterly" ||
        reportsFilter?.viewOption === "halfYearly" ||
        reportsFilter?.viewOption === "monthly")
    ) {
      columns.forEach((column,index) => {
        if (column.children) {
          formatedCol.push(...orderBy(column.children, ["key"], ["desc"]));
        } else {
          // Removed first year column if user select periodwise filter without any filter catogory
          if(isFilterSelected){
            formatedCol.push(column);
          } else {
            if(index !== 0){
              formatedCol.push(column);
            }
          }
        }
      });
      columns = formatedCol;
    }
    // else{
    //   columns = orderBy(columns,["key"], ["desc"])
    // }
    console.log("formatedCol", columns, resultSet?.tableColumns(pvConfig) ?? []);
    // return columns.slice(0, 6);
    return columns;
  }, [pvConfig, reportsFilter, resultSet]);

  console.log("resultSet", tableColumns, tableData, pvConfig);

  const accordionClick = (val) => {
    setShow(!show);
    if (val == select) {
      setSelected(0);
    } else {
      setSelected(val);
    }
  };

  const onProductChange = (value) => {
    setSelectedProduct(value);
    setReportsFilter({
      product: value,
    });
    ClearFilter();
    setIsRenderingInitial(true)
    setShowChartType(true)
    setChartType('')
  };

  const filterTypeCallback = (currentFilterKey, currentLen) => {
    // setSelectedFilterViewOption(currentFilterKey)
  };

  console.log("reportsFilter", reportsFilter);

  const handleIndustryChange = (e) => {
    const { name, checked, id } = e.target;
    let currentIndustry = [];

    if (name === "allSelect") {
      currentIndustry = IndustriesOptions.map((industry) => ({
        ...industry,
        isChecked: checked,
      }));
      setIndustriesOptions(currentIndustry);
    } else {
      currentIndustry = IndustriesOptions.map((industry) =>
        industry.id === parseInt(id)
          ? { ...industry, isChecked: checked }
          : industry
      );
      setIndustriesOptions(currentIndustry);
    }
    filterTypeCallback(
      "industry",
      currentIndustry?.filter((data) => data.isChecked)?.length
    );
  };

  const handleDealTypeChange = (e) => {
    const { name, checked, id } = e.target;
    let currentDealtype = [];

    if (name === "allSelect") {
      currentDealtype = DealTypeOptions.map((dealType) => ({
        ...dealType,
        isChecked: checked,
      }));
      setDealTypeOptions(currentDealtype);
    } else {
      currentDealtype = DealTypeOptions.map((dealType) =>
        dealType.id === parseInt(id)
          ? { ...dealType, isChecked: checked }
          : dealType
      );
      setDealTypeOptions(currentDealtype);
    }
    filterTypeCallback(
      "dealType",
      currentDealtype?.filter((data) => data.isChecked)?.length
    );
  };

  const handleSectorChange = (e) => {
    const { name, checked, id } = e.target;
    let currentSector = [];

    if (name === "allSelect") {
      currentSector = SectorOptions.map((sector) => ({
        ...sector,
        isChecked: checked,
      }));
      setSectorOptions(currentSector);
    } else {
      currentSector = SectorOptions.map((sector) =>
        sector.id === parseInt(id) ? { ...sector, isChecked: checked } : sector
      );
      setSectorOptions(currentSector);
    }
    filterTypeCallback(
      "sector",
      currentSector?.filter((data) => data.isChecked)?.length
    );
  };

  const handleStageChange = (e) => {
    const { name, checked, id } = e.target;
    let currentStages = [];

    if (name === "allSelect") {
      currentStages = StageOptions.map((stage) => ({
        ...stage,
        isChecked: checked,
      }));
      setStageOptions(currentStages);
    } else {
      currentStages = StageOptions.map((stage) =>
        stage.id === parseInt(id) ? { ...stage, isChecked: checked } : stage
      );
      setStageOptions(currentStages);
    }
    filterTypeCallback(
      "stage",
      currentStages?.filter((data) => data.isChecked)?.length
    );
  };

  const handleRoundChange = (e) => {
    const { name, checked, id } = e.target;
    let currentRounds = [];
    if (name === "allSelect") {
      currentRounds = RoundOptions.map((round) => ({
        ...round,
        isChecked: checked,
      }));
      setRoundOptions(currentRounds);
    } else {
      currentRounds = RoundOptions.map((round) =>
        round.id === parseInt(id) ? { ...round, isChecked: checked } : round
      );
      setRoundOptions(currentRounds);
    }
    filterTypeCallback(
      "rounds",
      currentRounds?.filter((data) => data.isChecked)?.length
    );
  };

  const handleInvestorTypeChange = (e) => {
    const { name, checked, id } = e.target;
    let currentTypes = [];
    if (name === "allSelect") {
      currentTypes = InvestorTypeOptions.map((type) => ({
        ...type,
        isChecked: checked,
      }));
      setInvestorTypeOptions(currentTypes);
    } else {
      currentTypes = InvestorTypeOptions.map((type) =>
        type.id === id ? { ...type, isChecked: checked } : type
      );
      setInvestorTypeOptions(currentTypes);
    }
    filterTypeCallback(
      "investorType",
      currentTypes?.filter((data) => data.isChecked)?.length
    );
  };

  const handleLocationChange = (e) => {
    const { name, checked, id } = e.target;
    let currentLocation = [];
    if (name === "allSelect") {
      currentLocation = locationOptions.map((location) => ({
        ...location,
        isChecked: checked,
      }));
      setLocationOptions(currentLocation);
    } else {
      currentLocation = locationOptions.map((location) =>
        location.id == id ? { ...location, isChecked: checked } : location
      );
      setLocationOptions(currentLocation);
    }
    filterTypeCallback(
      "location",
      currentLocation?.filter((data) => data.isChecked)?.length
    );
  };

  const handleDealRangeChange = (e) => {
    const { name, checked, id } = e.target;
    let dealRanges = [];
    if (name === "allSelect") {
      dealRanges = DealRangeOptions.map((dealRange) => ({
        ...dealRange,
        isChecked: checked,
      }));
      setDealRangeOptions(dealRanges);
    } else {
      dealRanges = DealRangeOptions.map((dealRange) =>
        dealRange.id == id ? { ...dealRange, isChecked: checked } : dealRange
      );
      setDealRangeOptions(dealRanges);
    }
  };

  const selectedIndustry = IndustriesOptions?.filter(
    (option) => option.isChecked
  );
  const selectedStage = StageOptions?.filter((option) => option.isChecked);
  const selectedRounds = RoundOptions?.filter((option) => option.isChecked);
  const selectedDealRanges = DealRangeOptions?.filter((option) => option.isChecked);
  const selectedIvestorType = InvestorTypeOptions?.filter(
    (option) => option.isChecked
  );
  const selectedLocation = locationOptions?.filter(
    (option) => option.isChecked
  );
  const selectedDealType = DealTypeOptions?.filter(
    (option) => option.isChecked
  );
  const selectedSector = SectorOptions?.filter((option) => option.isChecked);

  const ClearFilter = () => {
    setIndustriesOptions(Industries);
    setStageOptions(Stages);
    setRoundOptions(Rounds);
    setInvestorTypeOptions(InvestorTypeData);
    setLocationOptions(Regions);
    setSelectedTags([]);
    setDealTypeOptions(dealTypeFilterResponse?.ma ?? []);
    setSectorOptions(FundSector);
    setDateRange(initialDateRange);
    setselectedPeriodType("CY");
    setselectedViewOption("yearly");
    setDealRangeOptions(DealRangeData);
  };

  const applyFilter = (event) => {
    event?.preventDefault();
    if(isLoading) return;
    
    setResultSet(null);
    setReportsFilter({
      product: selectedProduct,
      industry: selectedIndustry,
      stage: selectedStage,
      rounds: selectedRounds,
      investorType: selectedIvestorType,
      location: selectedLocation,
      // tags: selectedTags,
      viewBy: selectedViewBy,
      period: selectedPeriodType,
      viewOption: selectedViewOption,
      filterViewOption: selectedFilterViewOption,
      dateRange,
      dealType: selectedDealType,
      sector: selectedSector,
      dealRange: selectedDealRanges,
    });
    if (
      selectedIndustry?.length === 0 &&
      selectedStage?.length === 0 &&
      selectedRounds?.length === 0 &&
      selectedIvestorType?.length === 0 &&
      selectedLocation?.length === 0 &&
      selectedTags?.length === 0 &&
      selectedSector?.length === 0 &&
      selectedDealType?.length === 0 &&
      selectedDealRanges?.length === 0
    ) {
      setIsRenderingInitial(true);
      setChartType('')
    }else{
      setIsRenderingInitial(false);
      setChartType('bar')
    }

    setExportFileName({periodType:selectedPeriodType,periodOptions:selectedViewOption})
  };

  const resetFilter = (event,openTab) => {
    event.preventDefault();
    setReportsFilter(prev=>({product:prev.product}));
    ClearFilter();
    setIsRenderingInitial(true)
    setShowChartType(false)
    setChartType('')
    setOpenTab(openTab)
  };
  console.log(reportsFilter, "reportsFilter");

  const viewByOptions = useMemo(() => {
    let options = [];

  if(selectedProduct=='PE')
  {
    if (selectedIndustry?.length != 0) {
      options.push({
        label: "Industry",
        value: "industry",
      });
    }
    if (selectedStage?.length != 0) {
      options.push({
        label: "Stage",
        value: "stage",
      });
    }
    if (selectedRounds?.length != 0) {
      options.push({
        label: "Round",
        value: "rounds",
      });
    }
    if (selectedDealRanges?.length != 0) {
      options.push({
        label: "Range",
        value: "dealRange",
      });
    }
    if (selectedIvestorType?.length != 0) {
      options.push({
        label: "Investor Type",
        value: "investorType",
      });
    }
    if (selectedLocation?.length != 0) {
      options.push({
        label: "Location",
        value: "location",
      });
    }
    if (selectedTags?.length != 0) {
      options.push({
        label: "Tags",
        value: "tags",
      });
    }
  }
  else if(selectedProduct=='MA'){
    if (selectedIndustry?.length != 0) {
      options.push({
        label: "Industry",
        value: "industry",
      });
    }
    if (selectedDealType?.length != 0) {
      options.push({
        label: "Deal Type",
        value: "dealType",
      });
    }
    if (selectedDealRanges?.length != 0) {
      options.push({
        label: "Range",
        value: "dealRange",
      });
    }
    if (selectedLocation?.length != 0) {
      options.push({
        label: "Location",
        value: "location",
      });
    }
    
  }
  else{
    if (selectedSector?.length != 0) {
      options.push({
        label: "Sector",
        value: "sector",
      });
    }
    if (selectedDealRanges?.length != 0) {
      options.push({
        label: "Range",
        value: "dealRange",
      });
    }
    if (selectedLocation?.length != 0) {
      options.push({
        label: "Location",
        value: "location",
      });
    }
  }

    if (options.at(0)?.value) {
      setSelectedFilterViewOption(options.at(0)?.value);
    }

    return options;
  }, [
    selectedDealType?.length,
    selectedIndustry?.length,
    selectedIvestorType?.length,
    selectedLocation?.length,
    selectedRounds?.length,
    selectedSector?.length,
    selectedStage?.length,
    selectedTags?.length,
    selectedDealRanges?.length,
  ]);

  const onPeriodTypeChange = (value) => {
    setselectedPeriodType(value);
    setDropdownInitial(value,selectedViewOption)
  };

  const onPeriodChange = (value) => {
    setselectedViewOption(value);
    setDropdownInitial(selectedPeriodType,value)
  };

  /**
   * The function `setDropdownInitial` sets the initial date range based on the year type and period
   * selected.
   */
  const setDropdownInitial = (yearType, period)=>{
    if(period === "halfYearly"){
      const latestDateMoment =yearType === "CY"
      ? moment().quarter() > 2 ? moment().endOf("year") : moment().quarter(2).endOf("quarter")
      : moment().quarter() > 3 ? moment().endOf("year").add(1, "quarter").endOf("quarter") : moment().quarter() === 1? moment().endOf("quarter"): moment().quarter(3).endOf("quarter")
     
      setDateRange([
        latestDateMoment.clone().subtract(24, "months").endOf("quarter").format("YYYY-MM-DD"),
        latestDateMoment.clone().format("YYYY-MM-DD"),
      ])
    }else if(period === "quarterly"){
      setDateRange([
        moment().endOf("quarters").subtract(4, "quarters").endOf("quarter").format("YYYY-MM-DD"),
        moment().endOf("quarters").format("YYYY-MM-DD"),
      ])
    }else if(period === "monthly"){
      setDateRange([
        moment().endOf("month").subtract(4, "month").endOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ])
    }else{
      setDateRange(initialDateRange)
    }
  }

console.log("quarter-09",moment().endOf("month").quarter(4).quarter());
  const onFilterViewChange = (value) => {
    setSelectedFilterViewOption(value);
  };

  const onDateRange = (range) => {
    setDateRange(range);
  };

  const handleToggle = (isOn) => {
    setChartType(isOn);
  };

  console.log('selectedProduct :',selectedProduct);

/** The above code is defining a function called `exportFile` using the `useCallback` hook. This
function is responsible for exporting data to an Excel file. */
  const exportFile = useCallback(() => {

    const selectedPeriodTypeOption = PeriodTypeOptions.find(option=>option.value == exportFileName.periodType)
    const selectedPeriod = PeriodOptions.find(option=>option.value == exportFileName.periodOptions)
    console.log('selectedPeriodTypeOption :',selectedPeriodTypeOption,'selectedPeriod :',selectedPeriod, 'selectedProduct :',selectedProduct);
    let fileName = `${selectedProduct} ${selectedPeriodTypeOption?.label}-${selectedPeriod?.label}.xlsx`
   
    const elt = tableRef.current.getElementsByTagName("TABLE")[0];
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, fileName);
    setIsDownloading(false)
  }, [exportFileName,selectedProduct]);

  const dataSet = resultSet?.loadResponse?.results[0]?.data;
  console.log(resultSet,'resultSet');

  return (
    <div className="">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="bg-[#F5F5F5] text-left h-full ">
          <div className="block rounded-lg bg-[#F5F5F5] col-span-6 md:col-span-4 lg:col-span-4 border-gray-300 ">
            <ReportHeader />
            <ul className="flex flex-wrap justify-center space-x-2 ">
              <li className="mr-2 ">
                <a
                  id="#live"
                  className={
                    "inline-block p-2 text-[18px]  text-[#333333] leading-[22px] cursor-pointer " +
                    (activeTab === 1
                      ? " border-b-2 border-[#F1CA75]  font-sans_medium_body  "
                      : " font-sans_book_body ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(1);
                  }}
                >
                  &nbsp;Live&nbsp;
                </a>
              </li>
              <li className="mr-2">
                <a
                  className={
                    "inline-block p-2 text-[18px] text-[#333333] leading-[22px] cursor-pointer " +
                    (activeTab === 2
                      ? " border-b-2 border-[#F1CA75]  font-sans_medium_body "
                      : " font-sans_book_body ")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTab(2);
                  }}
                >
                  Archive
                </a>
              </li>
            </ul>

            <div className="w-full">
              <div className={activeTab === 1 ? "block " : "hidden"}>
                <div className=" bg-white border shadow rounded block col-span-6 md:col-span-2 lg:col-span-4 border-gray-100 ml-3 mr-3 ">
                  <div className="px-3 border-t divide-y divide-solid">
                    <div className="flex justify-between">
                      <div className="flex space-x-8 items-center">
                        <div className="flex items-center space-x-2">
                          <ProductTab
                            onClick={() => onProductChange("PE")}
                            selected={selectedProduct === "PE"}
                            imgSrc={allowedModulesList.includes("pe") ? PE : PEInActive}
                            label="PE"
                            disabled={allowedModulesList.includes("pe")}
                          />
                          <ProductTab
                            onClick={() => onProductChange("MA")}
                            selected={selectedProduct === "MA"}
                            imgSrc={allowedModulesList.includes("ma") ? MA : MAInactive}
                            label="MA"
                            disabled={allowedModulesList.includes("ma")}
                          />
                          <ProductTab
                            onClick={() => onProductChange("PE-RE")}
                            selected={selectedProduct === "PE-RE"}
                            imgSrc={allowedModulesList.includes("re") ? RE: REInactive} 
                            label="PE-RE"
                            disabled={allowedModulesList.includes("re")}
                          />
                        </div>
                      </div>                      
                    </div>
                    <form
                      id="reportsFilter"
                      className=""
                      name="reportsFilter"
                      onSubmit={applyFilter}
                      onReset={(e)=>resetFilter(e,openTab)}
                    >
                      <div
                        className={
                          viewByOptions.length > 1
                            ? "overflow-x-auto scrollbar-remove"
                            : "2xl:flex items-center 2xl:justify-between overflow-x-auto scrollbar-remove"
                        }
                      >
                        <div className="flex items-center space-x-4 py-2">
                          <label className=" font-semibold whitespace-nowrap">
                            Filters :
                          </label>
                          {selectedProduct === "PE" ? (
                            <>
                              <div className="flex space-x-4 items-center ">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Industry"
                                    position="bottomLeft"
                                    count={selectedIndustry?.length}
                                    options={IndustriesOptions}
                                    handleChange={handleIndustryChange}
                                  />
                                </div>
                              </div>
                              <div className="flex space-x-4 items-center">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Stage"
                                    count={selectedStage?.length}
                                    options={StageOptions}
                                    handleChange={handleStageChange}
                                  />
                                </div>
                              </div>
                              <div className="flex space-x-4 items-center">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Round"
                                    count={selectedRounds?.length}
                                    options={RoundOptions}
                                    handleChange={handleRoundChange}
                                  />
                                </div>
                              </div>
                              <div className="flex space-x-4 items-center">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Range"
                                    count={selectedDealRanges?.length}
                                    options={DealRangeOptions}
                                    handleChange={handleDealRangeChange}
                                  />
                                </div>
                              </div>
                              <div className="flex space-x-4 items-center">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Investor Type"
                                    count={selectedIvestorType?.length}
                                    options={InvestorTypeOptions}
                                    handleChange={handleInvestorTypeChange}
                                  />
                                </div>
                              </div>
                              <div className="flex space-x-4 items-center">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Location"
                                    count={selectedLocation?.length}
                                    options={locationOptions}
                                    handleChange={handleLocationChange}
                                  />
                                </div>
                              </div>
                              {/* <div className="bg-white input-search relative pointer-events-auto border border-black hover:border-[#BC8B20] focus:border-[#BC8B20] rounded  py-0.5 opacity-[0.6]">
                                <label className=" flex  rounded ">
                                  <div className="min-w-[150px] max-w-[150px] flex items-center overflow-hidden">
                                    <TagSearch
                                      primaryMasterId={
                                        selectedProduct === "PE"
                                          ? 1
                                          : selectedProduct === "MA"
                                            ? 4
                                            : 3
                                      }
                                      TagsOptions={selectedTags}
                                      setTagsOptions={(opt) => {
                                        setSelectedTags(opt);
                                      }}
                                    />
                                  </div>
                                  </label>
                              </div> */}
                            </>
                          ) : selectedProduct === "MA" ? (
                            <>
                              <div className="flex space-x-4 items-center pl-1">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Industry"
                                    position="bottomLeft"
                                    count={selectedIndustry?.length}
                                    options={IndustriesOptions}
                                    handleChange={handleIndustryChange}
                                  />
                                </div>
                              </div>
                              <div className="flex space-x-4 items-center pl-1">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Deal Type"
                                    count={selectedDealType?.length}
                                    options={DealTypeOptions}
                                    handleChange={handleDealTypeChange}
                                  />
                                </div>
                              </div>
                              <div className="flex space-x-4 items-center">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Range"
                                    count={selectedDealRanges?.length}
                                    options={DealRangeOptions}
                                    handleChange={handleDealRangeChange}
                                  />
                                </div>
                              </div>
                              <div className="flex space-x-4 items-center">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Location"
                                    count={selectedLocation?.length}
                                    options={locationOptions}
                                    handleChange={handleLocationChange}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex space-x-4 items-center pl-1">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Sector"
                                    position="bottomLeft"
                                    count={selectedSector?.length}
                                    options={SectorOptions}
                                    handleChange={handleSectorChange}
                                  />
                                </div>
                              </div>
                              <div className="flex space-x-4 items-center">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Range"
                                    count={selectedDealRanges?.length}
                                    options={DealRangeOptions}
                                    handleChange={handleDealRangeChange}
                                  />
                                </div>
                              </div>
                              <div className="flex space-x-4 items-center">
                                <div className={"py-1"}>
                                  <Filter
                                    label="Location"
                                    count={selectedLocation?.length}
                                    options={locationOptions}
                                    handleChange={handleLocationChange}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="flex items-center justify-between py-2">
                          <div className="flex items-center">
                            <label
                              className={`font-semibold whitespace-nowrap ${viewByOptions.length <= 1 ? "2xl:ml-4" : ""
                                }`}
                            >
                              Period :
                            </label>
                            <div className="w-16">
                              <SelectInput
                                defaultValue="CY"
                                onChange={onPeriodTypeChange}
                                value={selectedPeriodType}
                                options={PeriodTypeOptions}
                              />
                            </div>
                            <div className="w-[100px]">
                              <SelectInput
                                defaultValue="yearly"
                                onChange={onPeriodChange}
                                value={selectedViewOption}
                                options={PeriodOptions}
                              />
                            </div>
                            <div>
                              {selectedViewOption !== "yearly"
                                ? 
                                // selectedPeriodType === "FY"
                                //   ? <FyPeriodRangePicker
                                //     value={dateRange}
                                //     onChange={onDateRange}
                                //     period={selectedViewOption}
                                //     periodType={selectedPeriodType}
                                //   />
                                //   : 
                                  <PeriodRangePicker
                                  key={dateRange}
                                    value={dateRange}
                                    onChange={onDateRange}
                                    period={selectedViewOption}
                                    periodType={selectedPeriodType}
                                  />
                                : <MonthRangePicker
                                  value={dateRange}
                                  onChange={onDateRange}
                                  isReports={true}
                                />}
                            </div>

                            {viewByOptions.length <= 1 ? null : (
                              <div className="flex items-center ml-4">
                                <div className="font-semibold whitespace-nowrap">
                                  View By :
                                </div>
                                <div>
                                  <SelectInput
                                    onChange={onFilterViewChange}
                                    value={selectedFilterViewOption}
                                    options={viewByOptions}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={viewByOptions.length > 1? "hidden md:flex gap-4": "2xl:hidden md:flex hidden gap-4"}>
                            <button
                              type="submit"
                              disabled={isLoading}
                              className={`font-sans_bold_body font-medium bg-[#BC8B20] px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-[#a37517] rounded-md ${isLoading?"opacity-50":""}`}
                            >
                              Filter
                            </button>
                            <button
                              type="reset"
                              className="font-sans_bold_body font-medium  bg-[#FFFFFF] px-4 py-1 text-base border border-gray-300 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none rounded-md"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                        {viewByOptions.length <= 1 && (
                          <div className="hidden 2xl:flex gap-4">
                            <button
                              type="submit"
                              className="font-sans_bold_body font-medium bg-[#BC8B20] px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-[#a37517] rounded-md"
                            >
                              Filter
                            </button>
                            <button
                              type="reset"
                              className="font-sans_bold_body font-medium  bg-[#FFFFFF] px-4 py-1 text-base border border-gray-300 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none rounded-md"
                            >
                              Reset
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                
                <div className="flex  space-x-5 md:justify-end justify-between items-center align-bottom px-3 md:px-6 my-4">
                    
                       {/* {viewByOptions.length > 1 ? */}
                        <div className={"md:hidden flex gap-4"}>
                            <button
                              type="submit"
                              disabled={isLoading}
                              onClick={applyFilter}
                              className={`font-sans_bold_body font-medium bg-[#BC8B20] px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-[#a37517] rounded-md ${isLoading?"opacity-50":""}`}
                            >
                              Filter
                            </button>
                            <button
                              type="reset"
                              onClick={(e)=>resetFilter(e,openTab)}
                              className="font-sans_bold_body font-medium  bg-[#FFFFFF] px-4 py-1 text-base border border-gray-300 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none rounded-md"
                            >
                              Reset
                            </button>
                        </div>
                        {/* :null} */}
                        {dataSet?.length >0 &&
                          <div className="flex md:space-x-5 space-x-3 md:justify-end justify-between align-bottom">
                      {!isRenderingInitial && 
                        <>
                          <Tooltip title="Charts" trigger={['hover','click']}>
                            <span                           
                              onClick={(e) => {
                                e.preventDefault();
                                // if(isRenderingInitial){
                                //   setOpenTab(1);
                                // }                                
                                setOpenTab(1);                         
                                setShowChartType(true)
                              }}
                            >
                              <button>
                                <img
                                  src={statistics}
                                  alt=""
                                  className={`w-[27px] h-[30px] ${openTab === 1 && showChartType && chartType =='' ? 'share-btn-color' :''}`}
                                />
                              </button>                            
                            </span>
                          </Tooltip>
                          {openTab === 1 && showChartType && !isRenderingInitial &&
                          <>
                          <Tooltip title="Bar Chart" trigger={['hover','click']}>
                            <span                           
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(1);
                                handleToggle('bar')
                              }}
                            >
                              <button>
                                <img
                                  src={chartType=='bar' ? chartDark :chartLite}
                                  alt=""
                                  className="w-[25px] h-[26px]"
                                />
                              </button>
                            </span>
                          </Tooltip>
                          <Tooltip title="Pie Chart" trigger={['hover','click']} mouseLeaveDelay={0.01}>
                            <span                      
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(1);
                                handleToggle('pie')
                              }}
                            >
                            {chartType=='pie' ?
                              <PiechartIconActive />
                              :
                              <PiechartIcon />
                            }
                            </span>
                          </Tooltip>
                          </>
                          }
                          <Tooltip title="Table" trigger={['hover','click']} mouseLeaveDelay={0.01}>
                            <span
                              // className={
                              //   "" +
                              //   (openTab === 3
                              //     ? "opacity-75 "
                              //     : "opacity-25 hover:opacity-100 ")
                              // }
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(3);
                              }}
                            >
                              <button
                                id="tableIcon"
                                className="inline-block font-Medium text-[#333333] text-[Sequel Sans] leading-[22px] space-x-6
                        dark:text-[#333333] dark:border-blue-500 items-center flex text-lg text-[18px]"
                                aria-current="page"
                              >
                                <img
                                  src={openTab === 3 ? tableDark : tableLite}
                                  alt=""
                                  className="w-[25px] h-[28px]"
                                />
                              </button>
                            </span>
                          </Tooltip>
                        </>
                      }
                        
                        {isTrialExport=='false' || isStudent =='true' ? <></> :
                        <Tooltip placement="topLeft" title="Export" trigger={['hover','click']}>
                          <div onClick={()=>{setIsDownloading(true);exportFile()}} className="cursor-pointer">
                            <img
                              src={isDownloading ?  Xls: XlsBlur}
                              alt=""
                              className="w-[25px] h-[27px]"
                            />
                          </div>
                        </Tooltip> 
                        } 
                        </div> 
                        }                                
                </div>
                           
                {openTab === 1 &&  activeTab===1 &&
                <div className={`mb-8 pb-4 ${openTab === 1 ? "md:flex space-y-4 md:space-y-0 m-3 md:m-0" : "hidden"} ${dataSet?.length >0 ? '' :'mt-[2rem]'}`}>
                  {resultSet && isRenderingInitial ? (
                    <div className="flex flex-col lg:flex-row bg-[#ffffff] gap-4   md:pr-7 md:py-7 md:pl-5 pr-2 py-2 pl-2 xl:py-7 xl:pr-7 xl:pl-5 w-full">
                        <div ref={tableRef} className='bg-[#ebebeb] border border-[#ebebeb]  shadow  rounded block w-[100%] lg:w-[50%] xl:w-[50%]'>
                          {/* <h3 className='m-3 border-b-2 border-b-primaryColor w-fit'> Table View </h3> */}                    
                          <TableTabTable
                            columnData={tableColumns}
                            rowData={tableData}
                            isLoading={isLoading}
                            pvConfig={pvConfig}
                            masterData={masterData?.resultSet?.rawData() ?? []}
                            resultSet={resultSet}
                            isRenderingInitial={isRenderingInitial}
                          />
                        </div>
                        <div className="w-[100%] lg:w-[50%] xl:w-[50%] border-[#ebebeb]">
                          <ReportsChartMixed
                            columnData={tableColumns}
                            rowData={tableData}
                            isLoading={isLoading}
                            isRenderingInitial={resultSet && isRenderingInitial}
                            pvConfig={pvConfig}
                            masterData={masterData?.resultSet?.rawData() ?? []}
                            resultSet={resultSet}
                            reportsFilter={reportsFilter}
                            dataColors={
                              '["#BC8B20", "#474747","#5284df","#6db685","#22A699","#F2BE22", "#F29727","#F24C3D"]'
                            }
                          />
                        </div>
                      </div>
                  ) : resultSet && !isRenderingInitial && chartType == "bar" ? (
                    <>
                      {" "}
                      <BarChart
                        reportsFilter={reportsFilter}
                        resultSet={resultSet}
                        chartFor={"amount"}
                        masterData={masterData?.resultSet?.rawData()}
                        dataColors={
                          '["#5284df","#6db685","#22A699","#F2BE22", "#F29727","#F24C3D"]'
                        }
                        title="Deal Amount"
                      />
                      <BarChart
                      reportsFilter={reportsFilter}
                        resultSet={resultSet}
                        masterData={masterData?.resultSet?.rawData()}
                        chartFor={"deal"}
                        dataColors={
                          '["#5284df","#6db685","#22A699","#F2BE22", "#F29727","#F24C3D"]'
                        }
                        title="No of Deals"
                      />
                    </>
                  )
                    : resultSet && !isRenderingInitial && chartType == "pie" ? (
                      <>
                        {" "}
                        <PieChart
                        reportsFilter={reportsFilter}
                          resultSet={resultSet}
                          chartFor={"amount"}
                          dataColors={
                            '["#5284df","#6db685","#22A699","#F2BE22", "#F29727","#F24C3D"]'
                          }
                          title="Deal Amount"
                        />
                        <PieChart
                        reportsFilter={reportsFilter}
                          resultSet={resultSet}
                          chartFor={"deal"}
                          dataColors={
                            '["#5284df","#6db685","#22A699","#F2BE22", "#F29727","#F24C3D"]'
                          }
                          title="No of Deals"
                        />
                      </>
                    )
                      : isRenderingInitial ? (
                        <div className='bg-white border shadow rounded block w-full border-gray-100 md:ml-3 md:mr-3'>
                          <h3 className='m-3 border-b-2 border-b-primaryColor w-fit'> {'Year on year'} </h3>
                          <div className='bg-white w-full flex items-center justify-center h-40'>
                            <Loader />
                          </div>
                        </div>
                      )
                        : !isRenderingInitial && (chartType == "pie" || chartType == "bar") && (
                          <>
                            <div className='bg-white border shadow rounded block w-full border-gray-100 md:ml-3 md:mr-3'>
                              <h3 className='m-3 border-b-2 border-b-primaryColor w-fit'>{'Deal Amount'}</h3>
                              <div className='p-1'>
                                <div className='bg-white w-full flex items-center justify-center h-40'>
                                  <Loader />
                                </div>
                              </div>
                            </div><div className='bg-white border shadow rounded block w-full border-gray-100 md:ml-3 md:mr-3'>
                              <h3 className='m-3 border-b-2 border-b-primaryColor w-fit'>{'No of Deals'}</h3>
                              <div className='p-1'>
                                <div className='bg-white w-full flex items-center justify-center h-40'>
                                  <Loader />
                                </div>
                              </div>
                            </div>
                          </>

                        )
                  }
                </div>
                }
                <div className={`mb-4 mx-3 ${openTab === 3 ? "block" : "hidden"}`}>
                  {isRenderingInitial ? 
                    (
                      <div className="flex flex-col lg:flex-row bg-[#ffffff] gap-4   md:pr-7 md:py-7 md:pl-5 pr-2 py-2 pl-2 xl:py-7 xl:pr-7 xl:pl-5">
                        <div ref={tableRef} className='bg-[#ebebeb] border border-[#ebebeb]  shadow  rounded block w-[100%] lg:w-[50%] xl:w-[50%] border-gray-100'>
                          {/* <h3 className='m-3 border-b-2 border-b-primaryColor w-fit'> Table View </h3> */}                    
                          <TableTabTable
                            columnData={tableColumns}
                            rowData={tableData}
                            isLoading={isLoading}
                            pvConfig={pvConfig}
                            masterData={masterData?.resultSet?.rawData() ?? []}
                            resultSet={resultSet}
                            isRenderingInitial={isRenderingInitial}
                          />
                        </div>
                        <div className="w-[100%] lg:w-[50%] xl:w-[50%] border-[#ebebeb]">
                          <ReportsChartMixed
                            columnData={tableColumns}
                            rowData={tableData}
                            isLoading={isLoading}
                            isRenderingInitial={resultSet && isRenderingInitial}
                            pvConfig={pvConfig}
                            masterData={masterData?.resultSet?.rawData() ?? []}
                            resultSet={resultSet}
                            reportsFilter={reportsFilter}
                            dataColors={
                              '["#BC8B20", "#474747","#5284df","#6db685","#22A699","#F2BE22", "#F29727","#F24C3D"]'
                            }
                          />
                        </div>
                      </div>
                    ) : 
                    (
                      <div ref={tableRef} className='bg-white border shadow rounded block w-full border-gray-100'>
                        {/* <h3 className='m-3 border-b-2 border-b-primaryColor w-fit'> Table View </h3> */}                    
                        <TableTabTable
                          columnData={tableColumns}
                          rowData={tableData}
                          isLoading={isLoading}
                          pvConfig={pvConfig}
                          masterData={masterData?.resultSet?.rawData() ?? []}
                          resultSet={resultSet}
                        />
                      </div>
                    )
                  }
                </div>

                                
              </div>

              {/* <!-- tab 2 --> */}
                {activeTab === 2 && 
              <div className={activeTab === 2 ? "block " : "hidden"}>
                <Archive 
                select={select}
                openTab ={openTab}
                setOpenTab ={setOpenTab}
                accordionClick = {accordionClick}
                />
              </div>
              }
              {/* <!-- tab3 contain table --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsMain;