import React, { useState, useMemo, useEffect, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import xls from "../../../images/download-file-icon.png";
import sorting from "../../../images/up-down-arrow.png";
import companyLogo1 from "../../../images/mindtree-logo copy/png/mindtree-logo copy.png";
import companyLogo2 from "../../../images/mindtree-logo copy/png/mindtree-logo copy.png";
import companyLogo3 from "../../../images/logo2/png/logo2.png";
import ExcelPopup from "../../../Components/ExcelPopup";
import { ADVISOR_COMPANY_EXITS } from "../../../Queries/Pe";
import moment from "moment"
import { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import CompanyLogo from '../../../Components/CompanyLogo';
import RandomLogo from '../../RandomLogo';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { reExportExcel, advisorExitTable } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import axios from 'axios'
import OverflowToolTip from '../../shared/OverflowToolTip';
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";


/**
 * The `TransactionAdvisorCompaniesExits` function is a React component that displays a table of
 * company exits for a transaction advisor.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TransactionAdvisorCompaniesExits` component. These properties can be accessed using dot notation,
 * such as `props.companyExits` or `props.advisorName`.
 * @returns The function `TransactionAdvisorCompaniesExits` returns a JSX element, which represents the
 * UI component to be rendered on the screen.
 */
const  TransactionAdvisorCompaniesExits = (props) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const [portfolio, setPortfolioData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [openTab, setOpenTab] = useState(1);
  const [searchParams] = useSearchParams();
  let advisorType = "L";
  const { companyId } = useParams();
  let advisorProfileId = parseInt(companyId)    
  const location = useLocation()
  const { pathname, state } = useLocation();
  const [showXlDownload, setshowXlDownload] = useState(false)
  const{primaryMasterId} =useModule();
  const {isTrialExport,isStudent} = useContext(UserContext)


  const pageNumbers = [];

  for (
    let i = 1;
    i <=
    Math.ceil(portfolio?.length / dataPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
  /**
   * The function `setPage` sets the current page to the specified page number.
   * @param pageNum - The pageNum parameter is the number of the page that you want to set as the
   * current page.
   */
  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
  };
  /* The `customStringSortMemo` constant is a memoized function that is used for sorting string values
  in a table column. It is created using the `useMemo` hook, which memoizes the function so that it
  is only re-computed when its dependencies change. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';
        return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
    }
}, []);

/* The `dealDateSort` constant is a memoized function that is used for sorting the "Deal Date" column
in the table. It is created using the `useMemo` hook, which memoizes the function so that it is only
re-computed when its dependencies change. */
const dealDateSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
  }
}, [])

const reMatch  =matchPath(`${routePathSegments.re}/*`,location.pathname);

  /* The `const columns` is an array of objects that defines the columns for the table in the
  `TransactionAdvisorCompaniesExits` component. Each object represents a column and contains
  properties such as `Header` (the column header text), `accessor` (the key to access the data for
  that column in each row), and `Cell` (a custom component to render the cell content). */
  const columns = useMemo(() => [
    {
      id:"companyName",
      Header: "Company Name",
      accessor: "companyName",
      headerClassName:"w-[23%]",
      className:"w-[24%]",
      Cell: ({ value }) => {
        return <div className="leading-[2rem] font-sans_book_body items-center justify-center"> 
            {value?.logo_path=="default.png" || value?.logo_path=="/default.png" || !value?.logo_path?
                <RandomLogo name={value.name} page="company page"/>
                :
                <CompanyLogo name={value.name} page="company page" logo={value?.logo_path}/>           
            }
            <OverflowToolTip component={<Link className='hover:text-[#BC8B20]' state={{ cameFrom: location.state?.prevPath }} 
            to={reMatch!=null?routeNames.reCompanyById(value?.id)
             :""}>
              {value.name ?? "--"}
            </Link> } />
           </div>
      },
      sortType: customStringSortMemo,
    },
    {
      id:"dealDate",
      Header: "Deal Date",
      accessor: "dealDate",
      className:"w-[10%]",
     
      Cell: (cell) => {
        return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
        to={reMatch!=null?routeNames.reCompanyExitsDealById(cell.value.id,cell.value.dealId)
          :""}>
            {cell?.value?.date !='' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"}
        </Link>
    },
    sortType:dealDateSort
    },
    {
      Header: "Exit Type",
      accessor: "exitType",
      className:"w-[10%]",
     
    },
    {
      Header: "Exit Status",
      accessor: "exitStatus",
      className:"text-left",
      className:"w-[11%]",
    },
    {
      Header: "Acquirer",
      accessor: "acquirer",
      className:"w-[24%]",
    },
   
  ], []);

  /* The `rowData` constant is a memoized function that transforms the `props.companyExits` data into a
  format that can be used by the `DataTable` component. */
  const rowData = useMemo(() => {
    const rows = [];
    {props.companyExits?.length>0 &&
      props.companyExits?.forEach(companies => {
      rows.push({
        companyName: { name: companies?.company, dealId: companies?.deal_id, id: companies?.company_id, logo_path:companies?.logo_path},
        dealDate: {date:companies?.deal_date, dealId: companies?.deal_id, id: companies?.company_id},
        exitType: companies?.exit_type,
        exitStatus: companies?.exit_status,
        acquirer: companies?.acquirer_name,
      })
    });
  }
    setshowXlDownload(rows.length!==0)
    return rows;
  }, [props.companyExits])

  /* The `const initialSort` is a constant that uses the `useMemo` hook to memoize the initial sorting
  configuration for the table in the `TransactionAdvisorCompaniesExits` component. */
  const initialSort = useMemo(() => [
    {
        id: 'dealDate',
        desc: true
    }
], []);
  return (
    <div className=" px-4 bg-[#EDEDED] w-[100%]">
      <div className="bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
        <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
          <div className="flex justify-between mb-3">
            <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] lg:leading-[22px] font-semibold ">
              To Companies - Exits
            </h3>
            {(isTrialExport != 'false' && isStudent == 'false'  && showXlDownload)&&<ExcelDownloader
                url={reExportExcel}
                fileName={Constants.exportNames.exit_investments(props.advisorName)}
                data={{
                  "advisor_type":"T",
                  "section": "exit_investments",
                  "advisor_id": advisorProfileId
               }
            } />}
          </div>
        </div>
        <div className="space-y-6">
          <div className="bg-white pb-2 rounded-[6px]">
            <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
          </div>
        </div>       
      </div>
    </div>
  );
};

export default TransactionAdvisorCompaniesExits;
