import { useState, useMemo, useCallback, useEffect, useContext, useRef } from 'react'
import axios from "axios";
import {  NavLink, useLocation } from 'react-router-dom';
import moment from "moment";
import { sortRows, filterRows} from '../helpers'
import Pagination from '../Pagination'
import { getAdvisorsApi, getPEGlobalSearchCompaniesApi} from '../../Config/config'
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import '../TableView.css'
import { PeFilterContext } from '../../Contexts/PeFilterContext'
import { TableDataContext } from '../../Contexts/TableDataContext'
import CompanyLogo from '../../Components/CompanyLogo';
import RandomLogo from '../RandomLogo';
import { routeNames } from '../../routeSegments';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { UserContext } from '../../Contexts/UserContext';
import Constants from '../../Constants';
import OverflowToolTip from '../shared/OverflowToolTip';
import AddNotePopover from '../shared/AddNotePopover';
import { getSection } from '../../Utils/utils'
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';
import { toast } from 'react-toastify';



const Table = ({ columns, menu, advisor_type,sub_type_master_id}) => {
  

  const { monthPickerValue,  isMonthPickerChanged, setIsMonthPickerChanged,  setIsTagClosed, refineSearchFilters,groupId , resetDealsByFilter } = useContext(PeFilterContext);
  const { rows, setRows, data, setData, isLoad, setTableAdvisorId, setIsLoad, setIsDataLoad, currentPage, setCurrentPage,  setCurrentFilter, setAllDealIds, globalSearchResult} = useContext(TableDataContext);

  const [filters, 
    setFilters
  ] = useState({})
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'advisor_name_sort' })
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null)
  const[reload, setReload] = useState(false)


/**
 * The function `findActivePage` sets the current page, renders a table with the given parameters, and
 * updates the load state.
 */
  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order,groupId)
    setIsLoad(true)
    // setIsDataLoad(false)
  }
  useEffect(()=>{
    console.log("jjjhhk inv" , location?.key)
    resetDealsByFilter();
  },[location?.key])

  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false

  const globalSearchCategory = menu === "pe_vc_advisor" || menu === 'vc_advisor' ? 'investments_ids' : menu === 'pe_vc_exit' || menu === 'vc_exit' ? 'exits_ids' : '';

 /* The above code is a JavaScript React function that is used to render a table of advisors based on
 various filters and sorting options. */
  const tableRender = useCallback((page, month, column, order,groupId) => {
    setIsMonthPickerChanged(0)
    setIsTagClosed(0)
    // axios(`${getAdvisorsApi}`, {
       
        const category= menu == "l-pe" || menu == "l-vc" || menu == "t-pe" || menu == "t-vc" ? "investments"
          : "investments"

          const dealCategory =  menu == "vc_advisor" ||  menu =="pe_vc_advisor"
          ? "PEVC"
          :  "EXIT"

        console.log(menu, 'menuuuuu');
                      
  
      const sortBy = dealCategory == "PEVC"
        ? "advisor_sort"
        
              : "advisor_sort"

              axios(`${getAdvisorsApi}`, {
                method: "POST",
                signal: abortRef.current.signal,
                data:{
                "category": category,
        // "type": menu == "l-pe" ? "pe_vc_investor" : menu == "vcinvestor" ? "vc_investor"
        //   : menu == "pevcExit" ? "pe_vc_exit" : menu == "maPeExit" ? "pe_ma_exit" : menu == "ma_vc" ? "vc_ma_exit" : menu == "public_pevc" ? "pe_pm_exit" : menu == "public_vc" ? "vc_pm_exit"
        //     : menu == "backedipo" ? "pe_vc_ipo" : menu == "vcbackedipo" ? "vc_ipo"

        //       : "",
        "type": menu == "pe_vc_advisor" ? "pe_vc_advisor" : menu == "vc_advisor" ? "vc_advisor" : menu == "pe_vc_ma_advisor" ? "pe_vc_ma_advisor" : menu == "vc_ma_advisor"?"vc_ma_advisor": menu == "vc_exit" ? "vc_exit_advisor" : menu == "pe_vc_pm_advisor"?"pe_vc_pm_advisor": menu == "pe_vc_exit" ? "pe_exit_advisor" : menu == "vc_pm_advisor" ? "vc_pm_advisor"
          : "",
        from: isFromGlobalSearch() ? "gs" : "",
        "advisor_type": advisor_type,
        date_range: isFromGlobalSearch()
                    ? {
                        from_date: globalSearchResult?.date_range?.from_date,
                        to_date: globalSearchResult?.date_range?.to_date,
                      }
                    : {
                        from_date: moment(month[0]).format("YYYY-MM-DD"),
                        to_date: moment(month[1]).format("YYYY-MM-DD"),
                      },
        "order_by": {
          "column": column,
          "direction": order
        },
        "page": page,
        advisor_ids: isFromGlobalSearch()
          ? globalSearchResult?.t_advisor?.[globalSearchCategory]?.length !== 0
            ? globalSearchResult?.t_advisor?.[globalSearchCategory]
            : [0]
          : refineSearchFilters[location.pathname]?.globalSearch?.at(0)?.value,
        "industry": refineSearchFilters[location.pathname]?.industry?.map(value => value.id), //Industries //[1],
        "sector": refineSearchFilters[location.pathname]?.sector?.map(value => value.id), //Industries //[1],
        "stage": refineSearchFilters[location.pathname]?.stage?.map(value => value.id),
        "round": refineSearchFilters[location.pathname]?.round?.map(value => value.id),
        "year_founded": refineSearchFilters[location.pathname]?.yearFounded?.at(0) && {
          "from": refineSearchFilters[location.pathname]?.yearFounded[0].name,
          "to": refineSearchFilters[location.pathname]?.yearFounded[1].name
        },
        "syndication": refineSearchFilters[location.pathname]?.syndication?.at(0)?.value ? [refineSearchFilters[location.pathname]?.syndication?.at(0)?.value] : [],
        "deals_involving": refineSearchFilters[location.pathname]?.dealsInvolving?.map(deal => deal.value),
        "country": refineSearchFilters[location.pathname]?.countries?.map(country => country.id),
        "investor_type": refineSearchFilters[location.pathname]?.investorType?.map(inv => inv.id),
        "region": refineSearchFilters[location.pathname]?.region?.map(region => region.id),
        "state": refineSearchFilters[location.pathname]?.state?.map(state => state.id),
        "city": refineSearchFilters[location.pathname]?.city?.map(city => city.id),
        "firm_type": refineSearchFilters[location.pathname]?.firmType?.map(firm => firm.id),
        "followOnFundStatus": refineSearchFilters[location.pathname]?.followexitStatus?.map(status => status.id),
        "exited": refineSearchFilters[location.pathname]?.exited?.map(status => status.id),
        "deal_investor_range": (refineSearchFilters[location.pathname]?.dealInvestorRange?.length ?? 0) != 0 ? {
          "from": refineSearchFilters[location.pathname]?.dealInvestorRange?.at(0)?.name,
          "to": refineSearchFilters[location.pathname]?.dealInvestorRange?.at(1)?.name,
        } : null,
        deals_operator : refineSearchFilters[location.pathname]?.deals_operator,
        company_ids : refineSearchFilters[location.pathname]?.target_company?.map(company => company.id),
        investor_ids : refineSearchFilters[location.pathname]?.investor_id?.map(acq => acq.id) ,
        advisor_ids : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.tadvisors?.map(acq => acq.id)  ,
        "deal_range": refineSearchFilters[location.pathname]?.dealRange?.at(0) && {
          "from": refineSearchFilters[location.pathname]?.dealRange[0].name,
          "to": refineSearchFilters[location.pathname]?.dealRange[1].name
        },
        exit_status: refineSearchFilters[location.pathname]?.exitStatus?.map(status => status.id),
        tag: refineSearchFilters[location.pathname]?.tags?.length != 0 ? {
          operator: refineSearchFilters[location.pathname]?.operator,
          data: refineSearchFilters[location.pathname]?.tags?.map(tag => tag.name)
        } : null,
        group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
    }
      },
     
      headers: {

        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response?.data?.message == "Success") {
          setCurrentFilter(response.config.data)
          console.log(response?.data, "axios api response")
          // setRows([])
          setIsLoad(false)
          setIsDataLoad(false)
          setIsMonthPickerChanged(0)
          setRows([])
          setRows(response?.data?.advisors)
          setData(response?.data)
          setTableAdvisorId([])
          setTableAdvisorId(response?.data?.all_advisor_ids)
          setAllDealIds(response?.data?.all_deal_ids)
        }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
          logout();
        }
        console.log("API not working", error);
      });
  }, [isMonthPickerChanged, refineSearchFilters]);
  // console.log(rows,"axios api response")
  // console.log(data,"jsonn")


  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage)
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order,groupId)
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    return () => {
      abortController.abort();
    }
  }, [tableRender, monthPickerValue, refineSearchFilters,groupId,reload]);



  // const addNote = (id) => {
  //   setShowNote(!showNote)
  //   setShowId(id)
  // }
  // const rowsPerPage = rowsPerPageData

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  const calculatedRows = sortedRows
  // console.log(calculatedRows, "pagination")
  const count = filteredRows.length

  // if(isMonthPickerChanged == 1 || isTagClosed == 1){
  //   console.log(monthPickerValue,"monthPickerValue")
  //   tableRender(1,monthPickerValue,orderByTAdvisor,advisororderingColumn)
  //   setActivePage(1)
  //  }

/**
 * The handleSort function is used to update the sorting order and column in a table, and trigger a
 * table render with the updated sorting parameters.
 */
  const handleSort = (accessor, order) => {
    setRows([])
    setCurrentPage(currentPage)
    setIsDataLoad(true)
    setIsLoad(true);
    setSort((prevSort) => ({
      order: prevSort.order == 'asc' && prevSort.orderBy == accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(currentPage, monthPickerValue, accessor, sort.order == 'asc' && sort.orderBy == accessor ? 'desc' : 'asc',groupId)
  }

  var location = useLocation();

/**
 * The function adds commas to a number to improve readability.
 * @returns The function `addCommasToNumber` returns a string with commas added to the number.
 */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] "+(rows.length > 5 ? '':'pr-2')}>
              <table className="listPage table w-full p-1 bg-grey-50 divide-y">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-sans_semi_bold text-[#666666]  text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor == sort.orderBy) {
                          if (sort.order == 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (
                        <th className={ i==0 ? "md:pl-5 md:pr-5 pl-5 pr-[8rem] lg:pr-[13rem] text-left bg-[#EBEBEB] sticky left-0 lg:relative":"px-5 " +(" py-2 font-sans_book_body")} key={i}>
                          <span className='flex whitespace-nowrap'><OverflowToolTip component={<a className='hover:text-[#000000]'>{column.accessor == "add" && groupId?.myList?.length > 0 ? "Delete": column.label ? column.label : "--"}</a>} />
                            {column.accessor == "add" ? "" :
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                            }</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[11pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333] text-left">
                      {rows.length > 0 ?
                        rows?.map((row, i) => {
                          return (
                            <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                              <td className="w-[7%] md:w-[10%] lg:w-[10%] xl:w-[10%] 2xl:w-[10%]  px-5 py-3 text-left sticky left-0 lg:relative  text-[#333333] font-medium  bg-white  font-sans_book_body items-center justify-center card-animate leading-[1.8rem] lg:leading-[2rem]" style={{whiteSpace: "initial"}}>
                                {row?.logo_path == "default.png" || row?.logo_path == "/default.png" || !row?.logo_path ?
                                  <RandomLogo name={row?.advisor_name} />
                                  :
                                  <CompanyLogo name={row?.advisor_name} id={row?.advisor_id} page="company page" logo={row?.logo_path} />
                                }
                                <OverflowToolTip className="w-[14rem] lg:w-auto lg:max-w-full" component={<NavLink className=' text-start flex lg:inline hover:text-[#BC8B20]' state={{ prevPath: location.pathname }}
                                  to={{
                                    pathname: menu == "vc_advisor" || menu == "vc_ma_advisor" || menu == "vc_pm_advisor" || menu == "vc_exit"
                                      ? routeNames.vcTransactionAdvisorById(row.advisor_id)
                                      : routeNames.peTransactionAdvisorById(row.advisor_id),
                                    search: getSection(menu == "vc_advisor" || menu == "pe_vc_advisor"
                                      ? Constants.primaryMasterSubIds.pe_vc_advisors_id
                                      : menu == "vc_exit" || menu == "pe_vc_exit"
                                        ? Constants.primaryMasterSubIds.pe_vc_advisors_exits_id
                                        : menu == "vc_ma_advisor" || menu == "pe_vc_ma_advisor"
                                          ? Constants.primaryMasterSubIds.pe_vc_advisors_ma_id
                                          : Constants.primaryMasterSubIds.pe_vc_advisors_pm_id)
                                  }} >
                                  <a className='hover:text-[#BC8B20] text-[#333333]'>{row.advisor_name ? row.advisor_name : "--"}</a>
                                </NavLink> } />
                              </td>
                              <td className="w-[5%] px-5 py-3 text-center pr-[2.8rem] md:pr-[3rem] font-sans_book_body"><a >{row.to_company_investments !=0 ? addCommasToNumber(row.to_company_investments) : "--"}</a></td>
                              <td className="w-[4%] px-5 py-3 text-center pr-[2.8rem] md:pr-[3rem] font-sans_book_body " > <a >{row.to_company_exits !=0 ? addCommasToNumber(row.to_company_exits) : "--"}</a></td>
                              <td className="w-[2%] px-5 py-3 text-center pr-[2.8rem]  font-sans_book_body" > <a >{row.to_investors !=0 ? addCommasToNumber(row.to_investors) : "--"}</a></td>
                              <td className="w-[2%] px-5 py-3 text-center pr-[2.8rem] md:pr-[3rem] font-sans_book_body"  ><a >{row.to_acquirers !=0 ? addCommasToNumber(row.to_acquirers) : "--"}</a></td>
                              <td className="w-[1%] px-[10px] py-3 font-sans_book_body " >
                              {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.t_advisor_id} setReload={setReload}/>
                                 :
                                <AddNotePopover className="mx-3" key={row?.advisor_id} companyId={row?.advisor_id} companyName={row.advisor_name} type_id={Constants.moduleIds.t_advisor_id} sub_type_master_id={sub_type_master_id}/>
                              }
                              </td>
                            </tr>
                          )
                        })
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                      }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>
              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='pr-2'>
                    <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                      <NoDataPlaceholder />
                    </div>
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {data?.num_advisors && rows.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.num_advisors}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />

      ) : (
        ""
      )}

      {/* <div>
          <p>
            <button onClick={clearAll}>Clear all</button>
          </p>
        </div> */}
    </>
  )
}

export default Table