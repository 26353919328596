import React, {useContext} from 'react'
import { NavLink } from 'react-router-dom'
import { TableDataContext } from '../../Contexts/TableDataContext'
import { PeFilterContext } from '../../Contexts/PeFilterContext';

/** The `NavbarItems` renders a navigation link (`NavLink`) with some
custom styling based on the provided props. */
function NavbarItems({ title, to, isLinkActive , pageTitle}) {
    const {setCurrentPage} =useContext(TableDataContext);
    const {setGroupId} = useContext(PeFilterContext)

    return ( 
        <NavLink
            onClick={() => {setGroupId({});setCurrentPage(1)}}
            to={to}
            className={({ isActive }) => {
                if (isActive || isLinkActive === true) {
                    document.title = pageTitle
                    return "relative top-[8px] mb-[8px] text-[15px] font-bold  text-[#2B2A29]  border-b-[3px] border-[#F1CA75] py-2 menu-item";
                } else {
                    return "relative top-[2px] text-[15px] text-[#2B2A29] font-sans_book_body opacity-[.8] menu-item";
                }
            }}>{title }</NavLink>
    )
}

export default NavbarItems