import React, { useContext } from 'react'
import Layout from '../Components/Layout'
import Card from '../Components/DealDetail/DealCard'
import RefineSearch from '../Components/RefineSearchComponent'
import CompanyDealDetail from '../Components/DealDetail/CompanyDealDetail'
import { COMPANY_PROFILE} from '../Queries/Pe'
import { useLocation, useSearchParams } from 'react-router-dom'
import Constants from '../Constants'
import InvestmentRefineSearch from '../Components/RefineSearch/InvestmentRefineSearch'
import ExitRefineSearch from '../Components/RefineSearch/ExitRefineSearch'
import BackedIPORefineSearch from '../Components/RefineSearch/BackedIPORefineSearch'
import AngelRefineSearch from '../Components/RefineSearch/AngelRefineSearch'
import IncubationRefineSearch from '../Components/RefineSearch/IncubationRefineSearch'
import { routeNames } from '../routeSegments'
import SubscribeProductPage from '../Components/shared/SubscribeProductPage'
import { UserContext } from '../Contexts/UserContext'

/**
 * The DealPage component renders a layout with a primary and secondary column, displaying a company
 * deal detail and a refine search component respectively.
 * @returns The `DealPage` component is returning a JSX element.
 */
const DealPage = ({menu, subMenu}) => {

  const { isDirectoryUser } = useContext(UserContext);
  const location = useLocation()

  const getRefineSearchFilters = (subMenu) => {
    switch (menu){
        case 'pe': {
          switch (subMenu) {
            case 'pe_vc_investments':
              return <RefineSearch><InvestmentRefineSearch customPathname={routeNames.PE_home} /></RefineSearch>;
            case 'exits':
              return <RefineSearch><ExitRefineSearch customPathname={routeNames.pe_companies_exists_pe_vc_investments} /></RefineSearch>;
            case 'ipos':
              return <RefineSearch><BackedIPORefineSearch customPathname={routeNames.pe_companies_backed_ipos_pe_vc_investments} /></RefineSearch>;
            case 'angel':
              return <RefineSearch><AngelRefineSearch customPathname={routeNames.pe_companies_angel_investments} /></RefineSearch>;
            case 'incubation':
              return <RefineSearch><IncubationRefineSearch customPathname={routeNames.pe_companies_incubation} /></RefineSearch>;
            default:
              return <RefineSearch><InvestmentRefineSearch customPathname={routeNames.PE_home} /></RefineSearch>;
          }
        }
        case 'vc': {
          switch (subMenu) {
            case 'pe_vc_investments':
              return <RefineSearch><InvestmentRefineSearch customPathname={routeNames.vc_companies_vc_investments} /></RefineSearch>;
            case 'exits':
              return <RefineSearch><ExitRefineSearch customPathname={routeNames.vc_companies_exists_vc_investments} /></RefineSearch>;
            case 'ipos':
              return <RefineSearch><BackedIPORefineSearch customPathname={routeNames.vc_companies_backed_ipos_vc_investments} /></RefineSearch>;
            case 'angel':
              return <RefineSearch><AngelRefineSearch customPathname={routeNames.vc_companies_angel_investments} /></RefineSearch>;
            case 'incubation':
              return <RefineSearch><IncubationRefineSearch customPathname={routeNames.vc_companies_incubation} /></RefineSearch>;
            default:
              return <RefineSearch><InvestmentRefineSearch customPathname={routeNames.vc_companies_vc_investments} /></RefineSearch>;
        }
      }
    }
  }

        return (
          <div>
            <Layout>
              {isDirectoryUser ? 
              <SubscribeProductPage to={location.state !== null ? location.state.cameFrom : '/'}/> 
              :
              <div className="flex h-screen flex-col">
                <Card />
                <div className="flex min-h-0 flex-1 overflow-hidden">
                  {/* Primary column */}
                  <section
                    aria-labelledby="primary-heading"
                    className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
                  >
                    <CompanyDealDetail
                      COMPANY_PROFILE={COMPANY_PROFILE}
                      menu={menu}
                    />

                    {/* <Outlet /> */}
                    {/* Your content */}
                  </section>
                  {/* Secondary column (hidden on smaller screens) */}
                  <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                    <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                      {/* Your content */}
                      {getRefineSearchFilters(subMenu)}
                    </div>
                  </aside>
                </div>
                {/* <div className="fixed  right-1 bottom-0">          
                    <img className="float-right w-[4rem] h-[4rem] cursor-pointer" src={chatPopupIcon} /> 
                    </div>  */}
                <br />
              </div>}
            </Layout>
          </div>
        );
}

export default DealPage
