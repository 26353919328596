import React, { useState, useMemo, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import xls from '../../../images/download-file-icon.png'
import sorting from '../../../images/up-down-arrow.png'
import companyLogo1 from '../../../images/mindtree-logo copy/png/mindtree-logo copy.png'
import companyLogo2 from '../../../images/mindtree-logo copy/png/mindtree-logo copy.png'
import companyLogo3 from '../../../images/logo2/png/logo2.png'
// import { T_ADVISOR_COMPANY_TOACQUIRER } from "../../../Queries/Pe";
import moment from "moment";
import ExcelPopup from '../../ExcelPopup'
import routePathSegments,{ routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import CompanyLogo from "../../CompanyLogo";
import RandomLogo from '../../RandomLogo';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import OverflowToolTip from '../../shared/OverflowToolTip';
import useModule from "../../../hooks/useModule";
import { propTypesSelected } from "@material-tailwind/react/types/components/select";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";
import ConfirmPopup from "../../../Utils/ConfirmPopup";

const TransactionAdvisorTo_Acquirer = (props) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const [portfolio, setPortfolioData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [showXlDownload, setshowXlDownload] = useState(false);

  const [openTab, setOpenTab] = useState(1);

  const { companyId } = useParams();
    let advisorProfileId = parseInt(companyId)
  const location = useLocation();
  const{primaryMasterId} = useModule();
  const {isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext);
  const {toAcquirersDataFound} = useContext(DataFoundContext);

  let advisorType = "T";
  //    const { investorId } = useParams();
  //   let companyProfileId = parseInt(investorId)
  /**
   * The function handles the callback for a fetch request to retrieve portfolio data and sets the
   * response data to the portfolio data.
   * @param response - The response parameter is the data returned from the fetch request. It could be
   * in various formats such as JSON, XML, or plain text, depending on the server's response.
   * @param requestSuccess - The `requestSuccess` parameter is a callback function that is called when
   * the fetch request is successful. It is typically used to handle any additional logic or actions
   * that need to be performed after the request is successfully completed.
   */
  const handleFetchPortfoliotRequestCallback = (response, requestSuccess) => {
    try {
      setPortfolioData(response);
    } catch (e) {
      console.log("Error", e);
    }
  };

  // const { data, loading, error } = useQuery(T_ADVISOR_COMPANY_TOACQUIRER, {
  //   variables: { CIAId: advisorIdPE, AdvisorType: advisorType },
  //   onCompleted: (response) =>
  //     handleFetchPortfoliotRequestCallback(response, true),
  //   onError: (response) => handleFetchPortfoliotRequestCallback(response),
  //   refetch:  T_ADVISOR_COMPANY_TOACQUIRER,
  // });
  // console.log("portfolio1",  T_ADVISOR_COMPANY_TOACQUIRER);

  // const PortfolioData = useMemo(() => portfolio, [portfolio]);
  // console.log("Tadivisor to acquirer", PortfolioData);

  // const indexOfLastPost = currentPage * dataPerPage;
  // const indexOfFirstPost = indexOfLastPost - dataPerPage;
  // const currentData = PortfolioData?.advisor?.advisorAcquirer?.slice(
  //   indexOfFirstPost,
  //   indexOfLastPost
  // );
  // console.log(currentData, "currentData");
  // alert(currentData)

  const pageNumbers = [];

  for (
    let i = 1;
    i <=
    Math.ceil(portfolio?.length / dataPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
  /**
   * The setPage function sets the current page to the specified page number.
   * @param pageNum - The pageNum parameter is the number of the page that you want to set as the
   * current page.
   */
  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
  };

 /* The above code is defining a custom sorting function called `customStringSortMemo` using the
 `useMemo` hook in React. This function takes in two rows (`rowA` and `rowB`), a column ID, and a
 boolean value `desc` indicating whether the sorting should be in descending order. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';
        return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
    }
}, []);

/* The `dealDateSort` constant is a custom sort function that is used to sort the "Deal Date" column in
the table. It is created using the `useMemo` hook to memoize the function so that it is only
recalculated when its dependencies change. */
const dealDateSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
  }
}, [])

const peMatch  =matchPath(`${routePathSegments.pe}/*`,location.pathname);

/**
 * The addCommasToNumber function takes a number as input and returns a string representation of the
 * number with commas added for thousands separators.
 * @param number - The `number` parameter is the number that you want to add commas to.
 * @returns The function `addCommasToNumber` returns a string representation of the input number with
 * commas added to separate thousands.
 */
const addCommasToNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

 /* The above code is defining an array of columns for a table. Each column has properties such as id,
 Header (column header text), accessor (data accessor for the column), className (CSS classes for
 the column), headerClassName (CSS classes for the column header), Cell (rendering component for the
 cell), and sortType (custom sort function for the column). The columns array is created using the
 useMemo hook, which memoizes the array so that it is only recalculated when its dependencies
 change. */
  const columns = useMemo(() => [
    {
      id:"acquirerName",
      Header: "Acquirer Name",
      accessor: "acquirerName",
      className: "clickable lg:relative text-left sticky left-0  bg-white",
      headerClassName: "w-[16%] lg:relative text-left sticky left-0   w-[3rem] bg-[#EDEDED] ",
      Cell: ({ value }) => {
        return <div className=" leading-[1.8rem] lg:leading-[2rem] font-sans_book_body items-center justify-center">
         {value?.logo_path=="default.png" || value?.logo_path=="/default.png" || !value?.logo_path?
                <RandomLogo name={value.name} page="company page"/>
                :
                <CompanyLogo name={value.name} page="company page" logo={value?.logo_path}/>           
            }          
        <OverflowToolTip className="w-[10rem] lg:w-auto" component={<Link className=' text-start flex lg:inline hover:text-[#BC8B20]'
          state={{ cameFrom: location.state?.prevPath }}
          to={routeNames.maAcquiresById(value?.id)}>
          {value.name ?? "--"}
        </Link> } />
        </div>
      },
      sortType: customStringSortMemo,
    },
    {
      id: 'dealDate',
      Header: "Deal Date",
      accessor: "dealDate",
      headerClassName:"w-[10%]",
      Cell: (cell) => {
        return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
        onClick={(e) => {
          if (isDirectoryUser) {
              e.preventDefault();
              e.stopPropagation();
              onBlurColumnClick();
          }
        }}
        to={peMatch != null ?routeNames.peCompanyInvestmentDealById(cell.value.id,cell.value.dealId)
          :routeNames.vcCompanyInvestmentDealById(cell.value.id,cell.value.dealId)
       }>
            {cell?.value?.date !='' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"}
  
        </Link>
    },
    sortType:dealDateSort
    },
    {
      Header: "Deal Amount (US$ M)",
      accessor: "dealAmountInUS",
      className:"px-0 text-right pr-[2.25rem] w-[10%]",
      headerClassName:"w-[10%]",
      headerspanClassName:"ml-auto",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    },
    {
      Header: "Company Name",
      accessor: "companyName",
      headerClassName:"w-[15%]",
      Cell: ({ value }) => {
        return <Link className='hover:text-[#BC8B20]'
          state={{ cameFrom: location.state?.prevPath }}
          to={routeNames.peCompanyFundById(value?.id)}>
          {value.name ?? "--"}
        </Link>
      },
      sortType: customStringSortMemo,
    },
    {
      Header: "Post Money Valuation (INR Cr)",
      accessor: "postMoney",
      className:"px-0 text-right pr-[2.25rem] w-[15%]",
      headerClassName:"w-[8%]",
      headerspanClassName:"ml-auto",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    },
    {
      Header: "Revenue Multiple",
      accessor: "revenueMultiple",
      className:"px-0 text-right pr-[2.25rem] w-[10%]",
      headerClassName:"w-[8%]",
      headerspanClassName:"ml-auto",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    }
  ], []);

  /* The `rowData` constant is using the `useMemo` hook to memoize the data that will be displayed in
  the table. It iterates over the `props.toAquirer` array and creates an object for each company.
  Each object contains the necessary data for each column in the table, such as the acquirer name,
  deal date, deal amount, company name, post money valuation, and revenue multiple. */
  const rowData = useMemo(() => {
    const rows = [];

    props.toAquirer?.forEach(company => {
      rows.push({
        acquirerName: { name: company?.Acquirer_Name, id: company?.AcquirerId, logo_path:company?.icon_path},
        dealDate: {date:company?.Deal_Date, dealId: company?.DealId, id: company?.CompanyId},
        dealAmountInUS: company?.Deal_Amount > 0 ? company?.Deal_Amount : "",
        companyName: { name: company?.Companyname, id: company?.CompanyId },
        postMoney: company?.Post_Money_Valuation > 0 ? company?.Post_Money_Valuation:'',
        revenueMultiple: company?.Revenue_Multiple >0 ? company?.Revenue_Multiple :'',
      })
    });
    setshowXlDownload(rows.length!=0)
    return rows;
  }, [props.toAquirer])

  const { pathname, state } = useLocation();
    const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, state?.prevPath ?? "")
  /* The `initialSort` constant is using the `useMemo` hook to memoize an array of objects. Each object
  represents a column in the table and specifies the initial sorting configuration for that column.
  In this case, the `dealDate` column is being sorted in descending order (`desc: true`) as the
  initial sort configuration. */
    const initialSort = useMemo(() => [
      {
        id: 'dealDate',
          desc: true
      }
  ], []);

  const [alert, setalert] = useState({ message: "", show: false });

  const onBlurColumnClick = () => {
          setalert({ show: true, message: Constants.maSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
  }

  const onConfirmation = () => {
          setalert({ show: false, message: "" })
  }

    return (
      <>
        {toAcquirersDataFound ? (
          <div className=" px-4 bg-[#EDEDED] w-[100%]">
            <div className="  bg-white border  overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
              <div className=" pt-4 px-5 border-b border-b-[#F1F1F1] ">
                <div className="flex justify-between mb-3">
                  <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:leading-[22px] font-semibold lg:text-[17px]">
                    To Acquirers
                  </h3>
                  {!isDirectoryUser ? (isStudent == 'false' && isTrialExport !='false' && showXlDownload)&&<ExcelDownloader
                                  url={exportExcel}
                                  fileName={Constants.exportNames.to_acuirers(props.advisorName)}
                                  data={{
                                    "type":primaryMasterId,
                                    "export_from": "detail",
                                    "module_type": "advisors",
                                    "advisor_type":"T",
                                    "section": "to_acquirers",
                                    "advisor_id": [advisorProfileId]
                                }} />: ""}
                  </div>
              </div>
              <div className="space-y-6">
                  <div className="bg-white pb-2 rounded-[6px]">
                    <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
                  </div>
              </div>        
            </div>
          </div>
        ) : ""}
        {<ConfirmPopup {...alert} onChange={onConfirmation} />}
      </>
    );
};

export default TransactionAdvisorTo_Acquirer;