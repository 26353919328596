import { generatePath } from "react-router-dom";


/**
 routePathSegments to construct `routeNames`
 */
const routePathSegments = {
    pe: "pe",
    vc: "vc",
    re: "re",
    companies: "companies",
    pe_vc_investments: "pe-vc-investments",
    fillings: "fillings",
    re_investments: "re-investments",
    re_exits: "re-exits",
    re_ipo: "re-ipo",
    vc_investments: "vc-investments",
    angel_invesments: "angel-invesments",
    investors: "investors",
    activeInvestors: "active-investors",
    newInvestors: "new-investors",
    company: "company",
    investor: "investor",
    exists: "exits",
    ipos: "pe-vc-backed-ipos",
    advisors: "advisors",
    advisor: "advisor",
    ma: "ma",
    pm: "pm",
    ma_exits: "ma-exits",
    dashboard: "dashboard",
    backed_ipos: "backed-ipos",
    incubation: "incubation",
    angel: "angel",
    investment: "investment",
    exit: "exit",
    re_exits: "exit",
    ipo: "ipo",
    re_ipo:"re-ipo",
    pe_vc_limited_partners: "pe-vc-limited-partners",
    vc_limited_partners: "vc-limited-partners",
    vc_funds: "vc-funds",
    limited_partners: "limited-partners",
    pe_vc_funds: "pe-vc-funds",
    funds_detail_page: "fund",
    re_funds: "re-funds",
    re_funds_detail: "re-fund",
    pe_vc_advisors: "pe-vc-advisors",
    vc_advisors: "vc-advisors",
    transaction_advisor: "transaction-advisor",
    transaction_advisors: "transaction-advisors",
    other_ma: "other-ma",
    reinvestor: "investor",
    readvisor: "advisor",
    retransaction: "transaction-advisor",
    re_company_page: "re-company-page",
    re_deal_page: "re-deal-page",
    cfs: "cfs",
    faq: "faq",
    pe_faq: "pe-faq",
    re_faq: "re-faq",
    ma_faq: "ma-faq",
    cfs_faq: "cfs-faq",
    setting: "setting",
    notes: "notes",
    reports: "reports",
    incubation_dealpage: "incubation-dealpage",
    acquirers: "acquirers",
    acquirer: "acquirer",
    ma_company_page: "company",
    ma_dealpage: "ma-dealpage",
    ma_advisors: "advisor",
    ma_transaction: "transaction",
    ma_acquirers: "acquirers",
    cfs_company_page: "cfs-company-page",
    login: "login",
    ip_login: "ip-login",
    requests: "requests",
    alerts: "alerts",
    notifications:"notifications",
    raise_request: "raise-request",
    device_authorization: "device-authorization",
    compare: "compare",
    vi_admin:"admin",
    admin_filters:"filters",
    query_builder:"query-builder", 
    coInvestor:"co-investors"  ,
    password:"password",
    reset:"reset" 
}

/**
 routeNames constructed with `routePathSegments` @var routePathSegments 
 */
const routeNames = {
    cfs: `/${routePathSegments.cfs}`,
    faqs: `/${routePathSegments.faq}`,
    pe_faq: `/${routePathSegments.faq}/${routePathSegments.pe_faq}`,
    re_faq: `/${routePathSegments.faq}/${routePathSegments.re_faq}`,
    ma_faq: `/${routePathSegments.faq}/${routePathSegments.ma_faq}`,
    cfs_faq: `/${routePathSegments.faq}/${routePathSegments.cfs_faq}`,
    reports: `/${routePathSegments.reports}`,
    notes: `${routePathSegments.notes}`,
    requests: `${routePathSegments.requests}`,
    alerts: `${routePathSegments.alerts}`,
    notifications: `${routePathSegments.notifications}`,
    login: `/${routePathSegments.login}`,
    ip_login: `/${routePathSegments.ip_login}`,
    device_authorization: `/${routePathSegments.device_authorization}`,
    PE_home: `/${routePathSegments.pe}/${routePathSegments.companies}/${routePathSegments.pe_vc_investments}`,
    //PE Menus
    PE_company: `/${routePathSegments.pe}/${routePathSegments.companies}`,
    PE_investor: `/${routePathSegments.pe}/${routePathSegments.investors}`,
    PE_advisor: `/${routePathSegments.pe}/${routePathSegments.advisors}`,
    PE_transaction_advisor: `/${routePathSegments.pe}/${routePathSegments.transaction_advisors}`,
    //VC Menus
    VC_company: `/${routePathSegments.vc}/${routePathSegments.companies}`,
    VC_investor: `/${routePathSegments.vc}/${routePathSegments.investors}`,
    VC_advisor: `/${routePathSegments.vc}/${routePathSegments.advisors}`,
    VC_transaction_advisor: `/${routePathSegments.vc}/${routePathSegments.transaction_advisors}`,
    //RE Menus
    RE_company: `/${routePathSegments.re}/${routePathSegments.companies}`,
    RE_investor: `/${routePathSegments.re}/${routePathSegments.investors}`,
    RE_advisor: `/${routePathSegments.re}/${routePathSegments.advisors}`,
    RE_transaction_advisor: `/${routePathSegments.re}/${routePathSegments.transaction_advisors}`,
    //MA Menus
    MA_company: `/${routePathSegments.ma}/${routePathSegments.companies}`,
    MA_acquirers: `/${routePathSegments.ma}/${routePathSegments.acquirers}`,
    MA_advisors: `/${routePathSegments.ma}/${routePathSegments.advisors}`,
    MA_transaction_advisor: `/${routePathSegments.ma}/${routePathSegments.transaction_advisors}`,

    maCompanyById: (id) => `/${routePathSegments.ma}/${routePathSegments.ma_company_page}/${id}`,
    maAcquiresById: (id) => `/${routePathSegments.ma}/${routePathSegments.acquirer}/${id}`,
    maAdvisorsById: (id) => `/${routePathSegments.ma}/${routePathSegments.ma_advisors}/${id}`,
    maTransactionAdvisorsById: (id) => `/${routePathSegments.ma}/${routePathSegments.transaction_advisor}/${id}`,
    ma_dealpage: `/${routePathSegments.ma_dealpage}`,
    maCompanyDealById: (id, cid) => `/${routePathSegments.ma}/${routePathSegments.company}/${id}/${cid}`,
    //MA deals
    maCompanyInvestmentDealById: (companyId, dealId) => `/${routePathSegments.ma}/${routePathSegments.company}/${routePathSegments.pe_vc_investments}/${companyId}/${dealId}`,
    maCompanyExitDealById: (companyId, dealId) => `/${routePathSegments.ma}/${routePathSegments.company}/${routePathSegments.exists}/${companyId}/${dealId}`,
    maCompanyAngelDealById: (companyId, dealId) => `/${routePathSegments.ma}/${routePathSegments.company}/${routePathSegments.angel}/${companyId}/${dealId}`,
    maCompanyIncubationDealById: (companyId, dealId) => `/${routePathSegments.ma}/${routePathSegments.company}/${routePathSegments.incubation}/${companyId}/${dealId}`,

    re_deal_page: `/${routePathSegments.re_deal_page}`,
    companyById: (id) => `/${routePathSegments.company}/${id}`,
    advisorById: (id) => `/${routePathSegments.advisor}/${routePathSegments.pe_vc_advisors}/${id}`,
    transactionAdvisorById: (id) => `/${routePathSegments.transaction_advisors}/${id}`,
    companyDealById: (companyId, dealId) => `/${routePathSegments.company}/${companyId}/${dealId}`,
    companyFundingById: (id, rootPath) => `/${routePathSegments.company}/${rootPath}/${id}`,
    companyFillingById: (id, rootPath) => `/${routePathSegments.company}/${routePathSegments.fillings}/${id}`,
    investorById: (id) => `/${routePathSegments.investor}/${id}`,
    investorIncubationId: (id) => `/${routePathSegments.investor}/${routePathSegments.incubation}/${id}`,
    investorAngelId: (id) => `/${routePathSegments.investor}/${routePathSegments.angel}/${id}`,
    investorInvestmentId: (id) => `/${routePathSegments.investor}/${routePathSegments.investment}/${id}`,
    investorExitId: (id) => `/${routePathSegments.investor}/${routePathSegments.exit}/${id}`,
    investorIPOId: (id) => `/${routePathSegments.investor}/${routePathSegments.ipo}/${id}`,
    investorsLimitedPartnerById: (id) => `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.limited_partners}/${id}`,
    reCompanyDealById: (companyId, dealId) => `/${routePathSegments.company}/${companyId}/${dealId}`,
    reCompanyById: (id) => `/${routePathSegments.re}/${routePathSegments.company}/${id}`,
    reInvestorById: (id) => `/${routePathSegments.re}/${routePathSegments.investor}/${id}`,
    reAdvisorById: (id) => `/${routePathSegments.re}/${routePathSegments.advisor}/${id}`,
    vcCompanyDealById : (companyId, dealId) => `/${routePathSegments.company}/${companyId}/${dealId}`,
    // MaCompanyDealId:(companyId, dealId) => `/${routePathSegments.ma}/${routePathSegments.company}/${companyId}/${dealId}`,

    reTransactionAdvisorById: (id) => `/${routePathSegments.re}/${routePathSegments.transaction_advisor}/${id}`,
    incubationDealPage: `/${routePathSegments.incubation_dealpage}`,
    //cfs
    cfsCompanyPageById: (id) => `/${routePathSegments.cfs}/${routePathSegments.company}/${id}`,
    cfsNewCompanyPageById: (id) => `/${routePathSegments.cfs}/${routePathSegments.company}/new/${id}`,
    cfsInvestorById: (id) => `/${routePathSegments.cfs}/${routePathSegments.investor}/${id}`,
    cfsCompanyInvestmentDealById: (companyId, dealId) => `/${routePathSegments.cfs}/${routePathSegments.company}/${routePathSegments.pe_vc_investments}/${companyId}/${dealId}`,
    cfsCompanyExitsDealById: (companyId, dealId) => `/${routePathSegments.cfs}/${routePathSegments.company}/${routePathSegments.exists}/${companyId}/${dealId}`,
    cfsCompanyBackedIPOSDealById: (companyId, dealId) => `/${routePathSegments.cfs}/${routePathSegments.company}/${routePathSegments.backed_ipos}/${companyId}/${dealId}`,
    cfsCompanyAngelDealById: (companyId, dealId) => `/${routePathSegments.cfs}/${routePathSegments.company}/${routePathSegments.angel}/${companyId}/${dealId}`,
    cfsCompanyIncubationDealById: (companyId, dealId) => `/${routePathSegments.cfs}/${routePathSegments.company}/${routePathSegments.incubation}/${companyId}/${dealId}`,
    cfs_companies_pe_vc_investments: `/${routePathSegments.cfs}/${routePathSegments.companies}/${routePathSegments.pe_vc_investments}`,

    requestById: (id) => `/${routePathSegments.raise_request}/${id}`,
    //PE->Companies
    peCompanyFundById: (id) => `/${routePathSegments.pe}/${routePathSegments.company}/${id}`,
    peCompanyInvestmentDealById: (companyId, dealId) => `/${routePathSegments.pe}/${routePathSegments.company}/${routePathSegments.pe_vc_investments}/${companyId}/${dealId}`,
    peCompanyExitsDealById: (companyId, dealId) => `/${routePathSegments.pe}/${routePathSegments.company}/${routePathSegments.exists}/${companyId}/${dealId}`,
    peCompanyBackedIPOSDealById: (companyId, dealId) => `/${routePathSegments.pe}/${routePathSegments.company}/${routePathSegments.backed_ipos}/${companyId}/${dealId}`,
    peCompanyAngelDealById: (companyId, dealId) => `/${routePathSegments.pe}/${routePathSegments.company}/${routePathSegments.angel}/${companyId}/${dealId}`,
    peCompanyIncubationDealById: (companyId, dealId) => `/${routePathSegments.pe}/${routePathSegments.company}/${routePathSegments.incubation}/${companyId}/${dealId}`,
    pe_companies_pe_vc_investments: `/${routePathSegments.pe}/${routePathSegments.companies}/${routePathSegments.pe_vc_investments}`,
    pe_companies_exists_pe_vc_investments: `/${routePathSegments.pe}/${routePathSegments.companies}/${routePathSegments.exists}/${routePathSegments.pe_vc_investments}`,
    pe_companies_exists_ma_pe_vc_investments: `/${routePathSegments.pe}/${routePathSegments.companies}/${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.pe_vc_investments}`,
    pe_companies_exists_pm_pe_vc_investments: `/${routePathSegments.pe}/${routePathSegments.companies}/${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.pe_vc_investments}`,
    pe_companies_angel_investments: `/${routePathSegments.pe}/${routePathSegments.companies}/${routePathSegments.angel_invesments}`,
    pe_companies_incubation: `/${routePathSegments.pe}/${routePathSegments.companies}/${routePathSegments.incubation}`,
    pe_companies_backed_ipos_pe_vc_investments: `/${routePathSegments.pe}/${routePathSegments.companies}/${routePathSegments.backed_ipos}/${routePathSegments.pe_vc_investments}`,
    //PE->Investors
    peInvestorById: (id) => `/${routePathSegments.pe}/${routePathSegments.investor}/${id}`,
    peCoInvestorById: (id) => `/${routePathSegments.pe}/${routePathSegments.investor}/${routePathSegments.coInvestor}/${id}`,
    peExitInvestorById: (id) => `/${routePathSegments.pe}/${routePathSegments.investor}/${routePathSegments.exit}/${id}`,
    peIpoInvestorById: (id) => `/${routePathSegments.pe}/${routePathSegments.investor}/${routePathSegments.ipo}/${id}`,
    peAngelInvestorById: (id) => `/${routePathSegments.pe}/${routePathSegments.investor}/${routePathSegments.angel}/${id}`,
    peIncubationInvestorById: (id) => `/${routePathSegments.pe}/${routePathSegments.investor}/${routePathSegments.incubation}/${id}`,
    pe_investors_pe_vc_investments: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.pe_vc_investments}`,
    companyDealById: (companyId, dealId) => `/${routePathSegments.company}/${companyId}/${dealId}`,
    pe_investors_exists_pe_vc_investments: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.pe_vc_investments}`,
    pe_investors_exists_ma_pe_vc_investments: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.pe_vc_investments}`,
    pe_investors_exists_pm_pe_vc_investments: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.pe_vc_investments}`,
    pe_investors_angel_investments: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.angel_invesments}`,
    pe_investors_incubation: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.incubation}`,
    pe_investors_backed_ipos_pe_vc_investments: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.backed_ipos}/${routePathSegments.pe_vc_investments}`,
    //PE->Investors -> Limited-partners
    pe_investors_pe_vc_limited_partners: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.pe_vc_limited_partners}`,
    pe_investors_exists_pe_vc_limited_partners: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.pe_vc_limited_partners}`,
    pe_investors_exists_ma_pe_vc_limited_partners: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.pe_vc_limited_partners}`,
    pe_investors_exists_pm_pe_vc_limited_partners: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.pe_vc_limited_partners}`,
    //PE->Investors -> Funds
    pe_investors_pe_vc_funds: `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.pe_vc_funds}`,
    pe_investors_funds_detail_page: (id, cid) => `/${routePathSegments.pe}/${routePathSegments.investors}/${routePathSegments.funds_detail_page}/${id}/${cid}`,
    vc_investors_funds_detail_page: (id, cid) => `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.funds_detail_page}/${id}/${cid}`,
    //PE=>Investors -> New investors
    pe_new_investors_investments: `/${routePathSegments.pe}/${routePathSegments.newInvestors}/${routePathSegments.pe_vc_investments}`,
    pe_new_investors_exits: `/${routePathSegments.pe}/${routePathSegments.newInvestors}/${routePathSegments.exists}`,
    pe_new_investors_exits_ma: `/${routePathSegments.pe}/${routePathSegments.newInvestors}/${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.pe_vc_investments}`,
    pe_new_investors_exits_pm: `/${routePathSegments.pe}/${routePathSegments.newInvestors}/${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.pe_vc_investments}`,
    pe_new_investors_angel: `/${routePathSegments.pe}/${routePathSegments.newInvestors}/${routePathSegments.angel}`,
    pe_new_investors_incubation: `/${routePathSegments.pe}/${routePathSegments.newInvestors}/${routePathSegments.incubation}`,

    //PE=>Investors -> Active investors
    pe_active_investors_investments: `/${routePathSegments.pe}/${routePathSegments.activeInvestors}/${routePathSegments.pe_vc_investments}`,
    pe_active_investors_exits: `/${routePathSegments.pe}/${routePathSegments.activeInvestors}/${routePathSegments.exists}`,
    pe_active_investors_exits_ma: `/${routePathSegments.pe}/${routePathSegments.activeInvestors}/${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.pe_vc_investments}`,
    pe_active_investors_exits_pm: `/${routePathSegments.pe}/${routePathSegments.activeInvestors}/${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.pe_vc_investments}`,
    pe_active_investors_angel: `/${routePathSegments.pe}/${routePathSegments.activeInvestors}/${routePathSegments.angel}`,
    pe_active_investors_incubation: `/${routePathSegments.pe}/${routePathSegments.activeInvestors}/${routePathSegments.incubation}`,

    //PE -> Advisors
    peAdvisorById: (id) => `/${routePathSegments.pe}/${routePathSegments.advisor}/${id}`,
    pe_advisors_pe_vc_advisors: `/${routePathSegments.pe}/${routePathSegments.advisors}/${routePathSegments.pe_vc_advisors}`,
    pe_advisors_ma_advisors_exits: `/${routePathSegments.pe}/${routePathSegments.advisors}/${routePathSegments.ma_exits}/${routePathSegments.pe_vc_advisors}`,
    pe_advisors_ma_pe_vc_advisors: `/${routePathSegments.pe}/${routePathSegments.advisors}/${routePathSegments.ma}/${routePathSegments.pe_vc_advisors}`,
    pe_advisors_pm_pe_vc_advisors: `/${routePathSegments.pe}/${routePathSegments.advisors}/${routePathSegments.pm}/${routePathSegments.pe_vc_advisors}`,
    //PE -> Transaction Advisor
    peTransactionAdvisorById: (id) => `/${routePathSegments.pe}/${routePathSegments.transaction_advisor}/${id}`,
    pe_transaction_advisors_pe_vc_advisors: `/${routePathSegments.pe}/${routePathSegments.transaction_advisors}/${routePathSegments.pe_vc_advisors}`,
    pe_transaction_advisors_ma_advisors_exits: `/${routePathSegments.pe}/${routePathSegments.transaction_advisors}/${routePathSegments.ma_exits}/${routePathSegments.pe_vc_advisors}`,
    pe_transaction_advisors_ma_pe_vc_advisors: `/${routePathSegments.pe}/${routePathSegments.transaction_advisors}/${routePathSegments.ma}/${routePathSegments.pe_vc_advisors}`,
    pe_transaction_advisors_pm_pe_vc_advisors: `/${routePathSegments.pe}/${routePathSegments.transaction_advisors}/${routePathSegments.pm}/${routePathSegments.pe_vc_advisors}`,

    //VC
    //VC->Companies
    vcCompanyFundById: (id) => `/${routePathSegments.vc}/${routePathSegments.company}/${id}`,
    vcCompanyInvestmentDealById: (companyId, dealId) => `/${routePathSegments.vc}/${routePathSegments.company}/${routePathSegments.vc_investments}/${companyId}/${dealId}`,
    vcCompanyExitsDealById: (companyId, dealId) => `/${routePathSegments.vc}/${routePathSegments.company}/${routePathSegments.exists}/${companyId}/${dealId}`,
    vcCompanyBackedIPOSDealById: (companyId, dealId) => `/${routePathSegments.vc}/${routePathSegments.company}/${routePathSegments.backed_ipos}/${companyId}/${dealId}`,
    vcCompanyAngelDealById: (companyId, dealId) => `/${routePathSegments.vc}/${routePathSegments.company}/${routePathSegments.angel}/${companyId}/${dealId}`,
    vcCompanyIncubationDealById: (companyId, dealId) => `/${routePathSegments.vc}/${routePathSegments.company}/${routePathSegments.incubation}/${companyId}/${dealId}`,
    vc_companies_vc_investments: `/${routePathSegments.vc}/${routePathSegments.companies}/${routePathSegments.vc_investments}`,
    vc_companies_exists_vc_investments: `/${routePathSegments.vc}/${routePathSegments.companies}/${routePathSegments.exists}/${routePathSegments.vc_investments}`,
    vc_companies_exists_ma_vc_investments: `/${routePathSegments.vc}/${routePathSegments.companies}/${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.vc_investments}`,
    vc_companies_exists_pm_vc_investments: `/${routePathSegments.vc}/${routePathSegments.companies}/${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.vc_investments}`,
    vc_companies_backed_ipos_vc_investments: `/${routePathSegments.vc}/${routePathSegments.companies}/${routePathSegments.backed_ipos}/${routePathSegments.vc_investments}`,
    vc_companies_angel_investments: `/${routePathSegments.vc}/${routePathSegments.companies}/${routePathSegments.angel_invesments}`,
    vc_companies_incubation: `/${routePathSegments.vc}/${routePathSegments.companies}/${routePathSegments.incubation}`,

    //VC->Investors
    vcInvestorById: (id) => `/${routePathSegments.vc}/${routePathSegments.investor}/${id}`,
    vcCoInvestorById: (id) => `/${routePathSegments.vc}/${routePathSegments.investor}/${routePathSegments.coInvestor}/${id}`,
    vcExitInvestorById: (id) => `/${routePathSegments.vc}/${routePathSegments.investor}/${routePathSegments.exit}/${id}`,
    vcIpoInvestorById: (id) => `/${routePathSegments.vc}/${routePathSegments.investor}/${routePathSegments.ipo}/${id}`,
    vcAngelInvestorById: (id) => `/${routePathSegments.vc}/${routePathSegments.investor}/${routePathSegments.angel}/${id}`,
    vcIncubationInvestorById: (id) => `/${routePathSegments.vc}/${routePathSegments.investor}/${routePathSegments.incubation}/${id}`,
    vc_investors_vc_investments: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.vc_investments}`,
    vc_investors_exists_vc_investments: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.vc_investments}`,
    vc_investors_exists_ma_vc_investments: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.vc_investments}`,
    vc_investors_exists_pm_vc_investments: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.vc_investments}`,
    vc_investors_backed_ipos_vc_investments: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.backed_ipos}/${routePathSegments.vc_investments}`,
    vc_investors_angel_investments: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.angel_invesments}`,
    vc_investors_incubation: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.incubation}`,
    //VC->Investors -> Limited-partners
    vcInvestorsLimitedPartnerById: (id) => `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.limited_partners}/${id}`,
    //VC=>Investors -> New investors
    vc_new_investors_investments: `/${routePathSegments.vc}/${routePathSegments.newInvestors}/${routePathSegments.vc_investments}`,
    vc_new_investors_exits: `/${routePathSegments.vc}/${routePathSegments.newInvestors}/${routePathSegments.exists}`,
    vc_new_investors_exits_ma: `/${routePathSegments.vc}/${routePathSegments.newInvestors}/${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.vc_investments}`,
    vc_new_investors_exits_pm: `/${routePathSegments.vc}/${routePathSegments.newInvestors}/${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.vc_investments}`,
    vc_new_investors_angel: `/${routePathSegments.vc}/${routePathSegments.newInvestors}/${routePathSegments.angel}`,
    vc_new_investors_incubation: `/${routePathSegments.vc}/${routePathSegments.newInvestors}/${routePathSegments.incubation}`,

    //VC=>Investors -> Active investors
    vc_active_investors_investments: `/${routePathSegments.vc}/${routePathSegments.activeInvestors}/${routePathSegments.vc_investments}`,
    vc_active_investors_exits: `/${routePathSegments.vc}/${routePathSegments.activeInvestors}/${routePathSegments.exists}`,
    vc_active_investors_exits_ma: `/${routePathSegments.vc}/${routePathSegments.activeInvestors}/${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.vc_investments}`,
    vc_active_investors_exits_pm: `/${routePathSegments.vc}/${routePathSegments.activeInvestors}/${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.vc_investments}`,
    vc_active_investors_angel: `/${routePathSegments.vc}/${routePathSegments.activeInvestors}/${routePathSegments.angel}`,
    vc_active_investors_incubation: `/${routePathSegments.vc}/${routePathSegments.activeInvestors}/${routePathSegments.incubation}`,

    vc_investors_vc_limited_partners: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.vc_limited_partners}`,
    vc_investors_vc_funds: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.vc_funds}`,
    vc_investors_exists_vc_limited_partners: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.vc_limited_partners}`,
    vc_investors_exists_ma_vc_limited_partners: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.ma}/${routePathSegments.vc_limited_partners}`,
    vc_investors_exists_pm_vc_limited_partners: `/${routePathSegments.vc}/${routePathSegments.investors}/${routePathSegments.exists}/${routePathSegments.pm}/${routePathSegments.vc_limited_partners}`,
    //VC-> Advisors
    vcAdvisorById: (id) => `/${routePathSegments.vc}/${routePathSegments.advisor}/${id}`,
    vc_advisors_vc_advisors: `/${routePathSegments.vc}/${routePathSegments.advisors}/${routePathSegments.vc_advisors}`,
    vc_advisors_ma_vc_advisors_exits: `/${routePathSegments.vc}/${routePathSegments.advisors}/${routePathSegments.ma_exits}/${routePathSegments.vc_advisors}`,
    vc_advisors_ma_vc_advisors: `/${routePathSegments.vc}/${routePathSegments.advisors}/${routePathSegments.ma}/${routePathSegments.vc_advisors}`,
    vc_advisors_pm_vc_advisors: `/${routePathSegments.vc}/${routePathSegments.advisors}/${routePathSegments.pm}/${routePathSegments.vc_advisors}`,
    //VC -> Transaction Advisor
    vcTransactionAdvisorById: (id) => `/${routePathSegments.vc}/${routePathSegments.transaction_advisor}/${id}`,
    vc_transaction_advisors_vc_advisors: `/${routePathSegments.vc}/${routePathSegments.transaction_advisors}/${routePathSegments.vc_advisors}`,
    vc_transaction_advisors_ma_vc_exits: `/${routePathSegments.vc}/${routePathSegments.transaction_advisors}/${routePathSegments.ma_exits}/${routePathSegments.vc_advisors}`,
    vc_transaction_advisors_ma_vc_advisors: `/${routePathSegments.vc}/${routePathSegments.transaction_advisors}/${routePathSegments.ma}/${routePathSegments.vc_advisors}`,
    vc_transaction_advisors_pm_vc_advisors: `/${routePathSegments.vc}/${routePathSegments.transaction_advisors}/${routePathSegments.pm}/${routePathSegments.vc_advisors}`,

    // RE
    // RE -> Companies
    reCompanyFundById: (id) => `/${routePathSegments.re}/${routePathSegments.company}/${id}`,
    reCompanyInvestmentDealById: (companyId, dealId) => `/${routePathSegments.re}/${routePathSegments.company}/${routePathSegments.re_investments}/${companyId}/${dealId}`,
    reCompanyExitsDealById: (companyId, dealId) => `/${routePathSegments.re}/${routePathSegments.company}/${routePathSegments.re_exits}/${companyId}/${dealId}`,
    reCompanyBackedIPOSDealById: (companyId, dealId) => `/${routePathSegments.re}/${routePathSegments.company}/${routePathSegments.re_ipo}/${companyId}/${dealId}`,
    reCompanyOtherMaDealById: (companyId, dealId) => `/${routePathSegments.re}/${routePathSegments.company}/${routePathSegments.other_ma}/${companyId}/${dealId}`,

    re_companies_re_investments: `/${routePathSegments.re}/${routePathSegments.companies}/${routePathSegments.re_investments}`,
    re_companies_re_exits: `/${routePathSegments.re}/${routePathSegments.companies}/${routePathSegments.re_exits}`,
    re_companies_other_ma: `/${routePathSegments.re}/${routePathSegments.companies}/${routePathSegments.other_ma}`,
    re_companies_re_ipo: `/${routePathSegments.re}/${routePathSegments.companies}/${routePathSegments.re_ipo}`,

    //RE -> Investors
    re_investors_re_investments: `/${routePathSegments.re}/${routePathSegments.investors}/${routePathSegments.re_investments}`,
    re_investors_exists: `/${routePathSegments.re}/${routePathSegments.investors}/${routePathSegments.exists}`,
    re_investors_other_ma: `/${routePathSegments.re}/${routePathSegments.investors}/${routePathSegments.other_ma}`,
    re_investors_backed_ipos: `/${routePathSegments.re}/${routePathSegments.investors}/${routePathSegments.backed_ipos}`,

    //RE->Investors -> Funds
    re_investors_funds: `/${routePathSegments.re}/${routePathSegments.investors}/${routePathSegments.re_funds}`,
    re_investors_funds_detail: (id, cid) => `/${routePathSegments.re}/${routePathSegments.investors}/${routePathSegments.re_funds_detail}/${id}/${cid}`,
    //RE -> Advisors

    re_advisors_re_investments: `/${routePathSegments.re}/${routePathSegments.advisors}/${routePathSegments.re_investments}`,
    re_advisors_re_exits: `/${routePathSegments.re}/${routePathSegments.advisors}/${routePathSegments.re_exits}`,
    re_advisors_other_ma: `/${routePathSegments.re}/${routePathSegments.advisors}/${routePathSegments.other_ma}`,
    re_advisors_re_ipo: `/${routePathSegments.re}/${routePathSegments.advisors}/${routePathSegments.re_ipo}`,

    //RE -> Transaction Advisors

    re_transaction_advisors_re_investments: `/${routePathSegments.re}/${routePathSegments.transaction_advisors}/${routePathSegments.re_investments}`,
    re_transaction_advisors_re_exits: `/${routePathSegments.re}/${routePathSegments.transaction_advisors}/${routePathSegments.re_exits}`,
    re_transaction_advisors_other_ma: `/${routePathSegments.re}/${routePathSegments.transaction_advisors}/${routePathSegments.other_ma}`,
    re_transaction_advisors_re_ipo: `/${routePathSegments.re}/${routePathSegments.transaction_advisors}/${routePathSegments.re_ipo}`,

    //MA
    // MaCompanyId:(companyId, dealId) => `/${routePathSegments.ma}/${routePathSegments.company}/${companyId}/${dealId}`,

    // maCompanyDealById:(companyId, dealId) => `/${routePathSegments.ma}/${routePathSegments.company}/${companyId}/${dealId}`,

    ma_companies: `/${routePathSegments.ma}/${routePathSegments.companies}`,
    ma_acquires: `/${routePathSegments.ma}/${routePathSegments.acquirers}`,
    ma_advisors: `/${routePathSegments.ma}/${routePathSegments.advisors}`,
    ma_transaction_advisor: `/${routePathSegments.ma}/${routePathSegments.transaction_advisors}`,

    //compare
    compareCompanyById: (companyId) => `/${routePathSegments.compare}/${routePathSegments.company}/${companyId}`,
    compareInvestorById: (investorId) => `/${routePathSegments.compare}/${routePathSegments.investor}/${investorId}`,
    compareLegalAdvisorById: (advisorId) => `/${routePathSegments.compare}/${routePathSegments.advisor}/${advisorId}`,
    compareTransactionById: (advisorId) => `/${routePathSegments.compare}/${routePathSegments.transaction_advisor}/${advisorId}`,
    compareCfsCompanyById: (cprofileId) => `/${routePathSegments.compare}/${routePathSegments.cfs}/${cprofileId}`,
    compareAcquirerById: (acquirerId) => `/${routePathSegments.compare}/${routePathSegments.acquirers}/${acquirerId}`,

}

/**
 * The function `getCompanyMenuPath` returns the corresponding company menu path based on the given
 * root path.
 * @param rootPath - The `rootPath` parameter is a string that represents the root path of a company
 * menu.
 * @returns The function `getCompanyMenuPath` returns the corresponding company route name based on the
 * provided `rootPath`. If `rootPath` matches any of the defined `routePathSegments`, the corresponding
 * `routeNames` value is returned. If `rootPath` does not match any of the defined `routePathSegments`,
 * the default value `routeNames.PE_company` is returned.
 */
const getCompanyMenuPath = (rootPath) => {
    if (rootPath === routePathSegments.pe) {
        return routeNames.PE_company;
    }
    if (rootPath === routePathSegments.vc) {
        return routeNames.VC_company;
    }
    if (rootPath === routePathSegments.re) {
        return routeNames.RE_company;
    }
    if (rootPath === routePathSegments.ma) {
        return routeNames.MA_company;
    }

    return routeNames.PE_company;
}

/**
 * The function `getRoutePathById` takes in an object with `moduleId`, `primaryId`, and `subPrimaryId`
 * properties and returns a route path based on the values of these properties.
 * @returns The function `getRoutePathById` returns a route path based on the values of `moduleId`,
 * `primaryId`, and `subPrimaryId`.
 */
const getRoutePathById = ({ moduleId, primaryId, subPrimaryId }) => {

    //pe
    if (primaryId == 1) {
        if (moduleId == 1) {
            if (subPrimaryId === 1) {
                return routeNames.pe_companies_pe_vc_investments;
            } else if (subPrimaryId === 2) {
                return routeNames.pe_companies_exists_pe_vc_investments;
            } else if (subPrimaryId === 3) {
                return routeNames.pe_companies_exists_ma_pe_vc_investments;
            } else if (subPrimaryId === 4) {
                return routeNames.pe_companies_exists_pm_pe_vc_investments;
            } else if (subPrimaryId === 5) {
                return routeNames.pe_companies_backed_ipos_pe_vc_investments;
            } else if (subPrimaryId === 6) {
                return routeNames.pe_companies_angel_investments;
            } else if (subPrimaryId === 7) {
                return routeNames.pe_companies_incubation;
            }
        }
        else if (moduleId == 2) {
            if (subPrimaryId === 1) {
                return routeNames.pe_investors_pe_vc_investments;
            } else if (subPrimaryId === 2) {
                return routeNames.pe_investors_exists_pe_vc_investments;
            } else if (subPrimaryId === 3) {
                return routeNames.pe_investors_exists_ma_pe_vc_investments;
            } else if (subPrimaryId === 4) {
                return routeNames.pe_investors_exists_pm_pe_vc_investments;
            } else if (subPrimaryId === 5) {
                return routeNames.pe_investors_backed_ipos_pe_vc_investments;
            } else if (subPrimaryId === 6) {
                return routeNames.pe_investors_angel_investments;
            } else if (subPrimaryId === 7) {
                return routeNames.pe_investors_incubation;
            }
            // else if (subPrimaryId === 12){        
            //     return routeNames.pe_investors_pe_vc_limited_partners
            // } else if (subPrimaryId === 13){        
            //     return routeNames.pe_investors_exists_pe_vc_limited_partners
            // } else if (subPrimaryId === 14){        
            //     return routeNames.pe_investors_exists_ma_pe_vc_limited_partners
            // } else if (subPrimaryId === 15){        
            //     return routeNames.pe_investors_exists_pm_pe_vc_limited_partners
            // } else if (subPrimaryId === 16){        
            //     return routeNames.pe_investors_pe_vc_funds
            // }


        }
        else if (moduleId == 3) {
            if (subPrimaryId === 8) {
                return routeNames.pe_advisors_pe_vc_advisors;
            } else if (subPrimaryId === 9) {
                return routeNames.pe_advisors_ma_advisors_exits;
            } else if (subPrimaryId === 10) {
                return routeNames.pe_advisors_ma_pe_vc_advisors;
            } else if (subPrimaryId === 11) {
                return routeNames.pe_advisors_pm_pe_vc_advisors;
            }
        }
        else if (moduleId == 4) {
            if (subPrimaryId === 8) {
                return routeNames.pe_transaction_advisors_pe_vc_advisors;
            } else if (subPrimaryId === 9) {
                return routeNames.pe_transaction_advisors_ma_advisors_exits;
            } else if (subPrimaryId === 10) {
                return routeNames.pe_transaction_advisors_ma_pe_vc_advisors;
            }
            else if (subPrimaryId === 11) {
                return routeNames.pe_transaction_advisors_pm_pe_vc_advisors;
            }
        }
    }
    //vc
    if (primaryId == 2) {
        if (moduleId == 1) {
            if (subPrimaryId === 17) {
                return routeNames.vc_companies_vc_investments;
            } else if (subPrimaryId === 18) {
                return routeNames.vc_companies_exists_vc_investments;
            } else if (subPrimaryId === 19) {
                return routeNames.vc_companies_exists_ma_vc_investments;
            } else if (subPrimaryId === 20) {
                return routeNames.vc_companies_exists_pm_vc_investments;
            } else if (subPrimaryId === 21) {
                return routeNames.vc_companies_backed_ipos_vc_investments;
            } else if (subPrimaryId === 22) {
                return routeNames.vc_companies_angel_investments;
            } else if (subPrimaryId === 23) {
                return routeNames.vc_companies_incubation;
            }
        }
        else if (moduleId == 2) {
            if (subPrimaryId === 17) {
                return routeNames.vc_investors_vc_investments;
            } else if (subPrimaryId === 18) {
                return routeNames.vc_investors_exists_vc_investments;
            } else if (subPrimaryId === 19) {
                return routeNames.vc_investors_exists_ma_vc_investments;
            } else if (subPrimaryId === 20) {
                return routeNames.vc_investors_exists_pm_vc_investments;
            } else if (subPrimaryId === 21) {
                return routeNames.vc_investors_backed_ipos_vc_investments;
            } else if (subPrimaryId === 22) {
                return routeNames.vc_investors_angel_investments;
            } else if (subPrimaryId === 23) {
                return routeNames.vc_investors_incubation;
            }
        }
        else if (moduleId == 3) {
            if (subPrimaryId === 24) {
                return routeNames.vc_advisors_vc_advisors;
            } else if (subPrimaryId === 25) {
                return routeNames.vc_advisors_ma_vc_advisors_exits;
            } else if (subPrimaryId === 26) {
                return routeNames.vc_advisors_ma_vc_advisors;
            } else if (subPrimaryId === 27) {
                return routeNames.vc_advisors_pm_vc_advisors;
            }
        }
        else if (moduleId == 4) {
            if (subPrimaryId === 24) {
                return routeNames.vc_transaction_advisors_vc_advisors;
            } else if (subPrimaryId === 25) {
                return routeNames.vc_transaction_advisors_ma_vc_exits;
            } else if (subPrimaryId === 26) {
                return routeNames.vc_transaction_advisors_ma_vc_advisors;
            }
            else if (subPrimaryId === 27) {
                return routeNames.vc_transaction_advisors_pm_vc_advisors;
            }
        }
    }
    //Re
    if (primaryId == 3) {
        if (moduleId == 1) {
            if (subPrimaryId === 33) {
                return routeNames.re_companies_re_investments;
            } else if (subPrimaryId === 34) {
                return routeNames.re_companies_re_exits;
            } else if (subPrimaryId === 35) {
                return routeNames.re_companies_other_ma;
            } else if (subPrimaryId === 36) {
                return routeNames.re_companies_re_ipo;
            }
        }
        else if (moduleId == 2) {
            if (subPrimaryId === 37) {
                return routeNames.re_investors_re_investments;
            } else if (subPrimaryId === 38) {
                return routeNames.re_investors_exists;
            } else if (subPrimaryId === 39) {
                return routeNames.re_investors_backed_ipos
            }
        }
        else if (moduleId == 3) {
            if (subPrimaryId === 40) {
                return routeNames.re_advisors_re_investments;
            } else if (subPrimaryId === 41) {
                return routeNames.re_advisors_re_exits;
            } else if (subPrimaryId === 42) {
                return routeNames.re_advisors_other_ma;
            }
        }
        else if (moduleId == 4) {
            if (subPrimaryId === 43) {
                return routeNames.re_transaction_advisors_re_investments;
            } else if (subPrimaryId === 44) {
                return routeNames.re_transaction_advisors_re_exits;
            } else if (subPrimaryId === 45) {
                return routeNames.re_transaction_advisors_other_ma;
            }
            else if (subPrimaryId === 27) {
                return routeNames.vc_transaction_advisors_pm_vc_advisors;
            }
        }
    }
    //ma
    if (primaryId == 4) {
        if (moduleId == 1) {
            return routeNames.ma_companies
        }
        else if (moduleId == 2) {
            return routeNames.ma_acquires
        }
        else if (moduleId == 3) {
            return routeNames.ma_advisors
        }
        else if (moduleId == 4) {
            return routeNames.ma_transaction_advisor
        }
    }

    return routeNames.pe_companies_pe_vc_investments;
}


export default routePathSegments;
export { routeNames, getCompanyMenuPath, getRoutePathById }