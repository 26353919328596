import React, { useContext } from "react"
import { useLocation } from 'react-router-dom'
import Layout from '../../../Components/InvestorLayout'
import MainMenu from '../../../Components/Investor/MainMenu'
import Card from '../../../Components/Investor/InvestorPageCard/Card'
import Table from '../../../Components/Investor/TableComponent'
// import {PE_TABLE_QUERY} from '../../../Queries/Pe';

import RefineSearchBar from '../../../Components/RemovedArrowRefineSearch'
import FilterTag from '../../../Components/Investor/Tag'

// import chatPopupIcon from '../../../images/chatPopupIcon.png'
// import ChatPopup from '../../../Components/ChatPopup'
import Constants from '../../../Constants'
import { PeFilterContext } from "../../../Contexts/PeFilterContext";

/**
 * The Incubation function is a React component that renders a layout with a primary column and a
 * secondary column, and includes various components such as a card, filter tag, main menu, table, and
 * refine search.
 * @returns The Incubation function returns JSX elements that make up the layout and components of a
 * page.
 */
function Incubation() {
    // const [showModal, setShowModal] = React.useState(false);
    // const location = useLocation();
    
    const {refineSearchFilters} = useContext(PeFilterContext);
    const { pathname } = useLocation(); 

    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <Layout>
            <div className="flex h-screen flex-col">

               {isFromGlobalSearch() ? ""
               :
             <div className="hidden lg:block">  <Card card1={window.location.pathname ? (window.location.pathname == '/pe/investors/incubation' ? 'Incubators' : '') : ''} card2={window.location.pathname ? (window.location.pathname == '/pe/investors/incubation' ? 'Incubation' : '') : ''} />

              </div> }
                <div className="flex min-h-0 flex-1 overflow-hidden">


                        {/* Primary column */}
                        <main className={isFromGlobalSearch() ?"mt-[3rem] min-w-0 flex-1 border-t border-gray-200 lg:flex":"min-w-0 flex-1 border-t border-gray-200 lg:flex"}>

                            <section
                                className="flex h-full min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]"
                            >
                                <FilterTag />
                                <MainMenu active={5} menu="incubation" primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_incubation_id}
                                typeId={Constants.moduleIds.investorsId}
                                exportFileName={Constants.exportNames.pe_investor_incubation}/>
                                <div className=' overflow-y-auto'>
                                    <Table menu="incubation" sub_type_master_id={Constants.subCategoryIds.pe_investor_incubation_id}/>
                                </div>



                                {/* <Outlet /> */}
                                {/* Your content */}
                            </section>
                             <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                        <div className="relative flex overflow-y-auto flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                            {/* Your content */}
                            <RefineSearchBar />
                        </div>
                    </aside>
                        </main>
                    </div>

                    {/* Secondary column (hidden on smaller screens) */}
                   
                   
            </div>
        </Layout>
    )
}

export default Incubation
