import { React, useState, useContext, useEffect } from "react";
import {
  useParams,
  NavLink,
} from "react-router-dom";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { ticketViewUrl , raiseRequestAttachments } from "../../Config/config";
import ProfileHighlight from "./RequestsDetailComponents/Comments";
import { routeNames } from "../../routeSegments";
import axios from "axios";
import Loader from "../../Components/Loader";
import moment from 'moment'
import { UserContext } from "../../Contexts/UserContext";
import downloadIcon from "../../images/ReportsImages/download.png";


const CompanyDetail = (props) => {

  const [isActive, setIsActive] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const [ticketDetails, setTicketDetails] = useState();
  const [attachments, setAttachments] = useState([]);
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const { requestId } = useParams();
  const { getToken } = useContext(UserContext);


  useEffect(() => {
    setisLoading(true);
    axios.post(ticketViewUrl, {
      "ticket_id": requestId
    }, {
      headers: {
        "Authorization": getToken()
      }
    }).then((response) => {
      setisLoading(false);
      if (response.status === 200) {
        setTicketDetails(response.data?.comments?.at(0));
      }
    })
  }, [requestId])

  const textToURL = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace URLs with clickable links
    const linkedText = text?.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer" style='color:#3366CC; text-decoration: underline'>${url}</a>`
    );

    return <div dangerouslySetInnerHTML={{ __html: linkedText }} />;
  };

  useEffect(() => {
    if (ticketDetails?.ticket_id) {
      setAttachmentsLoading(true);
      fetch(`${raiseRequestAttachments}${ticketDetails.ticket_id}/`, {
        method: "POST",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAttachmentsData(data.s3_links);
          setAttachmentsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching attachments:", error);
          setAttachmentsLoading(false);
        });
    }
  }, [ticketDetails, getToken]);

  return (
    <>
      <div className="bg-[#FFFFFF]">
        <nav
          className="flex p-1 px-4 lg:px-1 border-b "
          aria-label="Breadcrumb"
        >
          <ol className="flex items-center space-x-1">
            <li className="flex items-center">
              <div className="flex items-center">
                <NavLink
                  className="ml-2 text-[11pt] lg:text-[12px]  leading-[14px] font-sans_book_body text-[#666666]"
                  to={routeNames.pe_companies_pe_vc_investments}>
                  Home
                </NavLink>
              </div>

            </li>
            <li className="flex items-center">
              <div className="flex items-center">
              <span className=" text-[12px] text-[#666666] ">/</span>
                <NavLink
                  className="ml-2 text-[11pt] lg:text-[12px]  leading-[14px] font-sans_book_body text-[#666666]"
                  to="/requests"
                >
                  Requests
                </NavLink>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <span className=" text-[12px] text-[#666666] ">/</span>
                <span
                  href="#"
                  className="px-1 text-[11pt] lg:text-[12px]  text-[#666666] leading-[14px] font-sans_book_body font-semibold"
                  aria-current="page"
                >
                  {" "}
                  #{ticketDetails?.ticket_id}
                </span>
              </div>
            </li>
          </ol>
        </nav>
        {/* <!-- BarRaiser--> */}
        {isLoading
          ? <div className="h-48 flex items-center justify-center">
            <Loader />
          </div>
          : <div className="px-5 ">

            <div className="  pb-4 pt-6 bg-[#FFFFFF]">
              <ul role="list">
                <li className="w-full flex ">


                  <div className="ml-1 flex flex-1 flex-col space-y-1">
                    <p
                      className="text-[#333333] space-x-8 flex text-[22px] font-sans_semi_bold leading-[24px]"
                      href="#"
                    >
                      <a>
                        {" "}
                        {`#${ticketDetails?.ticket_id}`} - {ticketDetails?.subject}{" "}
                      </a>
                      {ticketDetails?.status != null ? <button className="flex  font-sans_book_body bg-[#BC8B20] px-2 h-[28px] text-[#FFFFFF] text-[14px] rounded-[2px] leading-[17px] items-center">
                        <span className="whitespace-nowrap px-4">{ticketDetails?.status}</span>
                      </button> : <></>}

                    </p>
                    <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body  ">
                      Product : {ticketDetails?.product ?? "--"}
                      {/* <span className="font-sans_book_body text-[#666666] underline ml-2">
                      <a className="cursor-pointer" href="https://vi-v2dev.vionweb.com/pe/companies/pe-vc-investments" target="_blank">URL: https://vi-v2dev.vionweb.com/pe/companies/pe-vc-investments
                      </a>
                    </span> */}
                    </span>
                    <span className="text-[11pt] leading-[15pt] text-[#777777] lg:text-[13px] font-sans_semi_bold lg:leading-[21px]">
                      Created Date :<span className="text-[11pt] leading-[15pt] text-[#777777] lg:text-[13px] font-sans_book_body lg:leading-[21px]">{moment(ticketDetails?.created_at).format("DD MMM yyyy hh:mm A")}</span>  -
                      Updated Date :<span className="text-[11pt] leading-[15pt] text-[#777777] lg:text-[13px] font-sans_book_body lg:leading-[21px]"> {moment(ticketDetails?.updated_at).format("DD MMM yyyy hh:mm A")} </span>
                    </span>
                  </div>
                  <div className="hidden lg:grid grid-cols-8 mr-2 gap-1">
                    <div className="col-span-8">
                      <a href="" className=" lg:block text-[10.7pt] leading-[17pt] lg:leading-[16px] font-sans_book_body px-5 mr-2 py-1 text-center text-[#333333] bg-[#EFEFEF] lg:text-[12px] rounded-[2px]">
                        Ticket ID : {`#${ticketDetails?.ticket_id}`}
                      </a>
                    </div>


                  </div>
                </li>
              </ul>
            </div>
            {/* <!-- tags--> */}
            <div className="bg-[#FFFFFF]">
              <div className="bg-[#FFFFFF] mt-3">
                <div>
                  <a
                    className="text-[#333333] text-[22px] font-sans_semi_bold leading-[24px]"
                    href="#"
                  >

                    Ticket Description
                  </a>
                  <p className="text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                    {ticketDetails?.description ? 
                        textToURL(ticketDetails.description)
                     : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>}
        <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-4  border-b border-[#D7D7D7] cursor-pointer">
          <span
            activeclassName="active"
            spy={true}
            smooth={true}
            duration={500}
            className={
              "py-2 whitespace-nowrap text-[12pt] leading-[17pt] lg:text-[13px] lg:leading-[17px]" +
              (isActive == 1
                ? " border-b-[3px] border-b-[#F1CA75] text-[#333333] font-sans_semi_bold group-hover:text-[#F1CA75]"
                : " text-[#333333] font-sans_semi_bold opacity-[0.4]")
            }
            onClick={() => {
              // overview();
              setIsActive(1);
            }}
          >
            Comments
          </span>
        </div>
      </div>
      <div className="bg-[#EDEDED] h-full flex ">
        <div id="profile" className={`${attachmentsData.length === 0 ? 'w-full' : 'w-[60%]'}`}>
          <ProfileHighlight ticketId={ticketDetails?.ticket_id} author={ticketDetails?.author} />
        </div> 
        <div className={`${attachmentsData.length === 0 ? 'hidden' : ''} w-[40%] h-max rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] bg-[#FFFFFF] space-y-6 px-4 mr-4 lg:border lg:mt-4 lg:bg-white rounded-[6px]" x-show="tab === 1`}>
          <div className="my-10 mx-5">
            <h1 className="text-[18px] mb-4">Attachments</h1>
            <div className="">
              {attachmentsData.map((link, index) => (
                <a
                  key={index}
                  className="pl-3 flex  py-3 text-[12pt] leading-[17pt] lg:text-[13px] lg:leading-[17px] font-sans_book_body text-[#2B2A29] tracking-[-0.3px] sm:pl-6"
                  href={link}
                  rel="noreferrer"
                >
                  <img
                    alt=""
                    src={downloadIcon}
                    className="h-4 w-4 flex mt-1 lg:mt-0 mr-3"
                    aria-hidden="true"
                  />
                  {link.substring(link.lastIndexOf("/") + 1)}
                </a>
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default CompanyDetail;
