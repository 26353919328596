import React, { Component ,  useState, useEffect }  from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, dots: '.' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info); // Log the error for debugging
    setTimeout(()=>{
      window.location.href = '/dashboard';

    },4000)
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(prevState => {
        let newDots = prevState.dots;
        if (prevState.dots === '.') {
          newDots = '..';
        } else if (prevState.dots === '..') {
          newDots = '...';
        } else {
          newDots = '.';
        }
        return { dots: newDots  }  ;
      });
    }, 1000);

  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }


  render() {
    if (this.state.hasError) {
      document.title ="Venture Intelligence"
      return (
        <div className='flex  items-center justify-center  h-[100vh]'>
          <article className="flex flex-col items-center justify-center gap-6 w-[100vw] h-[100vh]">
                <h1 className=" text-4xl  mb-4 font-medium text-[#474747]">Something went wrong</h1>
                <svg
                className="mb-6"
                alt="Web browser with concerned expression"
                width="251px"
                height="240px"
                viewBox="0 0 151 140"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    {/* SVG content */}
                        <g id="Well shoot..." stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(-31.000000, -40.000000)">
                            <g transform="translate(31.000000, 40.000000)">
                                <rect id="exclaim-1" fill="#c4942a" x="22" y="0" width="2" height="14" rx="1" />
                                <rect id="exclaim-2" fill="#c4942a" transform="translate(7.000000, 23.000000) rotate(90.000000) translate(-7.000000, -23.000000) " x="6" y="16" width="2" height="14" rx="1" />
                                <rect id="exclaim-3" fill="#c4942a" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) " x="11" y="5" width="2" height="14" rx="1" />
                                <ellipse id="shadow" fill="#000000" opacity="0.08" cx="79" cy="129.5" rx="72" ry="10.5" />
                                <rect id="body" fill="#FFFFFF" x="22" y="22" width="114" height="106" rx="9" />
                                <polygon id="toolbar" fill="#f0e4cc" points="23 47 79 47 135 47 135 32 135 29 132 25 128 23 29.9948981 23 26 25 24 27 23 30" />
                                <circle id="toolbar-button-1" stroke="#d1a84f" stroke-width="2" fill="#FFFFFF" cx="33.5" cy="34.5" r="2.5" />
                                <circle id="toolbar-button-2" stroke="#d1a84f" stroke-width="2" fill="#FFFFFF" cx="43.5" cy="34.5" r="2.5" />
                                <circle id="toolbar-button-3" stroke="#d1a84f" stroke-width="2" fill="#FFFFFF" cx="53.5" cy="34.5" r="2.5" />
                                <rect id="toolbar-border-bottom" fill="#d1a84f" x="22" y="45" width="114" height="2" />
                                <rect id="toolbar-shadow" fill="#000000" opacity="0.08" x="24" y="47" width="110" height="2" />
                                <rect id="window-border" stroke="#d1a84f" stroke-width="2" x="23" y="23" width="112" height="104" rx="9" />
                                <g id="eyes">
                                <ellipse id="eye-left" fill="#c4942a" cx="56.5" cy="72.5" rx="3.5" ry="3.5">
                                    <animate
                                    attributeName="ry"
                                    repeatCount="indefinite"
                                    fill="freeze"
                                    values="3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;0.5;2"
                                    from="3.5"
                                    to="0.5"
                                    dur="4s"
                                    id="leftEyeAnim"
                                    d="leftEyeAnim"
                                    />
                                </ellipse>
                                <ellipse id="eye-right" fill="#c4942a" cx="101.5" cy="72.5" rx="3.5" ry="3.5">
                                    <animate
                                    attributeName="ry"
                                    repeatCount="indefinite"
                                    fill="remove"
                                    values="3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;3.5;0.5;2"
                                    from="3.5"
                                    to="0.5"
                                    dur="4s"
                                    />
                                </ellipse>
                                <animateMotion 
                                    dur="4s" 
                                    fill="freeze" 
                                    calcMode="linear"
                                    repeatCount="indefinite" 
                                    keyPoints="0; 0;  0.25; 0.25; 0.25; 0.75; 0.75; 0.75;  0.75; 1; 1"
                                    keyTimes=" 0; 0.1; 0.2; 0.3;  0.4;  0.5;  0.6;  0.65; 0.7;  0.75; 0.8"
                                    path="
                                        M 0 0
                                        C 5 2, 5 2, 12 0
                                        C 0 3, 0 3, -10 0
                                        H 0
                                        " />
                                <rect id="mouth" fill="#c4942a" x="65" y="94" width="28" height="2" rx="1" />
                                </g>
                            </g>
                            </g>
                        </g>
                </svg>
                <p className="font-sans text-lg text-center">
                You are redirecting to the  <a href='/dashboard' className='text-blue-500 '>Dashboard</a> {this.state.dots}<br />
                </p>
            </article>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary