import { React, useState, useMemo, useEffect, useContext } from 'react'
import axios from 'axios'
import { useQuery } from '@apollo/client'
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from 'react-router-dom';
import xls from '../../../images/download-file-icon.png'
import sorting from '../../../images/up-down-arrow.png'
import ExcelPopup from '../../ExcelPopup'
import moment from 'moment'
import CompanyLogo from '../../CompanyLogo';
import RandomLogo from '../../RandomLogo';
import CustomNavigation from '../../../Utils/CustomNavigation';
import { routeNames } from '../../../routeSegments';
import DataTable from '../../DataTables/DataTable';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel, invIncInvestorDetail } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import { useCallback } from 'react';
import useModule from '../../../hooks/useModule';
import Constants from '../../../Constants';
import { UserContext } from '../../../Contexts/UserContext';
import ConfirmPopup from '../../../Utils/ConfirmPopup';

/**
 * The `InvestorIncubationPortfolioCompanies` function is a React component that renders a table of
 * incubatee companies and their investment details for a specific investor.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `InvestorIncubationPortfolioCompanies` component. These properties can be accessed using dot
 * notation, such as `props.investmentsData` or `props.investorName`.
 * @returns The InvestorIncubationPortfolioCompanies component is returning a JSX element.
 */

const InvestorIncubationPortfolioCompanies = (props) => {
    const { investorId } = useParams();
    const [showXlDownload, setshowXlDownload] = useState(false);
    const location = useLocation();
    const {primaryMasterId} = useModule();
    const {isStudent, isDirectoryUser ,isTrialExport} = useContext(UserContext);


/* The `isVcCompanyMatch` constant is using the `matchPath` function from the `react-router-dom`
library to check if the current location matches the path for a VC (Venture Capital) company. */
    const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, location.state?.prevPath ?? "")

    /* The `customStringSortMemo` constant is using the `useMemo` hook to memoize a sorting function
    for the table in the `InvestorIncubationPortfolioCompanies` component. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? 'A' : 'Z';
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);

    /* The `dealDateSort` constant is using the `useMemo` hook to memoize a sorting function for the
    "Deal Date" column in the table. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])

    /* The `const columns` is an array of objects that define the columns for the table in the
    `InvestorIncubationPortfolioCompanies` component. Each object represents a column and contains
    properties such as `id`, `Header`, `accessor`, `Cell`, and `sortType`. */
    const columns = useMemo(() => [
        {
            id: "incubateeName",
            Header: "Incubatee Name",
            accessor: "incubateeName",
            className: "w-[30%] ",
            Cell: ({ value }) => {
                console.log(value,"abc")
                return <div className='flex items-center '>
                    {/* <CompanyLogo id={value?.id} /> */}
                    {value?.icon_path == "default.png" || value?.icon_path == "/default.png" || !value?.icon_path ?
                        <RandomLogo name={value.name} page="company page" />
                        :
                        <CompanyLogo name={value.name} page="company page" logo={value?.icon_path} />
                    }
                    <Link className='hover:text-[#BC8B20]'
                        state={{ cameFrom: location.state?.prevPath }}
                        to={isVcCompanyMatch != null ? routeNames.vcCompanyFundById(value?.id)
                         : routeNames.peCompanyFundById(value?.id)}>
                        {value.name ?? "--"}
                    </Link>
                </div>
            },
            sortType: customStringSortMemo,
        },
        
        {
            id: "dealDate",
            Header: "Deal Date",
            accessor: "dealDate",
            Cell: (cell) => {
                return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
                onClick={(e) => {
                    if (isDirectoryUser) {
                        e.preventDefault();
                        e.stopPropagation();
                        onBlurColumnClick();
                    }
                }}  
                to={isVcCompanyMatch != null ? routeNames.vcCompanyInvestmentDealById(cell.value?.id, cell.value?.dealId) : routeNames.peCompanyInvestmentDealById(cell.value?.id, cell.value?.dealId)}>
                    {cell?.value?.date != '' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"}
                </Link>
            },
            sortType: dealDateSort
        },
        {
            Header: "Status",
            accessor: "status",
            
        },
        {
            Header: "Follow on funding",
            accessor: "followOnFunding",
           
        }
    ], [customStringSortMemo, location.state?.prevPath]);

    /* The `rowData` constant is using the `useMemo` hook to memoize a function that transforms the
    `props.investmentsData` array into a new array of objects. Each object in the new array
    represents a row in the table and contains properties such as `incubateeName`, `dealDate`,
    `status`, and `followOnFunding`. The values for these properties are extracted from the
    corresponding properties in each object of the `props.investmentsData` array. */
    const rowData = useMemo(() => {
        const rows = [];

        props.investmentsData?.forEach(company => {
            rows.push({
                incubateeName: { name: company?.incubatee_name, dealId: company?.deal_id, id: company?.company_id, icon_path: company?.icon_path },
                dealDate: { date: company?.deal_date, dealId: company?.deal_id, id: company?.company_id },
                status: company?.status,
                followOnFunding: company?.angel_vc_fund,
            })
        });
        setshowXlDownload(rows.length !== 0)

        return rows;
    }, [props.investmentsData])

    /* The `initialSort` constant is using the `useMemo` hook to memoize an array that represents the
    initial sorting configuration for the table in the `InvestorIncubationPortfolioCompanies`
    component. */
    const initialSort = useMemo(() => [
        {
            id: "dealDate",
            desc: true
        }
    ], []);

    const [alert, setalert] = useState({show: false, message:""})

    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets an alert message with a subscription message and displays
     * it.
     */
    const onBlurColumnClick = (cell) => {
        setalert({ show: true, message: Constants.peSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }

    return (
        <>
            <div className="space-y-6 pt-4 px-4 bg-[#EDEDED]">
                <div className=" bg-white rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
                        <div className="flex justify-between mb-2">
                            <p className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  mb-2 lg:leading-[22px]">Incubatee</p>
                            {isStudent =='false' && isTrialExport !='false' && showXlDownload && <ExcelDownloader
                                url={exportExcel}
                                fileName={Constants.exportNames.portfolio_companies_investments(props.investorName)}
                                data={{
                                    "type": primaryMasterId,
                                    "export_from": "detail",
                                    "module_type": "investors",
                                    "category": "incubation",
                                    "section": "company_investments",
                                    "investor_id": [investorId]
                                }} />}
                        </div>
                    </div>

                    <div className="space-y-6">
                        <div className="bg-white pb-2 rounded-[6px]">
                            <DataTable columns={columns} data={rowData} sortBy={initialSort} />

                        </div>

                    </div>
                </div>
            </div>
        {<ConfirmPopup {...alert} onChange={onConfirmation} />}
        </>
    )
}

export default InvestorIncubationPortfolioCompanies