import React from "react";
import {
  useGetRatioHighlights,
  useLatestFinancialHighlights,
} from "./FinancialComponents/ratios";
import { useCard } from "../DetailPageCard/useCard";
import { Tooltip } from "antd";
import { FaInfoCircle } from "react-icons/fa";

const Highlights = ({ financials, menu }) => {
  const latest = useLatestFinancialHighlights(financials);
  const profileRatio = useGetRatioHighlights(financials);

  const shp = menu === 'pe' || menu === 'vc' ? {
    Promoters:
      (financials.cfs_profile?.shp_promoter /
        (financials.cfs_profile?.shp_promoter +
          financials.cfs_profile?.shp_non_promoter)) *
      100,
    Non_Promoters:
      (financials.cfs_profile?.shp_non_promoter /
        (financials.cfs_profile?.shp_promoter +
          financials.cfs_profile?.shp_non_promoter)) *
      100,
      year: `as per FY${financials.cfs_profile?.shp_year?.toString().slice(-2)} MGT-7 document`,
  } : {
    Promoters:
      (financials.profile?.shp_promoter /
        (financials.profile?.shp_promoter +
          financials.profile?.shp_non_promoter)) *
      100,
    Non_Promoters:
      (financials.profile?.shp_non_promoter /
        (financials.profile?.shp_promoter +
          financials.profile?.shp_non_promoter)) *
      100,
    year: `as per FY${financials.profile?.shp_year?.toString().slice(-2)} MGT-7 document`,
  }

  const fundingValues = useCard(financials);

  return (
    <div className="w-full lg:col-span-2 bg-white mt-5 lg:mt-0  space-x-8 rounded-lg scrollbar">
      <div className="overflow-x-auto lg:grid lg:grid-rows-2 lg:grid-flow-col gap-3">
        <div className="row-span-2 px-5 lg:pl-8 pt-4">
          <div className="overflow-x-auto">
            <p className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold lg:leading-[22px]">
              {`${menu === 'pe' || menu === 'vc' && latest?.fy ? "Highlights" : `Highlights (FY${latest.fy.length > 2 ? latest.fy.slice(0,2) : latest.fy})`}`}
            </p>
            <div className=" w-full bg-[#FFFFFF] mt-1">
              <div className="">
                {menu === "pe" || menu === 'vc' ? (
                  <div>
                    <div className="grid grid-cols-3">
                    <div
                      className="border-b pr-[1rem]"
                    >
                      <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                        <p
                          className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                          readOnly
                        >
                          Total Funding
                        </p>

                        <p
                          className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                          readOnly
                        >
                          {fundingValues?.totalFunding !== "0.00"
                            ? `$ ${fundingValues?.totalFunding} M`
                            : "--"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="border-b pr-[1rem]"
                    >
                      <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                        <p
                          className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                          readOnly
                        >
                          Total Exits
                        </p>

                        <p
                          className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                          readOnly
                        >
                          {fundingValues?.totalExits !== "0.00"
                            ? `$ ${fundingValues?.totalExits} M`
                            : "--"}
                        </p>
                      </div>
                    </div>
                    <div
                      className="border-b pr-[1rem]"
                    >
                      <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                        <p
                          className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                          readOnly
                        >
                          No. of Investors
                        </p>

                        <p
                          className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                          readOnly
                        >
                          {fundingValues?.no_of_investors !== 0
                            ? fundingValues?.no_of_investors
                            : "--"}
                        </p>
                      </div>
                    </div>
                    </div>
                    {fundingValues?.numberOfAcquisitions !== 0 || fundingValues?.numberOfAcquirers !== 0 ? (
                      <div className="grid grid-cols-3">
                        <div
                          className="border-b pr-[1rem]"
                        >
                          <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                            <p
                              className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Acquisitions
                            </p>

                            <p
                              className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                              readOnly
                            >
                              {fundingValues?.numberOfAcquisitions !== 0
                                ? fundingValues?.numberOfAcquisitions
                                : "--"}
                            </p>
                          </div>
                        </div>
                        <div
                          className="border-b pr-[1rem]"
                        >
                          <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                            <p
                              className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                              readOnly
                            >
                              Acquired By
                            </p>

                            <p
                              className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                              readOnly
                            >
                              {fundingValues?.numberOfAcquirers !== 0
                                ? fundingValues?.numberOfAcquirers
                                : "--"}
                            </p>
                          </div>
                        </div>
                        <div className="border-b pr-[1rem]"></div>
                      </div>
                    ): (
                      <></>
                    )}
                  </div>
                ) : <></>}
                <div className="grid grid-cols-3">
                <div
                  className={`${menu === "cfs" ? "border-b" : ""} pr-[1rem]`}
                >
                  <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                    <p
                      className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                      readOnly
                    >
                      {`${menu === 'cfs' || latest?.fy === "--" ? "Revenue" : `Revenue (FY${latest.fy})`}`}
                    </p>

                    <p
                      className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                      readOnly
                    >
                      {latest.total_income}
                    </p>
                  </div>
                </div>
                <div
                  className={`${menu === "cfs" ? "border-b" : ""} pr-[1rem]`}
                >
                  <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                    <p
                      className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                      readOnly
                    >
                      {`${menu === 'cfs' || latest?.fy === "--" ? "EBITDA" : `EBITDA (FY${latest.fy})`}`}
                    </p>

                    <p
                      className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                      readOnly
                    >
                      {latest.ebitda}
                    </p>
                  </div>
                </div>
                <div
                  className={`${menu === "cfs" ? "border-b" : ""} pr-[1rem]`}
                >
                  <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                    <p
                      className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                      readOnly
                    >
                      {`${menu === 'cfs' || latest?.fy === "--" ? "PAT" : `PAT (FY${latest.fy})`}`}
                    </p>

                    <p
                      className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                      readOnly
                    >
                      {latest.pat}
                    </p>
                  </div>
                </div>
              </div>
              </div>
              {menu === "cfs" && (
                <>
                  <div className="grid grid-cols-2">
                    <div className="border-b pr-[1rem]">
                      <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                        <p
                          className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                          readOnly
                        >
                          Current Ratio
                        </p>

                        <p
                          className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                          readOnly
                        >
                          {profileRatio.current_ratio || "--"}
                        </p>
                      </div>
                    </div>
                    <div className="border-b pr-[1rem]">
                      <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                        <p
                          className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                          readOnly
                        >
                          Debt Equity Ratio
                        </p>

                        <p
                          className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                          readOnly
                        >
                          {profileRatio.debt_equity_ratio || "--"}
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-2">
                    <div className="pr-[1rem]">
                      <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                        <p
                          className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                          readOnly
                        >
                          RoE
                        </p>

                        <p
                          className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                          readOnly
                        >
                          {profileRatio.roe || "--"}
                        </p>
                      </div>
                    </div>
                    <div className="pr-[1rem]">
                      <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                        <p
                          className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                          readOnly
                        >
                          RoA
                        </p>

                        <p
                          className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                          readOnly
                        >
                          {profileRatio.roa || "--"}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {shp?.Promoters || shp?.Non_Promoters ? <div className="border-b" style={{margin:"0px"}}></div>: <></>}
      {shp?.Promoters || shp?.Non_Promoters ? (
        <div className="row-span-1 py-2 mb-2">
          <h3 className="flex items-center gap-1 text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold pt-2 lg:leading-[22px]">
            Shareholding Pattern
            {shp.year !== "" && <Tooltip 
              color="#ffffff"
              key="#ffffff"
              overlayStyle={{ maxWidth: '30%'  }}
              title={<span style={{ color: '#000000' }}>{shp.year}</span>}
            >
              <FaInfoCircle size={12} color="grey"/>
            </Tooltip>}
          </h3>
          <div className="w-4/5">
            <div className="grid grid-cols-2 gap-3 mt-1">
              <div className="py-1  lg:leading-[21px] font-sans_book_body">
                <p
                  className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                  readOnly
                >
                  Promoters
                </p>

                <p
                  className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                  readOnly
                >
                  {shp?.Promoters.toFixed(2)}%
                </p>
              </div>
              <div className="py-1  lg:leading-[21px] font-sans_book_body">
                <p
                  className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                  readOnly
                >
                  Non Promoters
                </p>

                <p
                  className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                  readOnly
                >
                  {shp?.Non_Promoters.toFixed(2)}%
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Highlights;
