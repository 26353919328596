import React, { useState, useEffect } from 'react'
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../../Components/Common/ChartsDynamicColor";
import { Link, NavLink } from 'react-router-dom';
import routePathSegments from '../../../../routeSegments';
import Loader from '../../../../Components/Loader';


/**
 * The function `ReportsChartMixed` is a React component that renders a mixed chart (bar and line)
 * based on the provided data and filters.
 * @returns The component is returning a React fragment that contains a div with a chart inside it. The
 * chart is rendered using the ReactApexChart component and displays a line chart with two series: "No
 * of Deals" and "Amount(US$ M)". The chart data is obtained from the chartData state variable, which
 * is updated in the useEffect hook based on the resultSet and reportsFilter props. The chart
 */
const ReportsChartMixed = ({ dataColors, resultSet,isRenderingInitial, reportsFilter, masterData, isLoading }) => {

    // chartData

    const product = reportsFilter?.product;
    // console.log('product from pie', reportsFilter);

   
    const [chartData, setChartData] = useState({
        deals: [],
        amount: [],
        years: [],
    });


    /**
     * The function "numberWithCommas" takes a number as input and returns a string representation of
     * that number with commas separating every three digits.
     * @param x - The parameter "x" is a number that you want to format with commas.
     * @returns the input number with commas added as thousands separators.
     */
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    // console.log('from Reports chartDataFull', resultSet?.loadResponse?.resultSet[0]);
    // console.log('from Reports chartDataFull', resultSet[0]?.loadResponse)

    useEffect(() => {
        try {

            const data = resultSet?.loadResponse;
            console.log('from from api', data);

            const deals = [];
            const amount = [];
            const years = [];

            const dimensions = resultSet?.loadResponse?.results[0]?.query?.dimensions;
            const periodKey = dimensions?.length > 1 ? dimensions[1] : dimensions[0]
            console.log("periodKey-08",periodKey);


            if (product == 'PE') {

                resultSet?.loadResponse?.results[0].data.forEach(item => {
                    deals.push(item['DealFlat.deal_id']);
                    amount.push(item['DealFlat.AmountM']);
                    years.push(item[periodKey]);
                });
            } else if (product == 'MA') {
                //MADateMaster.cy
                //MaInvestmentsFlat.dealAmount: 0
                // MaInvestmentsFlat.dealCount
                resultSet?.loadResponse?.results[0].data.forEach(item => {
                    deals.push(item['MaInvestmentsFlat.ma_dealCount']);
                    amount.push(item['MaInvestmentsFlat.ma_dealAmount']);
                    years.push(item[periodKey]);
                });
            } else if(product == 'PE-RE') {
                resultSet?.loadResponse?.results[0].data.forEach(item => {
                    deals.push(item['REinvestments.count']);
                    amount.push(item['REinvestments.amount']);
                    years.push(item[periodKey]);
                });
            }
            else{

                resultSet?.loadResponse?.results[0].data.forEach(item => {
                    deals.push(item['DealFlat.deal_id']);
                    amount.push(item['DealFlat.AmountM']);
                    years.push(item[periodKey]);
                });
            }


            if (resultSet?.loadResponse && resultSet?.loadResponse.results && resultSet?.loadResponse.results[0] && resultSet?.loadResponse.results[0].data) {
               
            }

            setChartData({
                deals,
                amount,
                years
            });

            // setIsLoading(false);
        } catch (error) {
            const deals = [];
            const amount = [];
            const years = [];
            console.error(error);
            // setIsLoading(false);
        }

    }, [resultSet,reportsFilter]);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //       setIsLoading(false);
    //     }, 5000);
    //     return () => clearTimeout(timer);
    //   }, []);

    const { deals, amount, years } = chartData;
    console.log("chartData",chartData);


    var chartMultiColors = getChartColorsArray(dataColors);

    
    const series = [
        {
            name: "No of Deals",
            type: "bar",
            data: deals //[1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
        },
        {
            name: "Amount(US$ M)",
            type: "line",
            data: amount //[20, 29, 37, 36, 44, 45, 50, 58],
        },
    ];

    var options = {
        chart: {
            type: 'bar',
            stacked:true,
            // stacked: !1,
            toolbar: {
                show: !1,
            },
            zoom:{
                enabled:false
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '20%',
                borderRadius: 6
            }
        },
        dataLabels: {
            enabled: !1,
        },
        stroke: {
            width: [1, 1, 1],
            dashArray: [0, 0],
        },
        // title: {
        //     text: "By Year on Year Chart",
        //     align: "left",
        //     offsetX: 110,
        //     style: {
        //         fontWeight: 600,
        //     },
        // },
        xaxis: {
            categories: years,
            tooltip: {
                enabled: false,
            },
            labels:{
                formatter: (value, timeStampe, opts) => {

                    const dateMasterKeys = {
                        PE: "DateMasterCombo",
                        MA: "DateMasterCombo",
                        "PE-RE": "DateMasterCombo",
                    }
                    const periodKeys = {
                        CY: "cy",
                        FY: "fy",
                    }
                    const periodTypeKeys = {
                        quarterly: "qy",
                        halfYearly: "hy",
                        monthly: "my",
                        yearly: "",
                    }
                    if ((reportsFilter?.period == "CY" && reportsFilter?.viewOption === "yearly") || reportsFilter?.period == null) {
                        return value;
                        // formatter: (value) => '$ '+ numberWithCommas(Math.floor(value)),
                    }
                    const searchKey = `${dateMasterKeys[product ?? "PE"]}.${periodKeys[reportsFilter?.period ?? "FY"]}${periodTypeKeys[reportsFilter?.viewOption ?? "yearly"]}`;
                    console.log("searchKey",searchKey,masterData?.filter(data => data[searchKey] == value)?.at(0)?.[`${searchKey}Desc`] );
                    return masterData?.filter(data => data[searchKey] == value)?.at(0)?.[`${searchKey}Desc`] 
                }
            }
        },
        yaxis: [
            {
                axisTicks: {
                    show: !0,
                },
                axisBorder: {
                    show: !0,
                    color: "#BC8B20",
                },
                labels: {
                    formatter: (value) => numberWithCommas(Math.floor(value)),
                    style: {
                        colors: "#BC8B20",
                    },
                },
                title: {
                    text: "No of Deals",
                    style: {
                        color: "#BC8B20",
                        fontWeight: 600,
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },

            {
                seriesName: "Amount",
                opposite: !0,
                axisTicks: {
                    show: !0,
                },
                axisBorder: {
                    show: !0,
                    color: "#474747",
                },
                labels: {
                    formatter: (value) => '$ '+ numberWithCommas(Math.floor(value)),
                    style: {
                        colors: "#474747",
                    },
                },
                title: {
                    text: "Amount(US$ M)",
                    style: {
                        color: "#474747",
                        fontWeight: 600,
                    },
                },
            },
        ],
        tooltip: {
            fixed: {
                enabled: !0,
                position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60,
            },
        },
        legend: {
            horizontalAlign: "left",
            offsetX: 40,
        },
        colors: chartMultiColors,
    };


    return (
        <React.Fragment>
            <div className='bg-white bg-[#ebebeb] border shadow rounded block w-full border-[#ebebeb] '>
                <div className='flex justify-center'>
                    {/* <h1 className=' text-lg my-1 font-medium'>Graphical Data</h1> */}
                </div>
                <div className='bg-[#ffffff] md:mt-5 md:mx-4 md:mb-4 my-1 mx-1 xl:mx-4 xl:my-4 border-t-[2px]  '>
                    <h3 className='m-3 border-b-2 border-b-primaryColor w-fit'> {' '} </h3>
                    <div className='p-1 xl:flex xl:justify-center xl:items-center '>
                        {isLoading && resultSet && isRenderingInitial ?  
                        <div className='w-full flex items-center justify-center h-[363px]'>
                            <Loader />
                        </div>
                        :   
                        <div className='     mr-1 xl:w-[580px]'>
                            <ReactApexChart
                                className="apex-charts w-full "
                                options={options}
                                series={series}
                                type="line"
                                height={350}
                                width={'100%'}
                            />  
                        </div>                                     
                        }
                    </div>
                </div>
                
            </div>

        </React.Fragment>
    );
};


export {
    ReportsChartMixed
};