import React from 'react';
import { useMatch } from 'react-router-dom';
import routePathSegments, { routeNames } from '../../routeSegments'
import NavbarItems from './NavbarItems'
import NavbarWrapper from './NavbarWrapper'

/** This component renders a header for a `VC` section of a
website. It uses the `useMatch` hook from the `react-router-dom` library to determine if the current
URL matches certain paths. */
function VCHeader() {
    const isCompanyMatch = useMatch({ path: `/${routePathSegments.vc}/${routePathSegments.company}/*` })
    const isInvestorMatch = useMatch({ path: `/${routePathSegments.vc}/${routePathSegments.investor}/*` })
    const isAdvisorMatch = useMatch({ path: `/${routePathSegments.vc}/${routePathSegments.advisor}/*` })
    const isTransactionAdvisorMatch = useMatch({ path: `/${routePathSegments.vc}/${routePathSegments.transaction_advisor}/*` })
    const isActiveInvestorMatch = useMatch({ path: `/${routePathSegments.vc}/${routePathSegments.activeInvestors}/*` })
    const isNewInvestorMatch = useMatch({ path: `/${routePathSegments.vc}/${routePathSegments.newInvestors}/*` })
    const companyMatch = useMatch({ path: `/${routePathSegments.company}/*` })

    return (
        <NavbarWrapper>
            <NavbarItems
                title="Companies"
                to={routeNames.VC_company}
                isLinkActive={isCompanyMatch != null || companyMatch != null}
                pageTitle="VC Companies PE-VC Investments - Venture Intelligence"
            />
            <NavbarItems
                title="Investors"
                to={routeNames.VC_investor}
                isLinkActive={isInvestorMatch != null || isNewInvestorMatch != null || isActiveInvestorMatch != null}
                pageTitle="VC Investors PE-VC Investments - Venture Intelligence"

            />
            <NavbarItems
                title="Legal Advisors"
                to={routeNames.VC_advisor}
                isLinkActive={isAdvisorMatch!=null}
                pageTitle="VC Advisors PE-VC Investments - Venture Intelligence"

            />
            <NavbarItems
                title="Transaction Advisors"
                to={routeNames.VC_transaction_advisor}
                isLinkActive={isTransactionAdvisorMatch!=null}
                pageTitle="VC Transaction Advisors PE-VC Investments - Venture Intelligence"

            />
        </NavbarWrapper>
    )
}

export default VCHeader