import { useEffect, useRef } from 'react';

const useScrollIntoView = (trigger, options = { behavior: 'smooth', block: 'nearest' }) => {
  const elementRef = useRef(null);
  const prevTriggerRef = useRef(trigger);

  useEffect(() => {
    console.log(trigger, "kjhgfu");
    if (trigger && !prevTriggerRef.current && elementRef.current) {
      setTimeout(() => {
        elementRef.current.scrollIntoView(options);
      }, 300);
    }
    prevTriggerRef.current = trigger;
  }, [trigger, options]);

  return elementRef;
};

export default useScrollIntoView;
