import React, { useState, useMemo, useEffect, useContext } from "react";
import {  useParams, Link, useLocation, matchPath } from 'react-router-dom';
import moment from "moment";
import CompanyLogo from "../CompanyLogo";
import RandomLogo from "../RandomLogo";
import DataTable from '../../Components/DataTables/DataTable';
import ExcelDownloader from "../shared/ExcelDownloader";
import { exportExcel, exportPDF, getSimilarCompanyUrl } from "../../Config/config";
import routePathSegments, { routeNames } from '../../routeSegments';
import axios from "axios";
import { UserContext } from "../../Contexts/UserContext";
import Constants from "../../Constants";
import useModule from "../../hooks/useModule";
import OverflowToolTip from '../shared/OverflowToolTip';
import { DataFoundContext } from "../../Contexts/DataFoundContext";
import { PeFilterContext } from "../../Contexts/PeFilterContext";


/**
 * The `SimilarCompanies` function is a React component that displays a table of similar companies
 * based on a given company name.
 */
const SimilarCompanies = ({ companyName, menu }) => {

    const [similarCompanies, setSimilarCompanies] = useState();
    const [showXlDownload, setshowXlDownload] = useState(false);
    const { companyId } = useParams();
    const location = useLocation();
    const {isTrialExport, getToken, isStudent } = useContext(UserContext);
    const { monthPickerValue } = useContext(PeFilterContext);
    const{primaryMasterId}=useModule();
    const [bodyForExport, setBodyForExport] = useState({
    "Export_from": "list",
    "with_deals": true,
    "with_transaction": true,
    "is_refine": true,
    "type": menu === "vc" ? "vc_investment" : "pe_vc_investment",
    "company_id": [],
    "deal_ids": [],
    "module_type": menu === "vc" ? "vc" : "pe",
    "with_agg_hide": 0,
    "profile_columns": [
        "ALL"
    ],
    "inv_deal_columns": [
        "Company Name",
        "Registered Entity Name",
        "Industry",
        "Sector",
        "CIN",
        "Location",
        "Website",
        "Company Type",
        "Contact",
        "Amount(US$M)",
        "Amount(INR Cr)",
        "Exit Status",
        "Date",
        "Stake (%)",
        "Stage",
        "Round",
        "Round(By Series)",
        "Link",
        "Price Per Share",
        "Book Value Per Share",
        "Price to Book",
        "Equity/Debt",
        "Valuation",
        "Year Founded",
        "Pre Money Valuation (INR Cr)",
        "Revenue Multiple (Pre)",
        "EBITDA Multiple (Pre)",
        "PAT Multiple (Pre)",
        "Post Money Valuation (INR Cr)",
        "Revenue Multiple (Post)",
        "EBITDA Multiple (Post)",
        "PAT Multiple (Post)",
        "Enterprise Valuation (INR Cr)",
        "Revenue Multiple (EV)",
        "EBITDA Multiple (EV)",
        "PAT Multiple (EV)",
        "Investors",
        "Investor Type",
        " Financial Year",
        "Revenue (INR Cr)",
        "EBITDA (INR Cr)",
        "PAT (INR Cr)",
        "Total Debt (INR Cr)",
        "Cash & Cash Equ. (INR Cr)",
        "Advisor-Company",
        "Advisor-Investors",
        "More Info"
    ],
    "transaction_category": [
        "investments",
        "exits",
        "ipos",
        "angel",
        "incubation"
    ],
    "exit_deal_columns": [
        "Portfolio Company",
        "Registered Entity Name",
        "Industry",
        "Sector_Business Description",
        "CIN",
        "Location",
        "Website",
        "Company Type",
        "Deal Amount (US$M)",
        "Exit Status",
        "Deal Date",
        "Acquirer",
        "Stake Sold(%)",
        "Deal Type",
        "Type",
        "Link",
        "Price Per Share",
        "Book Value Per Share",
        "Price to Book",
        "Exiting Investors",
        "Return Multiple",
        "IRR (%)",
        "Company Valuation (INR Cr)",
        "Revenue Multiple",
        "EBITDA Multiple",
        "PAT Multiple",
        "Valuation (More Info)",
        "Investment Details",
        "Addln Info",
        "More Info(Returns)",
        "Revenue (INR Cr)",
        "EBITDA (INR Cr)",
        "PAT (INR Cr)",
        "Advisor-Buyer",
        "Advisor-Seller",
        "Year Founded",
        "Investor Type"
    ],
    "ipo_deal_columns": ["ALL"],
    "angel_deal_columns": ["ALL"],
    "incb_deal_columns": ["ALL"],
    "date_range": {
        "from_date": "1998-07-01",
        "to_date": moment(monthPickerValue[1]).format("YYYY-MM-DD")
    }
})

    const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);
    const vcMatch = matchPath(`${routePathSegments.vc}/*`, location.pathname);
    
    const { similarCompaniesDataFound, setSimilarCompaniesDataFound } = useContext(DataFoundContext);
    

    const getSimilarCompanies = () => {


        axios.post(getSimilarCompanyUrl, {
            "company_id": companyId,
            "type": primaryMasterId
        }, {
            headers: {
                'Authorization': getToken(),
            }
        }).then(response => {
            if (response.status == 200 && response?.data?.data) {
                setSimilarCompanies(response.data.data);
                setSimilarCompaniesDataFound(true);
                setBodyForExport(prev => ({
                    ...prev,
                    company_id: response.data.data?.map(val => val.company_id)
                }));
            } else if (response?.data?.message === "No Data Found") {
                setSimilarCompaniesDataFound(false);
            }
        }).catch(error => {
            setSimilarCompaniesDataFound(false);
        })
    }

  console.log(similarCompaniesDataFound, 'from useEffect', companyId);


    useEffect(() => {
        getSimilarCompanies();
    return () => {
        setSimilarCompaniesDataFound(false);
    }
    }, [companyId]) 



    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);

    const customSectorSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.sector ?? defaultVal).localeCompare(rowB?.values[columnId]?.sector ?? defaultVal);
        }
    }, []);


    /* The `dealDateSort` constant is a custom sorting function that is used to sort the data in the
    "Latest Deal Date" column of the table. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {

            const dateA = moment.utc(rowA?.values[columnId].date);
            const dateB = moment.utc(rowB?.values[columnId].date);

            if (!dateA.isValid()) {
                return -1;
            }
            if (!dateB.isValid()) {
                return 1;
            }
            return dateA.diff(dateB)
        }
    }, [])

    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };

    const columns = useMemo(() => [
        {
            id: "companyName",
            Header: "Company Name",
            accessor: "companyName", 
            className: "clickable w-[15%] text-left sticky left-0 lg:relative   bg-white",
            headerClassName: "text-left sticky left-0  lg:relative w-[3rem] bg-[#EDEDED] ",
            Cell: ({ value }) => {
                return <span className=" leading-[1.8rem] lg:leading-[2rem] font-sans_book_body items-center justify-center">
                    {value?.logo_path == "default.png" || value?.logo_path == "/default.png" || !value?.logo_path ?
                        <RandomLogo name={value?.name} />
                        :
                        <CompanyLogo name={value?.name} id={value.id} page="company page" logo={value?.logo_path} />
                    }
                    {/* <CompanyLogo id={value.id} /> */}
                    <OverflowToolTip className="w-[10rem]" component={  <Link
                        // state={{ prevPath: location.state?.prevPath }}
                        // to={routeNames.companyById(value?.id)}>
                        className="flex text-start lg:inline leading-[1.8rem] hover:text-[#BC8B20] text-[#333333]"
                        // target='_blank'
                        // state={{ prevPath: location.pathname }}
                        to={peMatch != null
                            ? routeNames.peCompanyFundById(value?.id)
                            : vcMatch != null
                                ? routeNames.vcCompanyFundById(value?.id)
                                : routeNames.peCompanyFundById(value?.id)}>
                        {value.name ?? "--"}
                    </Link>}/>
                </span>

            },
            sortType: customStringSortMemo,
        },
        {
            Header: "Sector",
            accessor: "sector",
            className: "w-[2%]",
            Cell: ({value}) => {
                return <span style={{whiteSpace:"break-spaces"}} >
                     <OverflowToolTip className='w-[14rem]' component={ 
                            value.sector
                      }/>
                </span>
                       
                        
                    },
             
                    sortType: customSectorSortMemo,
        },
        {
            Header: "Total Funding (US$ M)",
            accessor: "totalFunding",
            className: "px-0 pr-[2.5rem] text-right w-[1%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },

        },
        {
            Header: "Latest Round",
            accessor: "latestRound",
            className: "w-[9%]",
        },
        {
            id: 'dealDate',
            Header: "Latest Deal Date",
            accessor: "latestDealDate",
            className: "w-[6%]",
            Cell: (cell) => {
                return <Link state={{ prevPath: location.state?.prevPath ?? "" }} to={vcMatch != null ?routeNames.vcCompanyInvestmentDealById(cell.value.id, cell.value.dealId)                    
                :routeNames.peCompanyInvestmentDealById(cell.value.id, cell.value.dealId)}>
                    {cell?.value?.date != '' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"}
                </Link>
            },
            sortType: dealDateSort
        },
        {
            Header: "Latest Deal Amount",
            accessor: "latestDealAmount",
            className: "px-0 pr-[2.5rem] text-right w-[2%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },

        },
        {
            Header: "City",
            accessor: "city",
            className: "w-[6%]",

        }
    ], []);

    const rowData = useMemo(() => {
        const rows = [];                 
        similarCompanies?.forEach(company => {
            // let dealId = 0;
            // company?.investments?.forEach(company => {
            //     dealId = company?.PEId
            // })
            rows.push({
                companyName: { name: company?.company_name, id: company?.company_id, logo_path: company?.icon_path },
                sector: {sector:company?.sector},
                totalFunding: company?.total_funding == 0 ? "" :company?.total_funding,
                latestRound: company?.latest_round,
                latestDealDate: { date: company?.deal_date, id: company?.company_id, dealId: company?.deal_id },
                latestDealAmount: company?.deal_amount == 0 ? "" :company?.deal_amount,
                city: company?.city,
            })
        });

        setshowXlDownload(rows.length != 0)

        return rows;
    }, [similarCompanies])
    const initialSort = useMemo(() => [
        {
            id: 'dealDate',
            desc: true
        }
    ], []);

    return (
        <>
        {similarCompaniesDataFound ? (
        <div className="mx-4">
            <div className="bg-white overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="pt-4 px-5">
                    <div className="flex justify-between mb-3">
                        <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] ">Similar Companies</h3>
                        {
                           (isTrialExport != 'false' && isStudent == 'false' && showXlDownload) && <ExcelDownloader
                                url={exportPDF}
                                fileName={Constants.exportNames.similarCompanies(companyName)}
                                data={bodyForExport} />
                        }
                    </div>
                </div>
                <div className="space-y-6">
                    <div className="bg-white pb-2 rounded-[6px]">
                        <DataTable columns={columns} data={rowData} sortBy={initialSort} />
                    </div>
                </div>
            </div>
        </div>
       ) : ("")}
    </>
    );
};

export default SimilarCompanies;
