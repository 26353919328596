import { React, useState, useMemo, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import moment from "moment";
import { routeNames } from '../../routeSegments';
import { FundingContext } from '../../Contexts/FundingDataProvider';
import OverflowToolTip from '../shared/OverflowToolTip';
import Constants from '../../Constants';
import Paginate from '../Paginate';
import ConfirmPopup from '../../Utils/ConfirmPopup';
import { useSortBy, useTable,usePagination } from 'react-table';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import LockIcon from 'mdi-react/LockIcon';
import { UserContext } from '../../Contexts/UserContext';

/* The above code is a React component written in JavaScript that renders a table for displaying data
related to funding and IPOs. */
const FundingTableBackedIpo = ({menu,data,companyFundingId}) => {
    const{setipoData} = useContext(FundingContext);
    const location = useLocation();
    const [alert, setalert] = useState({ message: "", show: false });
    const { isDirectoryUser} = useContext(UserContext);

    /**
     * The function `onConfirmation` sets the state of `alert` to hide the alert message.
     */
    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets an alert message with a subscription message and displays
     * it.
     */
    const onBlurColumnClick = (cell) => {

        setalert({ show: true, message: Constants.peSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }

    /* The above code is defining a custom sorting function called `dealDateSort` using the `useMemo`
    hook in React. This sorting function takes in two rows (`rowA` and `rowB`), a column ID, and a
    boolean flag `desc` indicating whether the sorting should be in descending order. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, []) 
    /* The above code is defining a custom sorting function called `investorSort` using the `useMemo`
    hook in React. This sorting function is used to sort rows in a table based on the values in a
    specific column. */
    const investorSort = useMemo(() => {
        return (rowA,rowB, columnId,desc)=>{
            const defaultVal = desc ? '--' : 'A';
            const valueA = rowA?.values[columnId].map(data=>data.Investor).join("")            
            const valueB = rowB?.values[columnId].map(data=>data.Investor).join("")

            return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
        }
    }, []) 
    /* The above code is defining an array of columns for a table in a React component. Each column has
    a Header (displayed in the table header), an accessor (used to access the data for that column),
    and a Cell component (used to render the data in each row of the table). The code also includes
    some conditional logic to determine whether certain columns should be blurred or disabled based
    on the value of the `allowedModulesList` variable. Additionally, there are some helper functions
    used to format numbers and a dependency array for the `useMemo` hook. */
    const columns = useMemo(() => {
        let blurColumns = true;

        const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
        const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];
        if (allowedModulesList.includes("pe")) {
            blurColumns = false;
        } else {
            blurColumns = true;
        }

        const addCommasToNumber = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          };

        return [
            
            {
                Header: "Investors",
                accessor: "investors",
                 
                className: "clickable text-left sticky left-0 lg:relative  w-[3rem] bg-white",
                headerClassName: "text-left sticky left-0  lg:relative  w-[3rem] bg-[#EDEDED] ",
               
                Cell: (cell) => {
                    return <OverflowToolTip className=" w-[12rem] lg:auto sm:max-w-[20rem] 2xl:max-w-[35rem]" component={
                        cell.value.map((inv, index) => (<Link
                            onClick={(e) => {
                                if (menu == "cfs" ||menu == "ma") {
                                    if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc")) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onBlurColumnClick();
                                    }
                                }
                            }}
                            rel="noopener noreferrer"
                            className={`${inv.investor_id === 9 ? "cursor-default" : 'hover:text-[#BC8B20]'}` }
                            // state={{ prevPath: location.pathname }}
                            to={menu == "cfs" ||menu == "ma"
                                ? allowedModulesList.includes("pe")
                                    ? inv.investor_id === 9 ? null : routeNames.peInvestorById(inv.investor_id)
                                    : inv.investor_id === 9 ? null : routeNames.vcInvestorById(inv.investor_id)
                                : menu =="vc"
                                    ? inv.investor_id === 9 ? null : routeNames.vcInvestorById(inv.investor_id)
                                    : inv.investor_id === 9 ? null : routeNames.peInvestorById(inv.investor_id)}>
                            {`${inv.investor_name}${index == cell.value?.length - 1 ? "" : ", "}`}
                        </Link>))
                    } />
                },
                sortType:investorSort
            },
            {
                id:"dealDate",
                Header: "Deal Date",
                accessor: "dealDate",
                Cell: ({ value }) => {
                    const date = value?.date ? moment(value.date , 'MMM YYYY').format('MMM YYYY') : ""; // Check if date is not null

                    return <Link 
                    onClick={(e) => {
                        if (menu == "cfs" || menu == "ma" || isDirectoryUser) {
                            if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc") || isDirectoryUser) {
                                e.preventDefault();
                                e.stopPropagation();
                                onBlurColumnClick();
                            }
                        }
                    }}
                    state={{ prevPath: location.state?.prevPath ?? "" }} to={
                        menu == "cfs"||menu == "ma"
                        ? allowedModulesList.includes("pe")
                            ? routeNames.peCompanyBackedIPOSDealById(companyFundingId, value.id)
                            : routeNames.vcCompanyBackedIPOSDealById(companyFundingId, value.id)
                        : menu=="vc" 
                        ?routeNames.vcCompanyBackedIPOSDealById(companyFundingId, value.id)
                       :routeNames.peCompanyBackedIPOSDealById(companyFundingId, value.id)}>
                        {date}
                      </Link>
                },
                sortType:dealDateSort
            },
             {
                Header: "IPO Size (US$ M)",
                accessor: "dealType",
                className:"clickable",
                className:"px-0 pr-[2.8rem] lg:pr-[2.5rem] w-[10%] text-right",
                headerspanClassName:"ml-auto",

                Cell: (cell) => {
                    return addCommasToNumber(cell?.value)
                },
                // Cell: ({ value }) => {
                //     return <p>
                //          {value.type ?? "--"}
                //       </p>
                // },
                // sortType: customStringSortMemo,
            },
            {
                Header: "Investor Sale in IPO?",
                accessor: "amountRealizedInUS",
                className:"px-5 w-[10%] ",
                headerspanClassName:"ml-auto",
            },
            {
                Header: "IPO Valuation (US$ M)",
                accessor: "existStatus",
                className: "px-0 pr-[2.7rem] lg:pr-[2.7rem] w-[10%] text-right",
                headerspanClassName:"ml-auto",
                Cell: (cell) => {
                    return addCommasToNumber(cell?.value)
                },
                // blurColumns: blurColumns,
                // disableSortBy: blurColumns,
            },
            
        ];
    }, [menu,companyFundingId])

    /* The above code is a React component that uses the `useMemo` hook to create a memoized version of
    the `rowData` variable. */
    const rowData = useMemo(() => {
        let rows = [];
    
        // console.log("investorIpo", funding);

        data?.forEach(ipoInvestor => {
            rows.push({
                investors: ipoInvestor?.Investors,
                dealDate: { date: ipoInvestor.Deal_Date, id: ipoInvestor.Deal_Id },
                dealType: ipoInvestor['IPO_Size (US$ M)'] == 0 ? "" : ipoInvestor['IPO_Size (US$ M)'],
                amountRealizedInUS: ipoInvestor?.Investor_Sale_in_IPO == 0 ? "" : ipoInvestor?.Investor_Sale_in_IPO,
                existStatus: ipoInvestor['IPO_Valuation_(US$ M)'] == 0 ? "" : ipoInvestor['IPO_Valuation_(US$ M)']
            })
        });

        setipoData(rows.length == 0 ? null : rows);

        return rows;

    }, [data])

   /* The above code is using the `useMemo` hook in React to create an initial sort configuration. It
   is creating an array with a single object that has two properties: `id` and `desc`. The `id`
   property is set to 'dealDate' and the `desc` property is set to `true`. This initial sort
   configuration can be used in a component to specify the initial sorting of data. */
    const initialSort = useMemo(() => [
        {
            id: 'dealDate',
            desc: true
        }
    ], []);

    /* The above code is using the `useTable` hook from the React-Table library to create a table
    instance. */
    const tableInstance = useTable({
        columns,
        data: rowData,
        disableSortRemove: true,
        initialState: {
            pageSize: Constants.noOfRowPerPage,
            pageIndex: 0,
            sortBy: initialSort,
        },
    },
        useSortBy,
        usePagination,
    );

    /* The above code is using destructuring assignment to extract specific properties and methods from
    the `tableInstance` object. These extracted properties and methods are then being used in a
    JavaScript React component. */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize }
    } = tableInstance;

    return (
        <div className="space-y-6">
            <div className="bg-white">
                <>

                    <div className='overflow-x-auto scrollbar relative'>
                        {rows?.length > 0 ?
                        <table className=" datatable w-full" {...getTableProps()}>

                            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-semibold py-4 px-0 text-center ">
                                {
                                    headerGroups.map(headerGroup => (

                                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps([
                                                        {
                                                            className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                            style: column.headerStyle,
                                                        },
                                                        column.getSortByToggleProps(),
                                                    ])} title="">
                                                        <div className="flex">
                                                            <span className='mr-1'>{column.render('Header')}</span>
                                                            {column.disableSortBy ? "" : column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                    : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>

                            <tbody {...getTableBodyProps()} className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                                {
                                // page?.length == 0
                                //     ? <tr>
                                //         <td colspan="8">
                                //             <div className="h-[4rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] text-center">
                                //                 No data found
                                //             </div>
                                //         </td>
                                //     </tr>
                                //     : 
                                    page?.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="border-b">
                                                {

                                                    row.cells.map(cell => {
                                                        if (cell.column.blurColumns || cell.value == Constants.restrictedPlaceholder) {
                                                            return (<td onClick={onBlurColumnClick} className=' relative whitespace-nowrap cursor-pointer' {...cell.getCellProps()}>
                                                                <div className='blur-sm bg-[#DCDCDC] select-none w-full h-full px-5 py-4'>{Constants.restrictedPlaceholder}</div>
                                                                <div className='absolute h-full w-full flex items-center justify-center top-0 bottom-0'>
                                                                    <LockIcon size={16} color='#696969'/>
                                                                </div>
                                                            </td>)
                                                        }

                                                        return (
                                                            <td colSpan={cell.column.colspan} rowSpan={cell.column.rowspan} {...cell.getCellProps([
                                                                {
                                                                    className: `data-td-animate  lg:whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                                                    style: cell.column.style,
                                                                },
                                                            ])}>
                                                                {
                                                                    cell.value ? cell.render('Cell') : " "
                                                                }
                                                            </td>


                                                        )
                                                    })}
                                            </tr>

                                        )
                                    })}
                            </tbody>
                        </table>
                        : 
                        <>
                            <div className="h-[3rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[1rem] text-center">
                                No data found
                            </div>
                        </>
                        }
                    </div>
                    {rows?.length <= Constants.noOfRowPerPage
                        ? <></>
                        : <Paginate
                            currentPage={pageIndex + 1}
                            totalCount={rows?.length}
                            pageSize={pageSize}
                            onPageChange={(page) => {
                                gotoPage(page - 1);
                            }}
                        />}
                    {<ConfirmPopup {...alert} onChange={onConfirmation} />}
                </>
            </div>
        </div>
    )
}

export default FundingTableBackedIpo