import React from 'react'

/**
 * The `FundMultiselectEntity` function is a React component that renders a multi-select dropdown list of
 * entities with search functionality.
 */
function FundMultiselectEntity(props) {

  const [primaryEntity, setPrimaryEntity] = React.useState(props.primaryEntity);
  const [searchText, setsearchText] = React.useState("");
  const handleEntityChange = props.handleEntityChange;


  React.useEffect(() => {
    if (searchText !== "") {
      const filteredEntity = props.primaryEntity?.filter(entity => entity?.name?.toLowerCase()?.indexOf(searchText.toLowerCase()) !== -1);
      setPrimaryEntity(filteredEntity);
    } else {
      setPrimaryEntity(props.primaryEntity)
    }
  }, [props.primaryEntity, searchText])

  const count = primaryEntity.filter(option => option.isChecked).length;

console.log(props , "hhh" , primaryEntity , count)

  return (
    <>
      {primaryEntity && primaryEntity.length > 0 ? (
        primaryEntity.map((entity, index) => (
          <div
            className={`form-check font-sans_medium_body cursor-default flex text-left  py-2 pr-2  w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto hover:bg-gray-50"`}
            key={index}>
            <input
              type="checkbox"
              className="form-check-input"
              name={entity.name}
              id={entity.id}
              checked={entity?.isChecked || false}
              onChange={handleEntityChange}
            />
            <label htmlFor={entity.id} className="form-check-label mx-2 mr-2 cursor-pointer  text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{entity.name}</label>
          </div>
        ))
      ) :
        (
          <h1 className="text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{props?.field == 'sector' ? 'Choose Industry' : 'No results found!'}</h1>
        )}
    </>
  )
}

export default FundMultiselectEntity
