import {React,useState,useEffect} from "react";
import { Popover } from "antd";

/**
 * The `DealInfo` function is a React component that renders a table displaying information about a
 * fund, including its type, target size, amount raised, date, fund manager, fund status, capital
 * source, and source links.
 * @param props - The `props` parameter is an object that contains data passed to the `DealInfo`
 * component. It is used to access the data needed to render the component.
 * @returns The `DealInfo` component is returning a JSX element, which represents the structure and
 * content of the component's rendered output.
 */
const DealInfo = (props) => {

  const data =
    Object.keys(props?.data).length > 0 &&
    Object.entries(props?.data).map(([key, value]) => {
      return (
        value != "The fund detail has been viewed successfully" &&
        key != "status" &&
        value?.length > 0 &&
        value?.map((round, i) => {

          return Object.entries(round).map(([key1, value1]) => {

            return (
              <div className="">
                {value1?.selected == true ? (
                  <p
                    key={value1?.deal_id}
                    className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold flex  "
                    readonly
                  >
                    {value1?.source ?
                      value1?.source.split(";")?.filter((link) => link?.trim().length !== 0).map((link, index) => {
                        return (
                          <div>
                            <div className="hidden lg:block">
                          <Popover
                            trigger="hover"
                            placement="topLeft"
                            className="text-[11pt] hover:text-[#A5711A] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold hover:underline "
                            content={
                              <a
                                className="text-[11pt] hover:text-[#A5711A] leading-[18pt] lg:text-[13px] text-[#333333] ursor-auto font-semibold hover:underline "
                                href={link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {link}
                              </a>
                            }
                          >
                            <a
                              className="text-[11pt] hover:text-[#A5711A] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold hover:underline "
                              href={link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {link.length > 0 ?
                                <span className={index>0 ? "ml-1":""}>
                                  Link {index + 1}
                                  {value1?.source.split(";")?.filter((link) => link?.trim().length !== 0).length > index + 1
                                    ? ", "
                                    : ""}
                                </span>
                                : "--"
                              }
                            </a>
                          </Popover>
                          </div>
                            <a className="lg:hidden text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold "
                              href={link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {link.length > 0 ?
                                <span className={index>0 ? "ml-1":""}>
                                  Link {index + 1}
                                  {value1?.source.split(";")?.filter((link) => link?.trim().length !== 0).length > index + 1
                                    ? ", "
                                    : ""}
                                </span>
                                : "--"
                              }
                            </a>
                          </div>
                          
                        );
                      }) :"--"
                    }
                  </p>
                ) : (
                  ""
                )}
              </div>
            );
          });
        })
      );
    });
    const [filteredData, setFilteredData] = useState({});

useEffect(() => {
    {Object.keys(props?.data).length > 0 &&
          Object.entries(props?.data).map(([key, value]) => {
            return (
              value != "The fund detail has been viewed successfully" &&
              key != "status" &&
              value?.length > 0 &&
              value?.map((round, i) => {
                console.log( "getroundhier",round);
                return Object.entries(round).map(([key1, value1]) => {
                  // console.log(key1,value1)
                  if (value1?.selected === true) {
                    // setOgData(value1);
                    const fundData = value1;
                    if (fundData) {
                      const keysToStore = {
                        'Fund Name':'fund_name',
                        'Fund Type': 'fund_type',
                        'Target Size': 'target_size',
                        'Amount Raised': 'amount_raised',
                        'Date': 'date',
                        'Launch Date': 'launchDate',
                        'Fund Manager': 'fundManager',
                        'Fund Status': 'fund_status',
                        'Capital Source': 'capital_source',
                        'Source': 'source',
                      };

                      const filtered = {};

                      Object.entries(keysToStore).forEach(([key, value]) => {
                        if (fundData[value]) {
                          filtered[key] = fundData[value];
                        }
                      });

                      setFilteredData(filtered);
                    }
                  }
                  return null;                  
                });
              })
            );
          })}
    
  }, [props]);

  // Convert filteredData into an array of key-value pairs
  const dataEntries = Object.entries(filteredData);

  // Function to chunk array into smaller arrays of a given size
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  // Chunk the data entries into rows of up to 3 items
  const rows = chunkArray(dataEntries, 3);
  return (
    <div className="px-5 ">
      <p className="  text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
        Fund Details
      </p>
      <div className="overflow-x-auto scrollbar">
        <table className=" w-full">
        {rows.map((row, rowIndex) => (
          <tr 
            key={rowIndex} 
            className={`${
              rowIndex === rows.length - 1 ? 'w-full' : 'border-b-[#E5E5E5] border-b'
            }`}
          >
            {row.map(([label, value], cellIndex) => (
              <td
                key={label}
                className={`${
                  cellIndex === 0 ? 'py-1 lg:leading-[21px] font-sans_book_body' :
                  cellIndex === 1 ? 'py-1 pl-12 lg:leading-[21px] font-sans_book_body' :
                  'py-2 pl-12 lg:leading-[21px]'
                }`}
              >
                <p className="text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto">
                  {label}
                </p>
                {
                  label == 'Source' ? <p className="text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold">
                  {data}
                </p> : <p className="text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold">
                  {value}
                </p>
                }
              </td>
            ))}
          </tr>
        ))}
        </table>
      </div>
    </div>
  );
};

export default DealInfo;
