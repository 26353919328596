import React, { useState, useMemo, useContext, useRef, useEffect } from "react";
import { useSearchParams, Routes, Route, useParams, useLocation } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { CompareCompanyContext } from '../../../Contexts/CompareCompanyContext'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { NavLink } from 'react-router-dom';
import compare from '../../../images/compare.png'
import pdf from '../../../images/pdf.png'
import atom from '../../../images/atom.png'
import xls from '../../../images/download-file-icon.png'
import edit from '../../../images/combined-shape.png'
import addNoted from "../../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
import addNotedActive from '../../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { descriptionCharLimit, exportPdf, invIncOverview, invIncProfile, invIncInvestorDetail, getInvestorFundingUrl, getInvestorProfileUrl } from "../../../Config/config"
import barRaiser from '../../../images/barraiser-logo.png'
import InvestorProfileHighlights from './InvestorProfileHighlight'
import InvestorPortfolioCompanies from './InvestorPortfolioCompanies'
import InvestorPortfolioExits from './InvestorPortfolioExits'
import InvestorFunds from './InvestorFunds'
// import InvestorCapTable from './CapTable'
import InvestorCo_Investors from './InvestorCo_Investors'
import InvestorCo_Investors_V2 from './InvestorCo_Investors_V2'

import InvestorNewsActivity from './InvestorNewsActivity'
import ComparePopUp from '../../../Pages/ComparePopUp'
import Warning from '../../../images/warning-btn.png'
import AddNote from "../../../Pages/addNote";
import CompanyLogo from '../../CompanyLogo'
import RandomLogo from '../../RandomLogo'
import AlertPopUp from "../../../Components/DetailPage/AlertPopUp";
import PENotesPopup from '../../../Components/NotesPopup/PENotesPopup'
import SendDealPopUp from "../../SendDealPopUp";
import ExcelPopup from '../../../Components/DetailPage/DetailExcelPopUp.jsx'
import { INVESTOR_COMPANY_PROFILE, INVESTOR_PROFILE_TABLE_QUERY, INVESTOR_PORTFOLIO_TABLE_QUERY, CO_INVESTOR_TAB, EXIT_PORTFOLIO_TABLE_QUERY, INVESTORNEWSLETTER } from "../../../Queries/Pe"
import PdfDownloader from "../../shared/PdfDownloader";
import Constants from "../../../Constants";
import { Tooltip } from "antd";
import axios from 'axios'
import routePathSegments, { routeNames } from '../../../routeSegments';
import useModule from "../../../hooks/useModule";
import { UserContext } from "../../../Contexts/UserContext";
import Loader from "../../Loader";
import InvestorPortfolioIPO from "./InvestorPortfolioIPO";
import AddNotePopover from "../../shared/AddNotePopover";
import Tab from '../../shared/Tab';
import CoInvestorLayOut from "./CoInvestorLayOut";
import { isNaN ,debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { DataFoundContext } from "../../../Contexts/DataFoundContext";
import { TableDataContext } from "../../../Contexts/TableDataContext";



/**
 * The `InverstorCompanyDetail` function is a React component that displays the details of an investor
 * company, including overview, investments, exits, IPOs, funds, co-investors, and similar companies.
 */
const InverstorCompanyDetail = (props) => {
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' });; }
    const investments = () => { document.getElementById("investments").scrollIntoView({ behavior: 'smooth' });; }
    const exits = () => { document.getElementById("exits").scrollIntoView({ behavior: 'smooth' });; }
    const ipo = () => { document.getElementById("ipos").scrollIntoView({ behavior: 'smooth' });; }
    const funds = () => { document.getElementById("funds").scrollIntoView({ behavior: 'smooth' });; }
    const co_investor = () => { document.getElementById("co-inverstor").scrollIntoView({ behavior: 'smooth' });; }
    const similarCompanies = () => { document.getElementById("news").scrollIntoView(); }
    const [showModal, setShowModal] = useState(false);
    const [showAdd, setShowAdd] = useState(false)
    const [isActive, setIsActive] = useState(1);
    const location = useLocation();
    const [note, setNote] = useState(false)
    const [skipScroll, setSkipScroll] = useState(false)
    const {
    investorsExitsDataFound,
    setInvestorsExitsDataFound,
    investorsIPODataFound,
    setInvestorsIPODataFound,
    investorsFundsDataFound,
    newsAndActivityDataFound,
  } = useContext(DataFoundContext);

    /* The code is using the `useInView` hook to track the visibility of certain elements on the
    page. It creates multiple instances of the hook, each with different options for the threshold,
    rootMargin, and skipScroll properties. The hook returns three values: the element reference, a
    boolean indicating whether the element is currently visible, and an entry object containing
    additional information about the visibility. These values are assigned to variables with
    descriptive names for each element being tracked. */
    const [overviewEl, isOverviewVisible, overviewEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [investmentEl, isInvestmentVisible, investmentEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [exitEl, isExitVisible, exitEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [ipoEl, isIpoVisible, ipoEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [fundEl, isFundVisible, fundEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [coInvestorEl, isCoInvestorVisible, coInvestorEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, newsEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })

    const { setCompanyDetail, setCurrentPage } = useContext(CompareCompanyContext);


    const [profile, setProfile] = useState({});
    const [profileHighlights, setProfileHighlights] = useState([]);
    const [investmentPortfolio, setInvestmentPortfolio] = useState([])
    const [exitPortfolio, setexitPortfolio] = useState([]);
    const [ipoPortfolio, setIpoPortfolio] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(true);
    const { primaryMasterId ,isPE,isVC} = useModule();
    const { getToken, isStudent, isDirectoryUser } = useContext(UserContext);
    const [fundingData, setFundingData] = useState(null)

    const { investorId } = useParams();

    const getFundsData = () => {
        setIsLoading(true);
        axios.post(getInvestorFundingUrl, {
            "category": props.page,
            "investor_id": investorId,
            "type": primaryMasterId
        }, {
            headers: {
                'Authorization': getToken(),
            }
        }).then(response => {
            if (response.status == 200) {
                setFundingData(response.data?.data)
                setInvestmentPortfolio(response.data?.data?.investments);
                if (response.data?.data?.exits.length !== 0) {
                    setInvestorsExitsDataFound(true);
                } else setInvestorsExitsDataFound(false);
                setexitPortfolio(response.data?.data?.exits);
                if (response.data?.data.ipos.length !== 0) {
                setInvestorsIPODataFound(true);
                } else setInvestorsIPODataFound(false);
                setIpoPortfolio(response.data?.data?.ipos)
                setIsLoading(false);
            }
        }).catch(error => {
            setInvestorsExitsDataFound(false);
            setInvestorsIPODataFound(false);
            setInvestmentPortfolio([]);
            setexitPortfolio([]);
            setIpoPortfolio([]);
            setIsLoading(false);
        })
    }

    const getProfileData = () => {
        setIsProfileLoading(true);
        axios.post(getInvestorProfileUrl, {
            "category": props.page,
            "investor_id": investorId,
            "type": primaryMasterId
        }, {
            headers: {
                'Authorization': getToken(),
            }
        }).then(response => {
            if (response.status == 200) {
                setProfile(response.data?.data?.topProfile)
                setProfileHighlights(response.data?.data?.profile ?? [])
                setIsProfileLoading(false);
            }
        }).catch(error => {
            setIsProfileLoading(false);
        })
    }

    useEffect(() => {
        getFundsData();
        getProfileData();
    return () => {
        setInvestorsExitsDataFound(false);
        setInvestorsIPODataFound(false);
    }
    }, [investorId])



    const tagsList = profile?.tags?.split(",").map((tag) => {
        const capital = tag.charAt(0).toUpperCase() + tag.slice(1);
        return (
            <li className="mt-2 lg:mt-0 nav-item flex active " role="presentation">
                {capital ? (
                    <p className=" lg:block text-[10.7pt] mt-2 leading-[17pt] lg:leading-[16px] font-sans_book_body px-3 mr-2 py-1 text-center text-[#333333] bg-[#EFEFEF] lg:text-[12px] rounded-[5px]">
                        {capital}
                    </p>
                ) : null}
            </li>
        );
    });

    // const desc = profile?.description?.trim()  ?? "";
    const desc = profile?.description + "." ?? "";

    const descLen = desc?.length;


   /**
    * The function `onTabClick` is used to handle tab clicks and perform different actions based on the
    * selected tab index.
    * @param tabIndex - The `tabIndex` parameter represents the index of the tab that was clicked. It
    * is used to determine which tab was clicked and perform the corresponding action.
    */
    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview()
        } else if (tabIndex === 2) {
            investments()
        } else if (tabIndex === 3) {
            exits()
        } else if (tabIndex === 4) {
            ipo()
        } else if (tabIndex === 5) {
            funds()
        } else if (tabIndex === 6) {
            co_investor()
        } else if (tabIndex === 7) {
            similarCompanies()
        }

        setIsActive(tabIndex);
    }

    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])

    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } else if (isInvestmentVisible) {
            setIsActiveOnScroll(2);
        } else if (isExitVisible) {
            setIsActiveOnScroll(3);
        } else if (isIpoVisible) {
            setIsActiveOnScroll(4);
        } else if (isFundVisible) {
            setIsActiveOnScroll(5);
        } else if (isCoInvestorVisible) {
            setIsActiveOnScroll(6);
        } else if (isnewsVisible) {
            setIsActiveOnScroll(7);
        }
    }, [isOverviewVisible, isFundVisible, isIpoVisible, isInvestmentVisible, isExitVisible, isnewsVisible, isCoInvestorVisible, setIsActiveOnScroll])

    // useEffect(() => {

    //     let sections = document.querySelectorAll('.details-page section')
    //     let tabs = document.querySelectorAll(".detail-tabs span")
    //     let page = document.querySelector(".details-screen")
    //     let profile = document.querySelector(".profile-detail")
    //     console.log("sections", sections);

    //     const onScroll = (e) => {
            
    //         sections?.forEach(section => {
    //             let top = e.target.scrollTop;
    //             let offset = section.offsetTop - profile?.offsetTop;
    //             offset = isNaN(offset - 28.0) ? offset : offset - 28.0
    //             let height = section.offsetHeight;
    //             let id = section.getAttribute("id");
    //             console.log("sections-scroll",id, top,offset ,  height,top >= offset && top < offset + height);
    //             if (top >= offset && top < offset + height) {
    //                 if (id === "profile") {
    //                     setIsActive(1);
    //                 } else if (id === "investments") {
    //                     setIsActive(2);
    //                 } else if (id === "exits") {
    //                     setIsActive(3);
    //                 } else if (id === "ipos") {
    //                     setIsActive(4);
    //                 } else if (id === "funds") {
    //                     setIsActive(5);
    //                 } else if (id === "co-inverstor") {
    //                     setIsActive(6);
    //                 } else if (id === "news") {
    //                     setIsActive(7);
    //                 }
    //             }else{
    //                 if(top === 0){
    //                     setIsActive(1);
    //                 }
    //             }

    //         })
    //     }

    //     page.addEventListener("scroll",onScroll)


    //     return ()=>{
    //         page.removeEventListener("scroll",onScroll)
    //     }

    // }, []) 
    const [URLSearchParams] = useSearchParams({ section: 1 })
    const section = URLSearchParams.get('section');
    console.log(section,'section');   


    const subTypeMasterId =() =>{
        if(isPE){
            if(section == Constants.primaryMasterSubIds.pe_vc_investments_id){
                return Constants.subCategoryIds.pe_investor_investments_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_exits_id){
                return Constants.subCategoryIds.pe_investor_exits_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_ma_id){
                return Constants.subCategoryIds.pe_investor_exitsma_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_pm_id){
                return Constants.subCategoryIds.pe_investor_exitspm_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_backed_ipos_id){
                return Constants.subCategoryIds.pe_investor_backed_ipos_id
              }
            
        }
        else if(isVC){
            if(section == Constants.primaryMasterSubIds.pe_vc_investments_id){
                return Constants.subCategoryIds.vc_investor_investments_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_exits_id){
                return Constants.subCategoryIds.vc_investor_exits_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_ma_id){
                return Constants.subCategoryIds.vc_investor_exitsma_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_pm_id){
                return Constants.subCategoryIds.vc_investor_exitspm_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_backed_ipos_id){
                return Constants.subCategoryIds.vc_investor_backed_ipos_id
            }            

        }
    }
    
    const { tableInvestorId: invId } = useContext(TableDataContext);

    console.log("dd", invId);

    const currentIndex = invId?.indexOf(Number(investorId));

    console.log("curr", currentIndex);

    console.log(location.state, 'loc')

function getLeftNavLinkRoute(id, page, inverstorId, index) {
  if (id === Constants.primaryMasterIds.vcId) {
    switch (page) {
      case 'investments':
        return routeNames.vcInvestorById(inverstorId[index - 1]);
      case 'exits':
        return routeNames.vcExitInvestorById(inverstorId[index - 1]);
      case 'ipos':
        return routeNames.vcIpoInvestorById(inverstorId[index - 1]);
      default:
        return null;
    }
  } else {
    switch (page) {
      case 'investments':
        return routeNames.peInvestorById(inverstorId[index - 1]);
      case 'exits':
        return routeNames.peExitInvestorById(inverstorId[index - 1]);
      case 'ipos':
        return routeNames.peIpoInvestorById(inverstorId[index - 1]);
      default:
        return null;
    }
  }
}
function getRightNavLinkRoute(id, page, inverstorId, index) {
  if (id === Constants.primaryMasterIds.vcId) {
    switch (page) {
      case 'investments':
        return routeNames.vcInvestorById(inverstorId[index + 1]);
      case 'exits':
        return routeNames.vcExitInvestorById(inverstorId[index + 1]);
      case 'ipos':
        return routeNames.vcIpoInvestorById(inverstorId[index + 1]);
      default:
        return null;
    }
  } else {
    switch (page) {
      case 'investments':
        return routeNames.peInvestorById(inverstorId[index + 1]);
      case 'exits':
        return routeNames.peExitInvestorById(inverstorId[index + 1]);
      case 'ipos':
        return routeNames.peIpoInvestorById(inverstorId[index + 1]);
      default:
        return null;
    }
  }
}

document.title = `${profile?.investor_name ? profile.investor_name + " - " : ""}PE-VC Investors - Venture Intelligence`;

    return (
        <> <div className='overflow-y-auto details-screen'>
            <div className="bg-[#FFFFFF] relative">
                <nav className="flex p-1 lg:px-1 px-3 border-b  " aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            <div className='flex items-center'>
                                <NavLink to={location.state?.prevPath != null
                                    ? location.state?.prevPath
                                    : primaryMasterId == Constants.primaryMasterIds.vcId ? routeNames.vc_investors_vc_investments
                                        : routeNames.pe_investors_pe_vc_investments}
                                    className="ml-2 text-[11pt] lg:text-[12px] text-[#666666] ">
                                    Investor</NavLink>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <span className=" text-[12px] text-[#666666] mr-1">/</span>
                                <span className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                                    aria-current="page">{profile?.investor_name}</span>
                            </div>
                        </li>
                    </ol>
                {invId && 
                    <>
                        <Tooltip title="Prev">
                            <NavLink
                            className="absolute top-2 right-8"
                            to={getLeftNavLinkRoute(primaryMasterId, props.page, invId, currentIndex )}
                            >
                            {invId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next">
                            <NavLink
                            className="absolute top-2 right-4"
                            to={getRightNavLinkRoute(primaryMasterId, props.page, invId, currentIndex)}
                            >
                            {invId.length - 1 === currentIndex ? null : (
                                <FaChevronRight size={15} color='grey'/>
                            )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
                </nav>
                {/* <!-- BarRaiser--> */}
                {isProfileLoading ?
                    <div className="h-[10rem] w-full bg-[#FFFFFF] flex items-center justify-center">
                        <Loader />
                    </div>
                    :
                    <div className="bg-[#FFFFFF] profile-detail">
                        <div className="pl-5 pr-3">
                            <div className="lg:hidden mt-3  grid  md:grid-cols-8 grid-cols-10  items-center mb-4">
                                <div className="md:col-span-6 col-span-5" title="Compare Company">
                                    <button
                                        className={
                                            showModal
                                                ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                                : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                        }
                                        type="button"
                                        onClick={() => {
                                            setShowModal(!showModal)
                                            setCompanyDetail([])
                                            setCurrentPage("investor")
                                        }
                                        }
                                    >
                                        <img
                                            className={
                                                showModal ? "pr-1 brightness-0 invert-[1]" : "pr-1"
                                            }
                                            src={compare}
                                            alt=""
                                        />
                                        <span>Compare</span>
                                    </button>
                                </div>
                                <div className="flex md:col-span-2 col-span-5 space-x-3">
                                    <div >
                                        <AlertPopUp cid={investorId} type_id={2} />
                                    </div>
                                    <div >
                                        {/* <span title="Save List">
                                            <img className=" cursor-pointer w-[24px] h-[22px]"
                                                src={showNote ? addNotedActive : addNoted} onClick={() => setShowNote(!showNote)}
                                                alt=""
                                            />
                                            {showNote ? 
                                            <AddNote module="Investors" page="detailpage" type_id={2} cname={profile?.investor_name ?? ""} cid={investorId} IsDetailed={true}/> : ""}
                                        </span> */}
                                        <AddNotePopover
                                            title="Save List"
                                            companyId={investorId}
                                            companyName={profile?.investor_name ?? ""}
                                            type_id={Constants.moduleIds.investorsId}
                                            sub_type_master_id={subTypeMasterId()}
                                            IsDetailed={true} />
                                    </div>


                                    <div className="col-span-1">
                                        <PENotesPopup note={note} cid={investorId} type_id={2} />
                                    </div>
                                    <div className="col-span-1">
                                        <SendDealPopUp data={profile?.investor_name ?? ""} />
                                    </div>
                                    {isStudent == 'true' ? <></> :
                                        <div className="col-span-1">
                                            <PdfDownloader
                                                url={exportPdf}
                                                fileName={profile?.investor_name?.replaceAll(/\s/g, '')}
                                                data={{
                                                    "type_id": 2,
                                                    "entity_id": investorId,
                                                    "category": props.page,
                                                    "type": primaryMasterId
                                                }} />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="pb-4 pt-6 bg-[#FFFFFF]">
                                <ul >
                                    <li className="w-full flex ">
                                        <div className=" rounded-md">
                                            {profile?.icon_path == "default.png" || profile?.icon_path == "/default.png" || !profile?.icon_path ?
                                                <RandomLogo name={profile?.investor_name} page="detail page" />
                                                :
                                                <CompanyLogo name={profile?.investor_name} page="detail page" logo={profile?.icon_path} />

                                            }
                                        </div>

                                        <div className="ml-3 flex flex-1 flex-col">
                                            <span className="text-[#333333] text-[22px] font-semibold  leading-[24px]" href="#"> {profile?.investor_name} </span>
                                            <span className="text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body  ">{profile?.firm_type ? profile?.firm_type : ""}</span>
                                        </div>

                                        <div className="hidden lg:grid grid-cols-8 gap-2  h-10">
                                            <div className="col-span-3 mt-2">
                                                <Tooltip title="Compare Investor">
                                                    <button

                                                        className={
                                                            showModal
                                                                ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                                                                : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                                                        }
                                                        type="button"
                                                        onClick={() => {
                                                            setShowModal(!showModal)
                                                            setCompanyDetail([])
                                                            setCurrentPage("investor")
                                                        }
                                                        }
                                                    >
                                                        <img
                                                            className={
                                                                showModal
                                                                    ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                                                    : "w-[20px] h-[18px] pr-1 blur-btn"
                                                            }
                                                            src={compare}
                                                            alt=""
                                                        />
                                                        <span>Compare</span>
                                                    </button>
                                                </Tooltip>
                                            </div>
                                            {/* <div className="col-span-2 ">
                                            <button className={showAdd ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center" : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center"}
                                                type="button" onClick={() => setShowAdd(!showAdd)}>
                                                <img className={showAdd ?
                                                    "pr-2 brightness-0 invert-[1]" : "pr-2"} src={addNoted} alt="" />
                                                <span>Add</span>
                                            </button>
                                        </div> */}
                                            <div className="col-span-5 mt-[5px]">
                                                <div className="grid grid-cols-5 gap-1 flex items-center">
                                                    <div className="col-span-1 ">
                                                        <AlertPopUp cid={investorId} type_id={Constants.moduleIds.investorsId} page="detailpage" />{" "}
                                                    </div>

                                                    <div className="col-span-1">
                                                        <AddNotePopover
                                                            title="Save List"
                                                            companyId={investorId}
                                                            companyName={profile?.investor_name ?? ""}
                                                            type_id={Constants.moduleIds.investorsId}
                                                            sub_type_master_id={subTypeMasterId()}
                                                            IsDetailed={true} />
                                                        {/* <Tooltip title="Save List">
                                                        <img
                                                            className="w-[22px] h-[22px] cursor-pointer"
                                                            src={showNote ? addNotedActive : addNoted} onClick={() => setShowNote(!showNote)}
                                                            alt=""
                                                        />
                                                        {showNote ? <AddNote module="Investors" page="detailpage" type_id={Constants.moduleIds.investorsId} cname={profile?.investor_name ?? ""} cid={investorId} /> : ""}
                                                    </Tooltip> */}
                                                    </div>
                                                    <div className="col-span-1 mt-1">
                                                        <PENotesPopup note={note} cid={investorId} type_id={Constants.moduleIds.investorsId} />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <SendDealPopUp data={profile?.investor_name ?? ""} />
                                                    </div>
                                                    { isStudent == 'true' ? <></> :
                                                        <div className="col-span-1">
                                                            {/* {props.menu == "dealpage" ?

                                                        <img
                                                            title="Export"
                                                            src={xls}
                                                            className="h-[27px] w-[25px] cursor-pointer"
                                                            onClick={() => setShowPopup(!showPopup)}
                                                        />

                                                        :
                                                        <img className="w-[25px] h-[27px] cursor-pointer" title="Export" src={pdf} alt="" />
                                                    }
                                                    {showPopup && (
                                                        <div>
                                                            <ExcelPopup menu="dealpage" showPop={showPopup} setShowPop={setShowPopup} />{" "}
                                                        </div>
                                                    )} */}
                                                            <PdfDownloader
                                                                url={exportPdf}
                                                                fileName={profile?.investor_name?.replaceAll(/\s/g, '')}
                                                                data={{
                                                                    "type_id": 2,
                                                                    "entity_id": investorId,
                                                                    "category": props.page,
                                                                    "type": primaryMasterId
                                                                }} />
                                                        </div>}

                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    {showAdd ? <AddNote id={1} /> : ""}
                                </ul>
                            </div>
                            {/* <!-- tags--> */}

                            {/* <a className="text-[#BC8B20] text-[13px] leading-[16px]" href="#">... View More</a> */}
                            {/* <ul
                            className="nav nav-tabs flex flex-wrap  list-none border-b-0 pl-0 mb-0"
                            id="tabs-tab"
                            role="tablist"
                        >
                            {tagsList}
                        </ul> */}
                            {profile?.industry != "" && profile?.industry != null ? <div>
                                <p className="text-[11pt] leading-[17pt] lg:block lg:leading-[16px] mb-2 text-[#333333]  lg:text-[14px] font-sans_book_body mr-3 ">
                                    Industries (Existing Investments): {profile?.industry}
                                </p>
                            </div> : <></>}
                            {/* <ul className="flex flex-wrap   " >
                            <li className="">
                                <a className=" text-[11pt] leading-[17pt] lg:block lg:leading-[16px]  py-1 text-[#333333]  lg:text-[13px] font-sans_book_body mr-3 mt-3"
                                >Industries (Existing Investments): {ProfileHighlightData?.existing_industries}</a>

                                {/* >Industries (Existing Investments): {JSON.parse(profile?.investor_nameIndutry)?.map((inv,i)=>{                                    
                                    console.log(JSON.parse(profile?.investor_nameIndutry)?.length,i,'1arrayvalue'); 
                                    return(
                                        `${inv} ${JSON.parse(profile?.investor_nameIndutry)?.length-1 == i  ? "": ", " }`
                                    )
                                })
                                }</a> 
                            </li>

                        </ul> */}
                            <div className="bg-[#FFFFFF]">

                                {desc != "" && desc != null ? <div >
                                    <p className=" text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                                        {/* {ProfileHighlightData?.Description ? */}
                                        <ReactReadMoreReadLess
                                            charLimit={descLen}
                                            readMoreText={"View More "}
                                            readLessText={"View Less "}
                                            readMoreClassName="read-more-less--more lg:[text-12px]"
                                            readLessClassName="read-more-less--less lg:[text-12px]"
                                        >
                                            {profile?.description ? desc : "--"}

                                        </ReactReadMoreReadLess>
                                        {/* : ""} */}
                                    </p>
                                </div> : <></>}


                            </div>
                        </div>
                    </div>
                }
                <div className="bg-[#EDEDED]">
                    <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index scrollbar-remove detail-tabs">


                        <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                        <Tab active={isActive === 2} label={"Investments"} onClick={() => onTabClick(2)} />
                        {investorsExitsDataFound ? (<Tab active={isActive === 3} label={"Exits"} onClick={() => onTabClick(3)} />) : ("")}
                        {investorsIPODataFound ? (<Tab active={isActive === 4} label={"IPO"} onClick={() => onTabClick(4)} />) : ("")}
                        {investorsFundsDataFound ? <Tab active={isActive === 5} label={"Funds"} onClick={() => onTabClick(5)} />: ""}
                        {!isDirectoryUser ? <Tab active={isActive === 6} label={"Co-Investors"} onClick={() => onTabClick(6)} />: ''}
                        {newsAndActivityDataFound ? (<Tab active={isActive === 7} label={"Recent News & Activity"} onClick={() => onTabClick(7)} />) : ("")}

                    </div>


                    <div className="h-full space-y-5 details-page">
                        <section ref={overviewEl} className="scroll-mt-14" id="profile"><InvestorProfileHighlights isLoading={isProfileLoading} profileHighlights={profileHighlights} page={props?.page} /></section>
                        <section ref={investmentEl} className="scroll-mt-14" id="investments"><InvestorPortfolioCompanies fundingData={fundingData} isLoading={isLoading} portfolio={investmentPortfolio} investorName={profile?.investor_name} /></section>
                        <section ref={exitEl} className="scroll-mt-14" id="exits"><InvestorPortfolioExits fundingData={fundingData} isLoading={isLoading} portfolio={exitPortfolio} investorName={profile?.investor_name} /></section>
                        <section ref={ipoEl} className="scroll-mt-14" id="ipos"><InvestorPortfolioIPO isLoading={isLoading} portfolio={ipoPortfolio} investorName={profile?.investor_name} /></section>
                        <section ref={fundEl} className="scroll-mt-14" id="funds"><InvestorFunds investorName={profile?.investor_name} /></section>
                        {!isDirectoryUser ? <section ref={coInvestorEl} className="scroll-mt-14" id="co-inverstor"><CoInvestorLayOut CO_INVESTOR_TAB={CO_INVESTOR_TAB} investorName={profile?.investor_name} /> </section> : ""}
                        {/* <section id="co-inverstor"><InvestorCo_Investors_V2 CO_INVESTOR_TAB={CO_INVESTOR_TAB} /></section> */}
                        <section ref={newsEl} className="scroll-mt-14" id="news"><InvestorNewsActivity /></section>
                    </div>
                </div>
            </div>
        </div>
            {showModal ? (<ComparePopUp id={1} onClose={(e) => setShowModal(false)} />) : null}
        </>
    )

}

export default InverstorCompanyDetail