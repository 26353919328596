/* The above code is exporting a constant variable named "data" which contains an object. */
export const data = {
    initial: [
        {
            "id": "1",
            "name": "Basic Profile",
            "fileName": "Basic Profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "Basic Profile",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,
        },
        {
            "id": "2",
            "name": "To Companies",
            "fileName": "To Companies",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "To Companies",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "To Investors",
            "fileName": "To Investors",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "To Investors",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": true,
        },
    ],

    "Basic Profile": [
        {
            "id": 1,
            "name": "Advisor Name",
            "value": "Advisor Name",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "advisor_cias.cianame",
            "filterData": {
                "searchType": [{"Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Starts with": false, "dataType": "StartWithSelect" }, {"Contains": false, "dataType": "Text" }, { "Does not contains": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/tadvisorsearch",
                "multiple": true
            }
        },
        {
            "id": 2,
            "name": "City",
            "value": "City",
            "isChecked": true,
            "hasMore": false,
            "parent": "Basic Profile",
            "category": "Basic Profile",
            "values": [""],
            "member": "advisor_cias.city",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/tadvisorcity/search",
                "multiple": true
            }
        }
    ],

    "To Companies": [
        {
            "id": 3,
            "name": "Company Basic Profile",
            "value": "Company Basic Profile",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Company Basic Profile",
            "parent": "To Companies",
            "category": "To Companies",
            "values": [""],
        },
        {
            "id": 4,
            "name": "Company Investments",
            "value": "Company Investments",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Company Investments",
            "parent": "To Companies",
            "category": "To Companies",
            "values": [""],
        },
        {
            "id": 5,
            "name": "Company Exits",
            "value": "Company Exits",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Company Exits",
            "parent": "To Companies",
            "category": "To Companies",
            "values": [""],
        },
    ],

    "Company Basic Profile": [
        {
            "id": 6,
            "name": "Country",
            "value": "Country",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Basic Profile",
            "category": "Company Basic Profile",
            "values": [""],
            "segments" : "advisor_pecompanies.standard",
            "member": "Pecompanies.country",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/countrysearch",
                "multiple": true
            }
        },
        {
            "id": 7,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Basic Profile",
            "category": "Company Basic Profile",
            "values": [""],
            "segments" : "advisor_pecompanies.standard",
            "member": "Pecompanies.region",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/regionsearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 8,
            "name": "State",
            "value": "State",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Basic Profile",
            "category": "Company Basic Profile",
            "values": [""],
            "segments" : "advisor_pecompanies.standard",
            "member": "Pecompanies.state",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/statesearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 9,
            "name": "City",
            "value": "City",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Basic Profile",
            "category": "Company Basic Profile",
            "values": [""],
            "segments" : "advisor_pecompanies.standard",
            "member": "Pecompanies.city",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/citysearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 10,
            "name": "Year Founded",
            "value": "Year Founded",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Basic Profile",
            "category": "Company Basic Profile",
            "values": [""],
            "segments" : "advisor_pecompanies.standard",
            "member": "Pecompanies.yearfounded",
            "filterData": {
                "searchType": [{"Before": true, "dataType": "Before" }, {"After": false, "dataType": "After" }, {"Between": false, "dataType": "Between" }, {"Equals": false, "dataType": "Before" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "number-years",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 11,
            "name": "Company Type",
            "value": "Company Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Basic Profile",
            "category": "Company Basic Profile",
            "values": [""],
            "segments" : "advisor_pecompanies.standard",
            "member": "profile_flat.investment_type",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "apiEndPoint": "/advanced/companytype/search",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 12,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Basic Profile",
            "category": "Company Basic Profile",
            "values": [""],
            "segments" : "advisor_pecompanies.standard",
            "member": "DealFlat.industry",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/industrysearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 13,
            "name": "Sector",
            "value": "Sector",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Basic Profile",
            "category": "Company Basic Profile",
            "values": [""],
            "segments" : "advisor_pecompanies.standard",
            "member": "Pecompanies.sectorBusiness",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Text",
                "apiEndPoint": "/advanced/sectorsearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 14,
            "name": "Tags",
            "value": "Tags",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Basic Profile",
            "category": "Company Basic Profile",
            "values": [""],
            "segments" : "advisor_pecompanies.standard",
            "member": "PecompanyTagCrosstabVw.tags",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "apiEndPoint": "/advanced/tagsearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ],

    "Company Investments": [
        {
            "id": 15,
            "name": "Firm Type",
            "value": "Firm Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Investments",
            "category": "Company Investments",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "investor_profile_flat.firmtype",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Text",
                "apiEndPoint": "/advanced/firmType/search",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 16,
            "name": "Deal (By Investor - $M)",
            "value": "Deal (By Investor - $M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Investments",
            "category": "Company Investments",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "peinvestments_investors.Amount_M",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers" }, { "Does not equal to": false ,"dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 17,
            "name": "Total Funding ($M)",
            "value": "Total Funding ($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Investments",
            "category": "Company Investments",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "profile_flat.totalFunding",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers" }, { "Does not equal to": false ,"dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 18,
            "name": "Total Funding (INR Cr)",
            "value": "Total Funding (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Investments",
            "category": "Company Investments",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "profile_flat.totalFundingInr",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false ,"dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 19,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Company Deal Info",
            "parent": "Company Investments",
            "category": "Company Investments",
            "values": [""],
        },
        {
            "id": 20,
            "name": "Valuation Info",
            "value": "Valuation Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Company Valuation Info",
            "parent": "Company Investments",
            "category": "Company Investments",
            "values": [""],
        }
    ],

    "Company Deal Info": [
        {
            "id": 21,
            "name": "Deal Amount ($M)",
            "value": "Deal Amount ($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Deal Info",
            "category": "Company Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.amountM",
            "filterData": {
                "searchType": [{"Range": true, "dataType": "Range"}, {"Greater than or equal to":false,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 22,
            "name": "Deal Amount (INR Cr)",
            "value": "Deal Amount (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Deal Info",
            "category": "Company Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.amountInr",
            "filterData": {
                "searchType": [{"Range": true, "dataType": "Range"}, {"Greater than or equal to":false, "dataType": "Numbers"}, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false ,"dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        }, 
        {
            "id": 23,
            "name": "Deal Type",
            "value": "Deal Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Deal Info",
            "category": "Company Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.SPV",
            "filterData": {
                "apiEndPoint": "/advanced/dealtype/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 24,
            "name": "Deal Involving",
            "value": "Deal Involving",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Deal Info",
            "category": "Company Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.investors",
            "filterData": {
                "apiEndPoint" : "/advanced/dealinvolving/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },        
        {
            "id": 25,
            "name": "Deal Date",
            "value": "Deal Date",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Deal Info",
            "category": "Company Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.date",
            "filterData": {
                "searchType": [{"Before": true, "dataType": "CalenderType" }, {"After": false, "dataType": "CalenderType" }, {"Between": false, "dataType": "MultiCalenderType" }, {"Equals": false, "dataType": "CalenderType" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "numeric",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 26,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Deal Info",
            "category": "Company Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.exitStatus",
            "filterData": {
                "apiEndPoint" : "/advanced/exitstatus/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 27,
            "name": "Stage",
            "value": "Stage",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Deal Info",
            "category": "Company Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.stage",
            "filterData": {
                "apiEndPoint" : "/advanced/stagesearch",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 28,
            "name": "Round",
            "value": "Round",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Deal Info",
            "category": "Company Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.round_type",
            "filterData": {
                "apiEndPoint" : "/advanced/roundsearch",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 29,
            "name": "Price To Book",
            "value": "Price To Book",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Deal Info",
            "category": "Company Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.priceToBook",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ],

    "Company Valuation Info": [
        {
            "id": 30,
            "name": "Pre Money Valuation",
            "value": "Pre Money Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.preMoneyValuationInr",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 31,
            "name": "Revenue Multiple (Pre-money)",
            "value": "Revenue Multiple (Pre-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.revenueMultiplePre",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false , "dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 32,
            "name": "EBITDA Multiple (Pre-money)",
            "value": "EBITDA Multiple (Pre-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.ebitdaMultiplePre",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 33,
            "name": "PAT Multiple (Pre-money)",
            "value": "PAT Multiple (Pre-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.patMultiplePre",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false , "dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 34,
            "name": "Post Money Valuation",
            "value": "Post Money Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.postMoneyValuationInr",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 35,
            "name": "Revenue Multiple (Post-money)",
            "value": "Revenue Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.revenueMultiplePost",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 36,
            "name": "EBITDA Multiple (Post-money)",
            "value": "EBITDA Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.ebitdaMultiplePost",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 37,
            "name": "PAT Multiple (Post-money)",
            "value": "PAT Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.patMultiplePost",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 38,
            "name": "Enterprise Valuation",
            "value": "Enterprise Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.enterpriseValuationInr",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 39,
            "name": "Revenue Multiple (EV)",
            "value": "Revenue Multiple (EV)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.revenueMultipleEv",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false,"dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 40,
            "name": "EBITDA Multiple (EV)",
            "value": "EBITDA Multiple (EV)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.ebitdaMultipleEv",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 41,
            "name": "PAT Multiple (EV)",
            "value": "PAT Multiple (EV)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Valuation Info",
            "category": "Company Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_pe.standard",
            "member": "DealFlat.patMultipleEv",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ],

    "Company Exits": [
        {
            "id": 42,
            "name": "Exits Deal Info",
            "value": "Exits Deal Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Company Exits Deal Info",
            "parent": "Company Exits",
            "category": "Company Exits",
            "values": [""],
        }, 
        {
            "id": 43,
            "name": "Returns",
            "value": "Returns",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Company Returns",
            "parent": "Company Exits",
            "category": "Company Exits",
            "values": [""],
        },
        {
            "id": 44,
            "name": "Exits Valuation Info",
            "value": "Exits Valuation Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Company Exits Valuation Info",
            "parent": "Company Exits",
            "category": "Company Exits",
            "values": [""],
        }   
    ],

    "Company Exits Deal Info": [
        {
            "id": 45,
            "name": "Deal Size ($M)",
            "value": "Deal Size ($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Exits Deal Info",
            "category": "Company Exits Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "exit_investment_flat.deal_amount",
            "filterData": {
                "searchType": [{"Range": true, "dataType": "Range"}, {"Greater than or equal to":false, "dataType": "Numbers"}, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 46,
            "name": "Deal Period",
            "value": "Deal Period",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Exits Deal Info",
            "category": "Company Exits Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "exit_investment_flat.deal_date",
            "filterData": {
                "searchType": [{"Before": true, "dataType": "Before" }, {"After": false, "dataType": "After" }, {"Between": false, "dataType": "Between" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "numeric",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 47,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Exits Deal Info",
            "category": "Company Exits Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "exit_investment_flat.exit_status",
            "filterData": {
                "apiEndPoint" : "/advanced/exits/exitstatus/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 48,
            "name": "Deal Type",
            "value": "Deal Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Exits Deal Info",
            "category": "Company Exits Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "exit_investment_flat.deal_type",
            "filterData": {
                "apiEndPoint" : "/advanced/exits/dealtype/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 49,
            "name": "Type",
            "value": "Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Exits Deal Info",
            "category": "Company Exits Deal Info",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "exit_investment_flat.deal_sub_type",
            "filterData": {
                "apiEndPoint" : "/advanced/exits/typesearch",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ],

    "Company Returns": [
        {
            "id": 50,
            "name": "Return Multiple",
            "value": "Return Multiple",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Returns",
            "category": "Company Returns",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "manda_investors.MultipleReturn",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 51,
            "name": "IRR",
            "value": "IRR",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Returns",
            "category": "Company Returns",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "manda_investors.Amount_M",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ],

    "Company Exits Valuation Info": [
        {
            "id": 52,
            "name": "Post Money Valuation",
            "value": "Post Money Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Exits Valuation Info",
            "category": "Company Exits Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "exit_investment_flat.company_valuation",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 53,
            "name": "Revenue Multiple (Post-money)",
            "value": "Revenue Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Exits Valuation Info",
            "category": "Company Exits Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "exit_investment_flat.revenue_multiple",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false,"dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 54,
            "name": "EBITDA Multiple (Post-money)",
            "value": "EBITDA Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Exits Valuation Info",
            "category": "Company Exits Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "exit_investment_flat.ebitda_multiple",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false,"dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 55,
            "name": "PAT Multiple (Post-money)",
            "value": "PAT Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Company Exits Valuation Info",
            "category": "Company Exits Valuation Info",
            "values": [""],
            "segments" : "advisor_pecompanies_exit.standard",
            "member": "exit_investment_flat.pat_multiple",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ],

    "To Investors": [
        {
            "id": 56,
            "name": "Investor Basic Profile",
            "value": "Investor Basic Profile",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Investor Basic Profile",
            "parent": "To Investors",
            "category": "To Investors",
            "values": [""],
        },
        {
            "id": 57,
            "name": "Portfolio Companies",
            "value": "Portfolio Companies",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Portfolio Companies",
            "parent": "To Investors",
            "category": "To Investors",
            "values": [""],
        },
    ],
    "Investor Basic Profile": [
        {
            "id": 58,
            "name": "Investor Name",
            "value": "Investor Name",
            "isChecked": true,
            "hasMore": false,
            "parent": "Investor Basic Profile",
            "category": "Investor Basic Profile",
            "values": [""],
            "segments" : "advisor_investors.standard",
            "member": "investor_profile_flat.investor_name",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Starts with": false, "dataType": "StartWithSelect" }, {"Contains": false, "dataType": "Text" }, { "Does not contains": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/investorsearch",
                "multiple": true
            }
        },
        {
            "id": 59,
            "name": "Investor Type",
            "value": "Investor Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Investor Basic Profile",
            "category": "Investor Basic Profile",
            "values": [""],
            "segments" : "advisor_investors.standard",
            "member": "DealFlat.investor_type",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/investortype/search",
                "multiple": true
            }
        },
        {
            "id": 60,
            "name": "Investment Stage",
            "value": "Investment Stage",
            "isChecked": true,
            "hasMore": false,
            "parent": "Investor Basic Profile",
            "category": "Investor Basic Profile",
            "values": [""],
            "segments" : "advisor_investors.standard",
            "member": "DealFlat.stage",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/stagesearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 61,
            "name": "Investor Headquarters",
            "value": "Investor Headquarters",
            "isChecked": true,
            "hasMore": false,
            "parent": "Investor Basic Profile",
            "category": "Investor Basic Profile",
            "values": [""],
            "segments" : "advisor_investors.standard",
            "member": "investor_profile_flat.country",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/countrysearch",
                "autoSuggest": true,
                "multiple": true
            }
        }
    ],

    "Portfolio Companies": [
        {
            "id": 62,
            "name": "Number of Portfolio Companies",
            "value": "Number of Portfolio Companies",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies",
            "category": "Portfolio Companies",
            "values": [""],
            "segments" : "advisor_investors.standard",
            "member": "investor_profile_flat.portfolio_count",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers" }, { "Does not equal to": false ,"dataType": "Numbers" }],
                "dataType": "Decimal",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 63,
            "name": "Number of Investments",
            "value": "Number of Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies",
            "category": "Portfolio Companies",
            "values": [""],
            "segments" : "advisor_investors.standard",
            "member": "investor_profile_flat.no_of_investments",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false ,"dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Decimal",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 64,
            "name": "Number of Lead Investments",
            "value": "Number of Lead Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies",
            "category": "Portfolio Companies",
            "values": [""],
            "segments" : "advisor_investors.standard",
            "member": "investor_profile_flat.no_of_lead_investments",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false ,"dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Decimal",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 65,
            "name": "Number of Exits",
            "value": "Number of Exits",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies",
            "category": "Portfolio Companies",
            "values": [""],
            "segments" : "advisor_investors.standard",
            "member": "investor_profile_flat.no_of_exits",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false ,"dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Decimal",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 66,
            "name": "Number of Exits (IPO)",
            "value": "Number of Exits (IPO)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies",
            "category": "Portfolio Companies",
            "values": [""],
            "segments" : "advisor_investors.standard",
            "member": "investor_profile_flat.no_of_ipos",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false ,"dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Decimal",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 67,
            "name": "Portfolio Companies Basic Profile",
            "value": "Portfolio Companies Basic Profile",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Portfolio Companies Basic Profile",
            "parent": "Portfolio Companies",
            "category": "Portfolio Companies",
            "values": [""],
        },
        {
            "id": 68,
            "name": "Portfolio Companies PE Transactions",
            "value": "Portfolio Companies PE Transactions",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Portfolio Companies PE Transactions",
            "parent": "Portfolio Companies",
            "category": "Portfolio Companies",
            "values": [""],
        },
    ],

    "Portfolio Companies Basic Profile" : [
        {
            "id": 69,
            "name": "Company Name",
            "value": "Company Name",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies Basic Profile",
            "category": "Portfolio Companies Basic Profile",
            "values": [""],
            "segments": "advisor_investors.standard",
            "member": "Pecompanies.companyname",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Starts with": false, "dataType": "StartWithSelect" }, {"Contains": false, "dataType": "Text" }, { "Does not contains": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/companysearch",
                "multiple": true
            }
        },
        {
            "id": 70,
            "name": "Country",
            "value": "Country",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies Basic Profile",
            "category": "Portfolio Companies Basic Profile",
            "values": [""],
            "segments": "advisor_investors.standard",
            "member": "Pecompanies.country",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "autoSuggest": true,
                "apiEndPoint": "/advanced/countrysearch",
                "multiple": true
            }
        },
        {
            "id": 71,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies Basic Profile",
            "category": "Portfolio Companies Basic Profile",
            "values": [""],
            "segments": "advisor_investors.standard",
            "member": "Pecompanies.region",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/regionsearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 72,
            "name": "State",
            "value": "State",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies Basic Profile",
            "category": "Portfolio Companies Basic Profile",
            "values": [""],
            "segments": "advisor_investors.standard",
            "member": "Pecompanies.state",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/statesearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 73,
            "name": "City",
            "value": "City",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies Basic Profile",
            "category": "Portfolio Companies Basic Profile",
            "values": [""],
            "segments": "advisor_investors.standard",
            "member": "Pecompanies.city",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/citysearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 74,
            "name": "Year Founded",
            "value": "Year Founded",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies Basic Profile",
            "category": "Portfolio Companies Basic Profile",
            "values": [""],
            "segments": "advisor_investors.standard",
            "member": "Pecompanies.yearfounded",
            "filterData": {
                "searchType": [{"Before": true, "dataType": "Before" }, {"After": false, "dataType": "After" }, {"Between": false, "dataType": "Between" }, {"Equals": false, "dataType": "Before" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "number-years",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 75,
            "name": "Company Type",
            "value": "Company Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies Basic Profile",
            "category": "Portfolio Companies Basic Profile",
            "values": [""],
            "segments": "advisor_investors.standard",
            "member": "DealFlat.companyType",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "apiEndPoint": "/advanced/companytype/search",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 76,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies Basic Profile",
            "category": "Portfolio Companies Basic Profile",
            "values": [""],
            "segments": "advisor_investors.standard",
            "member": "DealFlat.industry",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Select",
                "apiEndPoint": "/advanced/industrysearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 77,
            "name": "Sector",
            "value": "Sector",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies Basic Profile",
            "category": "Portfolio Companies Basic Profile",
            "values": [""],
            "segments": "advisor_investors.standard",
            "member": "Pecompanies.sectorBusiness",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Text",
                "apiEndPoint": "/advanced/sectorsearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 78,
            "name": "Tags",
            "value": "Tags",
            "isChecked": true,
            "hasMore": false,
            "parent": "Portfolio Companies Basic Profile",
            "category": "Portfolio Companies Basic Profile",
            "values": [""],
            "segments": "advisor_investors.standard",
            "member": "PecompanyTagCrosstabVw.tags",
            "filterData": {
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "apiEndPoint": "/advanced/tagsearch",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ],

    "Portfolio Companies PE Transactions": [
        {
            "id": 79,
            "name": "Investments",
            "value": "Investments",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Investments",
            "parent": "Portfolio Companies PE Transactions",
            "category": "Portfolio Companies PE Transactions",
            "values": [""],
        },
        {
            "id": 80,
            "name": "Exits",
            "value": "Exits",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Exits",
            "parent": "Portfolio Companies PE Transactions",
            "category": "Portfolio Companies PE Transactions",
            "values": [""],
        },
    ],

    "Investments": [
        {
            "id": 81,
            "name": "Total Funding ($M)",
            "value": "Total Funding ($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
            "member": "profile_flat.totalFunding",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers" }, { "Does not equal to": false ,"dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 82,
            "name": "Total Funding (INR Cr)",
            "value": "Total Funding (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
            "member": "profile_flat.totalFundingInr",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false ,"dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 83,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Deal Info",
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
        },
        {
            "id": 84,
            "name": "Valuation Info",
            "value": "Valuation Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Valuation Info",
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
        },
        {
            "id": 85,
            "name": "Financials Info",
            "value": "Financials Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Financials Info",
            "parent": "Investments",
            "category": "Investments",
            "values": [""],
        }
    ],

    "Deal Info": [
        {
            "id": 86,
            "name": "Deal Amount ($M)",
            "value": "Deal Amount ($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.amountM",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{"Range": true, "dataType": "Range"}, {"Greater than or equal to":false,"dataType": "Numbers"}, {"Less than or equal to": false ,"dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 87,
            "name": "Deal Amount (INR Cr)",
            "value": "Deal Amount (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.amountInr",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{"Range": true, "dataType": "Range"}, {"Greater than or equal to":false, "dataType": "Numbers"}, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false ,"dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        }, 
        {
            "id": 88,
            "name": "Deal Type",
            "value": "Deal Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.SPV",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "apiEndPoint": "/advanced/dealtype/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 89,
            "name": "Deal Involving",
            "value": "Deal Involving",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.investors",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "apiEndPoint" : "/advanced/dealinvolving/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },        
        {
            "id": 90,
            "name": "Deal Date",
            "value": "Deal Date",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.date",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{"Before": true, "dataType": "CalenderType" }, {"After": false, "dataType": "CalenderType" }, {"Between": false, "dataType": "MultiCalenderType" }, {"Equals": false, "dataType": "CalenderType" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "numeric",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 91,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.exitStatus",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "apiEndPoint" : "/advanced/exitstatus/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 92,
            "name": "Stage",
            "value": "Stage",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.stage",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "apiEndPoint" : "/advanced/stagesearch",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 93,
            "name": "Round",
            "value": "Round",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.round_type",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "apiEndPoint" : "/advanced/roundsearch",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 94,
            "name": "Price To Book",
            "value": "Price To Book",
            "isChecked": true,
            "hasMore": false,
            "parent": "Deal Info",
            "category": "Deal Info",
            "values": [""],
            "member": "DealFlat.priceToBook",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false ,"dataType": "Numbers"}, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ],

    "Valuation Info": [
        {
            "id": 95,
            "name": "Pre Money Valuation",
            "value": "Pre Money Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.preMoneyValuationInr",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 96,
            "name": "Revenue Multiple (Pre-money)",
            "value": "Revenue Multiple (Pre-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.revenueMultiplePre",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false , "dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 97,
            "name": "EBITDA Multiple (Pre-money)",
            "value": "EBITDA Multiple (Pre-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.ebitdaMultiplePre",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 98,
            "name": "PAT Multiple (Pre-money)",
            "value": "PAT Multiple (Pre-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.patMultiplePre",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true ,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false , "dataType": "Numbers"}, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 99,
            "name": "Post Money Valuation",
            "value": "Post Money Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.postMoneyValuationInr",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 100,
            "name": "Revenue Multiple (Post-money)",
            "value": "Revenue Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.revenueMultiplePost",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 101,
            "name": "EBITDA Multiple (Post-money)",
            "value": "EBITDA Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.ebitdaMultiplePost",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 102,
            "name": "PAT Multiple (Post-money)",
            "value": "PAT Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.patMultiplePost",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 103,
            "name": "Enterprise Valuation",
            "value": "Enterprise Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.enterpriseValuationInr",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 104,
            "name": "Revenue Multiple (EV)",
            "value": "Revenue Multiple (EV)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.revenueMultipleEv",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false,"dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 105,
            "name": "EBITDA Multiple (EV)",
            "value": "EBITDA Multiple (EV)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.ebitdaMultipleEv",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false, "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 106,
            "name": "PAT Multiple (EV)",
            "value": "PAT Multiple (EV)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Valuation Info",
            "category": "Valuation Info",
            "values": [""],
            "member": "DealFlat.patMultipleEv",
            "segments" : "advisor_investors_pe.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ],

    "Financials Info": [
        {
            "id": 107,
            "name": "Revenue (INR Cr)",
            "value": "Revenue (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Financials Info",
            "category": "Financials Info",
            "values": [""],
            "segments" : "advisor_investors_pe.standard",
            "member": "plstandard_latest_vw.OptnlIncome",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 108,
            "name": "EBITDA (INR Cr)",
            "value": "EBITDA (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Financials Info",
            "category": "Financials Info",
            "values": [""],
            "segments" : "advisor_investors_pe.standard",
            "member": "plstandard_latest_vw.EBITDA",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false , "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 109,
            "name": "PAT (INR Cr)",
            "value": "PAT (INR Cr)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Financials Info",
            "category": "Financials Info",
            "values": [""],
            "segments" : "advisor_investors_pe.standard",
            "member": "plstandard_latest_vw.PAT",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ],

    "Exits": [
        {
            "id": 110,
            "name": "Exits Deal Info",
            "value": "Exits Deal Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Exits Deal Info",
            "parent": "Exits",
            "category": "Exits",
            "values": [""],
        }, 
        {
            "id": 111,
            "name": "Returns",
            "value": "Returns",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Returns",
            "parent": "Exits",
            "category": "Exits",
            "values": [""],
        },
        {
            "id": 112,
            "name": "Exits Valuation Info",
            "value": "Exits Valuation Info",
            "isChecked": false,
            "hasMore": true,
            "filterKey": "Exits Valuation Info",
            "parent": "Exits",
            "category": "Exits",
            "values": [""],
        }   
    ],

    "Exits Deal Info": [
        {
            "id": 113,
            "name": "Deal Size ($M)",
            "value": "Deal Size ($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Deal Info",
            "category": "Exits Deal Info",
            "values": [""],
            "member": "exit_investment_flat.deal_amount",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "searchType": [{"Range": true, "dataType": "Range"}, {"Greater than or equal to":false, "dataType": "Numbers"}, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 114,
            "name": "Deal Period",
            "value": "Deal Period",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Deal Info",
            "category": "Exits Deal Info",
            "values": [""],
            "member": "exit_investment_flat.deal_date",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "searchType": [{"Before": true, "dataType": "Before" }, {"After": false, "dataType": "After" }, {"Between": false, "dataType": "Between" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "numeric",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 115,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Deal Info",
            "category": "Exits Deal Info",
            "values": [""],
            "member": "exit_investment_flat.exit_status",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "apiEndPoint" : "/advanced/exits/exitstatus/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 116,
            "name": "Deal Type",
            "value": "Deal Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Deal Info",
            "category": "Exits Deal Info",
            "values": [""],
            "member": "exit_investment_flat.deal_type",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "apiEndPoint" : "/advanced/exits/dealtype/search",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, {"Includes all": false, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 117,
            "name": "Type",
            "value": "Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Deal Info",
            "category": "Exits Deal Info",
            "values": [""],
            "member": "exit_investment_flat.deal_sub_type",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "apiEndPoint" : "/advanced/exits/typesearch",
                "searchType": [{ "Includes any": true, "dataType": "Select" }, { "Does not include": false, "dataType": "Select" }, {"Equals": false, "dataType": "Text" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ],

    "Returns": [
        {
            "id": 118,
            "name": "Return Multiple",
            "value": "Return Multiple",
            "isChecked": true,
            "hasMore": false,
            "parent": "Returns",
            "category": "Returns",
            "values": [""],
            "member": "manda_investors.MultipleReturn",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
        {
            "id": 119,
            "name": "IRR",
            "value": "IRR",
            "isChecked": true,
            "hasMore": false,
            "parent": "Returns",
            "category": "Returns",
            "values": [""],
            "member": "manda_investors.Amount_M",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": true,
                "multiple": true
            }
        },
    ],

    "Exits Valuation Info": [
        {
            "id": 120,
            "name": "Post Money Valuation",
            "value": "Post Money Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Valuation Info",
            "category": "Exits Valuation Info",
            "values": [""],
            "member": "exit_investment_flat.company_valuation",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 121,
            "name": "Revenue Multiple (Post-money)",
            "value": "Revenue Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Valuation Info",
            "category": "Exits Valuation Info",
            "values": [""],
            "member": "exit_investment_flat.revenue_multiple",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false,"dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 122,
            "name": "EBITDA Multiple (Post-money)",
            "value": "EBITDA Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Valuation Info",
            "category": "Exits Valuation Info",
            "values": [""],
            "member": "exit_investment_flat.ebitda_multiple",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true,"dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false,"dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
        {
            "id": 123,
            "name": "PAT Multiple (Post-money)",
            "value": "PAT Multiple (Post-money)",
            "isChecked": true,
            "hasMore": false,
            "parent": "Exits Valuation Info",
            "category": "Exits Valuation Info",
            "values": [""],
            "member": "exit_investment_flat.pat_multiple",
            "segments" : "advisor_investors_exit.standard",
            "filterData": {
                "searchType": [{ "Greater than or equal to": true, "dataType": "Numbers" }, {"Less than or equal to": false , "dataType": "Numbers"}, { "Equals to": false, "dataType": "Numbers" }, { "Does not equal to": false,  "dataType": "Numbers" }, {"Blanks": false, "dataType": "blank" }],
                "dataType": "Text",
                "autoSuggest": false,
                "multiple": false
            }
        },
    ]
};