import React, { useContext, useEffect, useState } from 'react';
import MultiSelectEntity from '../RefineSearch/AcquirerGroupSelect';
import FilterWrapper from './FilterWrapper';
import { PeFilterContext } from '../../Contexts/PeFilterContext';

function AcquirerGroup({ open, accordionClick, acquirerGroupOptions, setAcquirerGroupOptions, label , filterGroupUrl }) {

  const {acquirerGroup, setAcquirerGroup} = useContext(PeFilterContext);

  useEffect(()=> {
    const testopt = acquirerGroup?.filter(opt => opt.isChecked);
    if(!testopt.length){
      setAcquirerGroup(acquirerGroupOptions)
    }
  }, [acquirerGroupOptions])
  
  const [selectedCount, setSelectedCount] = useState(0);
  
  const handleGroupChange = (selectedNames) => {
    
    const updatedOptions = acquirerGroup.map(option => ({
        ...option,
        isChecked: selectedNames.includes(option.name)
    }));
    setAcquirerGroup(updatedOptions);
    setAcquirerGroupOptions(updatedOptions);

    console.log('targetGro', updatedOptions);
    
  };
  // setSelectedCount(acquirerGroup?.filter((option) => option.isChecked).length);
  console.log(acquirerGroup, 'outside');

      

  // console.log(selectedCount, 'count', acquirerGroup);
  

  return (
    <div className="border-b-[3px] border-[#E8E6E1]">
      <li className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"}>
        <FilterWrapper
          isSelect={false}
          label={label}
          count={acquirerGroup?.filter((option) => option.isChecked).length}
          onClick={accordionClick}
          open={open}
        />
        <div className="relative overflow-hidden transition-all max-h-0 duration-700 overflow-y-auto no-scrollbar" style={{ maxHeight: open ? "10rem" : "" }}>
          {open && (
            <>
              <MultiSelectEntity
                handleGroupChange={handleGroupChange}
                acquirerGroupOptions={acquirerGroup}
                filterGroupUrl={filterGroupUrl}
                setAcquirerGroupOptions={setAcquirerGroupOptions}
              />
            </>
          )}
        </div>
      </li>
    </div>
  );
}

export default AcquirerGroup;
