import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/FundMultiselectEntity';
import FilterWrapper from './FilterWrapper';

function FundStatusFilter({ open,
    accordionClick,
    FundStatusOptions,
    setFundStatusOptions,
    customPathname,
    updateSubOptions,
    FundStatusSuboptions,
    isSuboptionsFetch,
    setFundStatusSuboptions }) {

    const { FundStatus, refineSearchFilters } = useContext(PeFilterContext);

    console.log(isSuboptionsFetch,"fetch")

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case,
    the `useEffect` hook is used to update the `FundStatusOptions` state based on the `FundStatus`
    and `refineSearchFilters` values. */
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.fundStatus?.map(value => value.id) ?? [];
        if(industryIds?.length === 0){
            updateSubOptions("")
        }
        setFundStatusOptions(FundStatus?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [FundStatus, customPathname, refineSearchFilters, setFundStatusOptions])

    // useEffect(() => {
    //     const industryIds = refineSearchFilters[customPathname]?.closeStatus?.map(value => value.id) ?? [];
    //     setFundStatusSuboptions(FundStatusSuboptions?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    // }, [FundStatusSuboptions, customPathname, refineSearchFilters, setFundStatusSuboptions])



    /**
     * The handleChange function updates sub-options and fund status options based on the selected
     * value.
     * @param e - The parameter `e` is an event object that is passed to the `handleChange` function.
     * It represents the event that triggered the function, such as a change event on an input element.
     */
    const handleChange = (e) => {
        const value = e.target.value
        updateSubOptions(value)
        setFundStatusOptions(FundStatusOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.id === parseInt(value) })))

    };

    /**
     * The function `handleSubChange` updates the `isChecked` property of an array of objects based on
     * the user's selection.
     * @param e - The parameter `e` is an event object that is passed to the `handleSubChange`
     * function. It represents the event that triggered the function, such as a click event on a
     * checkbox.
     */
    const handleSubChange = (e) => {
        const { name, checked, id } = e.target;
        let currentIndustry = [];

        if (name === "allSelect") {
            currentIndustry = FundStatusSuboptions.map((industry) =>
                ({ ...industry, isChecked: checked })
            );
            setFundStatusSuboptions(currentIndustry);
        } else {
            currentIndustry = FundStatusSuboptions.map((industry) =>
                industry.id === parseInt(id) ? { ...industry, isChecked: checked } : industry
            );
            setFundStatusSuboptions(currentIndustry);
        }
    }

    const selected = FundStatusOptions?.filter(option => option.isChecked)?.at(0)

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selected?.name ? "Status - " + selected?.name : "Status"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    <select name="" id="" value={selected?.id ?? ""} onChange={handleChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        <option value="">Select</option>
                        {FundStatusOptions && FundStatusOptions.length > 0 ? (
                            FundStatusOptions.map((investorType, index) => (
                                <option key={investorType.id} value={investorType.id}>{investorType.name}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>

                    {isSuboptionsFetch
                        ? <p className='w-full py-1 text-center font-sans_light_body'>Loading...</p>
                        : FundStatusSuboptions?.length !== 0 ? <MultiSelectEntity
                            handleEntityChange={handleSubChange}
                            primaryEntity={FundStatusSuboptions}
                        /> : <></>}
                </div>

            </li>

        </div>
    )
}

export default FundStatusFilter