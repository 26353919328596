import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity';
import FilterWrapper from './FilterWrapper';
import useScrollIntoView from './useScrollIntoView';

/**
 * The CityFilter function is a component in JavaScript that renders a filter for selecting cities and
 * updates the selected city options based on user input.
 * @returns The CityFilter component is returning a JSX element.
 */
function CityFilter({ open, accordionClick, customPathname, setCityOptions, CityOptions }) {
    const { Cities, refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);
   /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
   `useEffect` hook is used to update the selected city options based on user input. */
    useEffect(() => {
        const ids = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];
        setCityOptions(Cities?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
    }, [Cities, customPathname, refineSearchFilters, setCityOptions])


    /* The `handleCityChange` function is an event handler that is triggered when the user selects or
    deselects a city option in the filter. It receives the event object `e` as a parameter. */
    const handleCityChange = ((e) => {
        const { name, checked, id } = e.target;
        if (name === "allSelect") {
            let currentCities = CityOptions.map((city) =>
                ({ ...city, isChecked: checked })
            );
            setCityOptions(currentCities);
        } else {
            let currentCities = CityOptions.map((city) =>
                city.id === parseInt(id) ? { ...city, isChecked: checked } : city
            );
            setCityOptions(currentCities);
        }
    });

    const len = CityOptions?.filter(option => option.isChecked)?.length ?? 0
    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
                ref={listItemRef}
            >
                <FilterWrapper
                    isSelect={false}
                    count={len}
                    label={"City (of Target Company)"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleCityChange}
                        primaryEntity={CityOptions}
                    /> : <></>}
                </div>

            </li>

        </div >
    )
}

export default CityFilter