import React from 'react';

function TgMultiSelectEntity(props) {

  const [primaryEntity, setPrimaryEntity] = React.useState(props.primaryEntity);
  const [searchText, setsearchText] = React.useState("");
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);

  const handleEntityChange = (e) => {
    clearSearchText();
    props.handleEntityChange(e);
  };

  const handleSearchTextChange = (e) => {
    setsearchText(e.target.value);
    props.setCompanyVal(e.target.value)
  };

  const clearSearchText = () => {
    setsearchText("");
  };

  React.useEffect(() => {
    if (searchText !== "") {
      const filteredEntity = props.primaryEntity?.filter(entity => entity?.name?.toLowerCase()?.indexOf(searchText.toLowerCase()) !== -1);
      // const filteredEntity = props.primaryEntity?.filter(entity => entity?.name?.toLowerCase()?.startsWith(searchText.toLowerCase()));
      setPrimaryEntity(filteredEntity);
    } else {
      setPrimaryEntity(props.primaryEntity)
    }
  }, [props.primaryEntity, searchText])

  const count = primaryEntity.filter(option => option.isChecked).length;

  const handleSelectAll = () => {
    const updatedPrimaryEntity = primaryEntity.map(entity => ({
      ...entity,
      isChecked: !selectAllChecked
    }));
    setPrimaryEntity(updatedPrimaryEntity);
    setSelectAllChecked(!selectAllChecked);
    handleEntityChange({
      target: { name: 'allSelect', checked: !selectAllChecked },
    });
  };

  const doneTypingInterval = 800;
  let typingTimer;

  const doneTyping = () => {
    if (searchText !== "") {
      if (props.field === "sector") {
        if (searchText.length > 1) {
          props.fetchData();
        }
      } else {
        props.fetchData();
      }
    }
  };
  
  const handleKeyUp = () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(doneTyping, doneTypingInterval);
  };

  const handleKeyDown = () => {
    clearTimeout(typingTimer);
  };
  return (
    <>
      <input
        type="search"
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onChange={handleSearchTextChange}
        value={searchText}
        className="input w-[98%] border-b-2 border-black my-2 p-2 pt-0 focus:outline-none"
        placeholder={props.city === "city" ? (count === 0 ?  "Type here to search" : count > 1 ? count + "  selected" : count + "  selected") : "Search"}
      />
      {primaryEntity && primaryEntity.length > 0 && (props.showAll ?? true) && (
        primaryEntity.length > 1 ? (
          <div
            className={`form-check font-sans_medium_body cursor-default flex text-left py-2 pr-2 w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto hover:bg-gray-50`}
          >
            <input
              type="checkbox"
              className="form-check-input"
              name="allSelect"
              id="0"
              checked={props.city === "city" ? selectAllChecked : !props?.primaryEntity?.some((entity) => entity?.isChecked !== true)}
              onChange={props.city === "city" ? handleSelectAll : handleEntityChange}
            />
            <label htmlFor='0' className="form-check-label mx-2 mr-2 cursor-pointer text-[13px] text-[#333333] leading-[17px] font-sans_book_body">
              {props.city === "city" ? 
                (count > 0 ? "Deselect All" : "Select All") 
                : 
                "All"
              }            
            </label>
          </div>
        ) : null
      )}

      {primaryEntity && primaryEntity.length > 0 ? (
        primaryEntity.map((entity, index) => (
          <div
            className={`form-check font-sans_medium_body cursor-default flex text-left  py-2 pr-2  w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto hover:bg-gray-50"`}
            key={index}>
            <input
              type="checkbox"
              className="form-check-input"
              name={entity.name}
              id={entity.id}
              checked={entity?.isChecked || false}
              onChange={handleEntityChange}
            />
            <label htmlFor={entity.id} className="form-check-label mx-2 mr-2 cursor-pointer  text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{entity.name}</label>
          </div>
        ))
      ) :
        (
          <h1 className="text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{' '}</h1>
        )}
    </>
  )
}

export default TgMultiSelectEntity;
