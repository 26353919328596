import { useState , useContext , useEffect,useRef } from "react";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { useLocation } from "react-router-dom";
import { BsArrowRightSquareFill } from "react-icons/bs";
/**
 * The Sidebar component is a dynamic sidebar that can be toggled open or closed, and it renders its
 * children components when open.
 * @returns a JSX element.
 */
export default function Sidebar({children}) {
    const {dropdown,setDropdown ,setOpen,open} = useContext(PeFilterContext);
    const location = useLocation(); 
    const hasNumberInURLRef = useRef(false);

    useEffect(() => {
        const hasNumberInURL = /\d/.test(location.pathname);

        // If the URL contains a number and the sidebar has not been opened due to a number yet, open the sidebar
        if (hasNumberInURL && !hasNumberInURLRef.current) {
            setOpen(true);
            hasNumberInURLRef.current = true;
        } 
        // If the URL does not contain a number, close the sidebar and reset the ref
        else if (!hasNumberInURL) {
            setOpen(false);
            hasNumberInURLRef.current = false;
        }
    }, [location.pathname]);

    console.log(dropdown,"expandcollapse")

    const handleToggle = () => {
        setDropdown(!dropdown);
        console.log(dropdown , "jgjhg")
    };

    return (
        <>
            <div
                className={` ${
                    open ? "w-12" : "w-60 "
                } flex flex-col h-full shadow duration-300 `}
            >
                <div className="space-y-3 h-screen ">
                    <div className="flex items-center pl-1 pr-3 pt-3 justify-between">
                        <h2 className="text-xl font-bold text-white">
                            <div style={{display:!open?"flex":"none"}} className="text-[16px] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer  flex-row" onClick={handleToggle}>
                                <span className="mt-[2px]">
                                    {dropdown  ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                                </span>
                                <span className="ml-1">REFINE SEARCH</span>
                            </div>
                        </h2>
                        <button onClick={() => setOpen(!open)}>
                            {!open ? <BsArrowRightSquareFill style={{ transform: 'rotate(180deg)', height: 24, width: 24, color: '#bc8b20',borderRadius: 4 }} /> : <BsArrowRightSquareFill style={{  height: 24, width: 24, color: '#bc8b20',borderRadius: 4 }} />}
                        </button>
                    </div>
                    
                    <div className="flex-1" style={{display:!open?"block":"none"}}>                        
                        {children}
                    </div>
                </div>
            </div>
            <div className="container mx-auto mt-12">
            </div>
        </>
    );
}