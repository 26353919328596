import { useState } from "react";

/**
 * The RefineSearchBar component is a fixed bar where RefineSearch filters constraints does not exists.
 * @returns a JSX element.
 */
export default function Sidebar({children}) {
    return (
        <>
            <div
                className="w-12 flex flex-col h-full shadow duration-300">
                <div className="space-y-3 h-screen">
                    {/* For display empty #FAF5EA color bar because of there is no filters for refine search */}
                </div>
            </div>
            <div className="container mx-auto mt-12">
            </div>
        </>
    );
}