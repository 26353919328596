import React, { useState, useMemo, useContext } from "react";
import {useParams, Link, useLocation, matchPath } from 'react-router-dom';
import moment from "moment";
import { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { reExportExcel } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import OverflowToolTip from '../../shared/OverflowToolTip';
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";

/**
 * The `AdvisorTo_Acquirer` function is a React component that displays a table of data for companies
 * that have been acquired by a specific advisor.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `AdvisorTo_Acquirer` component. These properties can be accessed using dot notation, such as
 * `props.propertyName`.
 * @returns The `AdvisorTo_Acquirer` component is returning a JSX element, which represents the
 * structure and content of the component's rendered output.
 */
const AdvisorTo_Acquirer = (props) => {

  const [showXlDownload, setshowXlDownload] = useState(false);
  const { companyId } = useParams(); 
    let advisorProfileId = parseInt(companyId)
  const location = useLocation();
  const {isTrialExport,isStudent} = useContext(UserContext)

 /* The `customStringSortMemo` is a custom sorting function that is used in the DataTable component. It
 is created using the `useMemo` hook to memoize the function and prevent unnecessary re-renders. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';
        return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
    }
}, []);

/* The `dealDateSort` constant is a custom sorting function that is created using the `useMemo` hook.
It is used in the DataTable component to sort the data based on the "Deal Date" column. */
const dealDateSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
  }
}, [])
const reMatch  =matchPath(`${routePathSegments.re}/*`,location.pathname);


/**
 * The addCommasToNumber function takes a number as input and returns a string representation of the
 * number with commas added for thousands separators.
 * @param number - The `number` parameter is the number that you want to add commas to.
 * @returns The function `addCommasToNumber` returns a string representation of the input number with
 * commas added to separate thousands.
 */
const addCommasToNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/* The `columns` constant is an array of objects that define the columns for the DataTable component.
Each object represents a column and contains properties such as `id`, `Header`, `accessor`,
`className`, `Cell`, and `sortType`. */
  const columns = useMemo(() => [
    {
      id: 'acquirerName',
      Header: "Acquirer Name",
      accessor: "acquirerName",
      className:"w-[22%]",
      // headerClassName:"w-[10%]",
      Cell: ({ value }) => {
        return <div className="leading-[2rem] font-sans_book_body items-center justify-center">
          {/* {
          value?.logo_path=="default.png" || value?.logo_path=="/default.png" || !value?.logo_path?
                <RandomLogo name={value.name} page="company page"/>
                :
                <CompanyLogo name={value.name} page="company page" logo={value?.logo_path}/>           
            } */}

        {/* <OverflowToolTip component={
        <Link className='hover:text-[#BC8B20]' state={{ cameFrom: location.state?.prevPath }} to={routeNames.maAcquiresById(value?.id)}>
          {value.name ?? "--"}
        </Link> } /> */}
        {value.name ?? "--"}
        </div>
      },
      sortType: customStringSortMemo,
    },
    {
        Header: "Company Name",
        accessor: "companyName",
        className:"w-[20%]",
        Cell: ({ value }) => {
          return <OverflowToolTip className=" " component={<Link className='hover:text-[#BC8B20]'
            state={{ cameFrom: location.state?.prevPath }}
            to={reMatch!=null?routeNames.reCompanyFundById(value?.id)
              :"" }>
            {value.name ?? "--"}
          </Link>} />
        },
        sortType: customStringSortMemo,
      },
      
    {
      id:"dealDate",
      Header: "Deal Date",
      accessor: "dealDate",
      className:"w-[12%]",
      
      Cell: (cell) => {
        return <Link state={{ cameFrom: location.state?.prevPath ?? "" }}
          to={reMatch != null ? routeNames.reCompanyOtherMaDealById(cell.value.id, cell.value.dealId)
            : ""}>
          {/* {cell?.value?.Deal_Date!='' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"} */}
          {cell?.value?.date != '' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"}

        </Link>
      },
    sortType:dealDateSort
    },
    {
      Header: "Deal Amount (US$ M)",
      accessor: "dealAmountInUS",
      className:"pl-[8rem] lg:pl-[110px] w-[50%]",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
      // headerspanClassName:"ml-auto"
    },
   
   
  ], []);
  
/* The `rowData` constant is using the `useMemo` hook to memoize the creation of an array of objects.
Each object represents a row of data for the DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];

    props.toAquirer?.forEach(companies => {
      rows.push({
        acquirerName: { name: companies?.acquirer_name, id: companies?.acquirer_id, logo_path:companies?.logo_path},
        dealDate: {date:companies?.deal_date, dealId: companies?.deal_id, id: companies?.company_id},
        dealAmountInUS: companies?.deal_amount >0 ? companies?.deal_amount:" ",
        companyName: { name: companies?.company, id: companies?.company_id },
      })
    });
    setshowXlDownload(rows.length!==0)
    return rows;
  }, [props.toAquirer])
  const { pathname, state } = useLocation();

 /* The `initialSort` constant is using the `useMemo` hook to memoize the creation of an array with a
 single object. This object represents the initial sorting configuration for the DataTable
 component. */
    const initialSort = useMemo(() => [
      {
          id:"dealDate",
          desc: true
      }
  ], []); 
  return (
    <div className="px-4 bg-[#EDEDED] w-[100%]">
      <div className="bg-white border overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
        <div className=" pt-4 px-5 border-b border-b-[#F1F1F1]  ">
          <div className="flex justify-between mb-3">
            <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:leading-[22px] font-semibold lg:text-[17px]">
              To Acquirers
            </h3>
            {(isTrialExport != 'false' && isStudent == 'false'  && showXlDownload)&&<ExcelDownloader
                            url={reExportExcel}
                            fileName={Constants.exportNames.to_acquirers(props.advisorName)}
                            data={{
                              
                              "advisor_type":"L",
                              "section": "to_acquirers",
                              "advisor_id": advisorProfileId
                           }} />}
          </div>
        </div>
        <div className="space-y-6">
          <div className="bg-white pb-2 rounded-[6px]">
            <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
          </div>
        </div>       
      </div>
    </div>
  );
};

export default AdvisorTo_Acquirer;
