import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { filterLocationUrl, filterSectorByIndutry , filterGroupUrl, filterAcquirerGroupUrl } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { TableHeaderContext } from '../../Contexts/TableHeaderContext';
import { UserContext } from '../../Contexts/UserContext';
import AcquirerDealRangeFilter from '../RefineSearchComponents/AcquirerDealRangeFilter';
import AcquirerTypeFilter from '../RefineSearchComponents/AcquirerTypeFilter';
import CityFilter from '../RefineSearchComponents/CityFilter';
import CompanyTypeFilter from '../RefineSearchComponents/CompanyTypeFilter';
import CountryFilter from '../RefineSearchComponents/CountryFilter';
import DealTypeFilter from '../RefineSearchComponents/DealTypeFilter';
import IndustryFilter from '../RefineSearchComponents/IndustryFilter';
import RegionFilter from '../RefineSearchComponents/RegionFilter';
import StateFilter from '../RefineSearchComponents/StateFilter';
import ValuationFilter from '../RefineSearchComponents/ValuationFilter';
import { TableDataContext } from '../../Contexts/TableDataContext';
import Constants from '../../Constants';
import SectorFilter from '../RefineSearchComponents/SectorFilter';
import TargetGroup from '../RefineSearchComponents/TargetGroup';
import AcquirerGroup from '../RefineSearchComponents/AcquirerGroup';
import TargetCompany from '../RefineSearchComponents/TargetCompany';
import {filterTargetCompany ,filterAcquirers,filterlAdvisors,filtertAdvisors } from "../../Config/config"
import Acquirers from '../RefineSearchComponents/Acquirers';
import LAdvisor from '../RefineSearchComponents/lAdvisor';
import TAdvisor from '../RefineSearchComponents/tAdvisor';
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
 
function MaCompaniesRefineSearch({ customPathname }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { setrefineSearchFilters,dbDropdown , setDbDropdown ,AllCities, onErrorHandler, refineSearchFilters, MinAcquirerDealRange, MaxAcquirerDealRange,setMonthPickerValue,Sectors, setSectors, setTargetGroup, setAcquirerGroup,TargetCompanyOptions,setTargetCompanyOptions ,setAcquirersOptions ,AcquirersOptions,setlAdvisorsOptions ,dropdown, lAdvisorsOptions ,tAdvisorsOptions ,settAdvisorsOptions  } = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const { getToken } = useContext(UserContext);
    const [select, setSelected] = useState(0);
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[customPathname]?.globalSearch?.length > 0 ? true : false
    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [CompanyTypeOptions, setCompanyTypeOptions] = useState([])
    const [CountryOptions, setCountryOptions] = useState([])
    const [targetGroupOptions, setTargetGroupOptions] = useState([]);
    const [acquirerGroupOptions, setAcquirerGroupOptions] = useState([]);
    const [RegionOptions, setRegionOptions] = useState([])
    const [StateOptions, setStateOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [DealTypeOption, setDealTypeOption] = useState([])
    const [AcquirerCountryOptions, setAcquirerCountryOptions] = useState([])
    const [AcquirerRegionOptions, setAcquirerRegionOptions] = useState([])
    const [AcquirerStateOptions, setAcquirerStateOptions] = useState([])
    const [AcquirerCityOptions, setAcquirerCityOptions] = useState([])
    const [AcquirerTypeOptions, setAcquirerTypeOptions] = useState([])
    const [MinDealRangeOptions, setMinDealRangeOptions] = useState([])
    const [MaxDealRangeOptions, setMaxDealRangeOptions] = useState([])
    const [ValuationOptions, setValuationOptions] = useState([])

    
    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedSectors = Sectors.filter(option => option.isChecked);
        const selectedGroup = targetGroupOptions?.filter(option => option.isChecked);
        const selectedAcquirerGroup = acquirerGroupOptions?.filter(opt => opt.isChecked);
        const selectedCompanyType = CompanyTypeOptions?.filter(option => option.isChecked);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked);
        const selectedState = StateOptions?.filter(option => option.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedAcquirerType = AcquirerTypeOptions?.filter(option => option.isChecked);
        const selectedAcquirerCountries = AcquirerCountryOptions?.filter(option => option.isChecked);
        const selectedAcquirerRegion = AcquirerRegionOptions?.filter(option => option.isChecked);
        const selectedAcquirerState = AcquirerStateOptions?.filter(option => option.isChecked);
        const selectedAcquirerCity = AcquirerCityOptions?.filter(option => option.isChecked);
        const minDealRange = MinDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const maxDealRange = MaxDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedDealTypes = DealTypeOption?.filter(option => option.isChecked);
        const selectedValuations = ValuationOptions?.filter(option => option.isChecked);
        const selectedTargetCompany = TargetCompanyOptions?.filter(option => option.isChecked); 
        const selectedAcquirers = AcquirersOptions?.filter(option => option.isChecked); 
        const selectedlAdvisors = lAdvisorsOptions?.filter(option => option.isChecked);
        const selectedtAdvisors = tAdvisorsOptions?.filter(option => option.isChecked);





        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                group : selectedGroup ,
                companyType: selectedCompanyType,
                countries: selectedCountries,
                region: selectedRegion,
                state: selectedState,
                city: selectedCity,
                acquirer_group: selectedAcquirerGroup,
                acquirers: selectedAcquirers,
                ladvisors: selectedlAdvisors,
                tadvisors: selectedtAdvisors,
                acquirerType: selectedAcquirerType,
                acquirerCountries: selectedAcquirerCountries,
                acquirerRegion: selectedAcquirerRegion,
                acquirerState: selectedAcquirerState,
                acquirerCity: selectedAcquirerCity,
                target_company:selectedTargetCompany,
                dealRange: minDealRange != null && maxDealRange != null ? [minDealRange, maxDealRange] : [],
                dealType: selectedDealTypes,
                valuations: selectedValuations,
            },
        }))
    }


    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
        setTargetCompanyOptions([]);
        setAcquirersOptions([]);
        setlAdvisorsOptions([]);
        settAdvisorsOptions([]);
        setAcquirerGroupOptions([]);
        setTargetGroupOptions([]);
        setTargetGroup([]);
        setSectors([]);
        setAcquirerGroup([]);
    }


    // Submitting Refine Search Form 
    const refineSearchFilter = (event) => {
        event.preventDefault();
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[]}
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
        setMonthPickerValue(Constants.initialMonthRange)
        }
        setCurrentPage(1);
        applyFilter();
        setSelected(0)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }

    }

    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        // Reset all options to unchecked
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    const onCountryChange = (options, type) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: ids,
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                if (type === "acquirer") {
                    setAcquirerRegionOptions(response.data?.region)
                    setAcquirerStateOptions(response.data.state)
                    setAcquirerCityOptions(response.data?.city)
                } else {
                    setRegionOptions(response.data?.region)
                    setStateOptions(response.data.state)
                    setCityOptions(response.data?.city)
                }
            }
        }).catch(onErrorHandler)
    }

    
    
    const onRegionChange = (options, type) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        let selectedCountries = [];

        if (type === "acquirer") {
            selectedCountries = AcquirerCountryOptions?.filter(option => option.isChecked)?.map(option => option.id)
        } else {
            selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id)
        }
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: ids,
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                if (type === "acquirer") {
                    setAcquirerStateOptions(response.data.state)
                    setAcquirerCityOptions(response.data?.city)
                } else {
                    setStateOptions(response.data.state)
                    setCityOptions(response.data?.city)
                }
            }
        }).catch(onErrorHandler)
    }

    const onStateChange = (options, type) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        let selectedCountries = [];
        let selectedRegion = [];

        if (type === "acquirer") {
            selectedCountries = AcquirerCountryOptions?.filter(option => option.isChecked)?.map(option => option.id)
            selectedRegion = AcquirerRegionOptions?.filter(option => option.isChecked)?.map(option => option.id);
        } else {
            selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id)
            selectedRegion = RegionOptions?.filter(option => option.isChecked)?.map(option => option.id);
        }

        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: selectedRegion,
            state: ids,
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                if (type === "acquirer") {
                    setAcquirerCityOptions(response.data?.city)
                } else {
                    setCityOptions(response.data?.city)
                }
            }
        }).catch(onErrorHandler)
    }

    const fetchGroupData = async () => {
        
        try {
            const response = await axios.post(filterGroupUrl);
            setTargetGroupOptions(response.data);
        } catch (error) {
            console.error('Error fetching target group options:', error);
        }
    };

    // useEffect(()=>{
    //     fetch(filterTargetCompany, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({ search_key: "initial" })
    //         })
    //         .then(response => {
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }
    //         return response.json();
    //         })
    //         .then(data => {
    //         setTargetCompanyOptions(data?.results);
    //         console.log(TargetCompanyOptions,"vbvb")
    //         })
    //         .catch(error => {
    //         console.error('Error fetching data:', error);
    //         });
    //     },[])

    //     useEffect(()=>{
    //         fetch(filterAcquirers, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({ search_key: "initial" })
    //             })
    //             .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //             })
    //             .then(data => {
    //                 setAcquirersOptions(data?.results);
    //             })
    //             .catch(error => {
    //             console.error('Error fetching data:', error);
    //             });
    //         },[])

    //         useEffect(()=>{
    //             fetch(filterlAdvisors, {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({ search_key: "initial" })
    //                 })
    //                 .then(response => {
    //                 if (!response.ok) {
    //                     throw new Error('Network response was not ok');
    //                 }
    //                 return response.json();
    //                 })
    //                 .then(data => {
    //                     setlAdvisorsOptions(data?.results);
    //                 })
    //                 .catch(error => {
    //                 console.error('Error fetching data:', error);
    //                 });
    //             },[])

    //             useEffect(()=>{
    //                 fetch(filtertAdvisors, {
    //                     method: 'POST',
    //                     headers: {
    //                         'Content-Type': 'application/json'
    //                     },
    //                     body: JSON.stringify({ search_key: "initial" })
    //                     })
    //                     .then(response => {
    //                     if (!response.ok) {
    //                         throw new Error('Network response was not ok');
    //                     }
    //                     return response.json();
    //                     })
    //                     .then(data => {
    //                         settAdvisorsOptions(data?.results);
    //                     })
    //                     .catch(error => {
    //                     console.error('Error fetching data:', error);
    //                     });
    //                 },[])
    
    const fetchAcquirerData = async () => {
        try {
            const response = await axios.post(filterAcquirerGroupUrl);
            setAcquirerGroupOptions(response.data);
        } catch (error) {
            console.error('Error fetching target group options:', error);
        }
    }


    useEffect(() => {
        fetchGroupData();
        fetchAcquirerData();
        console.log(targetGroupOptions);
      }, []); 

    // useEffect(() => {
    //     const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

    //     if (ids.length > 0) {
    //         axios.post(filterSectorByIndutry, {
    //             "industry": ids,
    //             category: "investments"
    //         }, {
    //             headers: {
    //                 "Authorization": getToken()
    //             }
    //         }).then(response => {
    //             if (response.status == 200) {
    //                 setSectors(response.data?.data ?? [])
    //             } else {
    //                 setSectors([])
    //             }
    //         }).catch(onErrorHandler)
    //     } else {
    //         setSectors([])
    //     }

    // }, [IndustriesOptions])


    useEffect(() => {
        const countryIds = refineSearchFilters[customPathname]?.countries?.map(value => value.id) ?? [];
        const ids = refineSearchFilters[customPathname]?.region?.map(value => value.id) ?? [];
        const stateIds = refineSearchFilters[customPathname]?.state?.map(value => value.id) ?? [];
        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];

        const acquirerCountryIds = refineSearchFilters[customPathname]?.acquirerCountries?.map(value => value.id) ?? [];
        const acquirerRegionIds = refineSearchFilters[customPathname]?.acquirerRegion?.map(value => value.id) ?? [];
        const acquirerStateIds = refineSearchFilters[customPathname]?.acquirerState?.map(value => value.id) ?? [];
        const acquirerCityIds = refineSearchFilters[customPathname]?.acquirerCity?.map(value => value.id) ?? [];

        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCountryOptions(response.data?.countries?.map(option => ({ ...option, isChecked: countryIds.includes(option.id) })))
                setRegionOptions(response.data?.region?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
                setStateOptions(response.data.state?.map(option => ({ ...option, isChecked: stateIds.includes(option.id) })))
                setCityOptions(AllCities?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))

                setAcquirerCountryOptions(response.data?.countries?.map(option => ({ ...option, isChecked: acquirerCountryIds.includes(option.id) })))
                setAcquirerRegionOptions(response.data?.region?.map(option => ({ ...option, isChecked: acquirerRegionIds.includes(option.id) })))
                setAcquirerStateOptions(response.data.state?.map(option => ({ ...option, isChecked: acquirerStateIds.includes(option.id) })))
                setAcquirerCityOptions(response.data.city?.map(option => ({ ...option, isChecked: acquirerCityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters,AllCities])

    const handleDbToggle = () => {
        setDbDropdown(!dbDropdown);
    };
console.log(dropdown,"lgujygjug")
    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter">
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                label="Target Industry"
                                open={select === 1} />
                            <SectorFilter
                                setOptions={setSectors}
                                options={Sectors}
                                accordionClick={() => accordionClick(14)}
                                customPathname={customPathname}
                                label="Target Sector"
                                open={select === 14} />
                            


                            <CompanyTypeFilter
                                CompanyTypeOptions={CompanyTypeOptions}
                                setCompanyTypeOptions={setCompanyTypeOptions}
                                customPathname={customPathname}
                                label="Target Company Type"
                                accordionClick={() => accordionClick(2)}
                                open={select === 2} />
                            <CountryFilter
                                CountryOptions={CountryOptions}
                                setCountryOptions={setCountryOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(3)}
                                onChange={onCountryChange}
                                label="Target Country"
                                open={select === 3} />
                            <RegionFilter
                                RegionOptions={RegionOptions}
                                setRegionOptions={setRegionOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(4)}
                                onChange={onRegionChange}
                                label="Target Region"
                                open={select === 4} />
                            <StateFilter
                                StateOptions={StateOptions}
                                setStateOptions={setStateOptions}
                                customPathname={customPathname}
                                onChange={onStateChange}
                                accordionClick={() => accordionClick(5)}
                                label="Target State"
                                open={select === 5} />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(6)}
                                label="Target City"
                                open={select === 6} />

                            <DealTypeFilter
                                DealTypeOption={DealTypeOption}
                                setDealTypeOption={setDealTypeOption}
                                accordionClick={() => accordionClick(13)}
                                customPathname={customPathname}
                                isMultiSelect={true}
                                showAll={false}
                                dataPath="ma"
                                open={select === 13}
                            />

                            <AcquirerTypeFilter
                                AcquirerTypeOptions={AcquirerTypeOptions}
                                setAcquirerTypeOptions={setAcquirerTypeOptions}
                                customPathname={customPathname}
                                label="Acquirer Company Type"
                                accordionClick={() => accordionClick(7)}
                                open={select === 7} />
                            <CountryFilter
                                CountryOptions={AcquirerCountryOptions}
                                setCountryOptions={setAcquirerCountryOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(8)}
                                onChange={(options) => onCountryChange(options, 'acquirer')}
                                filterPathkey="acquirerCountries"
                                label='Acquirer Country'
                                open={select === 8} />
                            <RegionFilter
                                RegionOptions={AcquirerRegionOptions}
                                setRegionOptions={setAcquirerRegionOptions}
                                customPathname={customPathname}
                                filterPathkey="acquirerRegion"
                                label='Acquirer Region'
                                accordionClick={() => accordionClick(9)}
                                onChange={(options) => onRegionChange(options, 'acquirer')}
                                open={select === 9} />
                            <StateFilter
                                StateOptions={AcquirerStateOptions}
                                setStateOptions={setAcquirerStateOptions}
                                customPathname={customPathname}
                                onChange={(options) => onStateChange(options, 'acquirer')}
                                filterPathkey="acquirerState"
                                label='Acquirer State'
                                accordionClick={() => accordionClick(10)}
                                open={select === 10} />
                            <CityFilter
                                CityOptions={AcquirerCityOptions}
                                setCityOptions={setAcquirerCityOptions}
                                filterPathkey="acquirerCity"
                                label='Acquirer City'
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(11)}
                                open={select === 11} />
                            <AcquirerDealRangeFilter
                                customPathname={customPathname}
                                MinDealOptions={MinDealRangeOptions}
                                setMinDealOptions={setMinDealRangeOptions}
                                MaxDealOptions={MaxDealRangeOptions}
                                setMaxDealOptions={setMaxDealRangeOptions}
                                MinDealRange={MinAcquirerDealRange}
                                MaxDealrange={MaxAcquirerDealRange} />
                            <ValuationFilter
                                ValuationOptions={ValuationOptions}
                                setValuationOptions={setValuationOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(12)}
                                dataPath="maCompanies"
                                open={select === 12}
                            />

                        </ul>
                        <ul  className='flex pt-3 text-[16px] pl-1 border-t-2 border-[#e8e6e1] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer ' onClick={handleDbToggle}>
                            <span className="mt-[2px]">
                                {dbDropdown? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            </span>
                            <h1 className='font-bold text-[16px] ml-1 '>{customPathname.includes("investors") ? "SHOW INVESTORS BY" : customPathname.includes("companies") ? "SHOW DEALS BY" : "SHOW ADVISORS BY"}</h1>
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dbDropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] mb-3  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]`}>
                            
                            <TargetCompany 
                                options={TargetCompanyOptions}
                                setOptions={setTargetCompanyOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(16)}
                                label="Target Company"
                                open={select === 16}
                            />

                            <TargetGroup
                                targetGroupOptions={targetGroupOptions}
                                setTargetGroupOptions={setTargetGroupOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(15)}
                                label="Target Group"
                                open={select === 15}
                                filterGroupUrl={filterGroupUrl}
                            />

                            <Acquirers 
                                options={AcquirersOptions}
                                setOptions={setAcquirersOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(17)}
                                label="Acquirers"
                                open={select === 17 }
                            />
                            
                            <AcquirerGroup
                                acquirerGroupOptions={acquirerGroupOptions}
                                setAcquirerGroupOptions={setAcquirerGroupOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(121)}
                                label="Acquirer Group"
                                open={select === 121}
                                filterGroupUrl={filterAcquirerGroupUrl}
                            />
                            <LAdvisor 
                                options={lAdvisorsOptions}
                                setOptions={setlAdvisorsOptions}
                                customPathname={customPathname}
                                label="Legal Advisors"
                                accordionClick={() => accordionClick(18)}
                                open={select === 18 }
                            />
                            <TAdvisor 
                                options={tAdvisorsOptions}
                                setOptions={settAdvisorsOptions}
                                label="Transaction Advisors"
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(19)}
                                open={select === 19 }
                            />

                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
        </div>
    )
}

export default MaCompaniesRefineSearch