import { ApolloClient, InMemoryCache } from "@apollo/client";
import moment from "moment";

export const PEMenuItems = ["/investors", "/companies", "/company", "/advisors", "/advisor", '/pe-vc-advisors', "/investor", '/transaction-advisor', '/investors/Funds-partners'];
 

export const PEAdvisorMenuItemsT = ["t_pe_vc_advisor", "t_vc_advisor", "t_pe_vc_ma_advisor", "t_vc_ma_advisor", "t_pe_vc_pm_advisor", 't_vc_pm_advisor'];
export const PEAdvisorMenuItemsL = ["l_pe_vc_advisor", "l_vc_advisor", "l_pe_vc_ma_advisor", "l_vc_ma_advisor", "l_pe_vc_pm_advisor", 'l_vc_pm_advisor'];

 export const MaMenuItems = ["/ma-acquirers", "/ma-companies", "/mahome", "/ma-company-page", "/ma-advisor", "/ma-deal-page", '/ma-transaction']

export const REMenuItems = ["/reinvestor", "/recompanies", "/rehome", "/company", "/companies", "/re-company-page", "/readvisor", "/re-advisors", "reinvestor", '/retransaction']
export const CFSMenuItems = ["/cfs-company-page", "/cfs-companies",]



 export const baseUrl = process.env.REACT_APP_API;
export const baseUrlAPP = process.env.REACT_APP_BACKEND;
export const logoUrl = process.env.REACT_APP_LOGO
export const cubeBaseUrl = process.env.REACT_APP_CUBEJS


/* The above code is creating a new ApolloClient instance in JavaScript React. It sets the URI to the
GraphQL endpoint, fetchOptions to 'no-cors' mode, and uses an InMemoryCache for caching data. */
export const client = new ApolloClient({
   uri: `${baseUrlAPP}/graphql`,
  fetchOptions: {
    mode: 'no-cors'
  },
  cache: new InMemoryCache()
});


// List page
export const getPECompaniesApi = `${baseUrl}/getCompanies`;
export const getPEDealsApi = `${baseUrl}/getDeals`;

//URL when global search used
export const getPEGlobalSearchCompaniesApi = `${baseUrl}/gsCompanyList`;
export const getInvestorsApi = `${baseUrl}/getInvestors`;
export const getAdvisorsApi = `${baseUrl}/getAdvisors`;
export const getLimitedPartners = `${baseUrl}/getLimitedPartners`;
export const getFunds = `${baseUrl}/getFunds`;
// export const exportPDF = `${baseUrl}/company/excel/export"
export const exportPDF = `${baseUrl}/company/excel/flatexport`
export const exportInvestorExcel = `${baseUrl}/investor/excel/flatexport`
export const exportAdvisorExcel = `${baseUrl}/advisor/excel/flatexport`
export const exportFundsExcel = `${baseUrl}/funds/excel/flatexport`
export const exportLPExcel = `${baseUrl}/limitedPartners/excel/flatexport`

export const getIndustriesApi = `${baseUrl}/refine/getIndustry`;

export const getCoinvestorApi = `${baseUrl}/coinvestor`;

export const exportReFundsExcel = `${baseUrl}/re/funds/excel/flatexport`

//RE list Page

export const exportREInvestorExcel = `${baseUrl}/re/investor/excel/flatexport`


// End List Page


// Detail page
export const getPEFinancialApi = `${baseUrl}/getFinacials`
export const getCfsRatioApi = `${baseUrl}/getCfsRatio`
export const getLimitedPartnersDetail = `${baseUrl}/limitedPartnersProfile`
export const getLimitedPartnersInvestor = `${baseUrl}/getLimitedPartnerInvestors`
export const getFundsDetails = `${baseUrl}/getFund/detail`
// export const AdvisorDetailPage = `${baseUrl}/advisorDetail`

export const AdvisorDetailPage = `${baseUrl}/advisorDetail`;
// export const TAdvisorDetailPage  = `${baseUrl}/tadvisor/advisorDetail`;
export const AdvisorTopCard = `${baseUrl}/advisorTopCard`

// ReDetailpage
export const retopCardUrl = `${baseUrl}/re/topcard`

// End Detail Page

//Deal detail page
export const getFilingsApi = `${baseUrl}/getFilings`

export const getDealFilingsApi = `${baseUrl}/deal/getFilings`

export const getRoundHierarchy = `${baseUrl}/getRoundHierarchy`

export const CompanydetailCard = `${baseUrl}/topcard/investments`




//Add note
export const createGroupListApi = `${baseUrl}/groupList/create`

export const groupListApi = `${baseUrl}/groupList`

export const addEntityApi = `${baseUrl}/groupList/createEntity`

export const deleteItemFromGroupApi = `${baseUrl}/groupList/removeEntity`

export const wishListApi = `${baseUrl}/groupListTable`

export const dashboardTopCardApi = `${baseUrl}/dashboard/topcard`

export const LatestActivitiyApi  = `${baseUrl}/dashboard/metrics`

//create notes
export const notesOptionsApi = `${baseUrl}/notes/options`

export const createNotesApi = `${baseUrl}/notes/create`

export const updateNotesApi = `${baseUrl}/notes/update`

export const listNotesApi = `${baseUrl}/notes/list`

export const notesApi = `${baseUrl}/notes`

export const singleNoteApi = `${baseUrl}/notes/singleview`

export const deleteNoteApi = `${baseUrl}/notes/delete`

export const sendDetailsApi = `${baseUrl}/share`

export const exportExcel = `${baseUrl}/company/excel/export`

export const exportXLS = `${baseUrl}/deal/excel/export`

export const reDealexportXLS = `${baseUrl}/re/deal/excel/export`


export const exportPdf = `${baseUrl}/generatePdf`

export const compareCompany = `${baseUrl}/compare/getCompany`

export const compareInvestor = `${baseUrl}/compare/getInvestor`

export const compareAdvisor = `${baseUrl}/compare/getAdvisor`

export const compareCfs = `${baseUrl}/compare/cfs/getCompany`

export const compareCompanyList = `${baseUrl}/search/masterData`

export const advisorExitTable = `${baseUrl}/advisorDetail`

//Alert

export const alertListApi = `${baseUrl}/alert/list`

export const createAlertListApi = `${baseUrl}/alert/create`

export const updateAlertListApi = `${baseUrl}/alert/update`

//deal page card

export const AngelDealCard = `${baseUrl}/topcard/angel`

export const IncubationDealCard = `${baseUrl}/topcard/incubation`

export const dealPageProfile = `${baseUrl}/profilesection`

export const dealPageInvestment = `${baseUrl}/dealdetail`

export const dealPageAngel = `${baseUrl}/dealdetail`

export const dealPageIpo = `${baseUrl}/topcard/ipo`



//saved List
export const savedFilterApi = `${baseUrl}/listview/filter`;

export const savedFilterListApi = `${baseUrl}/listview/filter/list`;

export const savedFilterDetailApi = `${baseUrl}/listview/filter/detail`;

export const makeViFilterApi = `${baseUrl}/makeViFilter`;

//re list page 
export const reListCard = `${baseUrl}/re/list/topcard`;


export const reExportPDF =`${baseUrl}/re/company/excel/flatexport`

//re deal page

export const redealPageProfile = `${baseUrl}/re/profilesection`

export const getReRoundHierarchy = `${baseUrl}/re/getRoundHierarchy`

export const redealdetail = `${baseUrl}/re/dealdetail`

export const DealdetailCard = `${baseUrl}/re/topcard/investments`


// re advisor list 

export const getReAdvisor = `${baseUrl}/re/getAdvisors`

//re detail

export const ReAdvisorTopCard = `${baseUrl}/re/advisor/topcard`

export const getReAdvisorDetail = `${baseUrl}/re/advisor/advisorDetail`

export const reExportExcel = `${baseUrl}/re/advisor/excel/export`

// re investor

export const reDetailExportExcel = `${baseUrl}/re/investor/excel/export`
 // re funds

 export const  getReFunds = `${baseUrl}/re/getFunds`
 export const  getReFundsDetails = `${baseUrl}/re/getFund/detail`


//cfs list page

export const getCfsCompaniesApi = `${baseUrl}/cfs/getCompanies`;

export const getCfsDetailsApi = `${baseUrl}/cfs/getDetails`

export const getCfsProfileApi = `${baseUrl}/company/getProfile`

export const getCfsFundingApi = `${baseUrl}/company/getFunding`

export const getCfsCaptableApi = `${baseUrl}/cfs/company/capTable`

// Ma deal  page

export const MaDealdetailCard = `${baseUrl}/ma/deal/topcard`

export const madealPageProfile =`${baseUrl}/ma/deal/profile`

export const getMaRoundHierarchy =`${baseUrl}/ma/getRoundHierarchy`

export const madealdetail =`${baseUrl}/ma/dealDetail`

export const  MaAdvisorExcel=`${baseUrl}/ma/advisor/excel/flatexport`



// export const savedFilterApi = `${baseUrl}/listview/filter"

export const newTicket = "https://www.ventureintelligence.info/tickets/new/"

export const existTicket = " https://www.ventureintelligence.info/tickets/comment/"

export const raiseRequestAttachments = "https://www.ventureintelligence.info/tickets/view-attachments/"

export const dateRangeFilter = [moment().subtract(12, 'months'), moment().startOf('second')] //List page Date picker Default Value

export const rowsPerPageData = 50;

export const currentPage = parseInt(localStorage.getItem("activePage"));

export const descriptionCharLimit = 250; //profile description character limit

export const newsLinkLimit = 20;

export const croreConvert = 10000000;

export const millionConvert = 1000000;

export const usdConvert = 0.0120912402;

export const tableDataLimit = 20;


export const fetchMCAInfoAPI = `${baseUrl}/getMasterData`;

export const getRefineSearchDataAPI = `${baseUrl}/getRefineData`

export const filterIndustryUrl = `${baseUrl}/refine/getOnlyIndustry`;

export const filterSectorUrl = `${baseUrl}/refine/getOnlySector`;

export const filterStageUrl = `${baseUrl}/refine/stage`;

export const filterRoundsUrl = `${baseUrl}/refine/rounds`;

export const filterInvestorTypeUrl = `${baseUrl}/refine/investorTypes`;

export const filterInvestorHeadquarterUrl = `${baseUrl}/refine/investorHeadquarters`;

export const filterExitStatusUrl = `${baseUrl}/refine/exitstatus`;

export const filterSectorByIndutry = `${baseUrl}/refine/sectorByIndustry`;

export const filterLocationUrl = `${baseUrl}/refine/getLocation`;

export const getAllCities = `${baseUrl}/refine/getCities`;

export const filterGroupUrl = `${baseUrl}/refine/companyGroup`;

export const filterTargetCompany = `${baseUrl}/refine/maCompanies`;

export const filterPESector = `${baseUrl}/refine/getPeSector`;

export const companySearch = `${baseUrl}/advanced/companysearch`;

export const FilterRecompany = `${baseUrl}/refine/re/companies`;

export const FilterReInvestor= `${baseUrl}/refine/re/investors`

export const FilterReAdvisor = `${baseUrl}/refine/re/advisors`;

export const FilterFunds = `${baseUrl}/refine/funds`;

export const FilterFundInvestor = `${baseUrl}/refine/fundInvestors`;

export const FilterREFundInvestor = `${baseUrl}/refine/reFundInvestors`;

export const FilterIncubator = `${baseUrl}/refine/incubators`;

export const filterInvestor = `${baseUrl}/refine/investors`;

export const filterAcquirers = `${baseUrl}/refine/acquirers`;

export const filterReAcquirers = `${baseUrl}/refine/re/acquirers`;

export const filterlAdvisors = `${baseUrl}/advanced/ladvisorsearch`;

export const filtertAdvisors = `${baseUrl}/advanced/tadvisorsearch`;

export const filterPeCompanies = `${baseUrl}/refine/companies`;

export const filterReSubsector = `${baseUrl}/refine/subSector`;

export const filterAcquirerGroupUrl = `${baseUrl}/refine/acquirerGroup`

export const filterSubsectorUrl = `${baseUrl}/refine/subSectors`;

export const createRequestUrl = `${baseUrl}/crm/raiseRequest/addRequest`;

export const getRequestListUrl = `${baseUrl}/crm/raiseRequest/list`;

export const commentListUrl = `${baseUrl}/crm/raiseRequest/commentList`;

export const addCommentUrl = `${baseUrl}/crm/raiseRequest/addComment`;

export const ticketViewUrl = `${baseUrl}/crm/raiseRequest/ticketview`;

export const alertListUrl = `${baseUrl}/alertList`;

export const alertDeleteUrl = `${baseUrl}/alert/delete`;

// investor incubation
export const invIncTopCard = `${baseUrl}/investor/topcard`;

export const invIncProfile = `${baseUrl}/investor/profilesection`;

export const invIncOverview = `${baseUrl}/investor/overview`;

export const invIncInvestorDetail = `${baseUrl}/investor/investordetail`;

export const loginUrl = `${baseUrl}/login`;

export const logoutUrl = `${baseUrl}/logout`;

export const forgotPasswordUrl = `${baseUrl}/forgotPassword`;

export const resetPasswordUrl = `${baseUrl}/reset/Password`;

export const getFundingUrl = `${baseUrl}/company/getFunding`;

export const getSimilarCompanyUrl = `${baseUrl}/company/getSimilarCompany`;

export const getRecentNewsUrl = `${baseUrl}/company/getRecentNews`;

export const getFinancialUrl = `${baseUrl}/company/getFinancials`;

export const updateFinancial = `${baseUrl}/financials/updatefinancials`

export const getCaptableUrl = `${baseUrl}/company/getCaptable`;

export const getMergeAcquisitionsUrl = `${baseUrl}/company/getMergeAcquisitions`;

export const getProfileUrl = `${baseUrl}/company/getProfile`;

export const createDeviceAuthUrl = `${baseUrl}/create/DeviceAuth`;

export const ipLoginApi = `${baseUrl}/iplogin`;

export const submitDeviceAuthUrl = `${baseUrl}/submit/DeviceAuth`;

export const topCardUrl = `${baseUrl}/topcard`;

export const cfsTopCardUrl = `${baseUrl}/cfs/topcard`;

export const reGetCompaniesUrl = `${baseUrl}/re/getCompanies`;

export const tagSearchUrl = `${baseUrl}/tagsearch`;

export const tagList = `${baseUrl}/refine/tags`

export const cfsExcelExport = `${baseUrl}/cfs/export/excel`;

export const reExcelExport = `${baseUrl}/re/export/excel`;

export const newInvestorUrl = `${baseUrl}/new/investors`;

export const activeInvestorsUrl = `${baseUrl}/mostActive/investors`;

export const getInvestorFundsUrl = `${baseUrl}/investor/getFunds`;

export const getInvestorFundingUrl = `${baseUrl}/investor/getFunding`;

export const getInvestorNewsUrl = `${baseUrl}/investor/getRecentNews`;

export const getInvestorProfileUrl = `${baseUrl}/investor/getProfile`;

export const getCoInvestorUrl = `${baseUrl}/investor/coInvestor`;

export const newInvestorExportUrl = `${baseUrl}/export/mostActive/new/investors`;

export const refineSearchFirmTypeUrl = `${baseUrl}/refine/firmType`;

export const refineSearchExitTypeUrl = `${baseUrl}/refine/exitType`;

export const refineSearchDealTypeUrl = `${baseUrl}/refine/dealType`;

export const refineSearchTypeUrl     = `${baseUrl}/refine/re/types`;

export const refineSearchCompanyTypeUrl = `${baseUrl}/refine/companyType`;

export const refineSearchIncubationStatusUrl = `${baseUrl}/refine/incubationStatus`;

export const refineSearchStageType = `${baseUrl}/refine/stageType`;

export const refineSearchIndustryType = `${baseUrl}/refine/industryType`;

export const refineSearchCapitalSource = `${baseUrl}/refine/capitalSource`

export const refineSearchFundSector    = `${baseUrl}/refine/sector`

export const refineSearchFundStatus = `${baseUrl}/refine/status`;

export const reInvestorListUrl = `${baseUrl}/re/getInvestors`;

export const reInvestorProfileUrl = `${baseUrl}/re/investor/getProfile`;

export const reInvestorFunding = `${baseUrl}/re/investor/getFunding`;

export const reInvestorNewsUrl = `${baseUrl}/re/investor/getRecentNews`;

export const reInvestorTopcard = `${baseUrl}/re/investor/topcard`;

export const reAdvisorListExport =`${baseUrl}/re/advisor/excel/flatexport`;

export const getMaCompaniesApi          =`${baseUrl}/ma/getCompanies`;

export const maCompanyListExcelExport   = `${baseUrl}/ma/company/excel/flatexport`;

export const maAdvisorDetails           = `${baseUrl}/ma/advisor/details`;

export const maAdvisorDetailTopCard     = `${baseUrl}/ma/advisor/detail/topcard`;

export const maAdvisorListApi           = `${baseUrl}/ma/getAdvisors`;

export const maAcquirerListApi          = `${baseUrl}/ma/getAcquirers`;

export const maAcquirerListExportApi    = `${baseUrl}/ma/acquirer/excel/flatexport`;

export const AcquirerDetailtopCard      = `${baseUrl}/ma/acquirer/detail/topcard`;

export const AcquirerDetail             = `${baseUrl}/ma/acquirer/details`;

export const AcquisitionExport          = `${baseUrl}/ma/acquirer/detail/export`;

export const maAdvisorDetailExcelApi    = `${baseUrl}/ma/advisor/detail/export`;

export const maCompareCompany           = `${baseUrl}/ma/compare/getCompany`;

export const maCompareAcquirer          = `${baseUrl}/ma/compare/getAcquirer`;

export const maCompareAdvisor           = `${baseUrl}/ma/compare/getAdvisor`;

export const maFilings                  = `${baseUrl}/getFilings`;

// FAQ

export const faqApi                     = `${baseUrl}/faq`;

export const faqTrendTopics             = `${baseUrl}/faq/trending/topics`;

export const faqLogApi                  = `${baseUrl}/faq/create/log`;

export const faqSearchApi               = `${baseUrl}/faq/search`;

export const auditorNameSearchApi       = `${baseUrl}/refine/auditorNames`;

export const chargeBasedSearchApi       = `${baseUrl}/refine/chargeNames ` ;       


export const getNotification = `${baseUrl}/notifications`;

export const markAsReadNotification = `${baseUrl}/markAsRead`;

export const getUserDetailsAPi      = `${baseUrl}/getUserDetails`;

export const getSubscriptionDetails =`${baseUrl}/getSubscriptionDetails`;

export const getSearch =`${baseUrl}/search`;

export const getChargeNames =`${baseUrl}/refine/chargeNames`;

//advance search

export const advanceSearchCountryUrl = `${baseUrl}/countrysearch`;

export const archivePage = `${baseUrl}/archive`;

export const getDateApi = `${baseUrl}/getDate`;

export const investorCitySearch = `${baseUrl}/advanced/citysearch`;

//python based api url

export const getCompanyDetails = "https://v2-api.ventureintelligence.com/private/pe/company";

export const getCfsCompaniesDetails = "https://v2-api.ventureintelligence.com/private/cfs/financials";

export const getForExRates = "https://v2-api.ventureintelligence.com/private/common/forex-monthly/"