import { Badge, Tooltip } from 'antd'
import React, { useState, useContext } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import DefinitionModal from '../DefinitionModal';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import { useLocation } from "react-router";



/**
 * The `FilterWrapper` function is a React component that renders a button with a label, count, and an
 * arrow icon that can be rotated based on the `open` prop.
 * @returns a button element with a label and an icon. The appearance of the button depends on the
 * values of the `open`, `count`, and `isSelect` variables. If `open` is true, the button displays a
 * downward arrow icon. If `open` is false, the button displays a count badge (if `count` is greater
 * than 0 and `isSelect`
 */
function FilterWrapper({ label, open, count, isSelect, onClick , disabled, definitionInfo,customPathname}) {
    
    const [showDefModal, setShowDefModal] = useState(false);
    
    const openModal = () =>{
        setShowDefModal(true);
    }
    
    const closeModal = () =>{
        setShowDefModal(false);
    }

    const {SwitchState , setSwitchState} = useContext(PeFilterContext);

    const toggleSwitchState = () => {
        setSwitchState(prevState => prevState === "and" ? "or" : "and");
    };
    
    const location = useLocation().pathname;

    const condn = location.includes("/pe/") && label === "Investor" && !location.includes("/pe/investors") || location.includes("/vc/")  && label === "Investor" && !location.includes("/vc/investors")

    return (
        <>
            <button
                type="button"
                className="w-full text-left"
                disabled={disabled}
                onClick={condn ? undefined : onClick}
            >
                <div className="flex items-center justify-between">
                    <div className='flex items-center gap-2' onClick={condn ? undefined : onClick}>
                        <span className="" onClick={onClick}>{label}</span>
                        {definitionInfo && <span className="cursor-pointer" onClick={openModal}>
                                    <Tooltip title='Definitions'>
                                            <FaInfoCircle size={12} color="grey"/>
                                    </Tooltip>
                        </span>}
                    </div>
                    <div className='flex gap-2' onClick={condn ? undefined : onClick}>
                    {condn ? 
                        <ToggleButton value={SwitchState} onLabel="AND" offLabel="OR" condn={condn} onToggle={toggleSwitchState} />
                    : <></>}
                    {open ? (

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                                onClick={onClick}

                        >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>

                        ) : (
                            <div className='flex items-center justify-center py-1.5' onClick={onClick}>
                                {(count > 0 && isSelect === false) && < Badge className='mr-2' count={count} showZero={false} color="#BC8B20" />}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </div>
                        )}
                    </div>
            </div>
            </button>
            {showDefModal && <DefinitionModal label={label} definitionInfo={definitionInfo} onClose={closeModal}/>}
        </>
    )
}

export default FilterWrapper