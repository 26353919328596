import React, { useContext, useMemo, useState } from 'react';
import ExcelDownloader from '../shared/ExcelDownloader';
import { maAdvisorDetailExcelApi } from '../../Config/config';
import Constants from '../../Constants';
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';
import OverflowToolTip from '../shared/OverflowToolTip';
import routePathSegments, { routeNames } from '../../routeSegments';
import moment from 'moment';
import RandomLogo from '../RandomLogo';
import CompanyLogo from '../CompanyLogo';
import DataTable from '../DataTables/DataTable';
import { UserContext } from '../../Contexts/UserContext';

/**
 * The `AdvisorToAcquirer` function is a React component that renders a table of acquirers and their
 * related information, such as deal date, deal amount, company name, enterprise value, and revenue
 * multiple.
 * @returns The `AdvisorToAcquirer` component is returning a JSX element, which represents the
 * structure and content of the component's rendered output.
 */
const AdvisorToAcquirer = ({ companies, advisorName, isLoading, advisorType }) => {
    const { advisorsId } = useParams();
    const location = useLocation();
    const [showXlDownload, setshowXlDownload] = useState(false);
    const {isTrialExport,isStudent} = useContext(UserContext)


  /* The `customStringSortMemo` variable is a memoized function that is used for sorting strings in a
  specific column of a table. It takes four parameters: `rowA`, `rowB`, `columnId`, and `desc`. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);

  /* The `dealDateSort` variable is a memoized function that is used for sorting the "Deal Date" column
  in the table. It takes four parameters: `rowA`, `rowB`, `columnId`, and `desc`. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])

   /* The `investorSort` variable is a memoized function that is used for sorting the "Investor" column
   in the table. It takes four parameters: `rowA`, `rowB`, `columnId`, and `desc`. */
    const investorSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';

            const valueA = rowA?.values[columnId].map(data => data.investor_name).join("")
            const valueB = rowB?.values[columnId].map(data => data.investor_name).join("")

            return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
        }
    }, [])

    const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);

    /**
     * The addCommasToNumber function takes a number as input and returns a string representation of
     * the number with commas added for thousands separators.
     * @param number - The `number` parameter is the number that you want to add commas to.
     * @returns a string representation of the number with commas added for every three digits.
     */
    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };

  /* The above code is defining an array of columns for a table. Each column has a Header (column
  title), accessor (data key), className (CSS class for styling), and a Cell component that
  determines how the data is rendered in each cell of the column. The code also includes sorting
  functions for some of the columns. */
    const columns = useMemo(() => [
        {
            Header: "Acquirer",
            accessor: "acquirer",
            headerClassName:"w-[25%]",
            className:"w-[25%]",
            Cell: ({ value }) => {
                return <div className='leading-[2rem] font-sans_book_body items-center justify-center'>
                    {value?.logo_path == "default.png" || value?.logo_path == "/default.png" || !value?.logo_path ?
                        <RandomLogo name={value.name} page="company page" />
                        :
                        <CompanyLogo name={value.name} page="company page" logo={value?.logo_path} />
                    }

                    <OverflowToolTip className="sm:max-w-[19rem] 2xl:max-w-[22rem]"component={
                        <Link className='hover:text-[#BC8B20]'
                            state={{ cameFrom: location.state?.prevPath }}
                            to={routeNames.maAcquiresById(value?.id)}>
                            {value.name ?? "--"}
                        </Link>
                    } />
                </div>

            },
            sortType: customStringSortMemo,
        },
        {
            id: "dealDate",
            Header: "Deal Date",
            accessor: "dealDate",
            className:"w-[15%]",
            Cell: (cell) => {
                return <Link state={{ cameFrom: location.state?.prevPath ?? "" }}
                    to={routeNames.maCompanyInvestmentDealById(cell.value.id, cell?.value?.dealId)}>
                    {cell?.value?.date != '' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"}
                </Link>
            },
            sortType: dealDateSort
        },
        {
            Header: "Deal Amount (US$ M)",
            accessor: "dealAmountInUS",
            className: "px-0 text-right pr-[2.5rem] sm:w-[10%] 2xl:w-[0%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Company Name",
            accessor: "companyName",
            className:"sm:w-[15%] xl:w-[18%] 2xl:w-[22%]",
            headerspanClassName: "sm:pl-[2.5rem] 2xl:pl-[7rem]",
            Cell: ({ value }) => {
                return <div className='sm:pl-[2.5rem] 2xl:pl-[7rem]'>
                    <OverflowToolTip component={
                    <Link className='hover:text-[#BC8B20]'
                        state={{ cameFrom: location.state?.prevPath }}
                        to={routeNames.maCompanyById(value?.id)}>
                        {value.name ?? "--"}
                    </Link>
                } /></div>
            },
            sortType: customStringSortMemo
        },
        {
            Header: "Enterprise Value",
            accessor: "enterpriseValue",
            className: "px-0 text-right pr-[2.7rem] w-[15%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Revenue Multiple",
            accessor: "revenueMultiple",
            className: "px-0 text-right pr-[2.7rem] w-[12%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        }
    ], []);

  /* The `rowData` variable is a memoized function that creates an array of objects based on the
  `companies` data. Each object in the array represents a row in the table and contains the
  necessary data for each column. */
    const rowData = useMemo(() => {
        const rows = [];
       
        companies?.forEach((company, i) => {
            rows.push({
                acquirer: { name: company?.acquirer_name, dealId: company?.deal_id, id: company?.acquirer_id, logo_path: company?.icon_path },
                dealDate: { date: company?.deal_date, dealId: company?.deal_id, id: company?.company_id },
                dealAmountInUS: company?.deal_amount > 0 ? company?.deal_amount :"",
                companyName: { name: company?.company, dealId: company?.deal_id, id: company?.company_id },
                enterpriseValue: company?.enterprise_value > 0 ? company?.enterprise_value : "",
                revenueMultiple: company?.revenue_value > 0 ? company?.revenue_value : "",
            })
        });
        setshowXlDownload(rows.length !== 0)
        return rows;
    }, [companies])

    /* The `initialSort` constant is using the `useMemo` hook to memoize an array of objects. Each
    object represents a column in the table and contains the `id` of the column and a `desc`
    property indicating whether the column should be sorted in descending order. In this case, the
    `dealDate` column is set to be sorted in descending order. The empty dependency array `[]`
    ensures that the `initialSort` value is only computed once and does not change during
    re-renders. */
    const initialSort = useMemo(() => [
        {
            id: "dealDate",
            desc: true
        }
    ], []);

    return (
        <div className="px-4 bg-[#EDEDED]">
            <div className=" bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
                    <div className="flex justify-between mb-2">
                        <p className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold mb-3 lg:leading-[22px]">To Acquirers</p>
                        {isTrialExport != 'false' && isStudent=='false' && showXlDownload && <ExcelDownloader
                            url={maAdvisorDetailExcelApi}
                            fileName={Constants.exportNames.to_acquirers(advisorName)}
                            data={{
                                "section": "to_acquirers",
                                "advisor_id": advisorsId,
                                "advisor_type": advisorType
                            }} />}
                    </div>
                </div>
                <div className="space-y-6">
                    <div className="bg-white pb-2 rounded-[6px]">
                        <DataTable
                            columns={columns}
                            data={rowData}
                            sortBy={initialSort}
                            showLoader={isLoading}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
};

export default AdvisorToAcquirer;