import { React, useContext, useState, } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import FundSizeFilter from "../../Components/RefineSearchComponents/FundSizeFilter";
import CapitalSourceFilter from "../RefineSearchComponents/CapitalSourseFilter";
import FundSectorFilter from "../RefineSearchComponents/FundSector";
import FundStatusFilter from '../RefineSearchComponents/FundStatusFilter';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { refineSearchFundStatus } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import { useEffect } from "react";
import { TableDataContext } from "../../Contexts/TableDataContext";
import Constants from "../../Constants";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import Fund from "../RefineSearchComponents/Fund";
import FundInvestor from "../RefineSearchComponents/FundInvestor";
/**
 * The InvestmentRefineSearch function is a React component that renders a form for refining search
 * filters for investment data.
 * @returns The function `InvestmentRefineSearch` is returning a JSX element.
 */
export default function InvestmentRefineSearch({ customPathname }) {
    const { setrefineSearchFilters, onErrorHandler, refineSearchFilters, setMonthPickerValue ,fundsOptions,setFundsOptions,fundInvestorsOptions,setFundInvestorsOptions ,dbDropdown , setDbDropdown ,dropdown } = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const { getToken } = useContext(UserContext);
    const [select, setSelected] = useState(0);
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false

    const navigate = useNavigate();


    const [FundStatusOptions, setFundStatusOptions] = useState([])
    const [FundStatusSuboptions, setFundStatusSuboptions] = useState([])
    const [FundsStageOptions, setFundsStageOptions] = useState([])
    const [FundsIndustryOptions, setFundsIndustryOptions] = useState([])
    const [CapitalSourceOptions, setCapitalSourceOptions] = useState([])
    const [fundSectorOptions, setFundSectorOptions] = useState([])
    const [MinFundOptions, setMinFundOptions] = useState([])
    const [MaxFundOptions, setMaxFundOptions] = useState([])
    const [isSuboptionsFetch, setisSuboptionsFetch] = useState(false);


   /**
    * The function `applyFilter` filters and updates the refine search filters based on the selected
    * options.
    */
    const applyFilter = () => {

        const selectedStage = FundsStageOptions?.filter(option => option.isChecked);
        const selectedIndystry = FundsIndustryOptions?.filter(option => option.isChecked);
        const selectedCpital = CapitalSourceOptions?.filter(option => option.isChecked);
        const selectedFundSector = fundSectorOptions?.filter(option => option.isChecked);
        const selectedFunds = fundsOptions?.filter(option => option.isChecked);
        const selectedFundInvestors = fundInvestorsOptions?.filter(option => option.isChecked);
        const invMin = MinFundOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const invMax = MaxFundOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedStatus = FundStatusOptions.filter(option => option.isChecked);
        const selectedSubStatus = FundStatusSuboptions.filter(option => option.isChecked);
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                fundIndustry: selectedIndystry,
                fundStage: selectedStage,
                capitalSource: selectedCpital,
                fundSector: selectedFundSector,
                fundSize: invMin != null && invMax != null ? [invMin, invMax] : [],
                fundStatus: selectedStatus,
                closeStatus: selectedSubStatus,
                funds:selectedFunds,
                investors:selectedFundInvestors,
            },
        }))
    }

   /**
    * The `resetFilter` function resets a specific filter in the `refineSearchFilters` state object by
    * setting its value to an empty object.
    */
    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
        setFundInvestorsOptions([])
    }


    // Submitting Refine Search Form 
 /**
  * The function `refineSearchFilter` updates search filters, resets certain values, applies filters,
  * and navigates to a new location if necessary.
  * @param event - The event parameter is an object that represents the event that triggered the
  * function. It contains information about the event, such as the type of event, the target element,
  * and any additional data associated with the event. In this case, it is used to prevent the default
  * behavior of the event, which is
  */
    const refineSearchFilter = (event) => {
        event.preventDefault();
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        setCurrentPage(1);
        applyFilter();
        setSelected(false)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

  /**
   * The `onResetClick` function resets the current page, filters, and fund status suboptions, and
   * navigates to a custom pathname.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. It contains information about the event, such as the type of event, the target element,
   * and any additional data associated with the event. In this case, the event is a click event.
   */
    const onResetClick = (event) => {
        event.preventDefault();

        setCurrentPage(1);
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
        const updatedFundStatusSuboptions = FundStatusSuboptions.map((status) => {

            return {
                ...status,
                isChecked: !status.isChecked,
            };
        });
        setFundStatusSuboptions(updatedFundStatusSuboptions)
        setFundInvestorsOptions([])
        setFundsOptions([])

        // console.log(FundStatusSuboptions, 'FundStatusSuboptions');

    }

   /**
    * The function `accordionClick` sets the selected value to either 0 or the provided value.
    * @param val - The `val` parameter is a value that is passed to the `accordionClick` function. It
    * is used to determine whether the accordion should be selected or deselected.
    */
    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

  /**
   * The function `updateSubOptions` is used to update the sub-options based on the selected option and
   * initial flag.
   * @param selected - The `selected` parameter is the value selected by the user. It represents the
   * statusId that will be used in the API request.
   * @param initial - The `initial` parameter is a boolean value that indicates whether this function
   * is being called for the initial setup or not. If `initial` is `true`, it means that the function
   * is being called during the initial setup and certain additional logic needs to be executed. If
   * `initial` is `false
   * @returns The function does not explicitly return anything.
   */
    const updateSubOptions = (selected, initial) => {
        if (selected == "") {
            setFundStatusSuboptions([])
            return;
        }

        setisSuboptionsFetch(true);
        axios.post(refineSearchFundStatus, {
            "statusId": selected,
            "closeStatusId": "All"
        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then((response) => {
            setisSuboptionsFetch(false);
            if (response.status === 200) {
                if (initial === true) {
                    const selectedSub = refineSearchFilters[customPathname]?.closeStatus?.map(value => value.id) ?? [];
                    setFundStatusSuboptions(response.data?.closeStatusList?.map(type => ({ ...type, isChecked: selectedSub.includes(type.id) })))
                } else {
                    setFundStatusSuboptions(response.data?.closeStatusList ?? [])
                }
            }
        }).catch((e) => {
            onErrorHandler(e)
            setisSuboptionsFetch(false);
        })
    }

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.fundStatus?.map(value => value.id) ?? [];

        if ((industryIds?.length ?? 0) !== 0) {
            updateSubOptions(industryIds?.at(0), true)
        }
    }, [customPathname])

    const handleDbToggle = () => {
        setDbDropdown(!dbDropdown);
    };
    return (
        <div className="h-full overflow-y-auto">
            <div className="flex flex-col  no-scrollbar  fixedFooter">
                {/* <div className="flex justify-between px-3 items-center pt-0">
                    <p className="text-[#333333] text-[15px] leading-[17px] tracking-[-0.3px]">Tag</p>
                    <Switch
                        SwitchState={SwitchState}
                        setSwitchState={setSwitchState}
                        customPathname={customPathname} />
                </div>
                <div className="border-b-[3px] border-[#E8E6E1] bg-white">
                    <div className="sticky top-0 bg-white m-3 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
                        <label className=" flex  rounded ">
                            <Tags
                                TagsOptions={TagsOptions}
                                customPathname={customPathname}
                                setTagsOptions={setTagsOptions} />
                        </label>
                    </div>
                </div> */}
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333]  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>

                            <FundSectorFilter
                                fundSectorOptions={fundSectorOptions}
                                setFundSectorOptions={setFundSectorOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(30)}
                                open={select === 30} />

                            <FundSizeFilter
                                MinFundOptions={MinFundOptions}
                                setMinFundOptions={setMinFundOptions}
                                MaxFundOptions={MaxFundOptions}
                                setMaxFundOptions={setMaxFundOptions}
                                customPathname={customPathname} />

                            <FundStatusFilter
                                FundStatusOptions={FundStatusOptions}
                                setFundStatusOptions={setFundStatusOptions}
                                FundStatusSuboptions={FundStatusSuboptions}
                                setFundStatusSuboptions={setFundStatusSuboptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(24)}
                                updateSubOptions={updateSubOptions}
                                isSuboptionsFetch={isSuboptionsFetch}
                                open={select === 24} />

                            <CapitalSourceFilter
                                CapitalSourceOptions={CapitalSourceOptions}
                                setCapitalSourceOptions={setCapitalSourceOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(21)}
                                open={select === 21} />

                        </ul>
                        <ul  className='flex pt-3 text-[16px] pl-1 border-t-2 border-[#e8e6e1] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer ' onClick={handleDbToggle}>
                            <span className="mt-[2px]">
                                {dbDropdown? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            </span>
                                <h1 className='font-bold text-[16px] ml-1'>
                                    SHOW FUNDS BY
                                </h1>
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dbDropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] mb-3  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]`}>
                            <FundInvestor 
                                options={fundInvestorsOptions}
                                setOptions={setFundInvestorsOptions}
                                customPathname={customPathname}
                                label="Investors"
                                accordionClick={() => accordionClick(3)}
                                open={select === 3}
                            />
                            <Fund 
                                options={fundsOptions}
                                setOptions={setFundsOptions}
                                customPathname={customPathname}
                                label="Funds"
                                accordionClick={() => accordionClick(1)}
                                open={select === 1}
                            />
                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
        </div>
    );
}
