import React from "react";
import "./profile.css";
import OverflowToolTip from '../shared/OverflowToolTip';
import linkedin from "../../Utils/linkedin.png";
import { Tooltip } from 'antd';

const ProfileHighlights = ({ profile,highlights,shareholdingPattern  ,menu}) => {

  // const ProfileHighlightData = useMemo(() => profile, [profile]);

console.log(highlights , "hjhhhk")
console.log(profile , "prefile data link text")
  return (   
      <div className="lg:mt-4 px-4 w-[100%]">         
          <div className="lg:bg-white rounded-[6px]" x-show="tab === 1">
            <div className="w-full lg:grid lg:grid-cols-5 rounded-[6px] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
              <div className="mt-3 lg:mt-0 rounded-lg lg:rounded col-span-3 lg:border-r bg-white ">
                <div className="mx-5 py-4   ">
                  <p className="text-[14pt] flex leading-[19pt] lg:text-[17px] text-[#333333] font-semibold  lg:leading-[22px]">
                    Profile  {
                      profile?.filter(pro => pro?.label === "LinkedIn").length > 0 && // Check if LinkedIn profile exists
                      profile?.filter(pro => pro?.label === "LinkedIn")[0]?.value ?  // Check if LinkedIn profile value exists
                      <a href={`${profile?.filter(pro => pro.label === "LinkedIn")[0]?.value}`} target="_blank" className="mt-[0.20rem] ml-2"><img src={linkedin} width={16} alt="linkedin logo" /></a>
                      : ""
                    }
                  </p>
                  <div className=" mt-2">
                    {(profile?.length??0) <= 0 ? (
                      <div className="overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:overflow-y-hidden">
                      <table className="h-[10rem] w-full bg-[#FFFFFF] ">
                        <center>
                          <div role="status" className="bg-white  h-[16rem]">
                            <svg
                              className="relative top-[6rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                        </center>
                      </table>
                      </div>
                    ) : (
                    <div className="overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:overflow-y-hidden">
                      <div className="  w-full bg-[#FFFFFF] ">
                        <div className="grid md:grid-cols-3 grid-cols-2  break-words ">
                          {profile?.map((profileData,i) => {

                            return (
                              profileData.label !='Status' &&(
                            <div  className={i<9 ? `${(shareholdingPattern?.length??0) <= 0 ? '' : "border-b "}`:""} >
                                <div className=" leading-[21px] font-sans_book_body mt-1">
                                {
                                  profileData?.label !== "LinkedIn" && (
                                    <OverflowToolTip component={
                                    <a className=" py-1 text-[10pt] lg:whitespace-nowrap hover:text-[#000000]  leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto"
                                      readOnly >
                                      {profileData?.label }
                                    </a>
                                  } />
                                  )
                                }
                                  <p className="mt-1 mb-2 w-[90%] text-[10pt] break-words leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold "
                                    readOnly >

                                    {profileData.value ? profileData.label=="Website"?<a target="_blank" className="w-[90%] break-words" href={profileData.value}>{profileData.value }</a>
                                    : profileData.label === "LinkedIn" ? "" 
                                    :profileData.value ? profileData.label=="Investor Board Member" ? 
                                    <div className="w-[90%] break-words">
                                      {profileData.value.length > 29 ? (
                                        <Tooltip
                                          color="white"
                                          title={
                                            <div style={{ color: 'black' }}>
                                              {profileData.value.split(';').map((item, index) => {
                                                const parts = item.split(',').map(subItem => subItem.trim());
                                                const name = parts[0];
                                                const additionalInfo = parts.slice(1).filter(Boolean).join(', ');
                                                const searchQuery = additionalInfo ? `${name} ${additionalInfo} site:linkedin.com` : name + " site:linkedin.com";
                                                return (
                                                  <p key={index} className="my-1">
                                                    <a
                                                      href={`https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                      className="text-[#a07924] "
                                                    >
                                                      {name}
                                                    </a>
                                                    {additionalInfo && `, ${additionalInfo}.`}
                                                  </p>
                                                );
                                              })}
                                            </div>
                                          }
                                          placement="topLeft"
                                        >
                                          <p className="text-[#a07924]">
                                            {profileData.value.slice(0, 23)}{profileData.value.length > 23 && "..."}
                                          </p>
                                        </Tooltip>
                                      ) : (
                                        profileData.value.split(';').map((item, index) => {
                                          const parts = item.split(',').map(subItem => subItem.trim());
                                          const name = parts[0];
                                          const additionalInfo = parts.slice(1).filter(Boolean).join(', '); 
                                          const searchQuery = additionalInfo ? `${name} ${additionalInfo} site:linkedin.com` : name + " site:linkedin.com";
                                          return (
                                            
                                            <p>
                                              <a
                                                href={`https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-[#a07924]"
                                              >
                                                {name}
                                              </a>
                                              {additionalInfo ? ', ' + additionalInfo : ""}
                                            </p>

                                          );
                                        })
                                      )}
                                  </div>
                                    :profileData.value ? profileData.label=="Top Management" ? <p className="w-[90%] break-words">{profileData.value }</p>: profileData.value 
                                    : profileData.value ? profileData.label=="CIN" ? <p className="w-[90%] break-words">{profileData.value }</p>
                                    : profileData.value ? profileData.label=="Email" ? <p className="w-[90%] break-words">{profileData.value }</p>: profileData.value :"--" :"--":"--":"--"}
                                 
                                  </p>
                                </div>

                              </div>)
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <!--first half end--> */}

              {menu=="ma"?
                    <div className="w-full lg:col-span-2 bg-white mt-5 lg:mt-0  space-x-10 rounded-lg scrollbar">
                    <div className={`overflow-x-auto lg:grid ${(shareholdingPattern?.length??0) <= 0 ? 'lg:grid-rows-1' : 'lg:grid-rows-2' }   lg:grid-flow-col gap-3`}>
                      <div className="row-span-1 px-5 lg:pl-8 pt-4 pb-2">
                        <div className="overflow-x-auto">
                          <p className=" mb-2 text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px]">
                            Highlights
                          </p>
                          {(highlights?.length??0) <= 0 ? (
                            <table className="h-[4rem] w-full bg-[#FFFFFF] ">
                              <center>
                                <div role="status" className="bg-white  h-[6rem]">
                                  <svg
                                    className="relative top-[2rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                </div>
                              </center>
                            </table>
                          ) : (
                            <div className="  w-full bg-[#FFFFFF] ">
                              <div className="grid grid-cols-3">
                                <div className="">
                                  <div className=" leading-[21px] font-sans_book_body ">
                                      <OverflowToolTip component={
                                            <p className="mt-2  text-[10pt] whitespace-nowrap  leading-[18pt]  lg:text-[13px] text-[#333333]  cursor-auto"
                                              readOnly >
                                              Acquisitions
                                            </p>
                                      } />
                                        <p className=" mb-2 text-[11pt] break-all leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold "
                                            readOnly >
        
                                            {highlights?.map((highlight,i)=>{
                                              return (
                                                  highlight.label === "Acquisitions" ? 
                                                      `${highlight.value != 0 && highlight.value !== "₹ 0 Cr" ? highlight.value : "--"}` 
                                                      : ""
                                              );
                                            } )  }
                                        </p>

                                  </div>
                                </div>
                                <div className="">
                                  <div className=" leading-[21px] font-sans_book_body ">
                                      <OverflowToolTip component={
                                            <p className="mt-2  text-[10pt] whitespace-nowrap  leading-[18pt]  lg:text-[13px] text-[#333333]  cursor-auto"
                                              readOnly >
                                              Acquired by
                                            </p>
                                      } />
                                      <p className=" mb-2 text-[11pt] break-all leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold "
                                            readOnly >
        
                                            {highlights?.map((highlight,i)=>{
                                              return (
                                                  highlight.label === "Acquired by" ? 
                                                      `${highlight.value != 0 && highlight.value !== "₹ 0 Cr" ? highlight.value : "--"}` 
                                                      : ""
                                              );
                                            } )  }
                                        </p>
                                  </div>
                                </div>
                                <div className="">
                                  <div className=" leading-[21px] font-sans_book_body ">
                                      <OverflowToolTip component={
                                            <p className="mt-2  text-[10pt] whitespace-nowrap  leading-[18pt]  lg:text-[13px] text-[#333333]  cursor-auto"
                                              readOnly >
                                              {highlights?.map((highlight, i) => (
                                                highlight.label.includes("Enterprise") ? 
                                                `${highlight.label}` 
                                                  : ""
                                              ))}
                                            </p>
                                      } />
                                      <p className="mb-2 text-[11pt] break-all leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold" readOnly>
                                        {highlights?.map((highlight, i) => (
                                          highlight.label.includes("Enterprise") ? 
                                            `${highlight.value != 0 && highlight.value !== "₹ 0 Cr" ? highlight.value : "--"}` 
                                            : ""
                                        ))}
                                      </p>
                                  </div>
                                </div>
                                <div className="">
                                  <div className=" leading-[21px] font-sans_book_body ">
                                      <OverflowToolTip component={
                                            <p className="mt-2  text-[10pt] whitespace-nowrap  leading-[18pt]  lg:text-[13px] text-[#333333]  cursor-auto"
                                              readOnly >
                                              {highlights?.map((highlight, i) => (
                                                highlight.label.includes("Revenue") ? 
                                                `${highlight.label}` 
                                                  : ""
                                              ))}
                                            </p>
                                      } />
                                      <p className="mb-2 text-[11pt] break-all leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold" readOnly>
                                        {highlights?.map((highlight, i) => (
                                          highlight.label.includes("Revenue") ? 
                                            `${highlight.value != 0 && highlight.value !== "₹ 0 Cr" ? highlight.value : "--"}` 
                                            : ""
                                        ))}
                                      </p>

                                  </div>
                                </div>
                                <div className="">
                                  <div className=" leading-[21px] font-sans_book_body ">
                                      <OverflowToolTip component={
                                            <p className="mt-2  text-[10pt] whitespace-nowrap  leading-[18pt]  lg:text-[13px] text-[#333333]  cursor-auto"
                                              readOnly >
                                              {highlights?.map((highlight, i) => (
                                                highlight.label.includes("EBITDA") ? 
                                                `${highlight.label}` 
                                                  : ""
                                              ))}
                                            </p>
                                      } />
                                      <p className="mb-2 text-[11pt] break-all leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold" readOnly>
                                        {highlights?.map((highlight, i) => (
                                          highlight.label.includes("EBITDA") ? 
                                            `${highlight.value != 0 && highlight.value !== "₹ 0 Cr" ? highlight.value : "--"}` 
                                            : ""
                                        ))}
                                      </p>
                                  </div>
                                </div>
                                <div className="">
                                  <div className=" leading-[21px] font-sans_book_body ">
                                      <OverflowToolTip component={
                                            <p className="mt-2  text-[10pt] whitespace-nowrap  leading-[18pt]  lg:text-[13px] text-[#333333]  cursor-auto"
                                              readOnly >
                                              {highlights?.map((highlight, i) => (
                                                highlight.label.includes("PAT") ? 
                                                `${highlight.label}` 
                                                  : ""
                                              ))}
                                            </p>
                                      } />
                                      <p className="mb-2 text-[11pt] break-all leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold" readOnly>
                                        {highlights?.map((highlight, i) => (
                                          highlight.label.includes("PAT") ? 
                                            `${highlight.value != 0 && highlight.value !== "₹ 0 Cr" ? highlight.value : "--"}` 
                                            : ""
                                        ))}
                                      </p>
                                  </div>
                                </div>
                              </div>
                          </div>
                          )}
                        </div>
                      </div>
                      {(shareholdingPattern?.length??0) <= 0 ? (
                        <></>
                      ) : (
                        <div className="row-span-1 border-t px-5 lg:pl-8 pt-2 mb-2 ">
                        <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  pt-2 lg:leading-[22px]">
                          Shareholding Pattern
                        </h3>
                        <div className="  w-full bg-[#FFFFFF] ">
                            <div className="grid grid-cols-2">
                              {shareholdingPattern?.map((pattern) => {
    
                                return (
                                  <div className="profiletable border-b  " >
                                    <div className=" leading-[21px] font-sans_book_body ">
                                      <OverflowToolTip component={
                                        <p className=" py-1 text-[11pt] whitespace-nowrap  leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto"
                                          readOnly >
                                          {pattern.label}
                                        </p>
                                      } />
                                      <p className=" mb-2 text-[10pt] break-all leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold "
                                        readOnly >
    
                                        {pattern.value !=0 && pattern.value !="0.00%"? pattern.value : "--"}
                                      </p>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                      </div>
                      )}
                      
                    </div>
                  </div>
                  :
                  <div className="w-full lg:col-span-2 bg-white mt-5 lg:mt-0  space-x-10 rounded-lg scrollbar">
                  <div className="overflow-x-auto lg:grid lg:grid-rows-3 lg:grid-flow-col">
                    <div className="row-span-2 px-5 lg:pl-8 pt-4">
                      <div className="overflow-x-auto">
                        <p className=" mb-2 text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px]">
                          Highlights
                        </p>
                        {(highlights?.length??0) <= 0 ? (
                          <table className="h-[4rem] w-full bg-[#FFFFFF] ">
                            <center>
                              <div role="status" className="bg-white  h-[6rem]">
                                <svg
                                  className="relative top-[2rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                              </div>
                            </center>
                          </table>
                        ) : (
                          <div className="  w-full bg-[#FFFFFF] ">
                          <div className="grid grid-cols-3 border-b">
                            {highlights?.slice(0,highlights?.length - 3)?.map((highlight,i) => {
  
                              return (
                                <div className={i<3 ? 'border-b':""} >
                                 {/* <div className="border-b pr-[1rem]">  */}
                                  <div className=" leading-[21px] font-sans_book_body ">
                                    <OverflowToolTip component={
                                      <p className="  text-[10pt] whitespace-nowrap  leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto"
                                        readOnly >
                                        {highlight.label}
                                      </p>
                                    } />
                                    <p className=" mb-2 text-[11pt] break-all leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold "
                                      readOnly >
  
                                      {highlight.value !=0 && highlight.value !="₹ 0.00 Cr" ? highlight.value && highlight.value !="$ 0 M" ? highlight.value : "--":"--"}
                                    </p>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                         
                         
                          <div className="grid grid-cols-3">
                            {highlights?.slice(highlights?.length - 3)?.map((highlight,i) => {
                              return (
                                <div>
                                <div className=" py-2 lg:leading-[21px] font-sans_book_body">
                                  
                                  <p
                                    className=" text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto"
                                    readOnly
                                  >
                                    {highlight.label}
                                  </p>
  
                                  <p
                                    className="text-[11pt]  leading-[18pt] lg:text-[13px] text-[#333333] whitespace-nowrap cursor-auto font-semibold mb-1"
                                    readOnly
                                  >
                                      {highlight.value !=0 && highlight.value !="₹ 0.00 Cr" ? highlight.value && highlight.value !="$ 0 M" ? highlight.value : "--":"--"}
                                  </p>
                                </div>
                                </div>
                    
                              )
                            })}
                          </div>
                        </div>
                        )}
                      </div>
                    </div>
                    {shareholdingPattern?.length != 0 ? (<div className="row-span-1 border-t px-5 lg:pl-8 pt-2 mb-2">
                      <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  pt-2 lg:leading-[22px]">
                        Shareholding Pattern
                      </h3>
                      {(shareholdingPattern?.length??0) <= 0 ? (
                          <table className="h-[4rem] w-full bg-[#FFFFFF] ">
                            <center>
                              <div role="status" className="bg-white  h-[6rem]">
                                <svg
                                  className="relative top-[2rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                              </div>
                            </center>
                        </table>
                        ) : (
                        <div className="  w-full bg-[#FFFFFF] ">
                          <div className="grid grid-cols-2">
                            {shareholdingPattern?.map((pattern) => {
  
                              return (
                                <div className="profiletable border-b  " >
                                  <div className=" leading-[21px] font-sans_book_body ">
                                    <OverflowToolTip component={
                                      <p className=" py-1 text-[11pt] whitespace-nowrap  leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto"
                                        readOnly >
                                        {pattern.label}
                                      </p>
                                    } />
                                    <p className=" mb-2 text-[10pt] break-all leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold "
                                      readOnly >
  
                                      {pattern.value !=0 && pattern.value !="0.00%"  && pattern.value !="0%"? pattern.value : "--"}
                                    </p>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}
                    </div>) : null}
                  </div>
                </div>

              }
             

              {/* <!--end--> */}
            </div>
          </div>        
      </div>    
  );
};

export default ProfileHighlights;
