// Assuming financialData is an array of objects containing financial data for multiple years

// Function to calculate growth percentage between two values
export const parseFinancials = (data) => {
    return data?.map(item => ({
        fy: parseInt(item.fy),
        finType: item.fin_type,
        ebitda: parseInt(item.ebitda),
        pat: parseInt(item.pat),
        totalIncome: parseInt(item.total_income)
    }));
};

const calculateGrowthPercentage = (currentValue, previousValue,n) => {
    const base = (currentValue - previousValue + Math.abs(previousValue)) / Math.abs(previousValue)
    const exp = 1/n
    const cagr = parseFloat(((Math.pow(base, exp) - 1) * 100).toFixed(2))
    return Math.abs(cagr);
};

export const calculateGrowth = (financials, finType) => {
    const filteredData = financials?.filter(item => item.finType === finType);
    const sortedData = filteredData?.sort((a, b) => b.fy - a.fy); // Sort by fiscal year in descending order
    const yearsToCalculate = [1, 3, 5];

    const result = {
      year :[]
    };
    const crValue = 10000000

    for (const year of yearsToCalculate) {
        const previousItem = sortedData?.find(item => item.fy === (sortedData[0].fy - year));

        if (previousItem) {
            const ebitdaGrowth = calculateGrowthPercentage(sortedData[0]?.ebitda, previousItem.ebitda, year);
            const patGrowth = calculateGrowthPercentage(sortedData[0]?.pat, previousItem.pat, year);
            const totalIncomeGrowth = calculateGrowthPercentage(sortedData[0]?.totalIncome, previousItem.totalIncome, year);

            result.year.push({ y: year,
                            current_total_income: parseFloat((sortedData[0].totalIncome/crValue).toFixed(2)), last_total_income: parseFloat((previousItem.totalIncome/crValue).toFixed(2)),
                            current_actual_total_income: sortedData[0].totalIncome, last_actual_total_income: previousItem.totalIncome,
                            totalIncomeGrowth: totalIncomeGrowth,
                            current_actual_ebitda: sortedData[0].ebitda, last_actual_ebitda: previousItem.ebitda,
                            current_ebitda: parseFloat((sortedData[0].ebitda/crValue).toFixed(2)), last_ebitda: parseFloat((previousItem.ebitda/crValue).toFixed(2)),
                            ebitdaGrowth: ebitdaGrowth, 
                            current_pat: parseFloat((sortedData[0].pat/crValue).toFixed(2)), last_pat: parseFloat((previousItem.pat/crValue).toFixed(2)),
                            current_actual_pat: sortedData[0].pat, last_actual_pat: previousItem.pat,
                            patGrowth: patGrowth
            });
        }
    }
    console.log(result, 'CAGR');

    return result;
};


