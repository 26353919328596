import { Tooltip } from "antd";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../Contexts/UserContext";
import { PeFilterContext } from "../Contexts/PeFilterContext";

const ToggleDealsViewButton = () => {
    const {isDirectoryUser} = useContext(UserContext);
    const {dealsView, setDealsView , toggleDealsBtn ,setToggleDealsBtn} = useContext(PeFilterContext);
    console.log('==>', dealsView)

    
    const dealsViewHandler = (checked) => {
        setDealsView(checked);
    };

    useEffect(()=>{
      if(!isDirectoryUser){
        setDealsView(true);
        console.log('=== done')
      }
    },[])

  return (
    <>
      {!isDirectoryUser && (
        <div className={`py-3 ${toggleDealsBtn ? '' : 'hidden'}`}>
          <div className="flex bg-white rounded-xs border p-0.5">
            {dealsView ?
              <>
                <div
                  className={`w-14 p-3 py-0 text-sm ${
                    dealsView ? "bg-[#ad7907] rounded-xs text-white" : "bg-white"
                  }  transition-all duration-200 delay-200`}
                >
                  <button onClick={() => dealsViewHandler(true)}>Deals</button>
                </div>
                <Tooltip
                  placement="bottom"
                  title="Switch To Companies View"
                >
                  <div
                    className={`w-14 p-3 py-0 text-sm ${
                      !dealsView ? "bg-[#ad7907] rounded-xs text-white" : "bg-white"
                    }  transition-all duration-200 delay-200`}
                  >
                    <button onClick={() => dealsViewHandler(false)}>Cos.</button>
                  </div>
                </Tooltip>
              </> :
              <>
                <Tooltip
                  placement="bottom"
                  title="Switch To Deals View"
                >
                <div
                  className={`w-14 p-3 py-0 text-sm ${
                    dealsView ? "bg-[#ad7907] rounded-xs text-white" : "bg-white"
                  }  transition-all duration-200 delay-200`}
                >
                  <button onClick={() => dealsViewHandler(true)}>Deals</button>
                </div>
                </Tooltip>
                <div
                  className={`w-14 p-3 py-0 text-sm ${
                  !dealsView ? "bg-[#ad7907] rounded-xs text-white" : "bg-white"
                  }  transition-all duration-200 delay-200`}
                >
                  <button onClick={() => dealsViewHandler(false)}>Cos.</button>
                </div>
              </>            
            }
          </div>
        </div>
      )}
    </>
  );
};

export default ToggleDealsViewButton;
