import React, { useState, useMemo, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from "react-router-dom";
import { useQuery } from "@apollo/client";
import xls from "../../../images/download-file-icon.png";
import sorting from "../../../images/up-down-arrow.png";
import companyLogo1 from "../../../images/mindtree-logo copy/png/mindtree-logo copy.png";
import companyLogo2 from "../../../images/mindtree-logo copy/png/mindtree-logo copy.png";
import companyLogo3 from "../../../images/logo2/png/logo2.png";
import ExcelPopup from "../../../Components/ExcelPopup";
import CompanyLogo from '../../../Components/CompanyLogo';
import RandomLogo from "../../RandomLogo";
import DataTable from "../../DataTables/DataTable";
import { routeNames } from '../../../routeSegments';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import moment from "moment";
import OverflowToolTip from "../../shared/OverflowToolTip";
import Paginate from '../../Paginate';
import Constants from '../../../Constants';
import { usePagination, useSortBy, useTable } from 'react-table';
import ConfirmPopup from '../../../Utils/ConfirmPopup';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import LockIcon from 'mdi-react/LockIcon';
import Loader from '../../Loader';
import useModule from "../../../hooks/useModule";
import { UserContext } from "../../../Contexts/UserContext";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";


/**
 * The function `InvestorPortfolioExits` is a React component that displays a table of portfolio
 * companies' exits for a specific investor.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `InvestorPortfolioExits` component. These properties can include the investor's ID, the portfolio
 * data, the investor's name, and other relevant information.
 * @returns The code is returning a React functional component named `InvestorPortfolioExits`.
 */
const InvestorPortfolioExits = (props) => {
  const { investorId } = useParams();
  const [showXlDownload, setshowXlDownload] = useState(false);
  const [alert, setalert] = useState({ message: "", show: false });
  const location = useLocation();
  const {isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext)

  const { primaryMasterId } = useModule();

  const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
  const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];
  const { investorsExitsDataFound } = useContext(DataFoundContext);


  /* The above code is defining a custom sorting function called `customAcquirerSortMemo` using the
  `useMemo` hook in JavaScript. This function takes in two rows (`rowA` and `rowB`), a column ID,
  and a boolean value `desc` indicating whether the sorting should be in descending order. */
  const customAcquirerSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      const r1 = rowA?.values[columnId]?.map(data => data?.acquirer_name)?.join('')
      const r2 = rowB?.values[columnId]?.map(data => data?.acquirer_name)?.join('')
      return (r1 ?? defaultVal).localeCompare(r2 ?? defaultVal);
    }
  }, []);
  /* The above code is defining a custom sorting function called `customStringSortMemo` using the
  `useMemo` hook in React. This function takes in two rows (`rowA` and `rowB`), a column ID, and a
  boolean value `desc` indicating whether the sorting should be in descending order. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
    }
  }, []);
  /* The above code is using the `matchPath` function to check if the `location.pathname` matches a
  specific pattern. The pattern is constructed using the `${routePathSegments.pe}/*` template
  string, where `routePathSegments.pe` is a variable that represents a specific path segment. The
  `/*` part of the pattern is a wildcard that matches any additional path segments after the
  `routePathSegments.pe` segment. The result of the `matchPath` function is stored in the `peMatch`
  constant. */
  const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);

  /* The above code is defining a custom sorting function called `dealDateSort` using the `useMemo`
  hook in React. This function takes in two rows (`rowA` and `rowB`), a column ID, and a boolean
  flag `desc` indicating whether the sorting should be in descending order. */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
    }
  }, [])

  
 /**
  * The function "addCommasToNumber" takes a number as input and returns a string representation of the
  * number with commas added for thousands separators.
  * @param number - The `number` parameter is the number that you want to add commas to.
  * @returns The function `addCommasToNumber` returns a string with commas added to the number.
  */
  const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  
  /* The above code is defining an array of columns for a table. Each column has properties such as id,
  Header (column header text), accessor (data accessor for the column), Cell (rendering component
  for the cell), and sortType (custom sort function for the column). The columns are defined using
  the useMemo hook, which memoizes the columns array so that it is only recomputed when its
  dependencies change. */
  const columns = useMemo(() => [
    {
      id: 'companyName',
      Header: "Company Name",
      accessor: "companyName",
      className: "clickable text-left lg:relative sticky left-0  bg-white",
      headerspanClassName: "",
      headerClassName: "w-[17%] text-left lg:relative sticky left-0   w-[3rem] bg-[#EDEDED] ",
      // className: "w-[10%]",
      Cell: ({ value }) => {
        return <div className=' leading-[1.8rem] lg:leading-[2rem] font-sans_book_body items-center justify-center'>
          {/* <CompanyLogo id={value?.id} /> */}
          {value?.icon_path == "default.png" || value?.icon_path == "/default.png" || !value?.icon_path ?
            <RandomLogo name={value.name} page="company page" />
            :
            <CompanyLogo name={value.name} page="company page" logo={value?.icon_path} />
          }
          <OverflowToolTip className="w-[12rem] lg:w-auto" component={
            <Link className="text-start flex lg:inline hover:text-[#BC8B20]"
                state={{ cameFrom: location.state?.prevPath }}
              to={peMatch != null ? routeNames.peCompanyFundById(value?.id)
                : routeNames.vcCompanyFundById(value?.id)}>
              {value.name ?? "--"}
            </Link>} />


          {/* // <Link
            //     state={{ prevPath: location.state?.prevPath }}
            //     to={routeNames.companyDealById(value.id, value.dealId)}>
            //     {value.name ?? "--"}
            // </Link>} /> */}
        </div>
      },
      sortType: customStringSortMemo,
    },
    {
      id: "dealDate",
      Header: "Deal Date",
      accessor: "dealDate",
      Cell: (cell) => {
        return <Link onClick={(e) => {
              if(isDirectoryUser){
                  e.preventDefault();
                  e.stopPropagation();
                  onBlurColumnClick();
              }
          }}
          state={{ cameFrom: location.state?.prevPath ?? "" }}
          to={peMatch != null ? routeNames.peCompanyExitsDealById(cell.value?.id, cell.value?.dealId)
            : routeNames.vcCompanyExitsDealById(cell.value?.id, cell.value?.dealId)}>
          {cell?.value?.date != '' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"}
        </Link>
      },
      sortType: dealDateSort
    },
    {
      Header: "Exit Type",
      accessor: "exitType",
    },
    {
      Header: "Exit Status",
      accessor: "exitStatus",
    },
    {
      Header: "Acquirer",
      accessor: "acquirer",
      // Cell: (cell) => {
      //   return <OverflowToolTip component={
      //     cell.value?.map((inv, index) => (`${inv.acquirer_name}${index === cell.value?.length - 1 ? "" : ", "}`))
      //   } />
      // },
      Cell: ({ value }) => {
        return <div className="leading-[2rem] font-sans_book_body items-center justify-center">
          <OverflowToolTip component={
            value.map((inv, index) => (<Link onClick={(e) => {
                  if(!allowedModulesList.includes("ma")){
                      e.preventDefault();
                      e.stopPropagation();
                      onDisabledLinkClick();
                  }
              }}
              className='hover:text-[#BC8B20]'
              state={{ cameFrom: location.pathname }} to={routeNames.maAcquiresById(inv.acquirer_id)}>
              {inv.acquirer_name != "NA" ? inv.acquirer_name : " "}
            </Link>))
          } />

        </div>
      },
      sortType: customAcquirerSortMemo,
    },
    {
      Header: "Return Multiple",
      accessor: "returnMultiple",
      className: "text-right pr-[2.5rem] md:pr-[2.75rem] lg:pr-[2.5rem] w-[10%]",
      headerClassName: "w-[10%]",
      headerspanClassName: "ml-auto",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    },

  ], [])

 /* The above code is a JavaScript function that uses the `useMemo` hook to create a memoized version
 of the `rowData` variable. */
  const rowData = useMemo(() => {
    let rows = [];
    props?.portfolio?.forEach(company => {
      rows.push({
        companyName: { name: company?.company_name, id: company?.company_id, dealId: company?.manda_id, icon_path: company?.icon_path, restricted: company?.return_multiple },
        dealDate: { date: company?.deal_date, dealId: company?.manda_id, id: company?.company_id, restricted: company?.return_multiple },
        exitType: company?.deal_type,
        exitStatus: company?.exit_status,
        acquirer: company?.acquirer,
        returnMultiple: company?.return_multiple == 0 ? "" : company?.return_multiple,
      })
    });
    setshowXlDownload(rows.length !== 0)
    return rows;

  }, [props?.portfolio])

  /* The above code is a JavaScript code snippet. It is using the `useMemo` hook to define a variable
  called `initialSort`. */
  const initialSort = useMemo(() => {
    if(props?.fundingData?.isSort === false){
        return []
    }else{
        return [
            {
                id: "dealDate",
                desc: true
            }
        ]
    }
  }, [props?.fundingData?.isSort]);

 /**
  * The function `onConfirmation` sets the `show` property of an `alert` object to `false` and clears
  * the `message` property.
  * @param flag - The `flag` parameter is a boolean value that determines whether to show or hide the
  * alert. If `flag` is `true`, the alert will be hidden. If `flag` is `false`, the alert will be
  * shown.
  */
  const onConfirmation = (flag) => {
    setalert({ show: false, message: "" })
  }

  /**
   * The function `onBlurColumnClick` sets an alert message to be displayed when a cell is clicked.
   * @param cell - The "cell" parameter is a variable that represents the clicked cell in a table
   * column.
   */
  const onBlurColumnClick = (cell) => {
    // console.log("cellcell", cell);
    // toast.warning("Please buy PE")
    setalert({ show: true, message: Constants.reachSalesTeamMsg, isAlertOnly: true, closeBtnName: "OK" })
  }

/**
 * The function `onDisabledLinkClick` sets an alert message with a subscription message and displays it
 * as an alert.
 */
  const onDisabledLinkClick = () => {
      setalert({ show: true, message: Constants.maSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
  }

 /* The above code is using the `useTable` function from a library to create an instance of a table. It
 is passing in some configuration options such as `columns` and `rowData` to define the structure
 and data of the table. */
  const tableInstance = useTable({
    columns,
    data: rowData,
    disableSortRemove: true,
    initialState: {
      pageSize: Constants.noOfRowPerPage,
      pageIndex: 0,
      sortBy: initialSort,
    },
  },
    useSortBy,
    usePagination,
  );

 /* The above code is destructuring properties from the `tableInstance` object. It is extracting the
 following properties: */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize }
  } = tableInstance;

/* The above code is checking if the current path matches a specific pattern. It uses the `matchPath`
function to compare the current path with the pattern `${routePathSegments.vc}/*`. The
`location.state?.prevPath` is used as the second argument to the `matchPath` function, but if
`location.state?.prevPath` is `undefined` or `null`, an empty string is used instead. The result of
the comparison is stored in the `isVcCompanyMatch` constant. */
  const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, location.state?.prevPath ?? "")
  return (
    <>
      {investorsExitsDataFound ? (
    <div className="px-4  bg-[#EDEDED] w-[100%]">
      <div className=" mt-4 pb-2 bg-white shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] sm:rounded-lg">
        <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
          <div className="flex justify-between mb-3">
            <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] lg:leading-[22px] font-semibold">
              Portfolio Companies - Exits
            </h3>
            {!isDirectoryUser ? (isStudent == 'false' && isTrialExport != 'false'  && showXlDownload) && <ExcelDownloader
              url={exportExcel}
              fileName={Constants.exportNames.portfolio_companies_exit(props.investorName)}
              data={{
                "type": primaryMasterId,
                "export_from": "detail",
                "module_type": "investors",
                "category": "exits",
                "section": "company_exits",
                "investor_id": [investorId]
              }} /> : ""}
          </div>
        </div>
        <div className="space-y-6">
          <div className="bg-white pb-2 rounded-[6px] overflow-x-auto scrollbar">
          { rows?.length > 0 ? 
            <table className=" datatable w-full" {...getTableProps()}>

              <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-bold py-4 px-0 text-center ">
                {
                  headerGroups.map(headerGroup => (

                    <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                      {
                        headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps([
                            {
                              className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                              style: column.headerStyle,
                            },
                            column.getSortByToggleProps(),
                          ])} title="">
                            <div className="flex">
                              <span className='mr-1'>{column.render('Header')}</span>
                              {column.disableSortBy ? "" : column.isSorted
                                ? column.isSortedDesc
                                  ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                  : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                            </div>
                          </th>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>

              <tbody {...getTableBodyProps()} className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                {page.length > 0 && !props?.isLoading
                  ? page.map(row => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} className="border-b">
                        {

                          row.cells.map(cell => {
                            if (cell.value == Constants.restrictedPlaceholder) {
                              return (<td onClick={onBlurColumnClick} className=' relative whitespace-nowrap cursor-pointer' {...cell.getCellProps()}>
                                <div className='blur-sm bg-[#C9C9C9] select-none w-full h-full px-5 py-4'>{Constants.restrictedPlaceholder}</div>
                                <div className='absolute h-full w-full flex items-center justify-center top-0 bottom-0'>
                                  <LockIcon size={16} />
                                </div>
                              </td>)
                            }

                            return (
                              <td colSpan={cell.column.colspan} rowSpan={cell.column.rowspan} {...cell.getCellProps([
                                {
                                  className: `data-td-animate lg:whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                  style: cell.column.style,
                                },
                              ])}>
                                {
                                  cell.value ? cell.render('Cell') : " "
                                }
                              </td>


                            )
                          })}
                      </tr>

                    )
                  })
                  : <></>}
              </tbody>
            </table>
          : 
          <div className='w-full h-16 flex items-center justify-center text-[#333333] text-[15px] mt-2'>
              {props.isLoading ? <Loader /> : "No data found"}
          </div>
          }
          </div>

        </div>

        {/* {props.isLoading || rows?.length <= 0
          ? <div className='w-full h-16 flex items-center justify-center'>
            {props.isLoading ? <Loader /> : "No data found"}
          </div>
          : <></>} */}
        {rows?.length <= Constants.noOfRowPerPage || props?.isLoading
          ? <></>
          : <Paginate
            currentPage={pageIndex + 1}
            totalCount={rows?.length}
            pageSize={pageSize}
            onPageChange={(page) => {
              gotoPage(page - 1);
            }}
          />}
        {<ConfirmPopup {...alert} onChange={onConfirmation} />}
      </div>
    </div>
    ) : (
        ""
      )}
    </>
  );
};

export default InvestorPortfolioExits;
