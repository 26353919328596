
import React from 'react';
import { useState } from 'react';
import PE_VC from '../../../images/noun-investment-2180740_4/png/noun-investment-2180740.png';
import TF_US from '../../../images/Shape_4/png/Shape.png';
import TF_INR from '../../../images/noun-investment-2180740_2/png/noun-investment-2180740.png';
import Company from '../../../images/noun-investment-2180740_5/png/noun-investment-2180740.png';
import { useParams } from 'react-router-dom';
import { cfsTopCardUrl } from '../../../Config/config';
import axios from 'axios';
import useModule from '../../../hooks/useModule';
import { useContext } from 'react';
import { UserContext } from '../../../Contexts/UserContext';
import { useEffect } from 'react';
import { TableDataContext } from '../../../Contexts/TableDataContext';
import { CurrencyContext } from '../../../Contexts/CurrencyContext'
import Loader from '../../Loader';
import CAGRarrow from './CAGRarrow'

/**
 * The `Card` component is a functional component in JavaScript that fetches and displays card details
 * based on certain parameters.
 * @returns The Card component is returning a JSX element, which represents the UI of the component.
 */
const Card = () => {

  const [detailCard, setDetailCard] = useState([]);
  const { companyId } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const { getToken } = useContext(UserContext);
  const { currency } = useContext(TableDataContext);
  const { financialType } = useContext(CurrencyContext);

 /**
  * The function `getCardDetails` makes a POST request to a specified URL with certain parameters, sets
  * a loading state, and updates the state with the response data if the request is successful.
  */
  const getCardDetails = () => {
    setisLoading(true);
    axios.post(cfsTopCardUrl, {
      "company_id": companyId,
      "currency_cd": currency,
      "financial_type": financialType

    }, {
      headers: {
        "Authorization": getToken()
      }
    }).then(response => {
      setisLoading(false);
      if (response.status === 200) {
        setDetailCard(response.data?.data)

      }
    }).catch(error => {
      setisLoading(false);
    })
  }

  useEffect(() => {
    if (financialType && currency) {
      getCardDetails();
    }


  }, [companyId, currency, financialType])

  console.log(financialType, "financial")


  return (

    <div className="bg-[#EDEDED]">
      <div className='sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]'>
        {/* <div> */}
        
        {isLoading || (detailCard?.length ?? 0) < 0
          ? <div className="w-full h-[72px] flex items-center justify-center items-center">
            <Loader sizeClassName='w-6 h-6' />
          </div>
          : <div className="overflow-x-auto lg:grid grid-cols-3 pl-1 pr-2 gap-2 py-[0.7rem] flex xl:overflow-hidden">
            {/* ----card1----- */}
            {detailCard?.map(detail => {
              return <section className="flex-shrink-0 ">
                <div className="bg-white rounded-md  border border-gray-200 overflow-hidden card-animate">
                      <div className=" text-[13pt]  leading-[17pt] lg:text-[13px] lg:leading-[17px] font-sans_bold_body font-bold  text-white tracking-[-0.3px] text-center bg-gray-500 py-1">
                        {detail?.year} YEAR GROWTH 
                    </div>
                  <div className='grid xl:grid-cols-3 sm:grid-cols-7 gap-1 bg-[#F2F2F2]'>                
                    <div className='xl:col-span-1 lg:col-span-3 bg-white space-y-1 py-1'>
                      <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] flex space-x-1 justify-center items-center">
                        <label className='whitespace-nowrap font-semibold'>TOTAL INCOME</label>
                        {detail?.total_income_perc ? <>
                        <CAGRarrow  arrow={detail?.total_income_arrow}/>
                        <span className={detail?.total_income_arrow =='down' ? 'text-red-500':'text-green-900'}>{detail?.total_income_perc !== "nan" ? detail?.total_income_perc+'%': null }</span> </> :<span className='pl-2 font-bold'>--</span>}
                      </div>
                      
                      <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                        {detail?.current_total_income !='₹ 0 Cr' && detail?.current_total_income !='₹ -0 Cr' ? detail?.current_total_income: "--"}
                      </div>
                      <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                        {detail?.last_total_income !='₹ 0 Cr' && detail?.last_total_income !='₹ -0 Cr' ? detail?.last_total_income: "--"}
                      </div>
                      
                    </div>

                    <div className='xl:col-span-1 lg:col-span-2  bg-white space-y-1 py-1'>
                      <div className=" text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] flex space-x-1 justify-center items-center">
                        <label className='font-semibold'>EBITDA</label>
                        {detail?.ebitda_perc ? <>
                            <CAGRarrow  arrow={detail?.ebitda_arrow}/>
                        <span className={detail?.ebitda_arrow =='down' ? 'text-red-500':'text-green-900'}>{detail?.ebitda_perc !== "nan" ? detail?.ebitda_perc+"%" :null }</span></> :<span className='pl-2 font-bold'>--</span>}
                      </div>
                     
                      <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                        {detail?.last_ebitda !='₹ 0 Cr' && detail?.current_ebitda !='₹ -0 Cr' ?detail?.current_ebitda: "--"}
                      </div>
                      <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                        {detail?.last_ebitda !='₹ 0 Cr' && detail?.last_ebitda !='₹ -0 Cr' ?detail?.last_ebitda: "--"}
                      </div>
                     
                    </div>

                    <div className='xl:col-span-1 lg:col-span-2  bg-white space-y-1 py-1'>
                      <div className=" text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] flex space-x-1 justify-center items-center">
                        <label className='font-semibold'>PAT</label>
                        {detail?.pat_perc ? <>
                        <CAGRarrow  arrow={detail?.pat_arrow}/>
                        <span className={detail?.pat_arrow =='down' ? 'text-red-500':'text-green-900'}>{detail?.pat_perc !== "nan" ? detail?.pat_perc+'%': null }</span> </> : <span className='pl-2 font-bold'>--</span>}
                      </div>
                     
                      <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                        {detail?.current_pat !='₹ 0 Cr' && detail?.current_pat !='₹ -0 Cr' ? detail?.current_pat: "--"}
                      </div>
                      <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                        {detail?.last_pat !='₹ 0 Cr' && detail?.last_pat !='₹ -0 Cr' ? detail?.last_pat: "--"}                      
                      </div>
                    </div>

                  </div>
                </div>

              </section>
            })
            }


          </div>
        }
        {/* </div> */}
        <div className='border-b-2 border-[#e5e5e5]'></div>

        {/* <Enterprice/> */}

      </div >
    </div >


  )
}

export default Card;