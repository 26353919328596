import React, { useState, useMemo, useEffect, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import xls from "../../../images/download-file-icon.png";
import sorting from "../../../images/up-down-arrow.png";
import companyLogo1 from "../../../images/mindtree-logo copy/png/mindtree-logo copy.png";
import companyLogo2 from "../../../images/mindtree-logo copy/png/mindtree-logo copy.png";
import companyLogo3 from "../../../images/logo2/png/logo2.png";
import ExcelPopup from "../../../Components/ExcelPopup";
import { ADVISOR_COMPANY_EXITS } from "../../../Queries/Pe";
import moment from "moment"
import { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import CompanyLogo from '../../../Components/CompanyLogo';
import RandomLogo from '../../RandomLogo';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel, advisorExitTable } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import axios from 'axios'
import OverflowToolTip from '../../shared/OverflowToolTip'; 
import Constants from "../../../Constants";
import useModule from "../../../hooks/useModule";
import { UserContext } from "../../../Contexts/UserContext";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";
import ConfirmPopup from "../../../Utils/ConfirmPopup";


const TAdvisorCompaniesExits = (props) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const [portfolio, setPortfolioData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [openTab, setOpenTab] = useState(1);
  const [searchParams] = useSearchParams();
  let advisorType = "T";

  const location = useLocation()
  const { pathname, state } = useLocation();
  const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, state?.prevPath ?? "")
  const [showXlDownload, setshowXlDownload] = useState(false)

  const { companyId } = useParams();
  let advisorProfileId = parseInt(companyId)
  const { primaryMasterId } = useModule();
  const {isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext);
  const {toCompaniesExitsDataFound} = useContext(DataFoundContext);

  // useEffect(() => {
  //   axios(`${advisorExitTable}`, {
  //     method: "POST",
  //     data: {
  //       "type": "exits",
  //       "advisor_id": advisorIdPE,
  //       "advisor_type": advisorType
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       // if (response.data) {
  //       setPortfolioData(response.data)
  //       console.log(response.data, "sxgfvhg")
  //       // }
  //     })
  //     .catch((error) => {
  //       console.log("API not working - exit deal ", error);
  //     });

  // }, [])


  const pageNumbers = [];

  for (
    let i = 1;
    i <=
    Math.ceil(portfolio?.length / dataPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
 /**
  * The function `setPage` sets the current page to the specified page number.
  * @param pageNum - pageNum is the page number that we want to set as the current page.
  */
  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
  };
 /* The `customStringSortMemo` constant is a custom sort function that is used to sort the data in a
 DataTable component. It is created using the `useMemo` hook to memoize the function so that it is
 only recomputed when its dependencies change. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
    }
  }, []);

  /* The `dealDateSort` constant is a custom sort function that is used to sort the "Deal Date" column
  in the DataTable component. It is created using the `useMemo` hook to memoize the function so that
  it is only recomputed when its dependencies change. */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
    }
  }, [])

  /* The `AcquirerSort` constant is a custom sort function that is used to sort the "Acquirer" column
  in the DataTable component. It is created using the `useMemo` hook to memoize the function so that
  it is only recomputed when its dependencies change. */
  const AcquirerSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';
  
        const valueA = rowA?.values[columnId].map(data => data.acquirer_name).join("")
        const valueB = rowB?.values[columnId].map(data => data.acquirer_name).join("")
  
        return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
    }
  }, [])
  

  const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);

  /**
   * The function adds commas to a number to improve readability.
   * @param number - The `number` parameter is the number that you want to add commas to.
   * @returns The function `addCommasToNumber` returns a string representation of the input number with
   * commas added as thousands separators.
   */
  const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };


  /* The above code is defining an array of columns for a table. Each column has properties such as id,
  Header (column header text), accessor (data accessor), className (CSS classes for the column),
  Cell (rendering component for the cell), and sortType (custom sort function for the column). The
  columns array is created using the useMemo hook, which memoizes the array so that it is only
  recomputed when its dependencies change. */
  const columns = useMemo(() => [
    {
      id: "companyName",
      Header: "Company Name",
      accessor: "companyName",
      className: "clickable  lg:relative text-left sticky left-0  bg-white",
      headerClassName: "w-[20%]  lg:relative text-left sticky left-0   w-[3rem] bg-[#EDEDED] ",
      Cell: ({ value }) => {
        return <div className=" leading-[1.8rem] lg:leading-[2rem] font-sans_book_body items-center justify-center">
          {value?.logo_path == "default.png" || value?.logo_path == "/default.png" || !value?.logo_path ?
            <RandomLogo name={value.name} page="company page" />
            :
            <CompanyLogo name={value.name} page="company page" logo={value?.logo_path} />
          }

          <OverflowToolTip className="w-[11rem] lg:w-auto" component={<Link rel="noopener noreferrer"
            className='text-start flex lg:inline  hover:text-[#BC8B20]'
            state={{ cameFrom: location.state?.prevPath }}
            to={peMatch != null ? routeNames.peCompanyFundById(value?.id)
              : routeNames.vcCompanyFundById(value?.id,)}>
            {value.name ?? "--"}
            {console.log(value.name, "uu")}
          </Link>} />
        </div>
      },
      sortType: customStringSortMemo,
    },
    {
      id: "dealDate",
      Header: "Deal Date",
      accessor: "dealDate",
      Cell: (cell) => {
        return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
        onClick={(e) => {
          if (isDirectoryUser) {
              e.preventDefault();
              e.stopPropagation();
              onBlurColumnClick();
          }
        }}
        to={peMatch!=null?routeNames.peCompanyExitsDealById(cell.value.id,cell.value.dealId)
          :routeNames.vcCompanyExitsDealById(cell.value.id,cell.value.dealId)}>
            {cell?.value?.date !='' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"}
        </Link>
    },
      sortType: dealDateSort
    },
    {
      Header: "Exit Type",
      accessor: "exitType",
      className:"whitespace-nowrap"
    },
    {
      Header: "Exit Status",
      accessor: "exitStatus",
      className: "text-left "
    },
    {
      Header: "Acquirer",
      accessor: "acquirer",
      Cell: ({ value }) => {
        return <div className="leading-[2rem] font-sans_book_body items-center justify-center">

          {/* <OverflowToolTip component={<Link  rel="noopener noreferrer"
           className='hover:text-[#BC8B20]' 
           state={{ cameFrom: location.state?.prevPath }} 
           to={
           routeNames.maAcquiresById(value?.id,value?.dealId)}>
            {value.name ?? "--"}
            {console.log(value.name,"uu")}
          </Link>} /> */}
          <OverflowToolTip className="w-[12rem] lg:w-auto" component={
            value.map((inv, index) => (<Link className='hover:text-[#BC8B20]' 
            state={{ cameFrom: location.pathname }} to={routeNames.maAcquiresById(inv.acquirer_id)}>
             {inv.acquirer_name != "NA" ?inv.acquirer_name :" "}
            </Link>))
          }/>
            

          
          </div>
      },
      sortType: AcquirerSort,
    },
    {
      Header: "Return Multiple",
      accessor: "revenueMultiple",
      className: "px-0 text-right pr-[2.5rem] w-[9%]",
      headerClassName: "w-[10%]",
      headerspanClassName: "ml-auto",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    }
  ], []);

  /* The `rowData` constant is using the `useMemo` hook to memoize the data that will be displayed in
  the DataTable component. It creates an empty array called `rows` and then iterates over the
  `props.companyExits` array. For each company in `props.companyExits`, it creates an object with
  properties for each column in the DataTable. The values for these properties are extracted from
  the company object. Finally, it sets the `showXlDownload` state based on whether `rows` has any
  elements and returns the `rows` array. This memoized data is then passed as the `data` prop to the
  DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];
    {
      props.companyExits?.length > 0 &&
        props.companyExits?.forEach(company => {
          rows.push({
            companyName: { name: company?.company_name,  id: company?.company_id, logo_path: company?.logo_path },
            dealDate: { date: company?.dates, dealId: company?.deal_id, id: company?.company_id },
            exitType: company?.dealtype,
            exitStatus: company?.exit_status,
            acquirer: company?.acquirer,
            revenueMultiple: company?.revenue_multiple > 0 ? company?.revenue_multiple : '',
          })
        });
    }
    setshowXlDownload(rows.length != 0)
    return rows;
  }, [props.companyExits])

 /* The `initialSort` constant is using the `useMemo` hook to memoize an array of sorting options for
 the DataTable component. It specifies that the initial sorting should be done based on the
 'dealDate' column in descending order. The empty dependency array `[]` ensures that the sorting
 options are only calculated once and not re-evaluated on subsequent renders. */
  const initialSort = useMemo(() => [
    {
      id: 'dealDate',
      desc: true
    }
  ], []);

  const [alert, setalert] = useState({ message: "", show: false });

  const onBlurColumnClick = () => {
          setalert({ show: true, message: Constants.peSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
  }

  const onConfirmation = () => {
          setalert({ show: false, message: "" })
  }

  return (
      <>
        {toCompaniesExitsDataFound ? (
          <div className=" px-4 bg-[#EDEDED]">
            <div className=" bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
              <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
                <div className="flex justify-between mb-3">
                  <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] lg:leading-[22px] font-semibold ">
                    To Companies - Exits
                  </h3>
                  {!isDirectoryUser ? (isStudent == 'false' && isTrialExport !='false' && showXlDownload) && <ExcelDownloader
                    url={exportExcel}
                    fileName={Constants.exportNames.to_companies_exit(props.advisorName)}
                    data={{
                      "type": primaryMasterId,
                      "export_from": "detail",
                      "module_type": "advisors",
                      "advisor_type": "T",
                      "section": "company_exits",
                      "advisor_id": [advisorProfileId]
                    }} />: ""}
                </div>
              </div>
              <div className="space-y-6">
                <div className="bg-white pb-2 rounded-[6px]">
                  <DataTable columns={columns} data={rowData} sortBy={initialSort} />
                </div>
              </div>
            </div>
          </div>
        ) : ""}
        {<ConfirmPopup {...alert} onChange={onConfirmation} />}
      </>
  );
};

export default TAdvisorCompaniesExits;
