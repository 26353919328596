import React, { useEffect, useState, useContext } from "react";
// import Select from "react-select";
import { Select } from 'antd';
import Switch from "../RefineSearchComponents/AndOrToggleSwtich";
import BasedFilterWrapper from './BasedFilterWrapper';
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { TableDataContext } from "../../Contexts/TableDataContext";
import useScrollIntoView from './useScrollIntoView';

/* The `options` constant is an array of objects that represents the available options for the
financial filter component. Each object in the array has the following properties: */
const options = [
  { value: "revenue", label: "Revenue", min: "", max: "", filterLable: "Rev" },
  { value: "ebitda", label: "EBITDA", min: "", max: "", filterLable: "EBITDA" },
  { value: "net_profit", label: "Net Profit", min: "", max: "", filterLable: "N_Profit" },
  { value: "total_debt", label: "Total Debt", min: "", max: "", filterLable: "Total_Debt" },
  { value: "net_worth", label: "Networth", min: "", max: "", filterLable: "Networth" },
  { value: "capital_employed", label: "Capital Employed", min: "", max: "", filterLable: "Capital_Employed" }
];
/**
 * The FinancialsBasedFilter component is a JavaScript function that renders a filter component for
 * financial data based on selected options and values.
 * @returns The FinancialsBasedFilter component is returning a JSX element.
 */
const FinancialsBasedFilter = ({
  open,
  accordionClick,
  FinancialYear,
  setFinancialYear,
  FinancialOptions,
  setFinancialOptions,
  FinancialAndOr,
  setFinancialAndOr,
  customPathname,
  currencyType,
  setCurrencyType,
  label = "FINANCIAL BASED"
}) => {
  const { refineSearchFilters } = useContext(PeFilterContext);
  const { currency } = useContext(TableDataContext);
  const [finOptions, setFinOptions] = useState(options);
  const listItemRef = useScrollIntoView(open);


  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
  `useEffect` hook is used to update the state variables `financialYear`, `financialOptions`,
  `financialCurrencyType`, and `finAndOr` based on the values stored in the `refineSearchFilters`
  object. */
  useEffect(() => {
    const financialYear = refineSearchFilters[customPathname]?.financialYear?.at(0)?.value;
    const financialOptions = refineSearchFilters[customPathname]?.financialPercentage?.map(option => option.value);
    const financialCurrencyType = refineSearchFilters[customPathname]?.financialCurrencyType?.at(0)?.value ?? true
    const finAndOr = refineSearchFilters[customPathname]?.financialOperator?.at(0)?.value ?? "and";
    setFinancialYear(financialYear ?? "any")
    setCurrencyType(financialCurrencyType)
    setFinancialOptions(financialOptions ?? []);
    setFinancialAndOr(finAndOr);
  }, [customPathname, refineSearchFilters, setCurrencyType, setFinancialAndOr, setFinancialOptions, setFinancialYear])

  /**
   * The function `onFinancialYearChange` sets the value of `financialYear` to the provided `value`.
   * @param value - The value parameter represents the new financial year that is being selected.
   */
  const onFinancialYearChange = (value) => {
    // setFinancialYear(prev => prev === value ? null : value)
    setFinancialYear(value)
    // setGrowthOptions([])
  }

  /**
   * The function `onFinancialOptionChange` updates the financial options based on the selected option.
   * @param e - The "e" parameter is an event object that represents the event that triggered the
   * change. It can be used to access information about the event, such as the target element that
   * triggered the change.
   * @param selected - The "selected" parameter is the value that has been selected or changed in the
   * financial options.
   */
  const onFinancialOptionChange = (e, selected) => {
    setFinancialOptions(selected)
  }


  /**
   * The function `onOptionsYearChange` is used to handle changes in financial options for a specific
   * year.
   * @param index - The index parameter represents the index of the financial option in the
   * FinancialOptions array. It is used to identify which option is being changed.
   * @param optionKey - The `optionKey` parameter represents the key of the option being changed. It
   * can have two possible values: "min" or "max".
   * @param value - The value parameter represents the new value that is being entered or selected for
   * the financial option.
   * @param key - The `key` parameter is a string that represents the unique identifier of the
   * financial option.
   */
  const onOptionsYearChange = (index, optionKey, value, key) => {
    const validNumber = new RegExp(/^\d*\.?\d*$/);
    let processedValue = "";
    if (validNumber.test(value)) {
      processedValue = value;
    } else {
      processedValue = optionKey === "min" ? FinancialOptions[index].min : FinancialOptions[index].max;
    }
    // const  = value?.replace(/[^\d\.]*/g, '')
    setFinancialOptions(prev => {
      let temp = [...prev];
      if (optionKey === "min") {
        temp[index].min = processedValue
      } else if (optionKey === "max") {
        temp[index].max = processedValue
      }
      return temp;
    })
    // setFinancialOptions(prev => {
    //   let temp = [...prev];
    //   const optionIndex = temp.findIndex(option => option.value === key)
    //   if (optionIndex !== -1) {
    //     if (optionKey === "min") {
    //       temp[optionIndex].min = processedValue === "" ? "" : Number(processedValue)
    //     } else if (optionKey === "max") {
    //       temp[optionIndex].max = processedValue === "" ? "" : Number(processedValue)
    //     }
    //   }
    //   return temp;
    // })
  }

  /**
   * The handleCurrencyChange function toggles the currency type.
   */
  const handleCurrencyChange = () => {
    setCurrencyType(prev => !prev)
  }

  console.log('FinancialOptions-12', currencyType);

  return (

    <div className="border-b-[3px]  border-[#E8E6E1] space-y-3">
      <li className={
        open
          ? "bg-white relative  py-1"
          : "relative  py-1"
      }
      ref={listItemRef}
      >
        <BasedFilterWrapper
          isSelect={true}
          label={label}
          onClick={accordionClick}
          open={open}
        />
        <div
          className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto px-3 no-scrollbar"
          style={{ maxHeight: open ? "16rem" : "" }}
        >
          {open === true ?
            <>
              <div className="flex justify-between items-center my-2">
                <div className="flex ">

                  <input className="cursor-pointer mr-1" type="checkbox" id="unit" checked={currencyType} onChange={handleCurrencyChange} />

                  <label htmlFor="unit" className="text-xs cursor-pointer whitespace-nowrap">{currency === 'USD' ? 'In $M' : 'In Cr'}</label>
                </div>
                <label className="font-semibold text-[13px] text-[#333333]">Year :</label>


                <div className="flex ">
                  <input
                    type="radio"
                    className="cursor-pointer mr-1"
                    value="any"
                    checked={FinancialYear === "any"}
                    onClick={() => onFinancialYearChange('any')}
                    id="any"
                  />

                  <label htmlFor="any" className="text-xs cursor-pointer whitespace-nowrap">Any of</label>
                </div>
                <div className="flex ">
                  <input
                    type="radio"
                    value="all"
                    className="cursor-pointer mr-1"
                    checked={FinancialYear === "all"}
                    onClick={() => onFinancialYearChange('all')}
                    id="all"

                  />
                  <label htmlFor="all" className="text-xs cursor-pointer whitespace-nowrap">Across All</label>
                </div>
              </div>
              <div className="py-2">
                <Select
                  mode="multiple"
                  showSearch
                  options={finOptions}
                  optionFilterProp='label'
                  value={FinancialOptions}
                  onChange={onFinancialOptionChange}
                  style={{
                    width: '100%',
                    border: '1px solid #BC8B20',
                    borderRadius: '4px',
                    background: '#ffffff',
                    maxHeight: '60px',
                    overflowY: "auto"
                  }}
                  bordered={false}
                />
                <div className="">
                  {FinancialOptions.length > 0 &&
                    <div className="flex justify-between my-3 gap-3">
                      <label className="whitespace-nowrap font-semibold text-[13px] text-[#333333]">Fields</label>
                      <label className="whitespace-nowrap font-semibold text-[13px] text-[#333333] ml-3">Greater than</label>
                      <label className="whitespace-nowrap font-semibold text-[13px] text-[#333333]">Less than</label>
                    </div>
                  }
                  {FinancialOptions.map((option, i) => {
                    return (
                      <>
                        <div key={option} className="grid grid-cols-3 space-y-2 flex items-center break-words mb-1">
                          <div className=" text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{option.label}</div>
                          <div className="px-1">

                            <input
                              className="border border-gray-300 rounded p-1 w-full"
                              type="text"
                              required
                              value={option.min}
                              onChange={(e) => onOptionsYearChange(i, "min", e.target.value, option.value)}
                            />
                          </div>
                          <div className="px-1">
                            <input
                              className="border border-gray-300 rounded p-1 w-full"
                              type="text"
                              required
                              value={option.max}
                              onChange={(e) => onOptionsYearChange(i, "max", e.target.value, option.value)}
                            />
                          </div>
                        </div>
                        {/* <p className="text-red-700 text-[12px] text-right">Value must not be empty</p> */}
                      </>
                    )
                  })}
                </div>
                <hr className="mt-2"></hr>
                <div className="mt-2">
                  <ToggleButton value={FinancialAndOr === "or"} onLabel="AND" offLabel="OR" onToggle={(value) => setFinancialAndOr(value)} />
                </div>
              </div>
            </> :

            <></>}

        </div>

      </li>
    </div>
  );
};
export default FinancialsBasedFilter;

