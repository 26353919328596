import { React, useState, useMemo, useContext } from "react";
import Table from "./Table";
import { TableHeaderContext } from "../Contexts/TableHeaderContext";
import { PeFilterContext } from "../Contexts/PeFilterContext";
import DealsTable from "./DealsTable";

/**
 * The TableComponent function renders a table based on the menu selection and displays the data and
 * pagination.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `TableComponent` component. These properties can be accessed using dot notation, such as
 * `props.menu` to access the `menu` property.
 * @returns The TableComponent is being returned.
 */
const TableComponent = (props) => {
  const {
    PEVCTableHeader,
    PEVCDealsTableHeader,
    PEVCExitDealsTableHeader,
    VCExitPMDealsTableHeader ,
    VCTableHeader,
    PEVCExitTableHeader,
    PEVCExitMATableHeader,
    PEVCExitMADealsTableHeader,
    PEVCExitPMTableHeader,
    PEVCExitPMDealsTableHeader,
    VCExitMATableHeader,
    VCExitMADealsTableHeader,
    VCExitPMTableHeader,
    PEVCBackedIposTableHeader,
    PEVCBackedIposDealsTableHeader,
    // VCBackedIposTableHeader,
    AngelInvestmentsTableHeader,
    IncubationTableHeader,
    //  investorTableHeader,
    orderBy,
    orderBya,
    OrderByVCTableHeader,
    OrderByPEVCExitMATableHeader,
    OrderByPEVCExitPMTableHeader,
    OrderByVCExitMATableHeader,
    OrderByVCExitPMTableHeader,
    OrderByPEVCBackedIposTableHeader,
    // OrderByVCBackedIposTableHeader,
    OrderByAngelInvestmentsTableHeader,
    OrderByIncubationTableHeader,
    // VCAngelInvestmentsTableHeader,VCIncubationTableHeader
    orderingColumn,
    exitOrderingColumn,
  } = useContext(TableHeaderContext);

  const { dealsView } = useContext(PeFilterContext);
  const [
    investments,
    //  setInvestments
  ] = useState([]);
  const [
    pagination,
    //  setPagination
  ] = useState();

  const tableRowData = useMemo(() => investments, [investments]);
  const paginationData = useMemo(() => pagination, pagination);
  console.log(paginationData, "paginatorInfo");

  // const order = props.menu == "pe" ? orderBy
  //              : props.menu == "vc" ?OrderByVCTableHeader
  //              :props.menu == "exit-pe-vc" ? orderBya
  //  : props.menu == "exit-ma-pe-vc" ? OrderByPEVCExitMATableHeader
  // : props.menu == "exit-pm-pe-vc" ? OrderByPEVCExitPMTableHeader
  // : props.menu == "exit-ma-vc" || props.menu === "vc_exit" ? OrderByVCExitMATableHeader
  // : props.menu == "exit-pm-vc" ? OrderByVCExitPMTableHeader
  // : props.menu == "backedIpos-pe-vc" ? OrderByPEVCBackedIposTableHeader
  // : props.menu == "backedIpos-vc" ? OrderByPEVCBackedIposTableHeader
  // // : props.menu == "backedIpos-vc" ? OrderByVCBackedIposTableHeader
  // : props.menu == "angel" ? OrderByAngelInvestmentsTableHeader
  // : props.menu == "incubation" ? OrderByIncubationTableHeader
  // // : props.menu == "vc-angel" ? VCAngelInvestmentsTableHeader
  // : props.menu == "vc-angel" ? OrderByAngelInvestmentsTableHeader
  // // : props.menu == "vc-incubation" ? VCIncubationTableHeader
  // : props.menu == "vc-incubation" ? OrderByIncubationTableHeader
  // :orderBy

  const columns =
    props.menu == "pe" || props.menu == 'vc'
      ? !dealsView
        ? PEVCTableHeader
        : PEVCDealsTableHeader
      : props.menu == "exit-pe-vc"
      ? !dealsView
        ? PEVCExitTableHeader 
        : PEVCExitDealsTableHeader
      : props.menu == "exit-ma-pe-vc" 
      ? ! dealsView
        ? PEVCExitMATableHeader 
        : PEVCExitMADealsTableHeader
      : props.menu == "exit-pm-pe-vc"  
      ? !dealsView
        ? PEVCExitPMTableHeader 
        : PEVCExitPMDealsTableHeader
      : props.menu == "exit-ma-vc" || props.menu === "vc_exit"
      ? !dealsView 
        ? VCExitMATableHeader 
        : VCExitMADealsTableHeader
      : props.menu == "exit-pm-vc"
      ? ! dealsView 
        ? VCExitPMTableHeader 
        : VCExitPMDealsTableHeader
      : props.menu == "backedIpos-pe-vc" 
      ? !dealsView
        ? PEVCBackedIposTableHeader 
        : PEVCBackedIposDealsTableHeader
      : // : props.menu == "backedIpos-vc" ? VCBackedIposTableHeader
      props.menu == "backedIpos-vc"
      ? !dealsView
        ? PEVCBackedIposTableHeader 
        : PEVCBackedIposDealsTableHeader
      : props.menu == "angel"
      ? AngelInvestmentsTableHeader
      : props.menu == "incubation"
      ? IncubationTableHeader
      : // : props.menu == "vc-angel" ? VCAngelInvestmentsTableHeader
      props.menu == "vc-angel"
      ? AngelInvestmentsTableHeader
      : // : props.menu == "vc-incubation" ? VCIncubationTableHeader
      props.menu == "vc-incubation"
      ? IncubationTableHeader
      : PEVCTableHeader;

  console.log(columns);

  return (
    <div className="pl-3 pr-2 lg:pr-2 md:pr-3 overflow-y-auto">
      {console.log("investments", tableRowData)}
      <div className="App lg:relative">
        {dealsView ? (
          <DealsTable
            order="desc"
            orderBy={
              props.menu == "pe"
                ? orderingColumn
                : props.menu == "vc"
                ? OrderByVCTableHeader
                : exitOrderingColumn
            }
            menu={props?.menu}
            columns={columns}
            pagination={paginationData}
            sub_type_master_id={props?.sub_type_master_id}
          />
        ) : (
          <Table
            order="desc"
            orderBy={
              props.menu == "pe"
                ? orderingColumn
                : props.menu == "vc"
                ? OrderByVCTableHeader
                : exitOrderingColumn
            }
            menu={props?.menu}
            columns={columns}
            pagination={paginationData}
            sub_type_master_id={props?.sub_type_master_id}
          />
        )}
      </div>
    </div>
  );
};
export default TableComponent;
