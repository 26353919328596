import { React, useContext, useEffect} from "react";
import { filterLabels, PeFilterContext } from "../../Contexts/PeFilterContext";
import { TableDataContext } from '../../Contexts/TableDataContext'
import { TableHeaderContext } from "../../Contexts/TableHeaderContext";
import { NavLink, useLocation } from "react-router-dom";
import moment from 'moment'
import { routeNames } from "../../routeSegments";
import FilterTag from "../FilterTag";
import CloseButton from "../../images/CloseBtn.svg";
import useModule from "../../hooks/useModule";
import Constants from "../../Constants";
import { Popover } from "antd";
import ExpandedTagList from "../FilterTag/ExpandedTagList";

/**
 * The `Tag` function is a React component that renders a list of tags based on the filters applied in
 * the application, and provides options to remove filters.
 * @returns The `Tag` component is returning a JSX element.
 */
const Tag = (props ,menu) => {
  const {
    monthPickerValue, setMonthPickerValue, removeFilter, refineSearchFilters,groupId ,setGroupId 
  } = useContext(PeFilterContext);
  const {setCurrentPage} = useContext(TableDataContext);
   const { setSelectedPage } = useContext(TableHeaderContext)  
  let tagList = "";
  const { pathname } = useLocation();
  const{primaryMasterId,isVC} = useModule();
  console.log(refineSearchFilters[pathname] , "gjygj");


  const closeIconTag = (id, key) => {
    // console.log(refineSearchFilters[pathname]);
    if (key == "monthRange") {
      // setIsLoad(true)
      setMonthPickerValue(Constants.initialMonthRange)
      setCurrentPage(1)
      setSelectedPage(1)
      // setIsTagClosed(1)
      // setShowMontnPicker(1)
    }
  };
  const removeGroup =(id,key)=>{
    if (key == "myList") {
      setGroupId({})
      setMonthPickerValue(Constants.initialMonthRange);
      setCurrentPage(1)
      setSelectedPage(1)

    }
  }
  
  useEffect(()=>{
    if(groupId?.myList?.length > 0)  {
      if(groupId?.myList[0]?.group_id !=null){
      setMonthPickerValue(['1998-01-01', moment().endOf("month").format("YYYY-MM-DD")])
      }
    }
    else{     
          // setMonthPickerValue(Constants.initialMonthRange)
        }  
  },[groupId])

  const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

  return (
    <div className={props?.page ==="pefund"?"px-4 py-3 flex justify-between items-center " :"px-4 py-3 flex justify-between items-center mt-[4.25rem] lg:mt-0"}>
      <div className="flex flex-wrap gap-2">
        <label
          htmlFor=""
          className="text-[10px] leading-[16px] font-sans_book_body whitespace-nowrap text-[#333333] px-3 py-0.5 flex rounded-md ring-1 ring-gray-400 items-center tag-animate"
        >
          <p className="p-[2px]">
            {moment(monthPickerValue[0]).format("MMM YY") +
              " - " +
              moment(monthPickerValue[1]).format("MMM YY")}
          </p>   
          {isFromGlobalSearch() || groupId?.myList?.length >0 ? <></> :    
          <img alt="" src={CloseButton} className="h-3 w-3 ml-3 cursor-pointer" onClick={() => closeIconTag(0, "monthRange")} key={"monthRange"} />}

        </label>
        {groupId?.myList?.length >0 ? 
           (groupId?.myList?.map((tags) => {
            return (<FilterTag title="My List" subTitle={tags?.name} onRemove={() => removeGroup(tags?.id, "myList")}/>)
           }))
           : null
        }
        {Object.keys(refineSearchFilters[pathname] ?? {})?.map((filter) => {
          tagList = refineSearchFilters[pathname][filter];
          if (tagList?.length <= 3) {
            if ((tagList?.length ?? 0) === 0) return <></>
            if (filter === "yearFounded") {
              return <FilterTag title={filterLabels[filter]} subTitle={`${tagList[0]?.name} - ${tagList[1]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "operator" || filter === "deals_operator") {
              return null;
            }
            if (filter === "dealRange") {
              return <FilterTag title={filterLabels[filter]} subTitle={`$${tagList[0]?.name} - $${tagList[1]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "fundRange" || filter === "fundSize") {
              return <FilterTag title={filterLabels[filter]} subTitle={`$${tagList[0]?.name} - $${tagList[1]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "dealInvestorRange") {
              return <FilterTag title={filterLabels[filter]} subTitle={`$${tagList[0]?.name} - $${tagList[1]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "returnMultiple") {
              return <FilterTag title={filterLabels[filter]} subTitle={`${tagList[0]?.name} - ${tagList[1]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "investorSaleInipo") {
              return <FilterTag subTitle={`${filterLabels[filter]} - ${tagList[0]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }

            return tagList?.map((tags) => {
                console.log(filter, "gjygj", filterLabels[filter], tags);
                {/* let subtitleValue = filter === "investor_city" ? tags : tags?.name; */}
                return (
                    <FilterTag
                        subTitle={tags?.name}
                        onRemove={() => removeFilter(filter, tags?.id, false)}
                    />
                );
            });
          }
          else {
            if ((tagList?.length ?? 0) === 0) return <></>
            return (
              <Popover placement="right" title={filterLabels[filter]} content={<ExpandedTagList tagList={tagList} onRemove={(id) => removeFilter(filter, id, false)} />}>
              <span><FilterTag title={filterLabels[filter]} subTitle={`${tagList[0]?.name} +${tagList?.length - 1} more`} onRemove={() => removeFilter(filter, 0, true)} /></span>
            </Popover>              
            )
          }
        })}
      </div>
      {props?.menu == "investor" ?
      <div className="space-x-3 md:space-x-4 flex">
      <NavLink
          onClick={()=>setGroupId({})}
        to={
          props?.menu == "investor"
            ? routeNames.pe_investors_pe_vc_limited_partners
            : props?.menu == "vcinvestor"
              ? routeNames.vc_investors_vc_limited_partners
              : props?.menu == "investor-exits"
                ? routeNames.pe_investors_pe_vc_limited_partners
                : props?.menu == "investor-exit-ma-pe"
                  ? routeNames.pe_investors_pe_vc_limited_partners
                  : props?.menu == "investor-exit-pm-pe"
                    ? routeNames.pe_investors_pe_vc_limited_partners
                    : props?.menu == "investor-exit-ma-vc"
                      ? routeNames.vc_investors_vc_limited_partners
                      : props?.menu == "investor-exit-pm-vc"
                        ? routeNames.vc_investors_vc_limited_partners
                        : routeNames.pe_investors_pe_vc_limited_partners}
      // to="/investors/limited-partners"
      >
        <button
          type="submit"
          className="flex  bg-[#BC8B20] px-2 h-[28px] text-[#FFFFFF] text-[14px] rounded-[5px] leading-[17px] items-center space-x-1"
        >
          {/* <img className="" src={LockIcon}/> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-[15px] h-[15px] md:w-5 md:h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
            />
          </svg>
          <span className="whitespace-nowrap"> Limited Partners</span>
        </button>
      </NavLink>


      <NavLink
        onClick={()=>setGroupId({})}
        to={
          primaryMasterId === Constants.primaryMasterIds.peId
            ? routeNames.pe_investors_pe_vc_funds
            :  routeNames.vc_investors_vc_funds}
      // to="/investors/limited-partners"
      >
        <button
          type="submit"
          className="bg-[#BC8B20] px-2 h-[28px] text-[#FFFFFF] rounded-[5px] text-[14px] leading-[17px]"
        >
          Funds
        </button>
      </NavLink>
    </div>
    :
    props?.menu== "re-fund" ?

 <NavLink

        to={
          primaryMasterId === Constants.primaryMasterIds.reId
            ? routeNames.re_investors_funds
            :  ""}
      // to="/investors/limited-partners"
      onClick={()=>setGroupId({})}

      >
        <button
          type="submit"
          className="bg-[#BC8B20] px-2 h-[28px] text-[#FFFFFF] rounded-[5px] text-[14px] leading-[17px]"
        >
          Funds
        </button>
      </NavLink> 
      :""
      }
      
    </div>
  );
};

export default Tag;
