import { Select, Spin, Tag } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, createContext, useRef, useState } from 'react';
import { tagSearchUrl } from '../../Config/config';
import '../../index.css'
import { Option } from 'antd/es/mentions';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { UserContext } from '../../Contexts/UserContext';
import { AiFillCloseCircle } from 'react-icons/ai'
import useModule from "../../hooks/useModule";


const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  // const onPreventMouseDown = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  // };
  console.log(props, "label-8");
  return (
    <div className="mx-1 my-1 text-[12px] flex items-stretch justify-between font-bold  px-1 py-1 rounded-sm bg-slate-100 text-black border ">
      <div>
        {label}
      </div>

      <div onClick={onClose} className='px-1 flex items-center justify-center cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-3 h-3">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>


    </div>
  );
};

/**
 * This is a React component that renders a Select input field for searching and selecting tags.
 */
const Tags = ({ TagsOptions, customPathname, setTagsOptions, getSearchTags }) => {

  const { getToken } = useContext(UserContext);
  const {primaryMasterId} = useModule();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const firstRender = useRef(false);
  const { refineSearchFilters } = useContext(PeFilterContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTagsOptions(refineSearchFilters[customPathname]?.tags ?? [])
  }, [customPathname, refineSearchFilters, setTagsOptions])



  useEffect(() => {
    let sub;
    if (firstRender.current) {
      sub = setTimeout(() => {
        if (searchText !== "") {
          setOptions([]);
          setFetching(true);
          getSearchTags(searchText).then(newOptions => {
            console.log(newOptions, "newOptionsnewOptions");
            setOptions(newOptions);
            setFetching(false);
          });
        } else {
          setOptions([]);
        }
      }, 500);
    } else {
      firstRender.current = true;
      setOptions(TagsOptions)
    }
    return () => clearTimeout(sub);
  }, [searchText])

  const handleChange = (value, options) => {
    console.log("optionsoptionsoptions", options, value);
    setTagsOptions(value?.map(option => ({ id: option, name: option })))
    setOpen(false)
    clearOptions()
  };
  
  const clearOptions = () => {
    setOptions([])
    setSearchText("");
  }


  return <Select
    mode="multiple"
    filterOption={false}
    style={{
      width: '100%',
      maxHeight: '60px',
      overflowY:"auto",
    }}
    className="text-black"
    open={open}
    onDropdownVisibleChange={(visible) => setOpen(visible)}
    placeholder="Search by Tags..."
    bordered={false}
    tagRender={tagRender}
    onSearch={(value) => setSearchText(value)}
    onChange={handleChange}
    value={TagsOptions?.map(tag => tag.name)}
    onBlur={clearOptions}
    notFoundContent={searchText === "" ? null : fetching ? <p className='text-black'>Searching...</p> : <p className='text-black'>No tags found</p>}
  >
    {options?.map(option => {
      return <Option key={option.name} value={option.name}>{option.name}</Option>
    })}
  </Select>
};

export default Tags;