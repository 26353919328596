import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';
import useScrollIntoView from './useScrollIntoView';

/**
 * The `FinancialResultsTypeFilter` function is a React component that renders a dropdown menu for
 * selecting a financial result type and updates the options based on the selected value.
 * @returns The FinancialResultsTypeFilter component is being returned.
 */
function FinancialResultsTypeFilter({ open, accordionClick, setFinancialsResultTypeOptions, FinancialsResultTypeOptions, customPathname, label="Financials Result Type" }) {

    const { FinancialResultType, refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);

    console.log( FinancialsResultTypeOptions,'FinancialsResultTypeOptions');

    /* The `useEffect` hook is used to perform side effects in a React functional component. In this
    case, the effect is triggered whenever the dependencies (`FinancialResultType`,
    `customPathname`, `refineSearchFilters`, `setFinancialsResultTypeOptions`) change. */
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.financialType?.map(value => value.id) ?? [];
        setFinancialsResultTypeOptions( FinancialResultType?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [FinancialResultType, customPathname, refineSearchFilters, setFinancialsResultTypeOptions])


    /**
     * The function `handleFinancialTypeChange` updates the `isChecked` property of each object in the
     * `FinancialsResultTypeOptions` array based on the selected value from the event target.
     * @param e - The parameter `e` is an event object that represents the event that triggered the
     * function. In this case, it is likely an event object from a change event, such as a change in
     * the value of an input field.
     */
    const handleFinancialTypeChange = (e) => {
        const value = e.target.value
        // const values = companyType.filter(companyType => companyType.id === e.target.value);
        setFinancialsResultTypeOptions(FinancialsResultTypeOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.id === value })))
    }

    const selectedFinancialsResultType = FinancialsResultTypeOptions?.filter(option => option.isChecked)?.at(0)
    console.log(selectedFinancialsResultType,'selectedFinancialsResultType');

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
                ref={listItemRef}
            >
                <FilterWrapper
                    isSelect={true}
                    label={selectedFinancialsResultType?.name ? ` ${label} - ` + selectedFinancialsResultType?.name : label}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    <select name="" id="" value={selectedFinancialsResultType?.id ?? ""} onChange={handleFinancialTypeChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-[#FFFFFF] w-full">
                        {FinancialsResultTypeOptions && FinancialsResultTypeOptions.length > 0 ? (
                            FinancialsResultTypeOptions.map((financialType, index) => (
                                <option key={financialType.id} value={financialType.id}>{financialType.name}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>

            </li>

        </div>
    )
}

export default FinancialResultsTypeFilter