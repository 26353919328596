import React from "react";
import { FaTimes } from "react-icons/fa";

const DefinitionModal = ({ values, onClose,CFSRefine }) => {
  // console.log(values, 'from inside')
  return (
    <div className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen  bg-gray-500 bg-opacity-75 transition-opacity'>
        <div className='flex flex-col w-[95%] md:w-[60%] h-3/4 bg-white rounded-lg border-b'>
          <div className="py-2 sticky top-1 rounded-t-lg">
              <span onClick={onClose} className="flex justify-end pr-4 pt-2 cursor-pointer">
                  <FaTimes color="grey" />
              </span>
              <h1 className="text-black font-medium text-lg text-center">{CFSRefine ? "Tag List - limited to PE backed companies" : "Tag List"}</h1>
          </div>
          <div className="flex justify-center overflow-auto">
          <div>
            <div className="m-6 grid grid-cols-3 gap-x-4">
              {values?.non_comp?.map(val => 
                  <div key={val.id} className="p-1">{val.name}</div>
              )}
            </div>
          <div className="py-2 rounded-t-lg">
              <h1 className="text-black font-medium text-lg text-center">Competitor Tags</h1>
          </div>
            <div className="m-6 grid grid-cols-3">
              {values?.comp?.map(val => 
                  <div key={val.id} className="p-1">{val.name}</div>
              )}
            </div>
          </div>
        </div>
        </div>
    </div>
  );
};

export default DefinitionModal;
