import { useState, useMemo, useContext, useEffect, useCallback } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { sortRows, filterRows} from '../helpers'
import Pagination from '../../Components/Pagination'
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import '../TableView.css'
import axios from 'axios';
import { UserContext } from '../../Contexts/UserContext';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { TableDataContext } from '../../Contexts/TableDataContext';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import moment from "moment";
import AddNotePopover from '../shared/AddNotePopover';
import Constants from '../../Constants';
import { routeNames } from '../../routeSegments';
import OverflowToolTip from '../shared/OverflowToolTip';
import RandomLogo from '../RandomLogo';
import CompanyLogo from '../CompanyLogo';
import { getReAdvisor,getPEGlobalSearchCompaniesApi } from '../../Config/config'
import { getSection } from '../../Utils/utils'
import { useRef } from 'react';
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';
import { toast } from 'react-toastify';


const Table = ({ columns, menu, advisor_type, order, orderBy ,sub_type_master_id}) => {
  const { monthPickerValue, setIsMonthPickerChanged, refineSearchFilters ,groupId,resetDealsByFilter} = useContext(PeFilterContext);
  const { rows, setRows, data, setData, isLoad, setAllDealIds,setTableAdvisorId, setIsLoad, setIsDataLoad, currentPage, setCurrentPage,setCurrentFilter, globalSearchResult } = useContext(TableDataContext);
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: "asc", orderBy: "advisor_name_sort" })
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null)
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false
  const[reload, setReload] = useState(false)

  /**
   * The function `findActivePage` sets the current page, renders a table with specific parameters, and
   * updates the load status.
   * @param page - The `page` parameter represents the current page number. It is used to update the
   * current page state and pass it to the `tableRender` function.
   */
  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order ,groupId)
    setIsLoad(true)
    // setIsDataLoad(false)
  }
  useEffect(()=>{
    console.log("jjjhhk inv" , location?.key)
    resetDealsByFilter();
  },[location?.key])

 /* The above code is using a conditional (ternary) operator to assign a value to the `dealCategory`
 variable based on the value of the `menu` variable. */
  const dealCategory = menu == "re-ladvisor"
  ? "RE_INV"
  : menu == "re-advisorexit"
    ? "RE_EXIT" 
      : menu == "re-advisor-otherma"  ?       
      "OTHER-MA" 
      :"";

      
      
      /* The above code is a JavaScript function that is used to render a table. It takes several parameters
      such as page, month, column, order, and groupId. */
      const tableRender = useCallback((page, month, column, order ,groupId) => {
        setIsMonthPickerChanged(0)
        const category = menu == "re-ladvisor" ? "re-investments" : menu == "re-advisorexit" ? "re-exits" :
        menu == "re-advisor-otherma" ? "other-manda" : ""  
        
        const globalSearchCategory = category.replace("-", "_")+"_ids";

    axios.post(getReAdvisor, 
    {
      category,
      from : isFromGlobalSearch() ? 'gs' : '',
        date_range: isFromGlobalSearch()
            ? {
                from_date: globalSearchResult?.date_range?.from_date,
                to_date: globalSearchResult?.date_range?.to_date,
              }
            : {
                from_date: moment(month[0]).format("YYYY-MM-DD"),
                to_date: moment(month[1]).format("YYYY-MM-DD"),
              },
      "type": menu == "re-ladvisor" ? "re_investment_advisor" : menu == "re-advisorexit" ? "re_exit_advisor" : menu == "re-advisor-otherma" ? "re_other_ma_advisor"
        : "",
      "advisor_type": advisor_type,
      "page": page,

      "order_by": {
        "column": column,
        "direction": order
      },
      // operator : refineSearchFilters[location.pathname]?.deals_operator,
      company_ids : refineSearchFilters[location.pathname]?.target_company?.map(company => company.id),
      // tadvisor_ids : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.tadvisors?.map(acq => acq.id)  ,
      investor_ids : refineSearchFilters[location.pathname]?.investor_id?.map(acq => acq.id) ,
      advisor_ids: isFromGlobalSearch() ? globalSearchResult?.l_advisor[globalSearchCategory].length === 0
      ? [0] : globalSearchResult?.l_advisor[globalSearchCategory]
      : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.ladvisors?.map(acq => acq.id),
      "city": refineSearchFilters[location.pathname]?.city?.map(city => city.id),
      "deal_range": refineSearchFilters[location.pathname]?.dealRange?.at(0) && {
        "from": refineSearchFilters[location.pathname]?.dealRange[0].name,
        "to": refineSearchFilters[location.pathname]?.dealRange[1].name
      },
      "investor_type": refineSearchFilters[location.pathname]?.investorType?.map(type => type.id),
      "sector_type": refineSearchFilters[location.pathname]?.fundSector?.map(sector => sector.id),
      "sub_sector": refineSearchFilters[location.pathname]?.subSector?.map(sector => sector.id),
      group:{  
        group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
        sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
  }, 
    },
    

      {
        signal: abortRef.current.signal,
        headers: {
          "Authorization": getToken()
        },
        
      }).then(response => {
        if (response?.data?.message == "Success") {
          setCurrentFilter(response.config.data)
          setIsLoad(false)
          setIsDataLoad(false)
          setIsMonthPickerChanged(0)
          setRows([])
          setRows(response?.data?.advisors)
          setData(response?.data)
          setTableAdvisorId([])
          setTableAdvisorId(response?.data?.all_advisor_ids)
          setAllDealIds(response?.data?.all_deal_ids)
          // console.log(response?.data?.companies,"api")
        }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
          logout();
        }
        console.log("API not working", error);
      });
  }, [monthPickerValue, refineSearchFilters]);


  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage)
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order ,groupId )
    return () => {
      abortController.abort();
    }
  }, [tableRender, monthPickerValue,groupId ,reload]);


  // const rowsPerPage = rowsPerPageData

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  // const calculatedRows = sortedRows
  const count = filteredRows.length


/**
 * The function `handleSort` is used to handle sorting of data in a table based on a given accessor and
 * order.
 * @param accessor - The `accessor` parameter is a value that represents the property or key of the
 * object that you want to sort by. It is used to identify the specific property or key in the object
 * that you want to sort the data by.
 * @param order - The "order" parameter is used to specify the sorting order. It can have two possible
 * values: 'asc' for ascending order and 'desc' for descending order.
 */
  const handleSort = (accessor, order) => {
    setRows([])
    setIsLoad(true);
    setCurrentPage(currentPage)
    setIsDataLoad(true)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(currentPage, monthPickerValue, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc',groupId)
  }


  var location = useLocation();

 /**
  * The function `addCommasToNumber` takes a number as input and returns a string representation of the
  * number with commas added for thousands separators.
  * @param number - The `number` parameter is the number to which commas need to be added.
  * @returns The function `addCommasToNumber` returns a string with commas added to the number.
  */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };


  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] "+(rows.length > 5 ? '':'pr-2')}>
              <table className="table w-full p-1 bg-grey-50 divide-y">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]  text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (
                        <th className=" tableTd  px-5 py-2 font-sans_book_body" key={i}>
                          <span className='flex'><OverflowToolTip component={<a className='cursor-text hover:text-[#000000]'>{column.accessor == "add" && groupId?.myList?.length > 0 ? "Delete": column.label ? column.label : "--"}</a>} />
                            {column.accessor == "add" ? "" :
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                            }</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[13pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body">
                      {rows.length > 0 ?
                        menu == "re-ladvisor" ?

                          rows?.map((row, i) => {
                            return (
                              <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                <td  className='w-[7%] md:w-[10%] lg:w-[10%] xl:w-[10%] 2xl:w-[10%]  px-5 font-medium py-3 leading-[2rem] font-sans_book_body items-center justify-center card-animate whitespace-nowrap'>
                                  {row?.logo_path == "default.png" || row?.logo_path == "/default.png" || !row?.logo_path ?
                                    <RandomLogo name={row?.advisor_name} />
                                    :
                                    <CompanyLogo id={row.advisor_id} name={row.advisor_name} page="company page" logo={row?.logo_path} />
                                  }

                                  <OverflowToolTip className=" " component={<NavLink className='hover:text-[#BC8B20]'
                                    state={{ prevPath: location.pathname }}
                                    to={{
                                      pathname: routeNames.reAdvisorById(row.advisor_id),
                                      search: getSection(Constants.primaryMasterSubIds.re_advisor_investment_id)

                                    }} >

                                    <a className='hover:text-[#BC8B20] '>{row?.advisor_name ? row.advisor_name : "--"}</a>
                                  </NavLink>} />
                                </td> 
                                <td  className="w-[5%]  px-5 title text-center pr-[2.7rem] xl:pr-[3.2rem] 2xl:pr-[4rem] 3xl:pr-[4.5rem] lg:pr-[3.2rem] py-3 font-sans_book_body"><a >{row.to_company_investments != 0 ? addCommasToNumber(row.to_company_investments) : "--"}</a></td>
                                <td className="w-[4%] px-5 text-center pr-[2.7rem] lg:pr-[3rem] xl:pr-[3.5rem] 2xl:pr-[4.3rem] 3xl:pr-[5rem] py-3 font-sans_book_body "  > <a >{row.to_company_exits !=0 ? addCommasToNumber(row.to_company_exits) : "--"}</a></td>
                                <td  className=" w-[2%] px-5 py-3 font-sans_book_body  pr-[2.7rem] text-center xl:pr-[3rem]  2xl:pr-[3.9rem] 3xl:pr-[5rem] lg:pr-[2.7rem]" > <a >{row.to_investors !=0 ? addCommasToNumber(row.to_investors) : "--"}</a></td>
                                <td  className=" w-[2%] px-5 py-3 font-sans_book_body text-center  pr-[2.7rem] xl:pr-[3rem] 2xl:pr-[3.5rem] 3xl:pr-[4rem] lg:pr-[3rem] "  ><a >{row.to_acquirers !=0 ? addCommasToNumber(row.to_acquirers) : "--"}</a></td>
                                <td  className=" w-[1%] px-[10px] py-3 font-sans_book_body " >
                                {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.l_advisor_id}  setReload={setReload}/>
                                 : <AddNotePopover className="mx-3" key={row.advisor_id} companyId={row.advisor_id} companyName={row.advisor_name} type_id={Constants.moduleIds.l_advisor_id} sub_type_master_id={sub_type_master_id}/>
                          }</td>
                              </tr>
                            )
                          })

                          :
                          menu == "re-advisorexit" ?
                            rows?.map((row, i) => {
                              return (
                                <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                  <td width="7%" className=' md:w-[10%] lg:w-[10%] xl:w-[10%] 2xl:w-[10%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center card-animate  whitespace-nowrap'>
                                    {row?.logo_path == "default.png" || row?.logo_path == "/default.png" || !row?.logo_path ?
                                      <RandomLogo name={row?.advisor_name} />
                                      :
                                      <CompanyLogo id={row.advisor_id} name={row.advisor_name} page="company page" logo={row?.logo_path} />
                                    }

                                    <OverflowToolTip className=" " component={<NavLink className='hover:text-[#BC8B20]'
                                      state={{ prevPath: location.pathname }}
                                      to={{
                                        pathname: routeNames.reAdvisorById(row.advisor_id),
                                        search: getSection(Constants.primaryMasterSubIds.re_advisor_exits_id)

                                      }} >

                                      <a className='hover:text-[#BC8B20] '>{row?.advisor_name ? row.advisor_name : "--"}</a>
                                    </NavLink>} />
                                  </td>
                                  <td width="5%" className="  px-5 title text-center xl:pr-[3.2rem] 2xl:pr-[4rem] 3xl:pr-[4.5rem] lg:pr-[3.2rem] py-3 font-sans_book_body"><a >{row.to_company_investments !=0  ? addCommasToNumber(row.to_company_investments) : "--"}</a></td>
                                  <td width="4%" className="px-5 text-center lg:pr-[3rem] xl:pr-[3.5rem] 2xl:pr-[4.3rem] 3xl:pr-[5rem] py-3 font-sans_book_body "  > <a >{row.to_company_exits !=0 ? addCommasToNumber(row.to_company_exits) : "--"}</a></td>
                                  <td width="2%" className="  px-5 py-3 font-sans_book_body text-center xl:pr-[3rem]  2xl:pr-[3.9rem] 3xl:pr-[5rem] lg:pr-[2.7rem] " > <a >{row.to_investors !=0 ? addCommasToNumber(row.to_investors) : "--"}</a></td>
                                  <td width="2%" className="  px-5 py-3 font-sans_book_body text-center xl:pr-[3rem] 2xl:pr-[3.5rem] 3xl:pr-[4rem] lg:pr-[3rem] "   ><a >{row.to_acquirers !=0 ? addCommasToNumber(row.to_acquirers) : "--"}</a></td>
                                  <td width="1%" className=" px-[10px] py-3 font-sans_book_body " >
                                  {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.l_advisor_id} setReload={setReload}/>
                                 : <AddNotePopover className="mx-3" key={row.advisor_id} companyId={row.advisor_id} companyName={row.advisor_name} type_id={Constants.moduleIds.l_advisor_id} sub_type_master_id={sub_type_master_id}/>
                            }</td>
                                </tr>
                              )
                            })

                            : menu == "re-advisor-otherma" ?
                              rows?.map((row, i) => {
                                return (
                                  <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                    <td width="7%" className='md:w-[10%] lg:w-[10%] xl:w-[10%] 2xl:w-[10%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center card-animate  whitespace-nowrap'>
                                      {row?.logo_path == "default.png" || row?.logo_path == "/default.png" || !row?.logo_path ?
                                        <RandomLogo name={row?.advisor_name} />
                                        :
                                        <CompanyLogo id={row.advisor_id} name={row.advisor_name} page="company page" logo={row?.logo_path} />
                                      }

                                      <OverflowToolTip className=" " component={<NavLink className='hover:text-[#BC8B20]'
                                        state={{ prevPath: location.pathname }}
                                        to={{
                                          pathname: routeNames.reAdvisorById(row.advisor_id),
                                          search: getSection(Constants.primaryMasterSubIds.re_advisor_otherma_id)

                                        }} >

                                        <a className='hover:text-[#BC8B20] '>{row?.advisor_name ? row.advisor_name : "--"}</a>
                                      </NavLink>} />


                                    </td>
                                    <td width="5%" className="  px-5 title text-center xl:pr-[3.2rem] 2xl:pr-[4rem] 3xl:pr-[4.5rem] lg:pr-[3.2rem] py-3 font-sans_book_body"><a >{row.to_company_investments !=0 ? addCommasToNumber(row.to_company_investments) : "--"}</a></td>
                                    <td width="4%" className="px-5 text-center lg:pr-[3rem] xl:pr-[3.5rem] 2xl:pr-[4.3rem] 3xl:pr-[5rem] py-3 font-sans_book_body "  > <a >{row.to_company_exits !=0 ? addCommasToNumber(row.to_company_exits) : "--"}</a></td>
                                    <td width="2%" className="  px-5 py-3 font-sans_book_body text-center xl:pr-[3rem]  2xl:pr-[3.9rem] 3xl:pr-[5rem] lg:pr-[2.7rem] " > <a >{row.to_investors !=0 ? addCommasToNumber(row.to_investors) : "--"}</a></td>
                                    <td width="2%" className="  px-5 py-3 font-sans_book_body text-center xl:pr-[3rem] 2xl:pr-[3.5rem] 3xl:pr-[4rem] lg:pr-[3rem] "   ><a >{row.to_acquirers !=0 ? addCommasToNumber(row.to_acquirers) : "--"}</a></td>
                                    <td width="1%" className=" px-[10px] py-3 font-sans_book_body " >
                                    {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.l_advisor_id} setReload={setReload}/>
                                 : <AddNotePopover className="mx-3" key={row.advisor_id} companyId={row.advisor_id} companyName={row.advisor_name} type_id={Constants.moduleIds.l_advisor_id} sub_type_master_id={sub_type_master_id}/>
                              }</td>
                                  </tr>
                                )
                              })
                              : ""
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>


                      }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>
              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='pr-2'>
                    <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                      <NoDataPlaceholder />
                    </div>
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {data?.num_advisors && rows.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.num_advisors}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />
      ) : (
        ""
      )}

    </>
  )
}

export default Table
