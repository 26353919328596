    import React, { useEffect, useState } from 'react';
import MultiSelectEntity from '../RefineSearch/TgMultiSelectEntity';
import FilterWrapper from './FilterWrapper';
import { filterPESector } from "../../Config/config";
import useScrollIntoView from './useScrollIntoView';

function SectorFilter({ open, accordionClick, options, setOptions, customPathname, label }) {
    const [sectorVal, setSectorVal] = useState("");
    const [fetchedData, setFetchedData] = useState([]);
    const shouldScroll = open || fetchedData.length > 0;
    const listItemRef = useScrollIntoView(shouldScroll);



    const fetchData = () => {
        const payload = {
            search_key: sectorVal,
        };

        fetch(filterPESector, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data?.results) {
                setFetchedData(data?.results); // Store fetched data
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };

    useEffect(() => {
        const uniqueFetchedData = fetchedData;
        setOptions(prevOptions => [...prevOptions.filter(item => item.isChecked === true), ...uniqueFetchedData]);
    }, [fetchedData]);

    const handleSectorChange = (e) => {
        const { name, checked, id } = e.target;
        let currentSectors = [];

        if (name === "allSelect") {
            currentSectors = options.map((sector) =>
                ({ ...sector, isChecked: checked })
            );
            setOptions(currentSectors);
        } else {
            currentSectors = options.map((sector) =>
                sector.id === id ? { ...sector, isChecked: checked } : sector
            );
            setOptions(currentSectors);
        }
    };

    const selectedSectorLength = options?.filter(option => option.isChecked)?.length ?? 0;

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"} ref={listItemRef}>
                <FilterWrapper
                    count={selectedSectorLength}
                    isSelect={false}
                    label={label}
                    onClick={accordionClick}
                    open={open}
                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700 overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? (
                        <MultiSelectEntity
                            fetchData={fetchData}
                            handleEntityChange={handleSectorChange}
                            primaryEntity={options}
                            setCompanyVal={setSectorVal}
                            field="sector"
                        />
                    ) : null}
                </div>
            </li>
        </div>
    );
}

export default SectorFilter;
