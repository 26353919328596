import React, { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { reDetailExportExcel, reInvestorFunding } from '../../../Config/config';
import { UserContext } from '../../../Contexts/UserContext';
import useModule from '../../../hooks/useModule';
import Constants from '../../../Constants';
import { Link, useLocation, useParams, matchPath } from 'react-router-dom';
import ExcelDownloader from '../../shared/ExcelDownloader';
import ConfirmPopup from '../../../Utils/ConfirmPopup';
import FundTable from './FundTable';
import Loader from '../../Loader';
import OverflowToolTip from '../../shared/OverflowToolTip';
import { routeNames } from '../../../routeSegments';
import moment from 'moment';
import routePathSegments from "../../../routeSegments";
import RandomLogo from '../../RandomLogo';
import CompanyLogo from '../../CompanyLogo';

/**
 * The Tab component is a clickable div element that displays a label and changes its appearance based
 * on whether it is active or not.
 * @returns The Tab component is returning a div element with a dynamic className based on the isActive
 * prop. The className includes various tailwind CSS classes and conditionally adds additional classes
 * if isActive is true. The onClick prop is also passed to the div element. The content of the div is
 * the label prop.
 */
const Tab = ({ onClick, isActive, label }) => {
    return <div
        className={
            "whitespace-nowrap   text-[12pt]  lg:text-[14px] lg:leading-[15px] block leading-normal cursor-pointer " +
            (isActive
                ? "text-[#2B2A29] py-[10px] border-b-[3px] border-[#F1CA75] font-semibold"
                : " text-[#2B2A29] py-3 ")
        }
        onClick={onClick}>
        {label}
    </div>
}


const InvestorFunds = ({ initialActiveTab = 1, category, InvestorName }) => {
    // const [showXlDownload, setShowXlDownload] = React.useState(false);
    const [fundingData, setfundingData] = useState({})
    const { reinvestorId } = useParams()
    const {isTrialExport, getToken, logout, isStudent } = useContext(UserContext);
    const { primaryMasterId } = useModule();
    const [isLoading, setIsLoading] = useState(true);
    const [activetabIndex, setActivetabIndex] = useState(initialActiveTab)
    const [alert, setalert] = useState({ message: "", show: false });
    const { pathname} = useLocation();
    const [enableExport, setenableExport] = useState(false)


    const [showXlDownload, setShowXlDownload] = useState()
    const [xlDownloadRequest, setxlDownloadRequest] = useState({
        "type": 3,
        "section": "funding",
        "category": category,
        "investor_id": reinvestorId


    });

    /* The above code is a React useEffect hook that is triggered whenever the `activetabIndex` or
    `reinvestorId` variables change. */
    useEffect(() => {
        let category = "re-investments";

        if (activetabIndex === 2) {
            category = "re-exits";

        }
        else if (activetabIndex === 3) {
            category = "re-ipo";
        }

        if (isReCompanyMatch !== null) {
            setxlDownloadRequest(prev => ({ ...prev, investor_id: reinvestorId, type: Constants.primaryMasterIds.reId, category: category }))
        }
        else {
            setxlDownloadRequest(prev => ({ ...prev, investor_id: reinvestorId, type: Constants.primaryMasterIds.reId, category: category }))
        }

    }, [activetabIndex, reinvestorId])


    /* The above code is using the useEffect hook in React to determine whether to show or hide a
    download button based on the active tab index, the funding data, and the enableExport flag. */
    useEffect(() => {
        if (activetabIndex === 1) {
            if (fundingData?.investments != null && fundingData?.investments?.length > 0 && enableExport) {
                setShowXlDownload(true);
            } else {
                setShowXlDownload(false);
            }
        } else if (activetabIndex === 2) {
            if (fundingData?.exits != null && fundingData?.exits?.length > 0 && enableExport) {
                setShowXlDownload(true);
            } else {
                setShowXlDownload(false);
            }
        }

        else if (activetabIndex === 3) {
            if (fundingData?.ipos != null && fundingData?.ipos?.length > 0 && enableExport) {
                setShowXlDownload(true);
            } else {
                setShowXlDownload(false);
            }
        }


    }, [activetabIndex, fundingData, enableExport])

    const isReCompanyMatch = matchPath({ path: `${routePathSegments.re}/*` }, pathname)

    /**
     * The function `onConfirmation` sets the `show` property of an `alert` object to `false` and
     * clears the `message` property.
     * @param flag - The flag parameter is a boolean value that determines whether the confirmation is
     * true or false.
     */
    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets an alert message with a subscription message when a cell
     * is clicked.
     * @param cell - The "cell" parameter is a reference to the cell that was clicked. It is typically
     * used in table or grid components to identify the specific cell that triggered the event.
     */
    const onBlurColumnClick = (cell) => {
        setalert({ show: true, message: Constants.peSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }


    /* The above code is a React useEffect hook that makes an API call using axios. It sets the
    isLoading state to true initially, then sends a POST request to the specified endpoint
    (reInvestorFunding) with the provided data (investor_id, type, category). It also includes the
    authorization token in the headers. */
    useEffect(() => {
        setIsLoading(true);
        const abortController = new AbortController()
        axios(reInvestorFunding, {
            method: "POST",
            data: {
                investor_id: reinvestorId,
                type: primaryMasterId,
                category: category,
            },
            signal: abortController.signal,
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                setIsLoading(false);
                if (response?.data?.success === true) {
                    setfundingData(response.data?.data);
                    setenableExport(response.data?.data?.isExport ?? true)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                if (error?.response?.status === Constants.sessionTerminatedStatus) {
                    logout()
                }
                console.log("API not working", error);
            });




        return () => abortController.abort()
    }, [category, getToken, logout, primaryMasterId, reinvestorId])

    /**
     * The function `onTabChange` sets the active tab index based on the selected tab.
     * @param selected - The "selected" parameter is the value that represents the index of the tab
     * that has been selected or clicked on.
     */
    const onTabChange = (selected) => {
        setActivetabIndex(selected)
    }

    /* The above code is defining a custom sorting function called `customStringSortMemo` using the
    `useMemo` hook in React. This function takes in two rows (`rowA` and `rowB`), a column ID, and a
    boolean value `desc` indicating whether the sorting should be in descending order. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = "--";
            // console.log(rowA?.values[columnId]?.name.toLowerCase(),'lower case');
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);

    /* The above code is defining a custom sorting function called `dealDateSort`. This function is
    used to compare two rows (`rowA` and `rowB`) based on a specific column (`columnId`) that
    contains date values. The function uses the `moment.utc` function to convert the date values to
    UTC format and then compares them using the `diff` method. The result of the comparison is
    returned, which will be a negative number if `rowA` is earlier than `rowB`, a positive number if
    `rowA` is later than `rowB`, or zero if they are */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])



    /* The above code is defining a constant variable called `fileName` using the `useMemo` hook in
    JavaScript. The `useMemo` hook is used to memoize the result of a function so that it is only
    recomputed when its dependencies change. */
    const fileName = useMemo(() => {

        let module = "PE";



        let fileName = Constants.exportNames.funding_re_investment(InvestorName, module);


        if (activetabIndex === 2) {
            fileName = Constants.exportNames.funding_re_exit(InvestorName, module);

        } else if (activetabIndex === 3) {
            fileName = Constants.exportNames.funding_re_ipo(InvestorName, module);
        }
        return fileName;
    }, [InvestorName, activetabIndex])

    /**
     * The function "addCommasToNumber" takes a number as input and returns a string representation of
     * the number with commas added for thousands separators.
     * @param number - The `number` parameter is the number that you want to add commas to.
     * @returns The function `addCommasToNumber` returns a string with commas added to the number.
     */
    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };


    /* The above code is defining a variable called `columns` using the `useMemo` hook in JavaScript.
    The `columns` variable is an array of objects that define the columns for a table. The columns
    are different based on the value of the `activetabIndex` variable. */
    const columns = useMemo(() => {

        if (activetabIndex === 1) {
            return [
                {
                    Header: "Company",
                    accessor: "company",
                    Cell: (cell) => {

                        if (cell.value === Constants.restrictedPlaceholder) {
                            return cell.value
                        }

                        return <div className='inline leading-[2rem]'>
                            {
                                cell.value?.icon_path == "default.png" || cell.value?.icon_path == "/default.png" || !cell.value?.icon_path ?
                                    <RandomLogo name={cell.value?.name} />
                                    :
                                    <CompanyLogo name={cell.value?.name} id={cell.value?.dealId} page="company page" logo={cell.value?.icon_path} />
                            }
                            <OverflowToolTip component={<Link
                                className="hover:text-[#BC8B20] text-[#333333]"
                                rel="noopener noreferrer"
                                to={routeNames.reCompanyInvestmentDealById(cell.value?.companyId, cell.value?.dealId)}>
                                {cell.value.name ?? "--"}

                            </Link>} />
                        </div>

                    },
                    sortType: customStringSortMemo,
                },
                {
                    Header: "Sector",
                    accessor: "sector",
                    className: "clickable w-[15%]",
                    headerClassName: "w-[5%]",
                },
                {
                    Header: "City",
                    accessor: "city",
                    className: "clickable w-[15%]",
                    headerClassName: "w-[5%]",
                },
                {
                    id: "dealDate",
                    Header: "Deal Date",
                    accessor: "dealDate",
                    className: "w-[10%]",
                    headerClassName: "w-[9%]",
                    Cell: (cell) => {
                        if (cell.value === Constants.restrictedPlaceholder) {
                            return cell.value
                        }
                        return <Link
                            to={routeNames.reCompanyInvestmentDealById(cell.value?.companyId, cell.value?.dealId)}>
                            {moment(cell.value.date , 'MMM YYYY').format('MMM YYYY') ?? "--"}
                        </Link>
                    },
                    sortType: dealDateSort
                },
                {
                    Header: "Exit Status",
                    accessor: "exitStatus",
                    className: "clickable w-[15%]",
                    headerClassName: "w-[5%]",
                },
                {
                    Header: "Deal Amount (US$ M)",
                    accessor: "dealAmount",
                    className: `text-right   w-[0%] pr-[2.5rem]`,
                    headerspanClassName: "ml-auto ",
                    headerClassName: "w-[10%] mr-auto",
                    Cell: ({ value }) => {
                        if (parseFloat(value) <= 0) {
                            return ""
                        } else {
                            return  addCommasToNumber(value)
                        }
                    },
                   
                },
            ];
        } else if (activetabIndex === 2) {
            return [
                {
                    Header: "Company",
                    accessor: "company",
                    className: "clickable w-[20%]",
                    Cell: (cell) => {
                        if (cell.value === Constants.restrictedPlaceholder) {
                            return cell.value
                        }
                        return <div className='inline leading-[2rem]'>
                            {
                                cell.value?.icon_path == "default.png" || cell.value?.icon_path == "/default.png" || !cell.value?.icon_path ?
                                    <RandomLogo name={cell.value?.name} />
                                    :
                                    <CompanyLogo name={cell.value?.name} id={cell.value?.dealId} page="company page" logo={cell.value?.icon_path} />
                            }
                            <OverflowToolTip component={<Link className='hover:text-[#BC8B20] text-[#333333]'
                                rel="noopener noreferrer"
                                to={routeNames.reCompanyExitsDealById(cell.value?.companyId, cell.value?.dealId)}>
                                {cell.value.name ?? "--"}
                            </Link>} />
                        </div>
                    },
                    sortType: customStringSortMemo,
                },
                {
                    Header: "Sector",
                    accessor: "sector",
                    className: "clickable w-[15%]",
                    headerClassName: "w-[5%]",
                },
                {
                    Header: "City",
                    accessor: "city",
                    className: "clickable w-[15%]",
                    headerClassName: "w-[5%]",
                },
                {
                    Header: "Acquirer",
                    accessor: "acquirer",
                    className: "clickable w-[15%]",
                    headerClassName: "w-[5%]",
                    sortType: customStringSortMemo,
                    Cell:({value})=>value.name
                },
                {
                    id: "dealDate",
                    Header: "Deal Date",
                    accessor: "dealDate",
                    className: "w-[10%]",
                    headerClassName: "w-[9%]",
                    Cell: (cell) => {
                        if (cell.value === Constants.restrictedPlaceholder) {
                            return cell.value
                        }
                        return <Link
                            to={routeNames.reCompanyExitsDealById(cell.value?.companyId, cell.value?.dealId)}>
                            {moment(cell.value.date , 'MMM YYYY').format('MMM YYYY') ?? "--"}
                        </Link>
                    },
                    sortType: dealDateSort
                },
                {
                    Header: "Exit Status",
                    accessor: "exitStatus",
                    className: "clickable w-[10%]",
                    headerClassName: "w-[5%]",
                },
                {
                    Header: "Deal Amount (US$ M)",
                    accessor: "dealAmount",
                    className: `text-right   w-[0%] pr-[2.5rem]`,
                    headerspanClassName: "ml-auto ",
                    headerClassName: "w-[10%] mr-auto",
                    Cell: ({ value }) => {
                        if (parseFloat(value) <= 0) {
                            return ""
                        } else {
                            return addCommasToNumber(value)
                        }
                    },
                },
            ];
        } else {
            return [
                {
                    Header: "Company",
                    accessor: "company",
                    className: "w-[40%]",
                    Cell: (cell) => {
                        if (cell.value === Constants.restrictedPlaceholder) {
                            return cell.value
                        }
                        return <div className='inline leading-[2rem]'>
                            {
                                cell.value?.icon_path == "default.png" || cell.value?.icon_path == "/default.png" || !cell.value?.icon_path ?
                                    <RandomLogo name={cell.value?.name} />
                                    :
                                    <CompanyLogo name={cell.value?.name} id={cell.value?.dealId} page="company page" logo={cell.value?.icon_path} />
                            }
                            <OverflowToolTip component={<Link className='hover:text-[#BC8B20] text-[#333333]'
                                rel="noopener noreferrer"
                                to={routeNames.reCompanyBackedIPOSDealById(cell.value?.companyId, cell.value?.dealId)}>
                                {cell.value.name ?? "--"}
                            </Link>} />
                        </div>
                    },
                    sortType: customStringSortMemo,
                },
                {
                    Header: "Sector",
                    accessor: "sector",
                    className: "clickable w-[15%]",
                    // headerClassName: "w-[15%]",
                },
                {
                    id: "dealDate",
                    Header: "Deal Date",
                    accessor: "dealDate",
                    className: "w-[15%]",
                    // headerClassName: "w-[19%]",
                    Cell: (cell) => {
                        if (cell.value === Constants.restrictedPlaceholder) {
                            return cell.value
                        }
                        return <Link
                            to={routeNames.reCompanyBackedIPOSDealById(cell.value?.companyId, cell.value?.dealId)}>
                            {moment(cell.value.date , 'MMM YYYY').format('MMM YYYY') ?? "--"}
                        </Link>
                    },
                    sortType: dealDateSort
                },
                {
                    Header: "Size (US$ M)",
                    accessor: "size",
                    className: "clickable w-[15%] text-right pr-[5.5%] xl:pr-[6%] 2xl:pr-[8.5%]",
                    headerClassName: "w-[15%]",
                    Cell: ({ value }) => {
                        if (parseInt(value) > 0) {
                            return addCommasToNumber(value)
                        } else {
                            return "--"
                        }
                    },
                },
                {
                    Header: "Exit Status",
                    accessor: "exitStatus",
                    className: "clickable w-[15%]",
                    headerClassName: "w-[15%]",
                },

            ];
        }
    }, [activetabIndex, customStringSortMemo, dealDateSort])

    /* The above code is a JavaScript function that uses the `useMemo` hook to memoize the data based
    on the `activetabIndex` and `fundingData` dependencies. */
    const data = useMemo(() => {
        let rows = []

        if (activetabIndex === 1) {
            fundingData?.investments?.forEach(fund => {
                rows.push({
                    company: { name: fund.company, dealId: fund.deal_id, companyId: fund.company_id, icon_path: fund?.icon_path },
                    sector: fund.sector,
                    city: fund.city !== "" && fund.city != null ? fund.city : "--",
                    dealDate: { date: fund.deal_date, dealId: fund.deal_id, companyId: fund.company_id },
                    exitStatus: fund.exit_status ?? "--",
                    dealAmount: fund.deal_amount > 0 ? fund.deal_amount : "",
                })
            });
        } else if (activetabIndex === 2) {
            fundingData?.exits?.forEach(fund => {
                rows.push({
                    company: { name: fund.company, dealId: fund.deal_id, companyId: fund.company_id, icon_path: fund?.icon_path },
                    sector: fund.sector !== "" && fund.sector != null ? fund.sector : "--",
                    acquirer: {name:fund.acquirer_name},
                    city: fund.city !== "" && fund.city != null ? fund.city : "--",
                    dealDate: { date: fund.deal_date, dealId: fund.deal_id, companyId: fund.company_id },
                    exitStatus: fund.exit_status ?? "--",
                    dealAmount: fund.deal_amount > 0 ? fund.deal_amount : "",
                })
            });
        } else {
            fundingData?.ipos?.forEach(fund => {
                rows.push({
                    company: { name: fund.company, dealId: fund.deal_id, companyId: fund.company_id, icon_path: fund?.icon_path },
                    sector: fund.sector !== "" && fund.sector != null ? fund.sector : "--",
                    dealDate: { date: fund.deal_date, dealId: fund.deal_id, companyId: fund.company_id },
                    exitStatus: fund.exit_status ?? "--",
                    size: fund.ipo_size ?? "",
                })
            });
        }

        return rows;
    }, [activetabIndex, fundingData])

    /* The above code is defining an initial sort configuration for a list of items. It is using the
    `useMemo` hook to memoize the sort configuration so that it is only recalculated when the
    dependencies change. The sort configuration is an array with a single object that specifies the
    sorting criteria. In this case, the sorting is based on the `dealDate` property in descending
    order (`desc: true`). */
    const initialSort = useMemo(() => [
        {
            id: 'dealDate',
            desc: true
        }
    ], []);

    // console.log("fundingData-09", fundingData, data, columns);


    return (
        <>
            <div className="space-y-6 px-4 bg-[#EDEDED]">
                <div className=" mt-4 bg-white border  overflow-hidden sm:rounded-lg">
                    <div className="px-4 pt-4 sm:px-6 border-b border-b-[#F1F1F1]">
                        <div className="flex justify-between">
                            <h3 className="text-[14pt] leading-[19pt] text-[#333333] font-semibold lg:text-[18px] lg:leading-[22px] mb-3">Funding</h3>
                            {isTrialExport != 'false' && isStudent == 'false'  && showXlDownload && <ExcelDownloader fileName={fileName} data={xlDownloadRequest} url={reDetailExportExcel} />}
                        </div>
                    </div>
                    <div className="space-y-6">
                        <div className="" >
                            <div className="flex flex-row overflow-x-auto space-x-9 scrollbar px-5  ">
                                {fundingData?.investments?.length >0 &&
                                <Tab isActive={activetabIndex === 1} label="PE-RE Investments" onClick={() => onTabChange(1)} />}
                                {fundingData?.exits?.length >0 &&
                                <Tab isActive={activetabIndex === 2} label="PE-RE Exits" onClick={() => onTabChange(2)} />}
                                {fundingData?.ipos?.length >0 &&
                                <Tab isActive={activetabIndex === 3} label="PE-RE Backed IPO" onClick={() => onTabChange(3)} />}
                            </div >
                            <div className="overflow-x-auto scrollbar">
                                <div className={isLoading ? "block" : "hidden"}>
                                    <div className="h-40 flex items-center justify-center">
                                        <Loader />
                                    </div>
                                </div>
                                <div className={isLoading ? "hidden" : "block"}>
                                    <FundTable
                                        onBlurColumnClick={(cell) => onBlurColumnClick(cell, "inves")}
                                        columns={columns}
                                        data={data}
                                        initialSort={initialSort}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {<ConfirmPopup {...alert} onChange={onConfirmation} />}

                </div>

            </div>
        </>
    )
}

export default InvestorFunds