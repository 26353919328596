import { Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity';
import useScrollIntoView from './useScrollIntoView';

function InvestorHeadquartersFilter({ open, accordionClick, HeadquartersOptions, HeadquarterCityOptions, setHeadquarterCityOptions, setHeadquartersOptions, customPathname }) {
    const { InvestorHeadquarters, refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);
    const [InvestorCountry, setInvestorCountry] = useState([
        {
            id: 1,
            name: "India",
            code: "IN",
            isChecked: false
        },
        {
            id: 2,
            name: "Non-India",
            code: "NON_IN",
            isChecked: false
        }
    ]);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.investorHeadquarters?.map(value => value.id) ?? [];
        setHeadquartersOptions(InvestorHeadquarters?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })));
    }, [InvestorHeadquarters, customPathname, refineSearchFilters, setHeadquartersOptions]);

    const handleInvestorCountryChange = (selectedValue) => {
        const isIndiaSelected = selectedValue === 1;
        setInvestorCountry(InvestorCountry.map(type => ({ ...type, isChecked: type.id === selectedValue })));
        setHeadquarterCityOptions(HeadquarterCityOptions.map(type => ({ ...type, isChecked: false })));

        if (isIndiaSelected ) {
            const selectedCityLength = HeadquarterCityOptions.filter(option => option.isChecked).length;
            setHeadquartersOptions([{ id: "IN", name: "India", isChecked: selectedCityLength === 0 }]);
        } else {
            setHeadquartersOptions(HeadquartersOptions.map(type => ({ ...type, isChecked: false })));
        }
    };

    const handleCityChange = (e) => {
        const { name, checked, id } = e.target;
        let updatedOptions;

        if (name === "allSelect") {
            updatedOptions = HeadquarterCityOptions.map(industry => ({ ...industry, isChecked: checked }));
        } else if (name === "selectedEntities") {
            const selectedEntityIds = e.target.selectedEntities.map(entity => entity.id);
            updatedOptions = HeadquarterCityOptions.map(industry => ({
                ...industry,
                isChecked: selectedEntityIds.includes(parseInt(industry.id)) ? !industry.isChecked : industry.isChecked
            }));
        } else {
            updatedOptions = HeadquarterCityOptions.map(industry => {
                if (industry.id === parseInt(id)) {
                    return { ...industry, isChecked: checked };
                }
                return industry;
            });
        }

        setHeadquarterCityOptions(updatedOptions);
    };

    const handleCountryChange = (e) => {
        const { name, checked, id } = e.target;
        let updatedOptions;

        if (name === "allSelect") {
            updatedOptions = HeadquartersOptions.map(industry => ({ ...industry, isChecked: checked }));
        } else if (name === "selectedEntities") {
            const selectedEntityIds = e.target.selectedEntities.map(entity => entity.id);
            updatedOptions = HeadquartersOptions.map(industry => ({
                ...industry,
                isChecked: selectedEntityIds.includes(industry.id) ? !industry.isChecked : industry.isChecked
            }));
        } else {
            updatedOptions = HeadquartersOptions.map(industry => {
                if (industry.id === id) {
                    return { ...industry, isChecked: checked };
                }
                return industry;
            });
        }

        setHeadquartersOptions(updatedOptions);
    };

    const countryOption = InvestorCountry.map(data => ({ value: data.id, label: data.name }));

    const selectedCountry = InvestorCountry.find(option => option.isChecked);

    const selectedCityLength = HeadquarterCityOptions?.filter(option => option.isChecked).length;
    const selectedCountryLength = HeadquartersOptions?.filter(option => option.isChecked).length;
    const selectedItemLength = selectedCityLength > 0 ? selectedCityLength : selectedCountryLength;

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"} ref={listItemRef}>
                <FilterWrapper
                    count={selectedItemLength}
                    isSelect={false}
                    label={"Investor Headquarters"}
                    onClick={accordionClick}
                    open={open}
                />
                <div className="relative overflow-hidden transition-all max-h-0 duration-700 overflow-y-auto no-scrollbar" style={{ maxHeight: open ? "10rem" : "" }}>
                    <div className='bg-white input-search pointer-events-auto border border-[#BC8B20] rounded'>
                        <Select
                            className='w-full'
                            optionFilterProp='label'
                            showSearch
                            bordered={false}
                            onChange={handleInvestorCountryChange}
                            placeholder="Select"
                            options={countryOption}
                        />
                    </div>
                    {selectedCountry && (
                        <div className='mt-2'>
                            {selectedCountry.name === 'India' ? (
                                <MultiSelectEntity
                                    handleEntityChange={handleCityChange}
                                    primaryEntity={HeadquarterCityOptions}
                                    city="city"
                                />
                            ) : (
                                <MultiSelectEntity
                                    handleEntityChange={handleCountryChange}
                                    primaryEntity={HeadquartersOptions}
                                    city="country"
                                />
                            )}
                        </div>
                    )}
                </div>
            </li>
        </div>
    );
}

export default InvestorHeadquartersFilter;
