import { useState , useContext} from "react";
import { BsArrowRightSquareFill } from "react-icons/bs";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { PeFilterContext } from '../../Contexts/PeFilterContext';
/**
 * The RefineSearch component is a collapsible RefineSearch that contains a title, a toggle button, and a content
 * area for children components.
 * @returns a JSX element.
 */
export default function Sidebar({children}) {
    const [open, setOpen] = useState(false);
    const {dropdown,setDropdown} = useContext(PeFilterContext);

    const handleToggle = () => {
        setDropdown(!dropdown);
        console.log(dropdown , "jgjhg")
    };

    return (
        <>
            <div
                className={` ${
                    open ? "w-12" : "w-60 "
                } flex flex-col h-full shadow duration-300`}
            >
                <div className="space-y-3 h-screen">
                    <div className="flex items-center pl-1 pr-3 pt-3 justify-between">
                        <h2 className="text-xl font-bold text-white">
                            <div style={{display:!open?"flex":"none"}} className="text-[16px] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer  flex-row" onClick={handleToggle}>
                                <span className="mt-[2px]">
                                    {dropdown  ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                                </span>
                                <span className="ml-1">REFINE SEARCH</span>
                            </div>
                        </h2>
                        <button onClick={() => setOpen(!open)}>
                            {!open ? <BsArrowRightSquareFill style={{ transform: 'rotate(180deg)', height: 24, width: 24, color: '#bc8b20',borderRadius: 4 }} /> : <BsArrowRightSquareFill style={{  height: 24, width: 24, color: '#bc8b20',borderRadius: 4 }} />}
                        </button>
                    </div>
                    
                    <div className="flex-1" style={{display:!open?"block":"none"}}>                        
                        {children}
                    </div>
                </div>
            </div>
            <div className="container mx-auto mt-12">
            </div>
        </>
    );
}