import React, { useContext, useEffect, useRef, useState} from 'react'
import { Outlet, matchPath, useLocation, useMatch, useSearchParams } from 'react-router-dom';
import PEHeader from './Components/Headers/PEHeader';
import Header from './Components/Headers/Header';
import SideMenu from './Components/SideMenu';
import routePathSegments, { routeNames } from './routeSegments';
import VCHeader from './Components/Headers/VCHeader';
import REHeader from './Components/Headers/REHeader';
import MAHeader from './Components/Headers/MAHeader';
import { ToastContainer } from 'react-toastify';
import chatPopupIcon from './images/chatPopupIcon.png'
import ChatPopup from './Components/ChatPopup'
import { RaiseRequestContext } from './Contexts/RaiseRequestContext'
import 'react-toastify/dist/ReactToastify.css';
import CfsHeader from './Components/Headers/CfsHeader';
import SubscribeProductPage from './Components/shared/SubscribeProductPage';
import Constants from './Constants';
import FaqHeader from './Components/Headers/FaqHeader';
import { PeFilterContext } from './Contexts/PeFilterContext';
import { TableDataContext } from './Contexts/TableDataContext';
import { UserContext } from './Contexts/UserContext';
import { getForExRates } from './Config/config';
import axios from 'axios';

// const isMatch = (path, pathSeg) => {
//   const pathSegments = path?.split('/')
//   return pathSegments.includes(pathSeg);
// }

// const isDetailPageMatch = (fromPath, toPath) => {

//   const fromPathSeg = fromPath?.split('/')?.at(2);
//   const toPathSeg = toPath?.split('/')?.at(2);

//   console.log('isDetailPageMatch', fromPathSeg, toPathSeg);

//   const detailsPaths = [
//     routePathSegments.acquirer,
//     routePathSegments.advisor,
//     routePathSegments.company,
//     routePathSegments.transaction_advisor,
//     routePathSegments.investor,
//   ];

//   if (detailsPaths.includes(fromPathSeg) && detailsPaths.includes(toPathSeg)) {
//     return true;
//   } else {
//     return false;
//   }

// }

const isCompanyMatch = (fromPath, toPath) => {
  const fromPathSegs = fromPath?.split('/');
  const toPathSegs = toPath?.split('/');

  return (fromPathSegs.includes(routePathSegments.company)
    || toPathSegs.includes(routePathSegments.company))
    && (fromPathSegs.includes(routePathSegments.companies)
      || toPathSegs.includes(routePathSegments.companies))
}

const isInvestorMatch = (fromPath, toPath) => {
  const fromPathSegs = fromPath?.split('/');
  const toPathSegs = toPath?.split('/');

  return (fromPathSegs.includes(routePathSegments.investor)
    || toPathSegs.includes(routePathSegments.investor))
    && (fromPathSegs.includes(routePathSegments.investors)
      || toPathSegs.includes(routePathSegments.investors))
}

const isActiveNewInvestorMatch = (fromPath, toPath) => {
  const fromPathSegs = fromPath?.split('/');
  const toPathSegs = toPath?.split('/');

  return (fromPathSegs.includes(routePathSegments.investor)
    || toPathSegs.includes(routePathSegments.investor))
    && (
      //active investor check
      (fromPathSegs.includes(routePathSegments.activeInvestors)
        || toPathSegs.includes(routePathSegments.activeInvestors))
      //new investor check
      || (fromPathSegs.includes(routePathSegments.newInvestors)
        || toPathSegs.includes(routePathSegments.newInvestors))
    )
}

const isAdvisorMatch = (fromPath, toPath) => {
  const fromPathSegs = fromPath?.split('/');
  const toPathSegs = toPath?.split('/');

  return (fromPathSegs.includes(routePathSegments.advisor)
    || toPathSegs.includes(routePathSegments.advisor))
    && (fromPathSegs.includes(routePathSegments.advisors)
      || toPathSegs.includes(routePathSegments.advisors))
}

const isTransactionAdvisorMatch = (fromPath, toPath) => {
  const fromPathSegs = fromPath?.split('/');
  const toPathSegs = toPath?.split('/');

  return (fromPathSegs.includes(routePathSegments.transaction_advisor)
    || toPathSegs.includes(routePathSegments.transaction_advisor))
    && (fromPathSegs.includes(routePathSegments.transaction_advisors)
      || toPathSegs.includes(routePathSegments.transaction_advisors))
}

const isAcquirerMatch = (fromPath, toPath) => {
  const fromPathSegs = fromPath?.split('/');
  const toPathSegs = toPath?.split('/');

  return (fromPathSegs.includes(routePathSegments.acquirer)
    || toPathSegs.includes(routePathSegments.acquirer))
    && (fromPathSegs.includes(routePathSegments.acquirers)
      || toPathSegs.includes(routePathSegments.acquirers))
}

const isFundMatch = (fromPath, toPath) => {
  const fromPathSegs = fromPath?.split('/');
  const toPathSegs = toPath?.split('/');

  return (fromPathSegs.includes(routePathSegments.funds_detail_page)
    || toPathSegs.includes(routePathSegments.funds_detail_page))
    && (fromPathSegs.includes(routePathSegments.pe_vc_funds)
      || toPathSegs.includes(routePathSegments.pe_vc_funds)
      || (fromPathSegs.includes(routePathSegments.vc_funds)
        || toPathSegs.includes(routePathSegments.vc_funds)))
}

const isLPMatch = (fromPath, toPath) => {
  const fromPathSegs = fromPath?.split('/');
  const toPathSegs = toPath?.split('/');

  return (fromPathSegs.includes(routePathSegments.pe_vc_limited_partners)
    || toPathSegs.includes(routePathSegments.pe_vc_limited_partners))
    && (fromPathSegs.includes(routePathSegments.limited_partners)
      || toPathSegs.includes(routePathSegments.limited_partners))
}

const isCfsMatch = (fromPath, toPath) => {
  const fromPathSegs = fromPath?.split('/');
  const toPathSegs = toPath?.split('/');

  const isFrpmPathCompanies = matchPath(`/${routePathSegments.cfs}`, fromPath)
  const isToPathCompanies = matchPath(`/${routePathSegments.cfs}`, toPath)

  return (fromPathSegs.includes(routePathSegments.company)
    || toPathSegs.includes(routePathSegments.company))
    && (isFrpmPathCompanies != null
      || isToPathCompanies != null)
}

const isReFundMatch = (fromPath, toPath) => {
  const fromPathSegs = fromPath?.split('/');
  const toPathSegs = toPath?.split('/');

  return (fromPathSegs.includes(routePathSegments.re_funds)
    || toPathSegs.includes(routePathSegments.re_funds))
    && (fromPathSegs.includes(routePathSegments.re_funds_detail)
      || toPathSegs.includes(routePathSegments.re_funds_detail))
}


/**
 * The `AppLayout` function is a React component that renders the layout of the application, including
 * the header, sidebar, main content area, and chat popup.
 * @returns The `AppLayout` component is returning a JSX element.
 */
function AppLayout() {

  const { open, openRequestPopup } = useContext(RaiseRequestContext)
  const location = useLocation();
  const { setrefineSearchFilters, setMonthPickerValue ,setGroupId } = useContext(PeFilterContext)
  const { setCurrentPage, forExRate, setForExRate, setForExMonthlyRate } = useContext(TableDataContext);
  const peMatch = useMatch({ path: `${routePathSegments.pe}/*` })
  const notesMatch = useMatch({path:`${routePathSegments.notes}/*`})
  const vcMatch = useMatch({ path: `${routePathSegments.vc}/*` })
  const reMatch = useMatch({ path: `${routePathSegments.re}/*` })
  const maMatch = useMatch({ path: `${routePathSegments.ma}/*` })
  const cfsMatch = useMatch({ path: `${routePathSegments.cfs}/*` })
  const faqMatch = useMatch({ path: `${routePathSegments.faq}/*` })
  
  // console.log('location.state-12', location.state);

  const locationRef = useRef({
    to: location.pathname,
    from: location.pathname
  })
  const [locationState, setLocationState] = useState(locationRef.current)

  const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
  const allowedModulesList = JSON.stringify(allowedModulesStr) ?? [];
  // console.log("allowedModulesListallowedModulesList", allowedModulesList, peMatch);
  const [searchParam] = useSearchParams();
  const {setIsStudent,isTrialExport,setIsTrialExport, setIsDirectoryUser} = useContext(UserContext)

  const type = searchParam.get('type')

 /**
  * The function `redirectionPath` returns a specific route name based on the current path and a list
  * of allowed modules.
  * @param currentPath - The `currentPath` parameter is a string that represents the current path or
  * route in the application.
  * @returns The function `redirectionPath` returns a string value.
  */
  const redirectionPath = (currentPath) => {
    if (allowedModulesList.includes("pe") && currentPath !== "pe") {
      return routeNames.pe_companies_pe_vc_investments
    } else if (allowedModulesList.includes("vc") && currentPath !== "vc") {
      return routeNames.vc_companies_vc_investments
    } else if (allowedModulesList.includes("ma") && currentPath !== "ma") {
      return routeNames.ma_companies
    } else if (allowedModulesList.includes("re") && currentPath !== "re") {
      return routeNames.re_companies_re_investments
    } else if (allowedModulesList.includes("cfs") && currentPath !== "cfs") {
      return routeNames.cfs
    } else {
      return ""
    }
  }

  /**
   * The getView function checks if a certain module is allowed and returns the appropriate component
   * based on the module.
   * @returns The function `getView` returns a React component. The component returned depends on the
   * conditions specified in the code. If `peMatch` is not null and "pe" is included in the
   * `allowedModulesList`, it returns the `<Outlet />` component. If `peMatch` is not null but "pe" is
   * not included in the `allowedModulesList`, it returns the `<SubscribeProduct />
   */
  const getView = () => {
    if (peMatch != null) {
      if (allowedModulesList.includes("pe")) {
        return <Outlet />;
      } else {
        return <SubscribeProductPage to={redirectionPath('pe')} />
      }
    } else if (vcMatch != null) {
      if (allowedModulesList.includes("vc") || allowedModulesList.includes("pe")) {
        return <Outlet />;
      } else {
        return <SubscribeProductPage to={redirectionPath('vc')} />
      }
    } else if (cfsMatch != null) {
      if (allowedModulesList.includes("cfs")) {
        return <Outlet />;
      } else {
        return <SubscribeProductPage to={redirectionPath('cfs')} />
      }
    } else if (maMatch != null) {
      if (allowedModulesList.includes("ma")) {
        return <Outlet />;
      } else {
        return <SubscribeProductPage to={redirectionPath('ma')} />
      }
    } else if (reMatch != null) {
      if (allowedModulesList.includes("re")) {
        return <Outlet />;
      } else {
        return <SubscribeProductPage to={redirectionPath('re')} />
      }
    }
    else {
      return <Outlet />;
    }
  }

/**
 * The function `shouldPreserveRefinesearchState` checks if we should preserve refine search data
 */
  const shouldPreserveRefinesearchState = () => {
    if (location?.state?.globalSearch != null) {
      return false
    }
    // console.log('isFundMatch(locationRef.current.from, locationRef.current.to)', isFundMatch(locationRef.current.from, locationRef.current.to));
    if (isFundMatch(locationRef.current.from, locationRef.current.to)
      || isCompanyMatch(locationRef.current.from, locationRef.current.to)
      || isInvestorMatch(locationRef.current.from, locationRef.current.to)
      || isAdvisorMatch(locationRef.current.from, locationRef.current.to)
      || isTransactionAdvisorMatch(locationRef.current.from, locationRef.current.to)
      || isAcquirerMatch(locationRef.current.from, locationRef.current.to)
      || isLPMatch(locationRef.current.from, locationRef.current.to)
      || isCfsMatch(locationRef.current.from, locationRef.current.to)
      || isActiveNewInvestorMatch(locationRef.current.from, locationRef.current.to)
      || isReFundMatch(locationRef.current.from, locationRef.current.to)) {
      return true
    } else {
      return false
    }

    // return isMatch(locationRef.current.from, routePathSegments.company)
    //   || isMatch(locationRef.current.to, routePathSegments.company)
    //   || isMatch(locationRef.current.from, routePathSegments.investor)
    //   || isMatch(locationRef.current.to, routePathSegments.investor)
    //   || isMatch(locationRef.current.from, routePathSegments.advisor)
    //   || isMatch(locationRef.current.to, routePathSegments.advisor)
    //   || isMatch(locationRef.current.from, routePathSegments.transaction_advisor)
    //   || isMatch(locationRef.current.to, routePathSegments.transaction_advisor)
    //   || isMatch(locationRef.current.from, routePathSegments.acquirer)
    //   || isMatch(locationRef.current.to, routePathSegments.acquirer)
    //   || isMatch(locationRef.current.to, routePathSegments.funds_detail_page)
    //   || isMatch(locationRef.current.from, routePathSegments.funds_detail_page)

  }

  // console.log('location-12', location);


/**
 * The function `initialSearchFn` retrieves and parses data from local storage, removes it, and returns
 * an object with the initial search and date range values.
 * @returns The function `initialSearchFn` returns an object with properties `initialSearch` and
 * `dateRange`. If there is a value stored in the `Constants.localStorageKeys.referer` key in the
 * localStorage, it retrieves the value, parses it as JSON, removes the value from localStorage, and
 * returns an object with properties `initialSearch` and `dateRange` based on the parsed data. If
 */
  const initialSearchFn = () => {
    const initialState = localStorage.getItem(Constants.localStorageKeys.referer);
    if (initialState) {
      const data = JSON.parse(initialState)
      localStorage.removeItem(Constants.localStorageKeys.referer);
      return {
        initialSearch: data?.initialSearch,
        dateRange: data?.dateRange
      }
    }

    return {}
  }

  useEffect(() => {
    locationRef.current = { to: location.pathname, from: locationRef.current?.to }
    const referer = localStorage.getItem(Constants.localStorageKeys.referer);
    setLocationState(locationRef.current)
    if (!shouldPreserveRefinesearchState()) {
      if (location?.state?.globalSearch != null) {
        setrefineSearchFilters(prev => ({
          ...prev,
          [location.pathname]: {
            ...prev[location.pathname],
            globalSearch: location?.state?.globalSearch
          },
        }))
        setMonthPickerValue(location.state?.globalMonthRange)
      } else if(location?.state?.filterData != null || location?.state?.myList != null ){
        let filter = location?.state?.filterData;
        let myList = location?.state?.myList;
        console.log(location?.state?.monthRange,'location?.state?.monthRange');
        const monthRange = location?.state?.monthRange ?? Constants.initialMonthRange;
        setMonthPickerValue(monthRange)
        
        setrefineSearchFilters(prev => ({
          ...prev,
          [location.pathname]: {
            ...prev[location.pathname],
            ...filter
          },
        }))

        setGroupId(myList)
          
      } else if(location?.state?.initialSearch||referer){
        //to apply initial search
        if (location?.state?.initialSearch) {
          setrefineSearchFilters(prev => ({
            ...prev,
            [location.pathname]: location?.state?.initialSearch,
          }))
        } else if (referer) {
          const { dateRange, initialSearch } = initialSearchFn()
          if(initialSearch){
            setrefineSearchFilters(prev => ({
              ...prev,
              [location.pathname]: initialSearch,
            }))
          }

          if(dateRange){
            setMonthPickerValue(dateRange)
          }
        }
          
      }else {
       
        setrefineSearchFilters(prev=>{

          const fromPathSegs = locationRef.current.from?.split("/").filter(path=>path !== "")
          const toPathSegs = locationRef.current.to?.split("/").filter(path=>path !== "")


         /* The above code is checking if the current location is either entering or leaving a limited
         partners page or a funds detail page. It uses the `locationRef.current` object to check the
         current and previous locations, and the `routePathSegments` object to check if the specific
         route segments are present in the locations. The code if the user is entering or leaving the
         specified pages. */
          const limitedPartnerLeaveOrEnter = (
            locationRef.current.to.includes(routePathSegments.limited_partners)
            &&
            !locationRef.current.from.includes(routePathSegments.limited_partners)
          )
            ||
            (
              locationRef.current.from.includes(routePathSegments.limited_partners)
              &&
              !locationRef.current.to.includes(routePathSegments.limited_partners)
            )

            const fundsLeaveOrEnter = (
              locationRef.current.to.includes(routePathSegments.funds_detail_page)
              &&
              !locationRef.current.from.includes(routePathSegments.funds_detail_page)
            )||
              (
                locationRef.current.from.includes(routePathSegments.funds_detail_page)
                &&
                !locationRef.current.to.includes(routePathSegments.funds_detail_page)
              )

          
          //check if global search applied or not
          if(prev[locationRef.current.from]?.globalSearch){
            
            //if applied check if product is same and not limited partner and funds
            if((fromPathSegs[0]===toPathSegs[0]) && limitedPartnerLeaveOrEnter === false && fundsLeaveOrEnter === false){
              
              //if product is same we only retain global search
              return {
                [location.pathname]: {
                  globalSearch:prev[locationRef.current.from]?.globalSearch
                },
              }
            }else{

              //if not same product then we reset month range and filter
              setMonthPickerValue(Constants.initialMonthRange)
              return {}
            }
          }else{
             //if global search not applied then we reset month range and filter
            setMonthPickerValue(Constants.initialMonthRange)
            return {}
          }
        })
        
      }
      window.history.replaceState({}, document.title)
      setCurrentPage(1);
      if(location?.state?.date !=null){
        const monthRange = location?.state?.date;
        setMonthPickerValue(monthRange)
      }
    }
  }, [location.pathname, location.state?.globalMonthRange, location.state?.globalSearch, setMonthPickerValue, setrefineSearchFilters, setCurrentPage])


  useEffect(() => {
    // ForEx rates for new CFS page
    const forExRates = () => {
    fetch("https://api-hub.ventureintelligence.com/private/common/forex-rate/").then((res) =>{
      if(!res.ok){
        throw new Error('Api failed')
      }
      return res.json()
    }).then((res) => {
          setForExRate(res.forex_data);
          console.log(res, 'from appl')
        })
    }
    const getConvertionRates = () => {
      axios.get(getForExRates).then((response) =>{
        setForExMonthlyRate(response.data.forex_data)
        console.log(response.data, 'fetching or not')
      }).catch((error) => {
        console.log(error, 'Error Fetching Convertion Rates')
      })
    }
    forExRates();
    getConvertionRates();
  }, [])

  
  useEffect(()=>{
    const Student = localStorage.getItem(Constants.localStorageKeys.studentLogin)
    const isexport = localStorage.getItem(Constants.localStorageKeys.isExport)
    const isDirectoryUser = localStorage.getItem(Constants.localStorageKeys.isDirectoryUser);
    console.log(isexport,'isExport');
      setIsStudent(Student)
      setIsTrialExport(isexport)
      setIsDirectoryUser(["true", true].includes(isDirectoryUser));
    },[])
        
    // console.log(isTrialExport,'isExport');

    // console.log("loc-634334343533",locationRef.current.to, location.pathname);

  return (
    <div className="bg-gray-100">
      <div className="min-h-screen flex flex-col">
        {/* Top nav*/}
        {
          peMatch !== null || type == 1 ? <PEHeader />
            : vcMatch !== null || type == 2 ? <VCHeader />
              : reMatch !== null ? <REHeader />
                : maMatch !== null || type == 4 ? <MAHeader />
                  : cfsMatch !== null || type == 5 ? <CfsHeader />
                    : faqMatch !== null || notesMatch !== null ? <FaqHeader />
                      : <Header />
        }
        {/* Bottom section */}
        <div className=" min-h-0 overflow-hidden">
          {/* Narrow sidebar*/}
          <div className="hidden md:block	">
            <SideMenu />
          </div>

          {/* Main area */}
          <main className="lg:ml-[5rem] md:ml-[5.25rem] ">
            {locationState.to === location.pathname? getView():null}
            {/* Chat popup */}
            <div className="fixed right-1 bottom-0 raise-request-btn z-10">
              <img alt='' className="float-right w-[4rem] h-[4rem] cursor-pointer" src={chatPopupIcon} onClick={() => openRequestPopup(faqMatch != null ? "faq" : location.pathname=='/setting' ? 'subscription':'')} />
              {open && (
                <div>
                  <ChatPopup product={peMatch != null ? "PE" : maMatch != null ? "M&A" : reMatch != null ? "RE" : cfsMatch != null ? "CFS" : faqMatch != null ? "FAQ" : "Other"} />{" "}
                </div>
              )}
            </div>
          </main>

        </div>
      </div>
      <ToastContainer
        position={"top-center"}
        autoClose={3000}
        toastClassName="bg-white shadow-lg rounded-lg flex items-center"
        bodyClassName="text-sm font-medium text-gray-900"
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      // className='bg-success text-white'
      />
    </div>
  )
}

export default AppLayout