import { React, useState, useContext, useRef } from "react";
import { useParams, matchPath, useLocation } from "react-router-dom";
import ReactReadMoreReadLess from "react-read-more-read-less";
import compare from "../../images/compare.png";
import ComparePopUp from "../../Pages/ComparePopUp";
import { CompareCompanyContext } from "../../Contexts/CompareCompanyContext";
// import ExcelPopup from "../../Components/ExcelPopup";
import AddNote from "../../Pages/addNote";
import { descriptionCharLimit, exportPdf } from "../../Config/config";
import CompanyLogo from "../NewLogo";
import RandomLogo from "../RandomLogo";
import AlertPopUp from "./AlertPopUp";
import PENotesPopup from "../../Components/NotesPopup/PENotesPopup";
import SendDealPopUp from "../SendDealPopUp";
import XLSPopup from "./DownloadXLS";
import PdfDownloader from "../shared/PdfDownloader";
import { RaiseRequestContext } from "../../Contexts/RaiseRequestContext";
import Constants from "../../Constants";
import { Tooltip } from "antd";
import { CurrencyContext } from "../../Contexts/CurrencyContext";
import routePathSegments from "../../routeSegments";
import AddNotePopover from "../shared/AddNotePopover";
import useModule from "../../hooks/useModule";
import { UserContext } from "../../Contexts/UserContext";
import ViewMoreViewLess from "../CFS/ViewMoreViewLess";

/**
 * The `CompanyDetailInformation` function is a React component that displays detailed information
 * about a company, including tags, a long text description.
 * @param props - The `props` parameter is an object that contains the data and functions passed down
 * to the `CompanyDetailInformation` component from its parent component. It is used to access and
 * manipulate the data and functions within the component.
 */
const CompanyDetailInformation = (props) => {
  
  const { setCompanyDetail, setCurrentPage } = useContext(
    CompareCompanyContext
  );
  const { currencyType, currency, isDetailed } = useContext(CurrencyContext);
  const { openRequestPopup } = useContext(RaiseRequestContext);
  
  const [showAdd,//  setShowAdd
  ] = useState(false);
  const { companyId } = useParams();
  let companyProfileId = parseInt(companyId);
  const [showModal, setShowModal] = useState(false);
  const [
    note,
    // setNote
  ] = useState(false);
  const location = useLocation();
  const isPeMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);
  const { isMA, isPE, isVC } = useModule();
  const { isStudent } = useContext(UserContext);

  const tagsList = props?.data?.profile?.tags?.split(",").map((tag) => {
    return (
      <li className="mt-2 lg:mt-0 nav-item flex active " role="presentation">
        {tag ? (
          <a className=" lg:block text-[10.7pt] leading-[17pt] lg:leading-[16px] font-sans_book_body px-3 mr-2 py-1 text-center text-[#333333] bg-[#EFEFEF] lg:text-[12px] rounded-[5px]">
            {tag}
          </a>
        ) : null}
      </li>
    );
  });

  const menuRef = useRef();

  const charLength = 400;

  const desc = props?.data?.cfs_profile !== null ? props?.data?.cfs_profile?.business_description : '';

  const subTypeMasterId = () => {
    if (isPE) {
      if (
        props?.section == Constants.primaryMasterSubIds.pe_vc_investments_id
      ) {
        return Constants.subCategoryIds.pe_investments_id;
      } else if (
        props?.section == Constants.primaryMasterSubIds.pe_vc_exits_id
      ) {
        return Constants.subCategoryIds.pe_exits_id;
      } else if (props?.section == Constants.primaryMasterSubIds.pe_vc_ma_id) {
        return Constants.subCategoryIds.pe_exitsma_id;
      } else if (props?.section == Constants.primaryMasterSubIds.pe_vc_pm_id) {
        return Constants.subCategoryIds.pe_exitspm_id;
      } else if (
        props?.section == Constants.primaryMasterSubIds.pe_vc_backed_ipos_id
      ) {
        return Constants.subCategoryIds.pe_backed_ipos_id;
      } else if (
        props?.section ==
        Constants.primaryMasterSubIds.pe_vc_angel_invesments_id
      ) {
        return Constants.subCategoryIds.pe_angel_invesments_id;
      } else if (
        props?.section == Constants.primaryMasterSubIds.pe_vc_incubation_id
      ) {
        return Constants.subCategoryIds.pe_incubation_id;
      }
    } else if (isVC) {
      if (props?.section == Constants.primaryMasterSubIds.vc_investments_id) {
        return Constants.subCategoryIds.vc_investments_id;
      } else if (
        props?.section == Constants.primaryMasterSubIds.pe_vc_exits_id
      ) {
        return Constants.subCategoryIds.vc_exits_id;
      } else if (props?.section == Constants.primaryMasterSubIds.pe_vc_ma_id) {
        return Constants.subCategoryIds.vc_exitsma_id;
      } else if (props?.section == Constants.primaryMasterSubIds.pe_vc_pm_id) {
        return Constants.subCategoryIds.vc_exitspm_id;
      } else if (
        props?.section == Constants.primaryMasterSubIds.pe_vc_backed_ipos_id
      ) {
        return Constants.subCategoryIds.vc_backed_ipos_id;
      } else if (
        props?.section ==
        Constants.primaryMasterSubIds.pe_vc_angel_invesments_id
      ) {
        return Constants.subCategoryIds.vc_angel_invesments_id;
      } else if (
        props?.section == Constants.primaryMasterSubIds.pe_vc_incubation_id
      ) {
        return Constants.subCategoryIds.vc_incubation_id;
      }
    } else if (isMA) {
      return Constants.subCategoryIds.ma_companies_id;
    }
  };

  return (
    <>
      <div>
        {/* <!-- BarRaiser--> */}
        <div className="pl-5 pr-3">
          <div className=" bg-[#FFFFFF]">
            <div className="lg:hidden mt-3  grid  md:grid-cols-8  grid-cols-10  items-center mb-4 ">
              <div className=" md:col-span-6 col-span-5">
                <Tooltip title="Compare Company">
                  <button
                    className={
                      showModal
                        ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                        : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                    }
                    type="button"
                    onClick={() => {
                      setShowModal(!showModal);
                      setCompanyDetail([]);
                      setCurrentPage("company");
                    }}
                  >
                    <img
                      className={
                        showModal
                          ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                          : "w-[20px] h-[18px] pr-1 blur-btn"
                      }
                      src={compare}
                      alt=""
                    />
                    <span>Compare</span>
                  </button>
                </Tooltip>
              </div>
              <div className="flex md:col-span-2 col-span-5 space-x-3">
                <div
                  className={
                    "grid grid-cols-5  gap-3 md:gap-2 lg:gap-1 items-center"
                  }
                >
                  <div className="col-span-1 ">
                    <AlertPopUp cid={props.id} type_id={1} />
                  </div>
                  <div className="col-span-1" ref={menuRef}>
                    <span>
                      <Tooltip title="Save List">
                        <AddNotePopover
                          companyId={props.id}
                          companyName={props?.data?.profile?.cname}
                          type_id={Constants.moduleIds.companiesId}
                          sub_type_master_id={subTypeMasterId()}
                        />
                      </Tooltip>
                    </span>
                  </div>
                  <div className="col-span-1 mt-1">
                    <PENotesPopup
                      note={note}
                      cid={props.id}
                      type_id={Constants.moduleIds.companiesId}
                    />
                  </div>

                  <div className="col-span-1">
                    <SendDealPopUp data={props?.data?.profile?.cname} />
                  </div>
                  {isStudent == "true" ? (
                    <></>
                  ) : (
                    <div className="col-span-1">
                      <PdfDownloader
                        url={exportPdf}
                        fileName={props?.data?.profile?.cname?.replaceAll(
                          /\s/g,
                          ""
                        )}
                        data={{
                          entity_id: props.id,
                          type_id: Constants.moduleIds.companiesId,
                          is_detailed: isDetailed,
                          currency_cd: currencyType,
                          display_in: currency,
                          type:
                            props.type == "pe"
                              ? Constants.primaryMasterIds.peId
                              : props.type == "vc"
                              ? Constants.primaryMasterIds.vcId
                              : props.type == "re"
                              ? Constants.primaryMasterIds.reId
                              : props.type == "ma"
                              ? Constants.primaryMasterIds.mANDaId
                              : props.type == "cfs"
                              ? Constants.primaryMasterIds.cfsId
                              : 6,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <ul role="list">
              {Object.keys(props?.data?.profile ?? {}).length > 0 ? (
                <>
                  <li className="w-full flex pt-6 bg-[#FFFFFF]">
                    <div className=" rounded-md">
                      <CompanyLogo
                        name={props?.cname}
                        id={props?.id}
                        menu="pe"
                        page="detail page"
                      />
                    </div>

                    <div className="ml-3 flex flex-1 flex-col">
                      <p className="text-[#333333] text-[22px] font-semibold leading-[24px]">
                        {props?.cname}{" "}
                        {props?.data?.cfs_profile?.full_name && 
                          <span className="text-[18px] leading-[20px]">
                            ({props?.data?.cfs_profile?.full_name})
                          </span>
                        }
                      </p>
                      <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body ">
                        {props.data?.profile?.industry}
                      </span>

                      <span className="text-[11pt] leading-[15pt]  text-[#777777] lg:text-[13px] font-sans_book_body lg:leading-[21px]">
                        {props?.data?.profile?.sector}
                      </span>
                    </div>

                    <div className="hidden lg:grid grid-cols-8 gap-2 ">
                      <div className="col-span-3 mt-2">
                        <Tooltip title="Compare Company">
                          <button
                            className={
                              showModal
                                ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                                : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                            }
                            type="button"
                            onClick={() => {
                              setShowModal(!showModal);
                              setCompanyDetail([]);
                              setCurrentPage("company");
                            }}
                          >
                            <img
                              className={
                                showModal
                                  ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                  : "w-[20px] h-[18px] pr-1 blur-btn"
                              }
                              src={compare}
                              alt=""
                            />
                            <span>Compare</span>
                          </button>
                        </Tooltip>
                      </div>
                      <div className="col-span-5 mt-[5px]">
                        <div className={"grid grid-cols-5 gap-3 items-center"}>
                          <div className="col-span-1 ">
                            <AlertPopUp
                              cid={props.id}
                              type_id={1}
                              currentDealId={props?.currentDealId}
                            />
                          </div>
                          <div className="col-span-1" ref={menuRef}>
                            <span>
                              <AddNotePopover
                                title="Save List"
                                companyId={props.id}
                                companyName={props?.data?.profile?.cname}
                                type_id={Constants.moduleIds.companiesId}
                                sub_type_master_id={subTypeMasterId()}
                                IsDetailed={true}
                              />
                            </span>
                          </div>
                          <div className="col-span-1 mt-1">
                            <PENotesPopup
                              note={note}
                              cid={props.id}
                              type_id={Constants.moduleIds.companiesId}
                            />
                          </div>
                          <div className="col-span-1">
                            <SendDealPopUp data={props?.data?.profile?.cname} />
                          </div>
                          {isStudent == "true" ? (
                            <></>
                          ) : (
                            <div className="col-span-1">
                              <PdfDownloader
                                url={exportPdf}
                                fileName={props?.data?.profile?.cname?.replaceAll(
                                  /\s/g,
                                  ""
                                )}
                                data={{
                                  entity_id: props.id,
                                  type_id: Constants.moduleIds.companiesId,
                                  is_detailed: isDetailed,
                                  currency_cd: currencyType,
                                  display_in: currency,
                                  type:
                                    props.type == "pe"
                                      ? Constants.primaryMasterIds.peId
                                      : props.type == "vc"
                                      ? Constants.primaryMasterIds.vcId
                                      : props.type == "re"
                                      ? Constants.primaryMasterIds.reId
                                      : props.type == "ma"
                                      ? Constants.primaryMasterIds.mANDaId
                                      : props.type == "cfs"
                                      ? Constants.primaryMasterIds.cfsId
                                      : 6,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    {props?.data?.profile?.tags &&
                      props?.data?.profile?.tags != "" && (
                        <div className="bg-[#FFFFFF] mt-4">
                          <ul
                            className="nav nav-tabs flex flex-wrap  list-none border-b-0 pl-0 pb-2"
                            id="tabs-tab"
                            role="tablist"
                          >
                            {tagsList}
                          </ul>
                        </div>
                      )}
                    <div>
                      {props?.data?.cfs_profile?.length !== 0 && (
                          <p className="mt-3 whitespace-pre-line text-[11pt] leading-[18pt] py-1  lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                            <ViewMoreViewLess>
                              {desc}
                            </ViewMoreViewLess>
                          </p>
                        )}
                    </div>
                  </li>
                </>
              ) : (
                <div className="w-full bg-[#FFFFFF]">
                  <center>
                    <div role="status" className=" h-[8rem]">
                      <svg
                        className="relative top-[4rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </div>
                  </center>
                </div>
              )}
              {showAdd ? <AddNote id={companyProfileId} /> : ""}
            </ul>
          </div>
          {/* <!-- tags--> */}
        </div>
      </div>
      {showModal ? (
        <ComparePopUp id={1} onClose={(e) => setShowModal(false)} />
      ) : null}
    </>
  );
};

export default CompanyDetailInformation;
