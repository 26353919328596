import { React, useContext, useEffect, useState ,useMemo} from 'react'
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { AcquirerDetail } from "../../Config/config"
import Profile from './Profile'
import Acquistions from './Acquistions'
import NewsActivity from './NewsActivity'
import ComparePopUp from '../../Pages/ComparePopUp'
import Constants from '../../Constants';
import { UserContext } from '../../Contexts/UserContext';
import axios from 'axios';
import AcquirerDetailInformation from './AcquirerDetailInformation';
import { routeNames } from '../../routeSegments';
import Tab from '../shared/Tab';
import CurrencyContextProvider from '../../Contexts/CurrencyContext';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { TableDataContext } from '../../Contexts/TableDataContext';
import { Tooltip } from 'antd';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { DataFoundContext } from '../../Contexts/DataFoundContext';


/**
 * The AcquirersDetail component is a React component that displays information about a specific
 * acquirer, including an overview, acquisitions, and recent news and activity.
 */
const AcquirersDetail = () => {
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' }); }
    const acquistions = () => { document.getElementById("acquistions").scrollIntoView({ behavior: 'smooth' }); }    
    const newsActivity = () => { document.getElementById("news").scrollIntoView({ behavior: 'smooth' }); }
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const [profileData, setProfileData] = useState({});
    const { getToken, logout } = useContext(UserContext);
    const location = useLocation();
    const { acquirerId } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [skipScroll, setSkipScroll] = useState(false)
    const { newsAndActivityDataFound, setNewsAndActivityDataFound} = useContext(DataFoundContext);

    const [overviewEl, isOverviewVisible, overviewEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [acquisitionEl, isAcquisitionVisible, acquisitionEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, newsEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
   

    useEffect(() => {
        setIsLoading(true);
        const abortController = new AbortController()
        axios(`${AcquirerDetail}`, {
            method: "POST",
            data: {
                acquirer_id: [acquirerId],
                type: Constants.primaryMasterIds.mANDaId
            },
            signal: abortController.signal, 
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                setIsLoading(false);
                if (response?.data?.status== 200) {                
                    console.log("acquirerProfileData", response);
                    setProfileData(response.data?.acquirer_details);
                }
                if(!response.data?.acquirer_details?.recent_news || response.data?.acquirer_details?.recent_news?.length === 0){
                    setNewsAndActivityDataFound(false);
                } else {
                    setNewsAndActivityDataFound(true);
                } 
            })
            .catch((error) => {
                setNewsAndActivityDataFound(false);
                if (error?.response?.status == Constants.sessionTerminatedStatus) {
                    logout()
                }
                console.log("API not working", error);
            });

        return () => {
            abortController.abort()
            setNewsAndActivityDataFound(false);
        }
    }, [acquirerId, getToken, logout])

    /**
     * The function `onTabClick` sets the active tab index, calls different functions based on the tab
     * index, and sets a flag to skip scrolling temporarily.
     */
    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview();
        } else if (tabIndex === 2) {
            acquistions();       
        } else if (tabIndex === 3) {
            newsActivity();
        }

        setIsActive(tabIndex);
    }


    /* The `setIsActiveOnScroll` constant is using the `useMemo` hook to memoize a debounced version of
    the `setIsActive` function. */
    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])
    


    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } else if (isAcquisitionVisible) {
            setIsActiveOnScroll(2);
        } else if (isnewsVisible) {
            setIsActiveOnScroll(3);
        }
    }, [isOverviewVisible, isAcquisitionVisible,  isnewsVisible,  setIsActiveOnScroll])

    const { tableAcquirerId: acqId } = useContext(TableDataContext);

    const currentIndex = acqId?.indexOf(Number(acquirerId));

    document.title = `${profileData?.top_profile?.acquirer_name ? profileData?.top_profile?.acquirer_name + " - " : ""}M&A Acquirers - Venture Intelligence`;

    return (
      
         <CurrencyContextProvider>
         <div className="bg-[#FFFFFF] relative">
             <nav className="flex p-1 border-b " aria-label="Breadcrumb">
                 <ol className="flex items-center space-x-1">
                     <li className='flex items-center'>
                         <div className='flex items-center'>
                             <NavLink state={location.state?.prevPath} to={location.state?.prevPath != null && location.state?.prevPath !=""
                                 ? location.state?.prevPath
                                 : routeNames.ma_acquires}
                                 className="ml-2 text-[11pt] lg:text-[12px] text-[#666666] font-sans_book_body">
                                 Acquirers
                             </NavLink>
                         </div>
                     </li>
                     <li className="flex items-center"><div className="flex items-center"><span className=" text-[12px] text-[#666666] mr-1">/</span><span className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold" aria-current="page">{profileData?.top_profile?.acquirer_name}</span></div></li>
                 </ol>
             </nav>
             {acqId && 
                <>
                    <Tooltip title="Prev">
                            <NavLink
                            className="absolute top-2 right-8"
                            to={routeNames.maAcquiresById(acqId[currentIndex - 1])}
                            >
                            {acqId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next">
                            <NavLink
                            className="absolute top-2 right-4"
                            to={routeNames.maAcquiresById(acqId[currentIndex + 1])}
                            >
                            {acqId.length - 1 === currentIndex ? null : (
                                <FaChevronRight size={15} color='grey'/>
                            )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
             <AcquirerDetailInformation data={profileData?.top_profile} id={acquirerId} page="companyPage" cname={profileData?.top_profile?.acquirer_name} type="ma"/>

             <div className="bg-[#EDEDED]">
                 <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index">
                    <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />                     
                    <Tab active={isActive === 2} label={"Acquisitions"} onClick={() => onTabClick(2)} />                     
                    {newsAndActivityDataFound ? <Tab active={isActive === 3} label={"Recent News & Activity"} onClick={() => onTabClick(3)} /> : ""}
                 </div>
                 <div className="bg-[#EDEDED] h-full space-y-5">

                     <section ref={overviewEl} className='scroll-mt-14' id="profile"><Profile isLoading={isLoading} profile={profileData?.acquirer_profile}/></section>
                    <section  ref={acquisitionEl} className='scroll-mt-14' id="acquistions"><Acquistions isLoading={isLoading} acquirers={profileData?.acquistions_funds} acquirerName={profileData?.top_profile?.acquirer_name}/> </section>
                    <section ref={newsEl} className='scroll-mt-14' id="news"><NewsActivity isLoading={isLoading} newsletter={profileData?.recent_news}/></section>

                 </div>
             </div>
             {showModal ? (<ComparePopUp id={acquirerId} onClose={(e) => setShowModal(false)} />) : null}
         </div>
     </CurrencyContextProvider>

    )

}

export default AcquirersDetail