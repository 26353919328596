import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import axios from 'axios';


function TargetGroupSelect({ handleGroupChange,filterGroupUrl, targetGroupOptions , setTargetGroupOptions }) {

    const [searchText, setSearchText] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    // console.log(filteredOptions ,"gjhbjtjyhjh" , targetGroupOptions)
    if(targetGroupOptions.length === 0) { 
        const fetchGroupData = async () => {
            try {
                const response = await axios.post(filterGroupUrl);
                setTargetGroupOptions(response.data);
            } catch (error) {
                console.error('Error fetching target group options:', error);
            }
        };

        fetchGroupData();
    }

    useEffect(() => {
        setFilteredOptions(targetGroupOptions.map(option => option.name));
    }, [targetGroupOptions]);

    const handleSearchTextChange = (value) => {
        setSearchText(value);
        setFilteredOptions(targetGroupOptions.filter(option =>  !option.isChecked && option.name.toLowerCase().includes(value.toLowerCase())).map(option => option.name ));
    };
    const selectedItems = Object.values(targetGroupOptions?.filter((option) => option.isChecked))?.map(name => name.name);

    return (
        <>
            <Select
                mode="multiple"
                style={{
                    width: '15rem',
                    borderBottom: '1px solid #BC8B20',
                    background: '#ffffff',
                    height: '30px',
                    maxHeight: '40px',
                    overflowY: 'auto',
                    minWidth: '10rem',
                }}
                placeholder="Search"
                onChange={handleGroupChange}
                filterOption={false}
                className="text-[#333333] AdvanceFilterSearch"
                bordered={false}
                onSearch={handleSearchTextChange}
                notFoundContent={
                    searchText === '' || searchText.length < 2 ? null : (
                        <p className="text-black">No results found</p>
                    )
                }
                value={selectedItems}
            >
                { !(searchText === '' || searchText.length < 2) ?
                    filteredOptions.map((option, index) => (
                        <Select.Option key={index} value={option}>
                            {option}
                        </Select.Option>
                    )) : null
                }
            </Select>
        </>
    );
}

export default TargetGroupSelect;
