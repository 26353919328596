import React from "react";
import ExpandedTagList from "../Components/ExpandedTagList";
import { Popover } from "antd";
import FilterTag from "../../FilterTag";

const FilterTags = ({ filtersFromLocalStorage, onClose, deleteFilterByMember }) => {
  console.log({filtersFromLocalStorage});
  return (
    <div className="m-3 flex flex-row gap-2 items-center">
      {filtersFromLocalStorage?.map((obj) =>
        obj.values?.length < 3 ? (
          obj.values.flatMap((value) => (
            <div>
              <FilterTag 
              subTitle={value} onRemove={() => onClose(value)} />
            </div>
          ))
        ) : (
          obj.member && <Popover
            placement="right"
            title={obj.member.split(".")[1].charAt(0).toUpperCase() + obj.member.split(".")[1].slice(1)}
            content={
              <ExpandedTagList tagList={obj} onRemove={onClose} />
            }
          >
            <span>
              <FilterTag
                title={obj.member.split(".")[1].charAt(0).toUpperCase() + obj.member.split(".")[1].slice(1)}
                subTitle={obj.values?`${obj.values[0]} +${obj.values.length - 1} more`:""}
                onRemove={() => deleteFilterByMember(obj.member)}
              />
            </span>
          </Popover>
        )
      )}
    </div>
  );
};

export default FilterTags;
