import React from 'react'
import Layout from '../../Components/InvestorLayout'
import InvestorCompanyDetail from '../../Components/Investor/InvestorDetailPage/InverstorIncubationCompanyDetail'
import RefineSearchBar from '../../Components/RemovedArrowRefineSearch';
import InvestorCard from '../../Components/Investor/InvestorDetailPage/InvestorIncubationDetailCard'
import ChatIcon from '../../images/Group 13/png/Group 13.png';
import chatPopupIcon from '../../images/chatPopupIcon.png'
import ChatPopup from '../../Components/ChatPopup'
import { INVESTOR_COMPANY_PROFILE, INVESTOR_CARD_QUERY } from '../../Queries/Pe'
/**
 * The `InvestorDetailPage` function is a React component that renders a layout with a primary and
 * secondary column, and includes a company detail section and a modal.
 * @returns a JSX element, which represents the structure and content of a web page.
 */
function InverstorDetailPage(props) {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <div>
            <Layout>
                <div className="flex h-screen flex-col">
                    {/* <Card DEATAILPAGE_CARD={DEATAILPAGE_CARD}/> */}
                    {/* <InvestorCard INVESTOR_CARD_QUERY={INVESTOR_CARD_QUERY} /> */}
                    <div className="flex min-h-0 mt-[3.8rem] md:mt-[3.2rem] flex-1 overflow-hidden">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-[#EDEDED]">
                            {/* <CompanyDetail COMPANY_PROFILE={COMPANY_PROFILE}  /> */}
                            <InvestorCompanyDetail COMPANY_PROFILE={INVESTOR_COMPANY_PROFILE} page="incubation" type="Incubation" menu={props.menu}/>

                            {/* <Outlet /> */}
                            {/* Your content */}
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex flex-col overflow-y-auto border-r border-gray-200 bg-[#FAF5EA]">
                               
                                <RefineSearchBar />
                                    
                            </div>
                        </aside>
                    </div>
                    <br />

                </div>
            </Layout>
        </div>
    )
}

export default InverstorDetailPage