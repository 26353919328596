import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';
import Constants from '../../Constants';

/**
 * The `StageFilter` function is a React component that renders a filter for selecting stages, with
 * options that can be expanded or collapsed.
 * @returns a JSX element.
 */
function StageFilter({ open, accordionClick, setStageOptions, StageOptions, customPathname }) {

    const { Stages, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.stage?.map(value => value.id) ?? [];
        setStageOptions(Stages?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [Stages, customPathname, refineSearchFilters, setStageOptions])


    const handleStageChange = ((e) => {
        const { name, checked, id } = e.target;
        if (name === "allSelect") {
            let currentStages = StageOptions.map((stage) =>
                ({ ...stage, isChecked: checked })
            );
            setStageOptions(currentStages);
        } else {
            let currentStages = StageOptions.map((stage) =>
                stage.id === parseInt(id) ? { ...stage, isChecked: checked } : stage
            );
            setStageOptions(currentStages);
        }
    });

    const len = StageOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    count={len}
                    isSelect={false}
                    label="Stage"
                    onClick={accordionClick}
                    open={open}
                    definitionInfo={Constants.stage}
                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleStageChange}
                        primaryEntity={StageOptions}
                    /> : <></>}
                </div>
            </li>
        </div>
    )
}

export default StageFilter