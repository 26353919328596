import { React, useContext, useState, } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import IndustryFilter from "../RefineSearchComponents/IndustryFilter";
import SectorFilter from "../RefineSearchComponents/SectorFilter";
import YearFoundedFilter from "../RefineSearchComponents/YearFoundedFilter";
import InvestorTypeFilter from "../RefineSearchComponents/InvestorTypeFilter";
import InvestorHeadquartersFilter from "../RefineSearchComponents/InvestorHeadquartersFilter";
import FirmTypeFilter from "../RefineSearchComponents/FirmTypeFilter";
import ExitStatusFilterSelect from "../RefineSearchComponents/ExitStatusFilterSelect";
import InvestorIpoSaleFilter from "../RefineSearchComponents/InvestorIpoSaleFilter";
import ReturnMultipleFilter from "../RefineSearchComponents/ReturnMultipleFilter";
import Switch from "../RefineSearchComponents/AndOrToggleSwtich";
import Tags from "../RefineSearchComponents/Tags";
import { useLocation,  useNavigate } from "react-router";
import { useEffect } from "react";
import axios from "axios";
import { filterSectorByIndutry, tagList } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import { TableDataContext } from "../../Contexts/TableDataContext";
import Constants from "../../Constants";
import { Tooltip } from "antd";
import { FaInfoCircle } from "react-icons/fa";
import DefinitionModal from "../TagDefinitionModal";
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import TargetCompany from '../RefineSearchComponents/TargetCompany';
import Investor from "../RefineSearchComponents/Investor";
import LAdvisor from '../RefineSearchComponents/lAdvisor';
import TAdvisor from '../RefineSearchComponents/tAdvisor';
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
/**
 * The function `BackedIPORefineSearch` is a React component that renders a form for refining search
 * filters in a web application.
 * @returns a JSX element, which represents the UI of the component.
 */

function BackedIPORefineSearch({ customPathname }) {
    const {
        setSectors,Sectors,lAdvisorsOptions,setlAdvisorsOptions,tAdvisorsOptions , settAdvisorsOptions,dbDropdown , setDbDropdown ,checkToggleBtn,setToggleDealsBtn,
        onErrorHandler,SwitchState,setSwitchState,dropdown,
        InvestorOptions , setInvestorOptions ,TargetCompanyOptions , setTargetCompanyOptions ,
        setrefineSearchFilters, setMonthPickerValue ,refineSearchFilters} = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const [select, setSelected] = useState(0);
    const navigate = useNavigate();
    const { getToken } = useContext(UserContext);
    // const isMatch = useMatch({ path: routeNames.pe_companies_backed_ipos_pe_vc_investments })
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false


    //local updated options
    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [EndYearOptions, setEndYearOptions] = useState([]);
    const [StartYearOptions, setStartYearOptions] = useState([])
    const [InvestorTypeOptions, setInvestorTypeOptions] = useState([])
    const [HeadquartersOptions, setHeadquartersOptions] = useState([])
    const [FirmTypeOptions, setFirmTypeOptions] = useState([])
    const [ExitStatusOptions, setExitStatusOptions] = useState([])
    const [ReturnMultiple, setReturnMultiple] = useState({ from: "", to: "" })
    const [TagSwitchState, setTagSwitchState] = useState(true)
    const [TagsOptions, setTagsOptions] = useState([])
    const [InvestorSaleOptions, setInvestorSaleOptions] = useState([]);


    // useEffect(() => {
    //     setFirmType(firmTypeResponseData?.investments ?? [])
    // }, [firmTypeResponseData?.investments, setFirmType])

    // useEffect(() => {
    //     setExitStatus(exitStatusResponseData?.exits ?? [])
    // }, [exitStatusResponseData?.exits, setExitStatus])

    // useEffect(() => {
    //     const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

    //     if (ids.length > 0) {
    //         axios.post(filterSectorByIndutry, {
    //             "industry": ids,
    //             category: "ipos"
    //         }, {
    //             headers: {
    //                 "Authorization": getToken()
    //             }
    //         }).then(response => {
    //             if (response.status === 200) {
    //                 setSectors(response.data?.data ?? [])
    //             } else {
    //                 setSectors([])
    //             }
    //         }).catch(onErrorHandler)
    //     } else {
    //         setSectors([])
    //     }

    // }, [IndustriesOptions])

  /**
   * The function `applyFilter` updates the refine search filters based on the selected options.
   */
    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedSectors = Sectors.filter(option => option.isChecked);
        const selectedStartYear = StartYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedEndYear = EndYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedInvestorType = InvestorTypeOptions?.filter(option => option.isChecked);
        const selectedHead = HeadquartersOptions?.filter(option => option.isChecked);
        const selectedFirm = FirmTypeOptions?.filter(option => option.isChecked);
        const selectedExitStatus = ExitStatusOptions?.filter(option => option.isChecked);
        const selectedIPO = InvestorSaleOptions?.filter(option => option.isChecked);
        const selectedOperators = SwitchState;
        const selectedTargetCompany = TargetCompanyOptions?.filter(option => option.isChecked); 
        const selectedInvestor = InvestorOptions?.filter(option => option.isChecked); 
        const selectedlAdvisors = lAdvisorsOptions?.filter(option => option.isChecked);
        const selectedtAdvisors = tAdvisorsOptions?.filter(option => option.isChecked);
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                investorType: selectedInvestorType,
                investorHeadquarters: selectedHead,
                yearFounded: selectedEndYear != null && selectedStartYear != null ? [selectedStartYear, selectedEndYear] : [],
                firmType: selectedFirm,
                operator: TagSwitchState ? 1 : 0,
                ladvisors: selectedlAdvisors,
                tadvisors: selectedtAdvisors,
                deals_operator : selectedOperators,
                investor_id : selectedInvestor ,
                target_company:selectedTargetCompany,
                exitStatus: selectedExitStatus,
                returnMultiple: ReturnMultiple?.from != "" && ReturnMultiple?.to != "" ? [{ id: 0, name: ReturnMultiple?.from }, { id: 1, name: ReturnMultiple?.to }] : [],
                tags: TagsOptions,
                investorSaleInipo: selectedIPO,
            },
        }))
    checkToggleBtn();
    }

  /**
   * The function `resetFilter` resets a specific filter in the `refineSearchFilters` object by setting
   * its value to an empty object.
   */
    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
        setTargetCompanyOptions([]);
        setInvestorOptions([]);
        setSectors([]);
        setlAdvisorsOptions([]);
        settAdvisorsOptions([]);
        setToggleDealsBtn(true);
    }
    useEffect(()=>{
        setToggleDealsBtn(true);
      },[customPathname])

    // Submitting Refine Search Form 
   /**
    * The function `refineSearchFilter` updates search filters, resets the month picker value, sets the
    * current page to 1, applies the filter, navigates to a custom pathname if necessary, and sets the
    * selected state to false.
    * @param event - The event parameter is an object that represents the event that triggered the
    * function. It could be an event like a button click or a form submission.
    */
    const refineSearchFilter = (event) => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        event.preventDefault();
        setCurrentPage(1);
        // if (isMatch == null) {
        //     navigate(routeNames.pe_companies_backed_ipos_pe_vc_investments);
        // }
        applyFilter();
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
        setSelected(false)

    }

  /**
   * The `onResetClick` function resets the current page, filters, and navigates to a custom pathname
   * if it is different from the current location.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is the click event that occurred when the user clicked on a reset
   * button.
   */
    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        // if (isMatch == null) {
        //     navigate(routeNames.pe_companies_backed_ipos_pe_vc_investments);
        // }
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

   /**
    * The function `accordionClick` sets the selected value to either 0 or the provided value.
    * @param val - The `val` parameter is a value that is passed to the `accordionClick` function. It
    * is used to determine whether the accordion should be selected or deselected.
    */
    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    const getSearchTags = async (searchText, type) => {
        try {
        const response = await axios.post(tagList, {
            "tag": searchText,
            "type"    : type

        }, {
            headers: {
            "Authorization": getToken()
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
        } catch (error) {
            return [];
        }
    }


    const fetchData = async () => {
        const tags = await getSearchTags("ALL", "noncompetitor");
        const competitorTags = await getSearchTags("ALL", "competitor")
        console.log(tags, competitorTags, 'promi')
        openTagModal(tags, competitorTags)
    };
    
    const  [showDefinitionModal, setShowDefinitionModal] = useState(false);
    const [defValues, setDefValues] = useState({})
    console.log(defValues, 'showValues');

    const openTagModal = ( non_comp, comp) => {
        setShowDefinitionModal(true);
        setDefValues({
            non_comp: non_comp,
            comp: comp
        })
    }

    const defCloseHandler = () =>{
        setShowDefinitionModal(false);
        setDefValues({})
    }


    const toggleSwitchState = () => {
        setSwitchState(prevState => prevState === "and" ? "or" : "and");
    };

    const handleDbToggle = () => {
        setDbDropdown(!dbDropdown);
    };

    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter">
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333]  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>
                        <div className="flex flex-col">
                            <div className="flex justify-between px-3 items-center pt-0">
                    <div className="flex items-end gap-2">
                                    <p className="text-[#333333] text-[15px] leading-[17px] tracking-[-0.3px]">Tag</p>                        
                        <span className="cursor-pointer" onClick={() => {fetchData()}
                        }>
                            <Tooltip title='Tag List'>
                                    <FaInfoCircle size={12} color="grey"/>
                            </Tooltip>
                        </span>
                    </div>
                                <Switch
                                    SwitchState={TagSwitchState}
                                    setSwitchState={setTagSwitchState}
                                    customPathname={customPathname} />
                            </div>

                            <div className="border-b-[3px] border-[#E8E6E1] bg-white">
                                <div className="bg-white m-3 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
                                    <label className=" flex  rounded ">
                                        <Tags
                                            TagsOptions={TagsOptions}
                                            customPathname={customPathname}
                                            setTagsOptions={setTagsOptions}
                                            getSearchTags={getSearchTags} />
                                    </label>
                                </div>
                            </div>
                        </div>
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                open={select === 1} />
                            <SectorFilter
                                setOptions={setSectors}
                                options={Sectors}
                                label="Sector"
                                accordionClick={() => accordionClick(2)}
                                customPathname={customPathname}
                                open={select === 2} />
                            <YearFoundedFilter
                                EndYearOptions={EndYearOptions}
                                customPathname={customPathname}
                                setEndYearOptions={setEndYearOptions}
                                StartYearOptions={StartYearOptions}
                                setStartYearOptions={setStartYearOptions} />
                            <InvestorTypeFilter
                                InvestorTypeOptions={InvestorTypeOptions}
                                setInvestorTypeOptions={setInvestorTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(14)}
                                open={select === 14} />
                            <InvestorHeadquartersFilter
                                HeadquartersOptions={HeadquartersOptions}
                                setHeadquartersOptions={setHeadquartersOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(15)}
                                open={select === 15} />
                            <InvestorIpoSaleFilter
                                InvestorSaleOptions={InvestorSaleOptions}
                                setInvestorSaleOptions={setInvestorSaleOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(3)}
                                open={select === 3} />
                            <FirmTypeFilter
                                FirmTypeOptions={FirmTypeOptions}
                                setFirmTypeOptions={setFirmTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(16)}
                                dataPath="investments"
                                open={select === 16} />
                            <ExitStatusFilterSelect
                                ExitStatusOptions={ExitStatusOptions}
                                setExitStatusOptions={setExitStatusOptions}
                                customPathname={customPathname}
                                dataPath="exits"
                                accordionClick={() => accordionClick(6)}
                                open={select === 6} />
                            <ReturnMultipleFilter
                                customPathname={customPathname}
                                ReturnMultiple={ReturnMultiple}
                                setReturnMultiple={setReturnMultiple} />

                        </ul>
                        <ul  className='flex pt-3 text-[16px] pl-1 border-t-2 border-[#e8e6e1] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer ' onClick={handleDbToggle}>
                            <span className="mt-[2px]">
                                {dbDropdown? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            </span>
                            <h1 className='font-bold text-[16px] ml-1 '>{customPathname.includes("investors") ? "SHOW INVESTORS BY" : customPathname.includes("companies") ? "SHOW DEALS BY" : "SHOW ADVISORS BY"}</h1>
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dbDropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] mb-3  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]`}>

                                <Investor 
                                    options={InvestorOptions}
                                    setOptions={setInvestorOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(32)}
                                    label="Investor"
                                    open={select === 32}
                                />

                                <TargetCompany 
                                    options={TargetCompanyOptions}
                                    setOptions={setTargetCompanyOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(31)}
                                    label="Company"
                                    open={select === 31}
                                />
                                <LAdvisor 
                                    options={lAdvisorsOptions}
                                    setOptions={setlAdvisorsOptions}
                                    customPathname={customPathname}
                                    label="Legal Advisors"
                                    accordionClick={() => accordionClick(21)}
                                    open={select === 21}
                                />

                                <TAdvisor 
                                    options={tAdvisorsOptions}
                                    setOptions={settAdvisorsOptions}
                                    label="Transaction Advisors"
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(29)}
                                    open={select === 29}
                                />

                        </ul>
                    </main>
                    <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                        <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                            Reset
                        </button>
                        <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                            Filter
                        </button>
                    </div>
                    {/* reset button */}
                </form>
            </div>
            {showDefinitionModal && <DefinitionModal values={defValues} onClose={defCloseHandler}/>}
        </div>
    )
}

export default BackedIPORefineSearch