import { useEffect, useContext } from 'react'
import './MonthPickerComponent.css'
import { PeFilterContext } from '../Contexts/PeFilterContext'
import { TableDataContext } from '../Contexts/TableDataContext'
import { TableHeaderContext } from '../Contexts/TableHeaderContext';
import MonthRangePicker from '../Components/shared/MonthRangePicker';
import moment from 'moment'
import Constants from '../Constants';

/**
 * The `MonthPickerComponent` is a component that renders a month range picker and handles the
 * selection of a month range.
 * @param props - props is an object that contains the properties passed to the MonthPickerComponent.
 */
const MonthPickerComponent = (props) => {
  
  const { monthPickerValue, setMonthPickerValue, setIsMonthPickerChanged, setShowMontnPicker,groupId, advSearchmonthPickerValue, setAdvSearchMonthPickerValue } = useContext(PeFilterContext);
  const { setIsLoad, setIsDataLoad, setCurrentPage } = useContext(TableDataContext);
  const { setSelectedPage } = useContext(TableHeaderContext);
     // console.log({props, checkDate})
  const MonthPickerValue = (date) => {
    setIsLoad(1)
    setIsLoad(true)
    setIsDataLoad(true)
    setSelectedPage(1);
    if(props.id){
      props.onDateChange(date);
      setAdvSearchMonthPickerValue(date);
    } else setMonthPickerValue(date)
    if (props.onChange) {
      props.onChange(date)
    }
    setIsMonthPickerChanged(1)
    setShowMontnPicker(0)
    setCurrentPage(1)
  }

  useEffect(() => {
    if (props?.date != null) {
      const date = [props?.date[0], props?.date[1]]
      setMonthPickerValue(date)
    }  
    if(groupId?.myList?.length > 0)  {
      setMonthPickerValue(['1998-01-01', moment().endOf("month").format("YYYY-MM-DD")])
    }

    const dateFilterIndex = props.filters?.findIndex(filter => filter.member === "DealFlat.date");
    const checkDate = dateFilterIndex !== -1 && props.filters?.filter(item => item.member === 'DealFlat.date')[0];

    const initialStartDate = moment('1999-01-01', 'YYYY-MM-DD');
    const initialEndDate = Constants.initialMonthRange[1];

    let startDate, endDate;
    
    if(props.id && dateFilterIndex !== -1){
      // console.log('render==>', checkDate.values);
      if (checkDate.operator === 'gt') {
        startDate = moment(checkDate.values[0], 'YYYY-MM-DD');
        endDate = initialEndDate;
      } else if (checkDate.operator === 'lt') {
        startDate = initialStartDate;
        endDate = moment(checkDate.values[0], 'YYYY-MM-DD');
      } else if (checkDate.operator === 'inDateRange') {
        startDate = moment(checkDate.values[0], 'YYYY-MM-DD');
        endDate = moment(checkDate.values[1], 'YYYY-MM-DD');
      } else if (checkDate.operator === 'gte' || checkDate.operator === 'lte' ){
        startDate = moment(checkDate.values[0], 'YYYY-MM-DD');
        endDate = startDate;
      }
      setAdvSearchMonthPickerValue([startDate.format('YYYY-MM-DD'), endDate]);
    } else {
      startDate = initialStartDate;
      endDate = initialEndDate;
      setAdvSearchMonthPickerValue([startDate.format('YYYY-MM-DD'), endDate]);
    }

      // console.log(checkDate, "mom", checkDate?.values, props.id && checkDate?.values?.length);

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div className="">
      {groupId?.myList?.length > 0 ? <></> :
      <MonthRangePicker value={props.id ? advSearchmonthPickerValue : monthPickerValue} onChange={MonthPickerValue} /> }
    </div>
  )
}

export default MonthPickerComponent
