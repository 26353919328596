import { React, useState, useMemo, useContext } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import moment from "moment";
import { routeNames } from '../../routeSegments';
import DataTable from '../DataTables/DataTable';
import { FundingContext } from '../../Contexts/FundingDataProvider';
import OverflowToolTip from '../shared/OverflowToolTip';
import ConfirmPopup from '../../Utils/ConfirmPopup';
import Constants from '../../Constants';
import useModule from '../../hooks/useModule';


/* The `FundingTableIncubation` component is a React functional component that displays a table of
funding data related to incubators. */
const FundingTableIncubation = ({ menu, data, companyFundingId }) => {
    const { setincubationData } = useContext(FundingContext);
    const location = useLocation();
    const [alert, setalert] = useState({ message: "", show: false });
    const { isCFS,isMA ,isPE} = useModule();
    const {cmpId} = useParams();


    /**
     * The function `onConfirmation` sets the `show` property of the `alert` state to `false` and
     * clears the `message` property.
     */
    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets an alert message with a subscription message and displays
     * it.
     */
    const onBlurColumnClick = (cell) => {
        setalert({ show: true, message: Constants.peSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }

    /* The `dealDateSort` constant is using the `useMemo` hook to memoize a sorting function. This
    sorting function is used to sort the data in the "Deal Date" column of the table in ascending or
    descending order. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])
    /* The `customStringSortMemo` constant is using the `useMemo` hook to memoize a sorting function.
    This sorting function is used to sort the data in a column of the table in ascending or
    descending order. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.type ?? defaultVal).localeCompare(rowB?.values[columnId]?.type ?? defaultVal);
        }
    }, []);

    /* The `const columns` variable is defining an array of column objects that will be used in the
    DataTable component. Each column object represents a column in the table and contains properties
    such as `Header` (the column header text), `accessor` (the key to access the data for that
    column), and `Cell` (a custom component to render the cell content). */
    const columns = useMemo(() => {
        const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
        const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];
        return [
            {
                id: 'dealDate',
                Header: "Deal Date",
                accessor: "dealDate",
                className:"w-[17%]",
                Cell: ({ value }) => {
                    const date = value?.date ? moment(value.date).format('MMM YYYY') : ""; // Check if date is not null

                    return <Link
                        onClick={(e) => {
                            if (menu == "cfs") {
                                if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc")) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBlurColumnClick();
                                }
                            }
                        }}
                        state={{ prevPath: location.state?.prevPath ?? "" }}
                        to={
                            menu == "cfs"
                                ? allowedModulesList.includes("pe")
                                    ? routeNames.peCompanyIncubationDealById(companyFundingId, value.id)
                                    : routeNames.vcCompanyIncubationDealById(companyFundingId, value.id)
                                : menu == "vc"
                                    ? routeNames.vcCompanyIncubationDealById(companyFundingId, value.id)
                                    // : menu == "ma"
                                    //     ? routeNames.maCompanyIncubationDealById(companyFundingId, value.id)
                                        : routeNames.peCompanyIncubationDealById(companyFundingId, value.id)}>
                        {date}
                    </Link>
                },
                sortType: dealDateSort
            },
            {
                Header: "Incubator", 
                accessor: "incubator",
                className:"w-[17%]",
                Cell: ({ cell }) => {
                    console.log(cell.value, 'from incubation');
                    return <OverflowToolTip className='sm:max-w-[20rem] 2xl:max-w-[35rem]'
                        component={
                                
                                <Link className='hover:text-[#BC8B20]'
                                    rel="noopener noreferrer"
                                    state={{ cameFrom: location.pathname }}
                                    to={menu == "pe"|| menu=="ma" || menu=='cfs' ? routeNames.peIncubationInvestorById(cell.value?.id) : routeNames.vcIncubationInvestorById(cell.value?.id)}>
                                    {cell.value?.name} 
                                </Link>
                        }
                    />
                },
                sortType: customStringSortMemo
            },
            // ...!(isCFS || isMA) ? [{
            //     Header: "Exit Status",
            //     accessor: "exitStatus",
            // }] : [],
            {
                Header: "Status",
                accessor: "status"
            }
        ]
    }, [menu, companyFundingId || cmpId])


    /* The `rowData` constant is using the `useMemo` hook to memoize a function that transforms the
    `data` array into a new array of objects. Each object in the new array represents a row in the
    table and contains properties such as `dealDate`, `incubator`, `status`, and `exitStatus`. The
    values for these properties are extracted from the corresponding properties in each object of
    the `data` array. */
    const rowData = useMemo(() => {
        let rows = [];

        data?.forEach(investment => {
            rows.push({
                dealDate: { date: investment?.date, id: investment?.id },
                incubator: investment?.incubator,
                status: investment?.status,
                exitStatus:investment?.Exit_Status??"--",
            })
        });

        setincubationData(rows.length == 0 ? null : rows)
        return rows;

    }, [data])
    /* The `const initialSort` variable is using the `useMemo` hook to memoize an array of sorting
    options. It specifies that the initial sorting should be done based on the 'dealDate' column in
    descending order. This means that the table will be initially sorted by the 'Deal Date' column
    in reverse chronological order. */
    const initialSort = useMemo(() => [
        {
            id: 'dealDate',
            desc: true
        }
    ], []);
    return (
        <div className="space-y-6">
            <div className="bg-white">
                <DataTable columns={columns} data={rowData}
                    sortBy={initialSort}
                />
            </div>
            {<ConfirmPopup {...alert} onChange={onConfirmation} />}

        </div>
    )
}

export default FundingTableIncubation