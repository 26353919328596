import React, { useContext } from 'react'
import dashboard from '../images/dashboard_image.png';
import PE from '../images/noun-investment-2180740/png/noun-investment-2180740.png';
import RE from '../images/Shape_1/png/Shape.png';
import MA from '../images/Shape/png/Shape.png';
import CFS from '../images/Group/png/Group.png';
import Report from '../images/Combined Shape/png/Combined Shape.png';
import FAQ from '../images/Combined Shape_1/png/Combined Shape.png';
import Notes from '../images/Combined Shape_2/png/Combined Shape.png';
import ReportsInactive from '../images/reportsInactive.png'
import FaqInactive from '../images/faqInactive.png'
import { NavLink, useMatch, useSearchParams } from 'react-router-dom';
import routePathSegments from '../routeSegments';
import Constants from '../Constants';
import { TableDataContext } from '../Contexts/TableDataContext'
import useModule from '../hooks/useModule';
import { Tooltip } from 'antd';
import { PeFilterContext } from '../Contexts/PeFilterContext';

/**
 * The Sidebar component is a navigation menu that displays different options based on the user's
 * allowed modules and current page.
 * The Sidebar component  includes images and text, and the NavLink components
 * are used for navigation within the application.
 */
const Sidebar = (props) => {
  // console.log(props, "sidebarprops");
  // const location = useLocation();
  const isPeCompanyMatch = useMatch(`${routePathSegments.pe}/*`)
  const isVcCompanyMatch = useMatch(`${routePathSegments.vc}/*`)
  const isReCompanyMatch = useMatch(`${routePathSegments.re}/*`)
  const isMaCompanyMatch = useMatch(`${routePathSegments.ma}/*`)
  const cfsMatch = useMatch(`${routePathSegments.cfs}`)

  // const requestMatch = useMatch(`${routePathSegments.requests}/*`)
  const raiseRequestMatch = useMatch(`${routePathSegments.raise_request}/*`)
  const isPEDealPagetMatch = useMatch(`${routePathSegments.company}/*`)

  const[searchParams, setSearchParams] = useSearchParams();
  const type = searchParams?.get("type");
  const {setCurrentPage} =useContext(TableDataContext);
  const {allowedModulesList} = useModule()
  const {setGroupId} = useContext(PeFilterContext)

  const onlyVC = allowedModulesList.includes('vc') && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re') && !allowedModulesList.includes('cfs')
  const onlyCFSorVC = (allowedModulesList.includes('vc') || allowedModulesList.includes('cfs')) && !allowedModulesList.includes('pe') && !allowedModulesList.includes('ma') && !allowedModulesList.includes('re') 

  const onClick =() =>{
    setGroupId({});
    setCurrentPage(1)
  }



  return (
    <div>
      {/* <div className='flex '> */}
      <div className="flex flex-col space-y-3">

        <div className=" w-[4.75rem] bg-[#474747] fixed  min-h-screen py-1 px-1 md:block hidden mt-[3rem]">

          <div className="flex flex-col items-center" >
            <div className="flex-1 mt-2 overflow-y-auto scrollbar-none max-h-[90vh]">
              {/* ----Dashboard--- */}
              <div className="border-b-2 border-[#3B3838]">

                <div
                  className={"m-1 text-[9.5px] font-sans_medium_body  rounded-md "

                  }

                  data-toggle="tab"
                  href="#"
                  role="tab1"
                >
                  <NavLink className={({ isActive }) => isActive ? 'menuimg bg-white text-white' : 'text-[#F1CA75] '}
                    to={routePathSegments.dashboard} >
                    <a
                    onClick={()=>onClick()}
                      href="#" className="rounded-md p-1 flex flex-col items-center">
                      <img className='h-[22px] w-[23px] '
                        src={dashboard} />
                      <span className="mt-1">Dashboard</span>
                    </a>
                  </NavLink>
                </div>
              </div>
              {/* ----PE--- */}
              <div className="border-b-2 border-[#3B3838]">
                <div
                  className={"m-1  text-[9.5px] font-sans_medium_body rounded-md "

                  }
                  data-toggle="tab"
                  href="#"
                  role="tab2"
                >
                  <NavLink
                    className={({ isActive }) => {
                      if(type != null){
                        if(parseInt(type)===Constants.primaryMasterIds.peId){
                          return 'menuimg bg-white text-white';
                        }
                        return 'text-[#F1CA75] ';
                      }
                      if (isActive||isPeCompanyMatch !== null || raiseRequestMatch!=null || isPEDealPagetMatch!=null ) {
                        return 'menuimg bg-white text-white';
                      }

                      return 'text-[#F1CA75] ';
                    }}
                    to={routePathSegments.pe}>
                    {/* ///// */}
                    <a onClick={()=>onClick()} className="rounded-md p-1 flex flex-col items-center">
                      <img className="text-white-300 group-hover:text-white h-[24px] w-[26px]"
                        alt=""
                        src={PE}
                      />

                      <span className="mt-[3px]">PE</span>
                    </a>
                    {/* /// */}
                    {/* <span className="rounded-md w-full p-1 flex flex-col items-center ">
                      <img className={({ isActive }) => isActive ? 'h-[19px] w-[28px] brightness-0 invert-[1]' : 'h-[20px] w-[30px]'}

                        src={PE} />
                      <span className="mt-[3px]">PE</span>
                    </span> */}
                  </NavLink>
                </div>
              </div>
              {/*---- VC ---- */}
              <div className="border-b-2 border-[#3B3838]">

                <div className={"m-1  text-[9.5px] font-sans_medium_body rounded-md "
                }
                  data-toggle="tab"
                  href="#"
                  role="tab3"

                >

                  <NavLink
                    className={({ isActive }) => {
                      if (isActive || parseInt(type)===Constants.primaryMasterIds.vcId||isVcCompanyMatch !== null) {
                        return 'menuimg bg-white text-white';
                      }

                      return 'text-[#F1CA75] ';
                    }}
                    to={routePathSegments.vc}>
                    <a onClick={()=>onClick()} className="rounded-md
                    p-1
                    flex flex-col items-center">

                      <img className="text-white-300 group-hover:text-white h-[24px] w-[26px]"
                        src={PE} />

                      <span className="mt-[3px]">VC</span>
                    </a>
                  </NavLink>
                </div>
              </div>
              {/* ----RE--- */}
              <div className="border-b-2 border-[#3B3838]">

                <div className={"m-1  text-[9.5px] font-sans_medium_body rounded-md "
                }
                  data-toggle="tab"
                  href="#"
                  role="tab4"

                >

                  <NavLink
                    className={({ isActive }) => {

                      if (isActive||parseInt(type)===Constants.primaryMasterIds.reId||isReCompanyMatch !== null) {
                        return 'menuimg bg-white text-white';
                      }
                      return 'text-[#F1CA75] ';

                    }}
                    to={routePathSegments.re}>
                    <a onClick={()=>onClick()} className="rounded-md w-full p-1 flex flex-col items-center ">
                      <img className={({ isActive }) => isActive ? 'h-[19px] w-[28px] brightness-0 invert-[1]' : 'h-[20px] w-[30px]'}

                        src={RE} />
                      <span className="mt-[3px]">RE</span>
                    </a>
                  </NavLink>
                </div>

              </div>

              {/* ----M&A--- */}
              <div className="border-b-2 border-[#3B3838]">

                <div className={"m-1  text-[9.5px] font-sans_medium_body rounded-md "
                }
                  data-toggle="tab"
                  href="#"
                  role="tab4" >
                  <NavLink className={({ isActive }) => {
                    if (isActive||parseInt(type)===Constants.primaryMasterIds.mANDaId||isMaCompanyMatch !== null) {
                      return 'menuimg bg-white text-white';
                    }
                    return 'text-[#F1CA75] ';

                  }} to={routePathSegments.ma}>
                    <a onClick={()=>onClick()} className="rounded-md  
           w-full p-1
           flex flex-col items-center ">

                      <img className="text-white-300 group-hover:text-white h-[28px] w-[26px]"

                        src={MA} />
                      <span className="mt-[3px]">M & A</span>
                    </a>
                  </NavLink>

                </div>
              </div>
              {/* ----CFS--- */}
              <div className="border-b-2 border-[#3B3838]">

                <div className={"m-1  text-[9.5px] font-sans_medium_body rounded-md "
                }
                  data-toggle="tab"
                  href="#"
                  role="tab5" >
                  <NavLink className={({ isActive }) => {
                    if (isActive||parseInt(type)===Constants.primaryMasterIds.cfsId||cfsMatch !== null) {
                      return 'menuimg bg-white text-white';
                    }

                    return 'text-[#F1CA75] ';
                  }} to={routePathSegments.cfs}>
                    <a onClick={()=>onClick()} className="rounded-md 
                    
               p-1
               flex flex-col items-center " >

                      <img className="text-white-300 group-hover:text-white h-[28x] w-[21px]"

                        src={CFS} />
                      <span className="mt-[3px]">CFS</span>
                    </a>
                  </NavLink>
                </div>
              </div>
              {/* ----Reports--- */}
              <Tooltip title={`${onlyCFSorVC ? 'You have not subcribed this section. For further information please contact sale team.':''}`} placement='right'>
              <button className={`border-b-2 border-[#3B3838] w-full ${onlyCFSorVC ?'pl-2':'pl-0'}`} disabled={onlyCFSorVC}>
                <div className={"mx-1 my-2 text-[9.5px] font-sans_medium_body  rounded-md "}
                  >
                  <NavLink className={({ isActive }) => isActive ? ' menuimg bg-white text-white' : ` ${onlyCFSorVC ? 'text-[#cccccc]':'text-[#F1CA75]'} `} to={routePathSegments.reports}>
                    <a onClick={()=>onClick()} className="rounded-md  p-1.5 flex flex-col items-center ">

                      <img className="text-white-300 group-hover:text-white h-[21px] w-[21px]"

                        src={onlyCFSorVC ? ReportsInactive:Report} />
                      <span className="mt-[3px]">Reports</span>
                    </a>
                  </NavLink>
                </div>
              </button>
              </Tooltip>
              {/* ----FAQ--- */}
              {/* <Tooltip title={`${onlyVC ? 'You have not subcribed this section. For further information please contact sale team.':''}`} placement='right'>
              <button className={`border-b-2 border-[#3B3838] w-full ${onlyVC ?'pl-2':'pl-0'}`} disabled={onlyVC}>
                <div className={"mx-1 my-2  text-[9.5px] font-sans_medium_body rounded-md "
                }
                  data-toggle="tab"
                  // href="#"
                  role="tab7" >
                  <NavLink className={({ isActive }) => isActive ? 'menuimg bg-white text-white' : ` ${onlyCFSorVC ? 'text-[#cccccc]':'text-[#F1CA75]'} `} to={routePathSegments.faq}>
                    <a className="rounded-md  
                                  p-1.5
                                  flex flex-col items-center ">

                      <img className="text-white-300 group-hover:text-white h-[24px] w-[22px]"

                        src={onlyVC ? FaqInactive :FAQ} />
                      <span className="mt-[3px]">FAQs</span>
                    </a>
                  </NavLink>
                </div> 
              </button>
              </Tooltip > */}
              {/* ----Notes--- */}
              <div className="">          
                <div className={"m-1 justify-center text-[9.5px] font-sans_medium_body rounded-md "
                }
                  data-toggle="tab"
                  // href="#"
                  role="tab8" >
                  <NavLink className={({ isActive }) => isActive ? 'menuimg bg-white text-white' : 'text-[#F1CA75] '} to={routePathSegments.notes} >
                    <a onClick={()=>onClick()} className="rounded-md  
                                  py-1.5 px-2
                                  flex flex-col items-center ">

                      <img className="text-white-300 group-hover:text-white h-[22px] w-[22px]"

                        src={Notes} />
                      <span className="mt-[3px]">Notes</span>
                    </a>
                  </NavLink>
                </div>
              </div> 
              {/* ----Query Builder--- */}
                {/* <div className={"m-1 justify-center text-[9.5px] font-sans_medium_body rounded-md "
                }
                  data-toggle="tab"
                  // href="#"
                  role="tab9" >
                  <NavLink className={({ isActive }) => isActive ? 'menuimg bg-white text-white' : 'text-[#F1CA75] '} to={routePathSegments.query_builder} >
                    <a onClick={()=>onClick()} className="rounded-md  
                                  py-1.5 px-2
                                  flex flex-col items-center ">

                      <img className="text-white-300 group-hover:text-white h-[22px] w-[22px]"

                        src={Notes} />
                      <span className="mt-[3px] text-center">QB</span>
                    </a>
                  </NavLink>
                </div>               */}
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Sidebar
