import React from 'react';
import { useMatch } from 'react-router-dom';
import NavbarItems from './NavbarItems';
import NavbarWrapper from './NavbarWrapper';
import routePathSegments, { routeNames } from '../../routeSegments';

function REHeader() {
    const isCompanyMatch = useMatch({ path: `/${routePathSegments.re}/${routePathSegments.company}/*` });
    const isInvestorMatch = useMatch({ path: `/${routePathSegments.re}/${routePathSegments.investor}/*` });
    const isAdvisorMatch = useMatch({ path: `/${routePathSegments.re}/${routePathSegments.advisor}/*` });
    const isTransactionAdvisorMatch = useMatch({ path: `/${routePathSegments.re}/${routePathSegments.transaction_advisor}/*` });
    const companyMatch = useMatch({ path: `/${routePathSegments.company}/*` });

    return (
        <NavbarWrapper>
            <NavbarItems
                title="Companies"
                to={routeNames.RE_company}
                isLinkActive={isCompanyMatch != null || companyMatch != null}
                pageTitle="RE Companies PE-VC Investments - Venture Intelligence" // Pass pageTitle here
            />
            <NavbarItems
                title="Investors"
                to={routeNames.RE_investor}
                isLinkActive={isInvestorMatch != null}
                pageTitle="RE Investors PE-VC Investments - Venture Intelligence" // Pass pageTitle here
            />
            <NavbarItems
                title="Legal Advisors"
                to={routeNames.RE_advisor}
                isLinkActive={isAdvisorMatch != null}
                pageTitle="RE Legal Advisors PE-VC Investments - Venture Intelligence" // Pass pageTitle here
            />
            <NavbarItems
                title="Transaction Advisors"
                to={routeNames.RE_transaction_advisor}
                isLinkActive={isTransactionAdvisorMatch != null}
                pageTitle="RE Transaction Advisors PE-VC Investments - Venture Intelligence" // Pass pageTitle here
            />
        </NavbarWrapper>
    );
}

export default REHeader;
