import React, {useContext } from "react";
import Layout from '../../Components/VC/VcCompanyLayout'
import MainMenu from '../../Components/VC/Mainmenu'
import Table from '../../Components/TableComponent'
// import {PE_VC_TABLE_QUERY} from '../../Queries/Pe';
import Card from '../../Components/ListPageCard/Card'
import RefineSearch from '../../Components/RefineSearchComponent'
import FilterTag from '../../Components/Tag'
// import chatPopupIcon from '../../images/chatPopupIcon.png'
// import ChatPopup from '../../Components/ChatPopup'
import Constants from '../../Constants';
import InvestmentRefineSearch from '../../Components/RefineSearch/InvestmentRefineSearch';
import { useLocation } from 'react-router-dom';
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { Switch, Tooltip } from "antd";
import { UserContext } from "../../Contexts/UserContext";
import ToggleDealsViewButton from "../../Components/ToggleDealsView";
/**
 * The function `Vc` is a React component that renders a layout with a top navigation bar, a main
 * section with a primary column and a secondary column, and a bottom section.
 * @returns The function `Vc` returns JSX elements that make up the layout of a page. It includes a top
 * navigation bar, a main section with a primary column and a secondary column, and a bottom section.
 * The primary column contains a filter tag, a main menu, and a table. The secondary column contains a
 * refine search component.
 */
function Vc() {
    // const [showModal, setShowModal] = React.useState(false);
    const location = useLocation(); 
    const { refineSearchFilters, dealsView, setDealsView, switchValue, setSwitchValue } = useContext(PeFilterContext);
    const { isDirectoryUser } = useContext(UserContext);

    const { pathname } = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    const dealsViewHandler = (checked) =>{
        setSwitchValue(checked);
        if(checked){ setDealsView(true) }else setDealsView(false);
    }

    return (
        <Layout>
        <div className="flex min-h-screen h-screen flex-col">
                    {/* Top nav*/}
                  {isFromGlobalSearch () ? "" : <div className="hidden lg:block"><Card/> </div>}  
                    {/* Bottom section */}
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Narrow sidebar*/}
                        {/* Main area */}
                        <main className= {isFromGlobalSearch() ? "mt-[3rem] min-w-0 flex-1 border-t border-gray-200 lg:flex":"min-w-0 flex-1 border-t border-gray-200 lg:flex"}>
                            {/* Primary column */}
                            <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                                <div className="mr-[4.25rem] flex justify-between items-center">
                                <FilterTag />
                                {/* <div className='px-3'> */}
                                <ToggleDealsViewButton />
                                </div>
                                {/* Your content */}
                                <MainMenu active={1} menu="vc" primaryTypeMasterId={Constants.primaryMasterIds.vcId}
                                subTypeMasterId={Constants.primaryMasterSubIds.vc_investments_id}
                                typeId={Constants.moduleIds.companiesId}
                                exportFileName={Constants.exportNames.vc_company_investment}/> 
                                <div className='overflow-y-auto'>
                                    <Table menu="vc" sub_type_master_id={Constants.subCategoryIds.vc_investments_id}/>
                                </div>
                                {/* </div> */}
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                                <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                                    <RefineSearch>
                                        <InvestmentRefineSearch customPathname={location.pathname}/>
                                    </RefineSearch>
                                </div>
                            </aside>
                        </main>
                    </div>                   
                       
                </div>
        </Layout>
    )
}

export default Vc
