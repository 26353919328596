import { React, useState, useMemo, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import moment from "moment";
import DataTable from '../DataTables/DataTable';
import { routeNames } from '../../routeSegments';
import { FundingContext } from '../../Contexts/FundingDataProvider';
import OverflowToolTip from '../shared/OverflowToolTip';
import ConfirmPopup from '../../Utils/ConfirmPopup';
import Constants from '../../Constants';
import useModule from '../../hooks/useModule';
import { UserContext } from '../../Contexts/UserContext';


/* The above code is a React component written in JavaScript. It is a FundingTableAngelInvestment
component that displays a table of funding data for angel investments. */
const FundingTableAngelInvestment = ({ menu, data, companyFundingId }) => {
    const { setangelData } = useContext(FundingContext);
    const [alert, setalert] = useState({ message: "", show: false });
    const location = useLocation();
    const { isMA, isCFS } = useModule();
    const { isDirectoryUser} = useContext(UserContext);
    
    /**
     * The function `onConfirmation` sets the `show` property of the `alert` state to `false` and
     * clears the `message` property.
     */
    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets an alert message with a subscription message and displays
     * it.
     */
    const onBlurColumnClick = (cell) => {
        setalert({ show: true, message: Constants.peSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }

    /* The `dealDateSort` constant is using the `useMemo` hook to memoize a sorting function. This
    sorting function is used to sort the data in the "Deal Date" column of the
    FundingTableAngelInvestment component. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])

    /* The `investorSort` constant is using the `useMemo` hook to memoize a sorting function. This
    sorting function is used to sort the data in the "Investors" column of the
    `FundingTableAngelInvestment` component. */
    const investorSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';

            const valueA = rowA?.values[columnId].map(data => data.investor_name).join("")
            const valueB = rowB?.values[columnId].map(data => data.investor_name).join("")

            return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
        }
    }, [])

    /* The `columns` constant is an array of objects that define the columns for the DataTable
    component in the FundingTableAngelInvestment component. Each object represents a column and
    contains properties such as `id`, `Header`, `accessor`, `Cell`, and `sortType`. */
    const columns = useMemo(() => {
        const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
        const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];
        return [
            {
                id: "dealDate",
                Header: "Deal Date",
                accessor: "dealDate",
                Cell: ({ value }) => {
                    const date = value?.date ? moment(value.date , 'MMM YYYY').format('MMM YYYY') : ""; // Check if date is not null
                    return <Link
                        onClick={(e) => {
                            if (menu == "cfs" || isDirectoryUser) {
                                if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc") || isDirectoryUser) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBlurColumnClick();
                                }
                            }
                        }}
                        state={{ prevPath: location.state?.prevPath ?? "" }} to={
                            menu == "cfs"
                                ? allowedModulesList.includes("pe")
                                    ? routeNames.peCompanyAngelDealById(companyFundingId, value.id)
                                    : routeNames.vcCompanyAngelDealById(companyFundingId, value.id)
                                : menu == "vc"
                                    ? routeNames.vcCompanyAngelDealById(companyFundingId, value.id)
                                    // : menu == "ma"
                                    //     ? routeNames.maCompanyAngelDealById(companyFundingId, value.id)
                                        : routeNames.peCompanyAngelDealById(companyFundingId, value.id)


                        }>
                        {date}
                    </Link>
                },
                sortType: dealDateSort
            },
            {
                Header: "Investors",
                accessor: "investors",

                Cell: (cell) => {
                    return <OverflowToolTip className='w-[18rem] lg:w-auto sm:max-w-[30rem] 2xl:max-w-[40rem]' component={
                        cell.value.map((inv, index) => (<Link
                            onClick={(e) => {
                                if (menu == "cfs" || menu == "ma") {
                                    if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc")) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onBlurColumnClick();
                                    }
                                }
                            }}
                            rel="noopener noreferrer"
                            className={`${inv.investor_id === 9 ? 'cursor-default' : 'hover:text-[#BC8B20]'}` }
                            state={{ cameFrom: location.pathname }} to={menu == "cfs" || menu == "ma"
                                ? allowedModulesList.includes("pe")
                                    ? inv.investor_id === 9 ? null : routeNames.peAngelInvestorById(inv.investor_id)
                                    : inv.investor_id === 9 ? null : routeNames.vcAngelInvestorById(inv.investor_id)
                                : menu == "vc"
                                    ? inv.investor_id === 9 ? null : routeNames.vcAngelInvestorById(inv.investor_id)
                                    : inv.investor_id === 9 ? null : routeNames.peAngelInvestorById(inv.investor_id)}>
                            {`${inv.investor_name}${index == cell.value?.length - 1 ? "" : ", "}`}
                        </Link>))
                    } />
                },
                sortType: investorSort,
            },
            {
                Header: "Exit Status",
                accessor: "exitStatus",
                className: "w-[15rem]",
                // headerspanClassName: "ml-auto ",
                headerClassName: "w-[15rem]",


            },
        ];
    }, [menu, companyFundingId]);

    /* The `rowData` constant is using the `useMemo` hook to memoize a function that transforms the
    `data` array into a new array of objects. Each object in the new array represents a row in the
    DataTable component. */
    const rowData = useMemo(() => {
        let rows = [];

        data?.forEach(investor => {
            rows.push({
                dealDate: { date: investor.Deal_Date, id: investor.Deal_Id },
                investors: investor?.Investors,
                exitStatus: investor?.Exit_Status ?? "--",
            })
        });


        setangelData(rows.length == 0 ? null : rows)

        return rows;

    }, [data])

    /* The `initialSort` constant is using the `useMemo` hook to memoize an array of objects. Each
    object represents a sorting configuration for a column in the DataTable component. In this case,
    the `id` property is set to `'dealDate'` and the `desc` property is set to `true`, indicating
    that the data should be sorted in descending order based on the 'dealDate' column when the
    DataTable component is initially rendered. */
    const initialSort = useMemo(() => [
        {
            id: 'dealDate',
            desc: true
        }
    ], []);
    return (
        <div className="space-y-6">
            <div className="bg-white">
                <DataTable columns={columns} data={rowData}
                    sortBy={initialSort}
                />
            </div>
            {<ConfirmPopup {...alert} onChange={onConfirmation} />}
        </div>
    )
}

export default FundingTableAngelInvestment