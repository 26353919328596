import { React, useState, useMemo, useContext } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import moment from "moment";
import { routeNames } from '../../routeSegments';
import { FundingContext } from '../../Contexts/FundingDataProvider';
import OverflowToolTip from '../shared/OverflowToolTip';
import Paginate from '../Paginate';
import Constants from '../../Constants';
import { usePagination, useSortBy, useTable } from 'react-table';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import LockIcon from 'mdi-react/LockIcon';
import ConfirmPopup from '../../Utils/ConfirmPopup';
import { TableDataContext } from '../../Contexts/TableDataContext';
import { UserContext } from '../../Contexts/UserContext';
import { Tooltip } from 'antd';



/* The above code is a React component written in JavaScript. It is a table component that displays
investment data. It receives props such as menu, data, and fundingData. */
const FundingTableInvestment = ({ menu, data, fundingData, companyId }) => {

    console.log(data, 'fundingData')

    const { setpevcData } = useContext(FundingContext);
    const { forExMonthlyRate } = useContext(TableDataContext);
    const location = useLocation();
    const [alert, setalert] = useState({ message: "", show: false });
    const {cmpId} = useParams()
    const { isDirectoryUser } = useContext(UserContext);

    /**
     * The function `onConfirmation` sets the `show` property of the `alert` state to `false` and
     * clears the `message` property.
     */
    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets an alert message with a subscription message and displays
     * it.
     */
    const onBlurColumnClick = (cell) => {
        setalert({ show: true, message: Constants.peSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }

    /* The above code is defining a custom sorting function using the `useMemo` hook in React. The
    function takes in two rows (`rowA` and `rowB`), a column ID, and a boolean value `desc`
    indicating whether the sorting should be in descending order. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            // console.log(rowA?.values[columnId]?.roundName.toLowerCase(),'lower case');
            return (rowA?.values[columnId]?.roundName ?? defaultVal).localeCompare(rowB?.values[columnId]?.roundName ?? defaultVal);
        }
    }, []);


    /* The above code is defining a custom sorting function called `dealDateSort` using the `useMemo`
    hook in React. This sorting function is used to compare two rows (`rowA` and `rowB`) based on a
    specific column (`columnId`) containing date values. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])

    /* The above code is defining a custom sorting function called `investorSort` using the `useMemo`
    hook in React. This sorting function takes in two rows (`rowA` and `rowB`), a column ID, and a
    boolean flag `desc` indicating whether the sorting should be in descending order. */
    const investorSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';

            const valueA = rowA?.values[columnId].map(data => data.investor_name).join("")
            const valueB = rowB?.values[columnId].map(data => data.investor_name).join("")

            return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
        }
    }, [])

    const getForExRate = (dealDate) => {
        const dealMoment = moment(dealDate, "MM-YYYY");
        let rate = forExMonthlyRate[0]?.value; // Default to the earliest rate if no match found
        forExMonthlyRate.forEach((forex) => {
            const forexMoment = moment(forex.date, "YYYY-MM-DD").format("MM-YYYY");
            if (forexMoment === dealMoment.format("MM-YYYY")) {
                rate = forex.value;
            }
        });
        return parseFloat(rate);
    };

    const isValidNumber = (value) => {
        return typeof value === 'number' && Number.isFinite(value);
      }


    const humanReadableAmountUsd = (a) => {
        if (!a) {
            return ""
        }

        if (typeof a === 'string') {
            a = parseFloat(a.replace(",", ""))
        }
        if (!isValidNumber(a)) {
            return ""
        }

        const millionBillion = (a < 1000) ? "M" : "B"
        const usdValue = (a < 1000) ? Math.round(a*100)/100 : (Math.round(a/10) / 100);
        const usdValueStr = Number(usdValue).toString()
        return `$ ${usdValueStr} ${millionBillion}`
    }

    // Function to calculate post-money valuation in USD
    const calculatePostMoneyUsd = (companyValuationPostMoney, date, amountInr, amount) => {
        if (!companyValuationPostMoney) {
            return ''
        }

        const exRate = (amountInr > 0 && amount > 0) ? (parseFloat(amountInr) / parseFloat(amount)) : getForExRate(date);
        const usdValue = companyValuationPostMoney / exRate        
        const usdValueStr = humanReadableAmountUsd(usdValue)
        return (`(${usdValueStr})`); // Converting and formatting to 2 decimal places
    };

    /* The above code is defining an array of columns for a table in a React component. Each column has
    properties such as id, Header (displayed column name), accessor (data key), className (CSS
    classes), headerspanClassName (CSS classes for the header span), headerClassName (CSS classes
    for the header), Cell (rendering logic for the cell), sortType (custom sorting logic), and more.
    The columns are generated dynamically based on the values of menu and companyId
    variables. The code also includes a helper function addCommasToNumber to format numbers with
    commas. */
    const columns = useMemo(() => {
        let blurColumns;

        const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
        const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];

        if (allowedModulesList.includes("pe") && !isDirectoryUser) {
            blurColumns = false;
        } else {
            blurColumns = true;
        }

        const addCommasToNumber = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          };

        return [
            {
                id: "round",
                Header: "Round",
                accessor: "round",
                className: "clickable w-[11%] text-left sticky left-0 lg:relative bg-white",
                headerspanClassName: "",
                headerClassName: "text-left sticky left-0 lg:relative  w-[3rem] bg-[#EDEDED] ",
                Cell: (cell) => {
                    if (cell.value == Constants.restrictedPlaceholder) {
                        return cell.value
                    }
                    return <OverflowToolTip className="w-[9rem] lg:w-auto" component={<Link
                    className='hover:text-[#9d6c01]'
                        onClick={(e) => {
                            if (menu == "cfs") {
                                if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc")) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBlurColumnClick();
                                }
                            }else if(cell.value.restricted == Constants.restrictedPlaceholder){
                                e.preventDefault();
                                e.stopPropagation();
                                onBlurColumnClick();
                            }
                        }}
                        rel="noopener noreferrer"
                        state={{ prevPath: location.state?.prevPath ?? "" }}
                         to={menu == "cfs"
                            ? allowedModulesList.includes("pe")
                                ? routeNames.peCompanyInvestmentDealById(companyId, cell.value.id)
                                : routeNames.vcCompanyInvestmentDealById(companyId, cell.value.id)
                            : menu == "vc"
                                ? routeNames.vcCompanyInvestmentDealById(companyId, cell.value.id)
                                : menu == "ma"
                                    ? routeNames.peCompanyInvestmentDealById(companyId, cell.value.id)
                                    : routeNames.peCompanyInvestmentDealById(companyId, cell.value.id)}>
                        {cell.value.roundName ?? "--"}
                    </Link>} />
                },
                sortType: customStringSortMemo,
            },

            {
                id: "dealDate",
                Header: "Deal Date",
                accessor: "dealDate",
                className: "w-[9%]",
                headerspanClassName: "",
                headerClassName: "",
                Cell: (cell) => {
                    const date = cell?.value?.date ? moment(cell.value.date).format('MMM YYYY') : ""; // Check if date is not null

                    if (cell.value == Constants.restrictedPlaceholder) {
                        return cell.value
                    }
                    return <Link
                        className='hover:text-[#9d6c01]'
                        onClick={(e) => {
                            if (menu == "cfs") {
                                if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc")) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBlurColumnClick();
                                }
                            }else if(cell.value.restricted == Constants.restrictedPlaceholder){
                                e.preventDefault();
                                e.stopPropagation();
                                onBlurColumnClick();
                            }
                        }}
                        state={{ prevPath: location.state?.prevPath ?? "" }}
                        to={menu == "cfs"
                            ? allowedModulesList.includes("pe")
                                ? routeNames.peCompanyInvestmentDealById(companyId, cell.value.id)
                                : routeNames.vcCompanyInvestmentDealById(companyId, cell.value.id)
                            : menu == "vc"
                                ? routeNames.vcCompanyInvestmentDealById(companyId, cell.value.id)
                                : menu == "ma"
                                    ? routeNames.peCompanyInvestmentDealById(companyId, cell.value.id)
                                    : routeNames.peCompanyInvestmentDealById(companyId, cell.value.id)}>
                        {date}
                        {cell.value.agghide && (
                            <Tooltip 
                                placement="right" 
                                color="#ffffff"
                                key="#ffffff"
                                overlayStyle={{ maxWidth: '30%'  }}
                                title={<span style={{ color: '#000000' }}>Not Included for Aggregate calculation as it does not meet the Venture Intelligence definition of PE</span>}
                            >
                                <span className="rounded-md bg-[#f3e8ff] px-2 py-[3px] ml-2 text-xs font-sans_book_body text-[#333333]">
                                NIA
                                </span>
                            </Tooltip>
                            )}
                            {cell.value.debt && (
                            <span className="rounded-md bg-[#f3e8ff] px-2  py-[3px] ml-2 text-xs  font-sans_book_body text-[#333333]">
                                Debt
                            </span>
                        )}
                    </Link>
                },
                sortType: dealDateSort

            },
            {
                Header: "Deal Amount ($M)",
                accessor: "dealAmountInUS",
                className: "text-right  w-[16%] pr-[2.7rem] md:pr-[2.7rem] 2xl:pr-[2.4rem] lg:pr-[2.75rem]",
                headerspanClassName: "ml-auto ",
                headerClassName: "",
                Cell: (cell) => {
                    return addCommasToNumber(cell?.value)
                },


            },
            {
                Header: "Investors",
                accessor: "investors",
                className: "clickable w-[17%]",
                headerspanClassName: " ",
                headerClassName: "",
                sortType: investorSort,
                Cell: (cell) => {
                    if (cell.value == Constants.restrictedPlaceholder) {
                        return cell.value
                    }

                    return <OverflowToolTip className="w-[20rem] lg:w-auto max-w-[18rem] lg:max-w-[15rem] 2xl:max-w-[25rem] lg:overflow-x-clip lg:overflow-ellipsis" component={
                        cell.value?.map((inv, index) => (
                            <Link onClick={(e) => {
                                if (menu == "cfs" || menu == "ma") {
                                    if (!allowedModulesList.includes("pe") && !allowedModulesList.includes("vc")) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onBlurColumnClick();
                                    }
                                }
                            }} rel="noopener noreferrer" className={`${inv.id === 9 ? "cursor-default" : 'hover:text-[#BC8B20]'}` }
                                state={{ cameFrom: location.pathname }} to={menu == "cfs" || menu == "ma"
                                    ? allowedModulesList.includes("pe")
                                        ? inv.id === 9 ? null : routeNames.peInvestorById(inv.id)
                                        : inv.id === 9 ? null : routeNames.vcInvestorById(inv.id)
                                    : menu == "vc"
                                        ? inv.id === 9 ? null : routeNames.vcInvestorById(inv.id)
                                        : inv.id === 9 ? null : routeNames.peInvestorById(inv.id)}>
                                {`${inv.name}${index == cell.value?.length - 1 ? "" : ", "}`}
                            </Link>))

                    } />
                },

            },
            {
                Header: "Exit Status",
                accessor: "exitStatus",
                className: "w-[10rem]",
                // headerspanClassName: "ml-auto ",
                headerClassName: "w-[10rem]",


            },
            {
                Header: "Post Money Valuation",
                accessor: "postMoneyValuation",
                className: `text-right w-[0%]  pr-[2.7rem]`,
                headerspanClassName: "ml-auto ",
                headerClassName: "",
                Cell: (cell) => {
                    return addCommasToNumber(cell?.value)
                },
                disableSortBy: blurColumns,
                blurColumns: blurColumns,
                Cell: ({ value }) => {
                    if (!blurColumns) {
                        return value;
                    }
                    return <div className='cursor-pointer' onClick={(e) => onBlurColumnClick(value)}>{value ?? "XXX"}</div>;
                }
            },
            // {
            //     Header: "($M)",
            //     accessor: "postmoneyValuation",
            //     className: `lg:px-0 w-[1%] text-right lg:pr-[2.5rem]`,
            //     headerspanClassName: "ml-auto",
            //     headerClassName: "w-[13%]",
             
            // },
            {
                Header: "Revenue Multiple",
                accessor: "revenueMultiple",
                className: `text-right  w-[15%] pr-[2.5rem]`,
                headerspanClassName: "ml-auto ",
                headerClassName: "mr-auto",
                Cell: (cell) => {
                    return addCommasToNumber(cell?.value)
                },
                disableSortBy: blurColumns,
                blurColumns: blurColumns,
                Cell: ({ value }) => {
                    if (!blurColumns) {
                        return value;
                    }
                    return <div className='cursor-pointer' onClick={(e) => onBlurColumnClick(value)}>{value ?? "XXX"}</div>;
                }
            },
        ];
    }, [menu, companyId || cmpId])


const postMoneyValuationElement = (postMoneyValuationINR, postMoneyValuationUSD) => {
    if (isDirectoryUser) {
        return Constants.restrictedPlaceholder
    }
    if (postMoneyValuationINR === 0) {
        return ""
    }
    const postMoneyValuationINRAlt = postMoneyValuationINR < 100 ? postMoneyValuationINR : Math.round(postMoneyValuationINR)
    return (<div> 
                <span className='hidden'>{postMoneyValuationINR}</span> 
                <span>₹ {postMoneyValuationINRAlt.toLocaleString('en-IN')} Cr</span> 
                <span className='pl-2 text-[#9d6c01] text-xs font-medium'>{postMoneyValuationUSD}</span>
            </div>)
}


const dealDateElement = (investment) => {
    if (investment?.deal_date === Constants.restrictedPlaceholder) {
        return investment.deal_date
    } 
    
    return { date: investment.deal_date, 
            id: investment.id, 
            restricted: investment.Revenue_Multiple,  
            debt : investment?.is_debt_deal , 
            agghide : investment?.is_agg_hide }
}

    /* The above code is a JavaScript React code snippet. It defines a function that uses the `useMemo`
    hook to memoize the calculation of `rowData`. */
const rowData = useMemo(() => {
    const rows = data?.map(investment => {
        const dealDate = investment?.deal_date ? moment(investment.deal_date).format("MM-YYYY") : null;
        const postMoneyValuationINR = parseFloat(investment?.company_valuation_post_money) || 0;
        const postMoneyValuationUSD = calculatePostMoneyUsd(postMoneyValuationINR, dealDate, investment?.amount_inr, investment?.amount);

        const sortedInvestors = investment?.investors?.sort((a, b) => {
    if (a.name === 'Others') return 1; // 'Others' should come last
    if (b.name === 'Others') return -1; // 'Others' should come last
    return 0; // Maintain original order for all other investors
}) ?? [];


        return {
            round: investment?.round === Constants.restrictedPlaceholder ? investment.round : { roundName: investment.round, id: investment.id, restricted: investment.Revenue_Multiple },
            dealDate: dealDateElement(investment),
            dealAmountInUS: investment.amount === "0.00" || investment.is_amount_hide ? "" : investment.amount,
            investors: sortedInvestors,
            exitStatus: investment.exit_status === "0" ? "" : investment.exit_status,
            revenueMultiple: isDirectoryUser ? Constants.restrictedPlaceholder : investment.revenue_multiple_post_money === "0" ? "" : investment.revenue_multiple_post_money,
            postMoneyValuation: postMoneyValuationElement(postMoneyValuationINR, postMoneyValuationUSD)
        };
    }) || [];

    setpevcData(rows.length === 0 ? null : rows);
    return rows;
}, [data]);

    console.log(isDirectoryUser, 'dir')
    /* The above code is using the `useMemo` hook in React to create a memoized value called
    `initialSort`. */
    const initialSort = useMemo(() => {
        if (fundingData?.data?.isSort == false) {
            return []
        } else {
            return [
                {
                    id: 'dealDate',
                    desc: true
                }
            ]
        }
    }, [fundingData?.data?.isSort]);

    /* The above code is using the `useTable` hook from the React-Table library to create a table
    instance. */
    const tableInstance = useTable({
        columns,
        data: rowData,
        disableSortRemove: true,
        initialState: {
            pageSize: Constants.noOfRowPerPage,
            pageIndex: 0,
            sortBy: initialSort,
        },
    },
        useSortBy,
        usePagination,
    );

    /* The above code is using destructuring assignment to extract specific properties and methods from
    the `tableInstance` object. These extracted properties and methods are then used in a React
    component to render a table. */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize }
    } = tableInstance;


    return (

        <div className="space-y-6">
            <div className="bg-white">
                <>

                    <div className='overflow-x-auto scrollbar relative'>
                        {/* <div className='table absolute w-full h-full'>
                            <div className='table-row '>
                                <div className='table-cell invisible pointer-events-none whitespace-nowrap px-5 py-4 w-[10%]'></div>
                                <div className='table-cell bg-slate-300'>
                                    <LockIcon />
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='w-full h-full flex absolute justify-end'>
                            <div className='w-[40%] bg-slate-200'>

                            </div>
                        </div> */}
                        {rows?.length > 0 ? 
                        <table className=" datatable w-full" {...getTableProps()}>

                            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-semibold py-4 px-0 text-center ">
                                {
                                    headerGroups.map(headerGroup => (

                                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps([
                                                        {
                                                            className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                            style: column.headerStyle,
                                                        },
                                                        column.getSortByToggleProps(),
                                                    ])} title="">
                                                        <div className="flex">
                                                            <span
                                                                {...column.getHeaderProps([
                                                                    {
                                                                        className: `mr-1 ${column.headerspanClassName}`,
                                                                    },

                                                                ])}
                                                            >{column.render('Header')}</span>
                                                            {/* <span className='mr-1'>{column.render('Header')}</span> */}
                                                            {column.disableSortBy ? "" : column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                    : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>

                            <tbody {...getTableBodyProps()} className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                                {
                                // page.length == 0
                                //     ? <tr>
                                //         <td colspan="8">
                                //             <div className="h-[4rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] text-center">
                                //                 No data found
                                //             </div>
                                //         </td>
                                //     </tr>
                                //     : 
                                    page.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="border-b">
                                                {

                                                    row.cells.map(cell => {
                                                        console.log("cell.column.renderCol", cell.value);
                                                        if (cell.column.blurColumns || cell.value == Constants.restrictedPlaceholder) {
                                                            return (<td onClick={onBlurColumnClick} className=' relative whitespace-nowrap cursor-pointer' {...cell.getCellProps()}>
                                                                <div className='blur-sm bg-[#DCDCDC] flex justify-center select-none w-full h-full px-5 py-4'>{Constants.restrictedPlaceholder}</div>
                                                                <div className='absolute h-full w-full flex items-center justify-center top-0 bottom-0'>
                                                                    <LockIcon size={16} color='#696969'/>
                                                                </div>
                                                            </td>)
                                                        }
 
                                                        return (
                                                            <td colSpan={cell.column.colspan} rowSpan={cell.column.rowspan} {...cell.getCellProps([
                                                                {
                                                                    className: `data-td-animate lg:whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                                                    style: cell.column.style,
                                                                },
                                                            ])}>
                                                                {
                                                                    cell.value ? cell.render('Cell') : " "
                                                                }
                                                            </td>


                                                        )
                                                    })}
                                            </tr>

                                        )
                                    })}
                            </tbody>
                        </table>
                        :   
                        <>
                            <div className="h-[3rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[1rem] text-center">
                                No data found
                            </div>    
                        </>
                        }
                    </div>
                    {rows?.length <= Constants.noOfRowPerPage
                        ? <></>
                        : <Paginate
                            currentPage={pageIndex + 1}
                            totalCount={rows?.length}
                            pageSize={pageSize}
                            onPageChange={(page) => {
                                gotoPage(page - 1);
                            }}
                        />}
                    {<ConfirmPopup {...alert} onChange={onConfirmation} />}
                </>
            </div>
        </div>
    )
}

export default FundingTableInvestment