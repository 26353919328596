import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';
import Constants from '../../Constants';
import useScrollIntoView from './useScrollIntoView';

/**
 * The `InvestorTypeFilter` function is a React component that renders a dropdown menu for selecting an
 * investor type.
 * @returns a JSX element.
 */
function InvestorTypeFilter({ open, accordionClick, InvestorTypeOptions, setInvestorTypeOptions, customPathname }) {
    const { InvestorType, refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.investorType?.map(value => value.id) ?? [];
        setInvestorTypeOptions(InvestorType?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [InvestorType, customPathname, refineSearchFilters, setInvestorTypeOptions])



    const handleInvestorTypeChange = (e) => {
        const value = e.target.value
        // const values = companyType.filter(companyType => companyType.id === e.target.value);
        setInvestorTypeOptions(InvestorTypeOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.id === value })))

    };

    const selected = InvestorTypeOptions?.filter(option => option.isChecked)?.at(0)


    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
                ref={listItemRef}
            >
                <FilterWrapper
                    isSelect={true}
                    label={selected?.name ? "Investor Type - " + selected?.name : "Investor Type"}
                    onClick={accordionClick}
                    open={open}
                    definitionInfo={Constants.investorType}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    <select name="" id="" value={selected?.id ?? ""} onChange={handleInvestorTypeChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {InvestorTypeOptions && InvestorTypeOptions.length > 0 ? (
                            InvestorTypeOptions.map((investorType, index) => (
                                <option key={investorType.id} value={investorType.id}>{investorType.name}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>

            </li>

        </div>
    )
}

export default InvestorTypeFilter