    import { Badge } from 'antd';
    import React, { useEffect, useState } from 'react';
    import { useLocation } from 'react-router';
    import MultiSelectEntity from '../RefineSearch/TgMultiSelectEntity';
    import FilterWrapper from './FilterWrapper';
    import { filterReSubsector} from "../../Config/config";

    const Subsectors = ({ open, accordionClick, options, setOptions, customPathname, label }) => {
    const [subsectorVal, setSubsectorVal] = useState("");
    const [fetchedData, setFetchedData] = useState([]);

    useEffect(() => {
        if (subsectorVal !== "") {
        fetchData();
        }
    }, []);

    const categoryval = customPathname?.includes("exit") ? "exits" :
        customPathname?.includes("backed-ipos") ? "ipo" :
        customPathname?.includes("other-ma") ? "others"  : "investments";

    const fetchData = () => {
        const payload = {
        search_key: subsectorVal,
        category : categoryval,
        };
        
        fetch(filterReSubsector, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
        })
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data?.results) {
            const uniqueData = data.results.filter(item => !options.some(option => option.id === item.id));
            setFetchedData(uniqueData);
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };

    useEffect(() => {
        const uniqueFetchedData = fetchedData.filter(item => !options.some(option => option.id === item.id));
        setOptions(prevOptions => [...prevOptions.filter(item => item.isChecked === true), ...uniqueFetchedData]);
    }, [fetchedData]);

    const handleSubsectorChange = (e) => {
        const { name, checked, id } = e.target;
        let currentSubsector = [];

        if (name === "allSelect") {
        currentSubsector = options.map((subsector) =>
            ({ ...subsector, isChecked: checked })
        );
        setOptions(currentSubsector);
        } else {
        currentSubsector = options.map((subsector) =>
            // subsector.id === parseInt(id) ? { ...subsector, isChecked: checked } : subsector
            subsector.id === id ? { ...subsector, isChecked: checked } : subsector
        );
        setOptions(currentSubsector);
        }
    };

    const selectedSubsectorLength = options?.filter(option => option.isChecked)?.length ?? 0;

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
        <li className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"}>
            <FilterWrapper
            count={selectedSubsectorLength}
            isSelect={false}
            label={label}
            onClick={accordionClick}
            open={open}
            />
            <div
            className="relative overflow-hidden transition-all max-h-0 duration-700 overflow-y-auto no-scrollbar"
            style={{ maxHeight: open ? "10rem" : "" }}
            >
            {open === true ? <MultiSelectEntity
                fetchData={fetchData}
                handleEntityChange={handleSubsectorChange}
                primaryEntity={options}
                setCompanyVal={setSubsectorVal}
                city="city"
            /> : <></>}
            </div>
        </li>
        </div>
    );
    };

    export default Subsectors;
