import React, { useContext, useEffect, useState } from 'react';
import MultiSelectEntity from '../RefineSearch/TargetGroupSelect'; // Assuming MultiSelectEntity handles selection
import FilterWrapper from './FilterWrapper';
import { PeFilterContext } from '../../Contexts/PeFilterContext';


function TargetGroup({ open, accordionClick, targetGroupOptions,filterGroupUrl, setTargetGroupOptions, label }) {

  const {targetGroup, setTargetGroup} = useContext(PeFilterContext);

  useEffect(()=> {
    const testopt = targetGroup?.filter(opt => opt.isChecked);
    console.log(testopt, 'testOpt');
    if(!testopt.length){
      setTargetGroup(targetGroupOptions)
    }
  }, [targetGroupOptions])

  
  const handleGroupChange = (selectedNames) => {
    
    const updatedOptions = targetGroup.map(option => ({
        ...option,
        isChecked: selectedNames.includes(option.name)
    }));
    setTargetGroup(updatedOptions);
    setTargetGroupOptions(updatedOptions);

    console.log('targetGro', updatedOptions);
    
  };
  console.log(targetGroup, 'outside');
      
  

  return (
    <div className="border-b-[3px] border-[#E8E6E1]">
      <li className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"}>
        <FilterWrapper
          isSelect={false}
          label={label}
          count={targetGroup?.filter((option) => option.isChecked).length}
          onClick={accordionClick}
          open={open}
        />
        <div className="relative overflow-hidden transition-all max-h-0 duration-700 overflow-y-auto no-scrollbar" style={{ maxHeight: open ? "10rem" : "" }}>
          {open && (
            <>
              <MultiSelectEntity
                handleGroupChange={handleGroupChange}
                targetGroupOptions={targetGroup}
                setTargetGroupOptions={setTargetGroupOptions}
                filterGroupUrl={filterGroupUrl}
              />
            </>
          )}
        </div>
      </li>
    </div>
  );
}

export default TargetGroup;
