import React from 'react';
import { FaTimes } from 'react-icons/fa';

const DefinitionModal = ({onClose, definitionInfo, label}) => {
  console.log(Object.entries(definitionInfo).length,"h-3/4")
  return (
    <div className='flex font-sans_book_body cursor-default justify-center items-center z-10 fixed left-0 top-0 w-screen h-screen bg-gray-500 bg-opacity-75 transition-opacity'>
      <div className={`flex flex-col w-[95%] ${Object.entries(definitionInfo).length < 5 ? 'h-fit md:w-2/5' : "h-3/4  md:w-[65%]"} bg-white rounded-lg`}>
        <div className="py-2 sticky flex items-center justify-between top-1 rounded-t-lg">
          <p className="font-bold text-lg mt-1 mx-auto">{label}</p>
          <span onClick={onClose} className="pr-4 cursor-pointer">
            <FaTimes color="grey" />
          </span>
        </div>
        <div className="p-4 flex flex-col overflow-auto">
          {Object.entries(definitionInfo).map(([key, info]) => (
            label === "Investor Type"  ? (
              <div key={key} className="flex flex-row gap-7 my-2">
                <div className="font-bold">{key.replace(/_/g, ' ')}</div> 
                <div className="leading-5">
                  {info}
                </div>
              </div>
            ) : (
              <div key={key} className="mb-2">
                <div className="pl-2 font-bold">{key.replace(/_/g, ' ')}</div> 
                <div className="pl-8 py-2 leading-5">
                  {info}
                </div>
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default DefinitionModal;
