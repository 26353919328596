import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity';
import FilterWrapper from './FilterWrapper';
import Constants from '../../Constants';

/**
 * The `ExitDealTypeFilter` function is a React component that renders a filter for selecting exit deal
 * types, with options that can be expanded or collapsed.
 * @returns a JSX element.
 */
function ExitDealTypeFilter({ open, accordionClick, heading, ExitDealTypeOptions, setExitDealTypeOptions, customPathname, dataPath }) {
    const { refineSearchFilters, dealTypeFilterResponse } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.dealType?.map(value => value.id) ?? [];
        setExitDealTypeOptions(dealTypeFilterResponse[dataPath]?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [dealTypeFilterResponse, customPathname, refineSearchFilters, dataPath, setExitDealTypeOptions])


    const handleChange = ((e) => {
        const { name, checked, id } = e.target;

        if (name === "allSelect") {
            setExitDealTypeOptions(prev => (prev.map(data => ({ ...data, isChecked: checked }))));
        } else {
            setExitDealTypeOptions(prev => (prev.map(data => ({ ...data, isChecked: data.id === parseInt(id) ? checked : data.isChecked }))));
        }
    });

    const len = ExitDealTypeOptions?.filter(option => option.isChecked)?.length ?? 0


    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li className={open ? "bg-white relative px-3 py-2" : "relative px-3 py-1"}>
                <FilterWrapper
                    isSelect={false}
                    label={heading}
                    count={len}
                    onClick={accordionClick}
                    open={open}
                    definitionInfo={Constants.dealType}
                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleChange}
                        primaryEntity={ExitDealTypeOptions}
                    /> : <></>}
                </div>
            </li>
        </div>
    )
}

export default ExitDealTypeFilter