import React from 'react'

/**
 * The `MultiSelectEntity` function is a React component that renders a multi-select dropdown list of
 * entities with search functionality.
 */
function MultiSelectEntity(props) {
  console.log(props.primaryEntity,"jhgj")

  const [primaryEntity, setPrimaryEntity] = React.useState(props.primaryEntity);
  const [searchText, setsearchText] = React.useState("");
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);
  const handleEntityChange = props.handleEntityChange;

  const handleSearchTextChange = (e) => {
    setsearchText(e.target.value);
    props.setCitySearchText(e.target.value);
  }

  React.useEffect(() => {
    if (searchText !== "") {
      const filteredEntity = props.primaryEntity?.filter(entity => entity?.name?.toLowerCase()?.indexOf(searchText.toLowerCase()) !== -1);
      setPrimaryEntity(filteredEntity);
    } else {
      setPrimaryEntity(props.primaryEntity)
    }
  }, [props.primaryEntity, searchText])

  const count = primaryEntity.filter(option => option.isChecked).length;

console.log(props , "hhh" , primaryEntity , count)
    const handleSelectAll = () => {
      if (searchText === '') {
        const updatedPrimaryEntity = primaryEntity.map(entity => ({
          ...entity,
          isChecked: !selectAllChecked
        }));
        console.log(updatedPrimaryEntity , "ggktt")

        setPrimaryEntity(updatedPrimaryEntity);
        setSelectAllChecked(!selectAllChecked);
        handleEntityChange({
          target: { name: 'allSelect', checked: !selectAllChecked },
        });
      } else {
        const updatedPrimaryEntity = primaryEntity.map(entity => ({
          ...entity,
          isChecked: !selectAllChecked,
          // isChecked: filteredOptions.some(filteredEntity => filteredEntity.id === entity.id)
        }));
        setSelectAllChecked(!selectAllChecked);
        setPrimaryEntity(updatedPrimaryEntity);

        console.log("ggktt", updatedPrimaryEntity);

        const selectedEntities = updatedPrimaryEntity.map(entity => ({
          id: entity.id,
          name: entity.name,
          isChecked: !selectAllChecked
        }));

        handleEntityChange({
          target: {
            name: 'selectedEntities', 
            selectedEntities,
            checked: !selectAllChecked
          }
        });
      }
    };
  return (
    <>
      {props?.primaryEntity?.length > 0 && (
        <input
          type="search"
          onChange={handleSearchTextChange}
          className="input w-[98%] border-b-2 border-black my-2 p-2 pt-0 focus:outline-none"
          placeholder={props.city === "city" ? (count === 0 ? "Select city" : count > 1 ? count + " cities selected" : count + " city selected") : props.city === "country" ? (count === 0 ? "Select country" : count > 1 ? count + " countries selected" : count + " country selected") : "Search"}
          />
      )}
      {primaryEntity && primaryEntity.length > 0 && (props.showAll ?? true) ?
        <div
          className={`form-check font-sans_medium_body   cursor-default flex text-left  py-2 pr-2  w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto hover:bg-gray-50"`}
        >
          <input
            type="checkbox"
            className="form-check-input"
            name="allSelect"
            id="0"
            checked={props.city === "city" ? selectAllChecked : !props?.primaryEntity?.some((entity) => entity?.isChecked !== true)}
            onChange={ props.city === "city" ? handleSelectAll : handleEntityChange}
          />
          <label htmlFor='0' className="form-check-label mx-2 mr-2 cursor-pointer text-[13px] text-[#333333] leading-[17px] font-sans_book_body">
            {props.city === "city" ? 
              count > 822 ? 
              "Deselect All" : "Select All" 
            : 
            "All"
            }
          </label>
        </div>
        : ""}
      {primaryEntity && primaryEntity.length > 0 ? (
        primaryEntity.map((entity, index) => (
          <div
            className={`form-check font-sans_medium_body cursor-default flex text-left  py-2 pr-2  w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto hover:bg-gray-50"`}
            key={index}>
            <input
              type="checkbox"
              className="form-check-input"
              name={entity.name}
              id={entity.id}
              checked={entity?.isChecked || false}
              onChange={handleEntityChange}
            />
            <label htmlFor={entity.id} className="form-check-label mx-2 mr-2 cursor-pointer  text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{entity.name}</label>
          </div>
        ))
      ) :
        (
          <h1 className="text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{props?.field == 'sector' ? 'Choose Industry' : 'No results found!'}</h1>
        )}
    </>
  )
}

export default MultiSelectEntity
