import { React, useEffect, useState ,useMemo} from 'react'
import { useParams, NavLink, useLocation } from 'react-router-dom';
import compare from '../../images/compare.png'
import { exportPdf, maAdvisorDetails } from "../../Config/config"
import AdvisorOverview from './AdvisorOverview'
import AdvisorToCompanies from './AdvisorTo_Companies'
import AdvisorToAcquirer from './AdvisorTo_Acquirer'
import AdvisorNewsActivity from '../Advisor/AdvisorDetailPage/AdvisorNewsActivity'
import ComparePopUp from '../../Pages/ComparePopUp'
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { routeNames } from '../../routeSegments';
import RandomLogo from '../RandomLogo';
import CompanyLogo from '../CompanyLogo';
import ReadMoreReadLess from '../shared/ReadMoreReadLess';
import Tab from '../shared/Tab';
import PdfDownloader from '../shared/PdfDownloader';
import Constants from '../../Constants';
import SendDealPopUp from '../SendDealPopUp';
import AddNotePopover from '../shared/AddNotePopover';
import AlertPopUp from '../DetailPage/AlertPopUp';
import { Tooltip } from 'antd';
import PENotesPopup from '../../Components/NotesPopup/PENotesPopup'
import { CompareCompanyContext } from '../../Contexts/CompareCompanyContext';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { TableDataContext } from '../../Contexts/TableDataContext';
import { DataFoundContext } from '../../Contexts/DataFoundContext';


/**
 * The `AdvisorCompanyDetail` component is a React component that displays detailed information about
 * an advisor company, including an overview, companies they have invested in, acquirers they have
 * worked with, and recent news and activity. It also allows users to compare the advisor company with
 * other companies and perform various actions such as adding notes, sending deals, and downloading
 * PDFs.
 */
const AdvisorCompanyDetail = ({ advisorType }) => {
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' });; }
    const toCompanies = () => { document.getElementById("toCompanies").scrollIntoView({ behavior: 'smooth' });; }
    const toAcuirer = () => { document.getElementById("toAcuirer").scrollIntoView({ behavior: 'smooth' });; }
    const news = () => { document.getElementById("news").scrollIntoView({ behavior: 'smooth' }); }

    const [showModal, setShowModal] = useState(false);
    const [note, setNote] = useState(false)
    const [isActive, setIsActive] = useState(1);
    const { advisorsId } = useParams();
    const { getToken, isStudent } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true)
    const [advisorDetails, setAdvisorDetails] = useState({})
    const location = useLocation();
    const { setCompanyDetail, setCurrentPage } = useContext(CompareCompanyContext);

    const [skipScroll, setSkipScroll] = useState(false)
    const { newsAndActivityDataFound, setNewsAndActivityDataFound} = useContext(DataFoundContext);

    const [overviewEl, isOverviewVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [toCompanyInvestmentEl, isToCompanyInvestmentVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [toAcquirerEl, istoAcquirerVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    


    useEffect(() => {
        const abortController = new AbortController();
        setIsLoading(true);
        axios.post(maAdvisorDetails, {
            "advisor_id": advisorsId,
            "advisor_type": advisorType
        }, {
            signal: abortController.signal,
            headers: { "Authorization": getToken() }
        }).then(response => {
            setIsLoading(false);
            if (response.status === 200) {
                setAdvisorDetails(response?.data?.advisor_detail ?? {})
            }
            if(!response.data?.advisor_detail?.news_letter || response.data?.advisor_detail?.news_letter.length === 0){
                setNewsAndActivityDataFound(false);
            }else setNewsAndActivityDataFound(true);
            console.log(response.data, "res[");
        }).catch(error => {
                setNewsAndActivityDataFound(false);
        })

        return () =>{
            setNewsAndActivityDataFound(false);
            abortController.abort();
        } 
    }, [advisorType, advisorsId, getToken])

    /**
     * The function `onTabClick` is used to handle tab clicks and perform different actions based on
     * the selected tab index.
     */
    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview();
        } else if (tabIndex === 2) {
            toCompanies()
        } else if (tabIndex === 3) {
            toAcuirer()
        } else {
            news()
        }
        setIsActive(tabIndex);
    }

    /* The above code is using the `useMemo` hook in React to memoize a function called
    `setIsActiveOnScroll`. This function is created using the `debounce` function, which is likely
    imported from a library. The `debounce` function takes two arguments - a callback function
    (`index => setIsActive(index)`) and a delay time (100 milliseconds in this case). */
    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])


    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } else if (isToCompanyInvestmentVisible) {
            setIsActiveOnScroll(2);
        
          
        } else if (istoAcquirerVisible) {
            setIsActiveOnScroll(3);
        
        } else if (isnewsVisible) {
            setIsActiveOnScroll(4);
        }
    }, [isOverviewVisible, isToCompanyInvestmentVisible,  istoAcquirerVisible, isnewsVisible, setIsActiveOnScroll])

    const { tableAdvisorId: advId } = useContext(TableDataContext);

    const currentIndex = advId?.indexOf(Number(advisorsId));

    document.title = advisorType === "L" ?
        `${advisorDetails?.advisor_profile?.advisor_name ? advisorDetails.advisor_profile.advisor_name + " - " : ""}M&A Advisors - Venture Intelligence` :
        `${advisorDetails?.advisor_profile?.advisor_name ? advisorDetails.advisor_profile.advisor_name + " - " : ""}M&A Transaction Advisors - Venture Intelligence`;

        return (
        <>
            <div className="bg-[#FFFFFF] relative">
                <nav className="flex p-1 px-4 lg:px-1 border-b " aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            <div className='flex items-center'>
                                <NavLink
                                    to={location.state?.prevPath != null
                                        ? location.state?.prevPath
                                        : advisorType === 'L' ? routeNames.ma_advisors : routeNames.ma_transaction_advisor}
                                    className="ml-2 text-[11pt] lg:text-[12px]  leading-[14px] font-sans_book_body text-[#666666]">
                                    Advisors
                                </NavLink>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <span className=" text-[12px] text-[#666666] ">/</span>
                                <span className="px-1 text-[11pt] lg:text-[12px]  text-[#666666] leading-[14px] font-sans_book_body font-semibold">
                                    {advisorDetails?.advisor_profile?.advisor_name}
                                </span>
                            </div>
                        </li>
                    </ol>
                </nav>
                {advId &&
                    <>
                        <Tooltip title="Prev">
                            <NavLink
                            className="absolute top-2 right-8"
                            to={advisorType === 'L' ? routeNames.maAdvisorsById(advId[currentIndex - 1]) : routeNames.maTransactionAdvisorsById(advId[currentIndex - 1])}
                            >
                            {advId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next">
                            <NavLink
                            className="absolute top-2 right-4"
                            to={advisorType === 'L' ? routeNames.maAdvisorsById(advId[currentIndex + 1]) : routeNames.maTransactionAdvisorsById(advId[currentIndex + 1])}
                            >
                            {advId.length - 1 === currentIndex ? null : (
                                <FaChevronRight size={15} color='grey'/>
                            )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
                <div className="pl-5 pr-3">
                 <div className="lg:hidden mt-3  grid  md:grid-cols-8  grid-cols-10  items-center mb-4 ">
                    <div className=" md:col-span-6 col-span-5">
                        <Tooltip title="Compare Legal Advisor">
                            <button

                                className={
                                    showModal
                                        ? "bg-[#BC8B20] text-white text-[13px] font-sans_book_body py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center"
                                        : "bg-[#FFFFFF] text-[#666666] text-[13px] font-sans_book_body py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                }
                                type="button"
                                onClick={() => {
                                    setShowModal(!showModal)
                                    setCompanyDetail([])
                                    setCurrentPage(advisorType === "L" ? 'l_advisor' : "t_advisor")
                                }
                                }
                            >
                                <img
                                    className={
                                        showModal
                                            ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                            : "w-[20px] h-[18px] pr-1 blur-btn"
                                    }
                                    src={compare}
                                    alt=""
                                />
                                <span>Compare</span>
                            </button>
                        </Tooltip>
                    </div>
                    <div className="flex md:col-span-2 col-span-5 space-x-3">
                        <div className="grid grid-cols-5  gap-3 md:gap-2 lg:gap-1  flex items-center">
                            <div className="col-span-1 ">
                                <AlertPopUp cid={advisorsId} type_id={advisorType === "L" ? Constants.moduleIds.l_advisor_id : Constants.moduleIds.t_advisor_id} />{" "}
                            </div>

                            <div className="col-span-1">
                                <AddNotePopover
                                    title="Save List"
                                    companyId={advisorsId}
                                    companyName={advisorDetails?.advisor_profile?.advisor_name ?? ""}
                                    type_id={advisorType === "L" ? Constants.moduleIds.l_advisor_id : Constants.moduleIds.t_advisor_id} IsDetailed={true} 
                                    sub_type_master_id={advisorType === "L" ? Constants.subCategoryIds.ma_l_advisor_id : Constants.subCategoryIds.ma_t_advisor_id} />
                            </div>
                            <div className="col-span-1 mt-1">
                                <PENotesPopup note={note} cid={advisorsId} type_id={advisorType === "L" ? Constants.moduleIds.l_advisor_id : Constants.moduleIds.t_advisor_id} />
                            </div>
                            <div className="col-span-1">
                                <SendDealPopUp data={advisorDetails?.advisor_profile?.advisor_name ?? ""} />
                            </div>
                            {isStudent == 'true' ? <></> :
                            <div className="col-span-1">
                                <PdfDownloader
                                    url={exportPdf}
                                    fileName={advisorDetails?.advisor_profile?.advisor_name?.replaceAll(/\s/g, '')}
                                    data={{
                                        entity_id: advisorsId,
                                        type_id: advisorType === "L" ? Constants.moduleIds.l_advisor_id : Constants.moduleIds.t_advisor_id,
                                        type: Constants.primaryMasterIds.mANDaId
                                    }} />
                            </div>
                            }
                        </div>
                    </div>
                                                
                    </div>
                    <div className=" py-4 bg-[#FFFFFF]">
                        <ul>
                            <li className="w-full flex ">
                                <div className=" rounded-md">
                                    {advisorDetails?.advisor_profile?.icon_path == "default.png" || advisorDetails?.advisor_profile?.icon_path == "/default.png" || !advisorDetails?.advisor_profile?.icon_path ?
                                        <RandomLogo name={advisorDetails?.advisor_profile?.advisor_name} page="detail page" />
                                        :
                                        <CompanyLogo name={advisorDetails?.advisor_profile?.advisor_name} page="detail page" logo={advisorDetails?.advisor_profile?.icon_path} />
                                    }
                                </div>

                                <div className="ml-3 flex flex-1 flex-col">
                                    <span className="text-[#333333] text-[22px] font-semibold  leading-[24px]"> {advisorDetails?.advisor_profile?.advisor_name} </span>
                                    {/* <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body  ">IT & ITES</span>
                                    <span className="text-[11pt] leading-[15pt] text-[#777777] lg:text-[13px] font-sans_book_body lg:leading-[21px]">Enterprise Software (SaaS - HR Services - Recuirement)</span> */}
                                </div>
                                <div className="hidden lg:grid grid-cols-8  gap-2">
                                    <div className="col-span-3 mt-2">
                                        <Tooltip title="Compare Legal Advisor">
                                            <button

                                                className={
                                                    showModal
                                                        ? "bg-[#BC8B20] text-white text-[13px] font-sans_book_body py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center"
                                                        : "bg-[#FFFFFF] text-[#666666] text-[13px] font-sans_book_body py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                                }
                                                type="button"
                                                onClick={() => {
                                                    setShowModal(!showModal)
                                                    setCompanyDetail([])
                                                    setCurrentPage(advisorType === "L" ? 'l_advisor' : "t_advisor")
                                                }
                                                }
                                            >
                                                <img
                                                    className={
                                                        showModal
                                                            ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                                            : "w-[20px] h-[18px] pr-1 blur-btn"
                                                    }
                                                    src={compare}
                                                    alt=""
                                                />
                                                <span>Compare</span>
                                            </button>
                                        </Tooltip>
                                    </div>
                                    <div className="col-span-5 mt-[5px]">
                                        <div className=" grid grid-cols-5 gap-1 items-center">
                                            <div className="col-span-1 ">
                                                <AlertPopUp cid={advisorsId} type_id={advisorType === "L" ? Constants.moduleIds.l_advisor_id : Constants.moduleIds.t_advisor_id} />{" "}
                                            </div>

                                            <div className="col-span-1">
                                                <AddNotePopover
                                                    title="Save List"
                                                    companyId={advisorsId}
                                                    companyName={advisorDetails?.advisor_profile?.advisor_name ?? ""}
                                                    type_id={advisorType === "L" ? Constants.moduleIds.l_advisor_id : Constants.moduleIds.t_advisor_id} IsDetailed={true} 
                                                    sub_type_master_id={advisorType === "L" ? Constants.subCategoryIds.ma_l_advisor_id : Constants.subCategoryIds.ma_t_advisor_id} />
                                            </div>
                                            <div className="col-span-1 mt-1">
                                                <PENotesPopup note={note} cid={advisorsId} type_id={advisorType === "L" ? Constants.moduleIds.l_advisor_id : Constants.moduleIds.t_advisor_id} />
                                            </div>
                                            <div className="col-span-1">
                                                <SendDealPopUp data={advisorDetails?.advisor_profile?.advisor_name ?? ""} />
                                            </div>
                                            {isStudent == 'true' ?<></> :
                                            <div className="col-span-1">
                                                <PdfDownloader
                                                    url={exportPdf}
                                                    fileName={advisorDetails?.advisor_profile?.advisor_name?.replaceAll(/\s/g, '')}
                                                    data={{
                                                        entity_id: advisorsId,
                                                        type_id: advisorType === "L" ? Constants.moduleIds.l_advisor_id : Constants.moduleIds.t_advisor_id,
                                                        type: Constants.primaryMasterIds.mANDaId
                                                    }} />
                                            </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- tags--> */}
                    {advisorDetails?.advisor_profile?.about && <div className="bg-[#FFFFFF]">
                        <div className='flex items-center'>
                            <span className='text-[14px] text-[#333333] font-sans_medium_body font-medium mr-2'>About :</span>
                            <ReadMoreReadLess
                                className="text-[14px] text-[#333333] font-sans_book_body"
                                text={advisorDetails?.advisor_profile?.about}
                            />
                        </div>
                    </div>}
                </div>
            </div>
            <div>
                <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index scrollbar-remove">
                    <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                    <Tab active={isActive === 2} label={"To Companies"} onClick={() => onTabClick(2)} />
                    <Tab active={isActive === 3} label={"To Acquirers"} onClick={() => onTabClick(3)} />
                    {newsAndActivityDataFound ? <Tab active={isActive === 4} label={"Recent News & Activity"} onClick={() => onTabClick(4)} /> : ""}
                </div>
                <div className="bg-[#EDEDED]  space-y-5">
                    <section ref={overviewEl} className='scroll-mt-14' id="profile"><AdvisorOverview isLoading={isLoading} advisorDetails={advisorDetails?.advisor_profile} /></section>
                    <section ref={toCompanyInvestmentEl} className='scroll-mt-14 w-full' id="toCompanies">
                        <AdvisorToCompanies
                            advisorName={advisorDetails?.advisor_profile?.advisor_name}
                            isLoading={isLoading}
                            companies={advisorDetails?.to_companies_investment}
                            advisorType={advisorType}
                        />
                    </section>
                    <section ref={toAcquirerEl} className='scroll-mt-14 w-full' id="toAcuirer">
                        <AdvisorToAcquirer
                            advisorName={advisorDetails?.advisor_profile?.advisor_name}
                            isLoading={isLoading}
                            companies={advisorDetails?.to_acquirer_investment}
                            advisorType={advisorType}
                        />
                    </section>
                    <section ref={newsEl} className='scroll-mt-14' id="news"><AdvisorNewsActivity newsActivity={advisorDetails?.news_letter} /></section>
                </div>
            </div>
            {showModal ? (<ComparePopUp entityId={advisorsId} onClose={(e) => setShowModal(false)} />) : null}
        </>
    )

}

export default AdvisorCompanyDetail