import React, { useState, useMemo, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, Link, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { ADVISOR_COMPANY_TOACQUIRER } from "../../../Queries/Pe";
import moment from "moment";
import { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import CompanyLogo from "../../CompanyLogo";
import RandomLogo from '../../RandomLogo';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import OverflowToolTip from '../../shared/OverflowToolTip';
import useModule from "../../../hooks/useModule";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";
import ConfirmPopup from "../../../Utils/ConfirmPopup";

const AdvisorTo_Acquirer = (props) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const [portfolio, setPortfolioData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [openTab, setOpenTab] = useState(1);
  const [showXlDownload, setshowXlDownload] = useState(false);
  const { companyId } = useParams();
    let advisorProfileId = parseInt(companyId)
  const location = useLocation();
  let advisorType = "L";
  const{primaryMasterId} = useModule();
  const {isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext);
  const {toAcquirersDataFound} = useContext(DataFoundContext);
  // const handleFetchPortfoliotRequestCallback = (response, requestSuccess) => {
  //   try {
  //     setPortfolioData(response);
  //   } catch (e) {
  //     console.log("Error", e);
  //   }
  // };

  // const { data, loading, error } = useQuery(ADVISOR_COMPANY_TOACQUIRER, {
  //   variables: { CIAId: advisorIdPE, AdvisorType: advisorType },
  //   onCompleted: (response) =>
  //     handleFetchPortfoliotRequestCallback(response, true),
  //   onError: (response) => handleFetchPortfoliotRequestCallback(response),
  //   refetch: ADVISOR_COMPANY_TOACQUIRER,
  // });
  // console.log("portfolio1", ADVISOR_COMPANY_TOACQUIRER);

  // const PortfolioData = useMemo(() => portfolio, [portfolio]);
  // console.log("advisortoacquirer", PortfolioData);

  // const indexOfLastPost = currentPage * dataPerPage;
  // const indexOfFirstPost = indexOfLastPost - dataPerPage;
  // const currentData = portfolio?.advisor?.advisorAcquirer?.slice(
  //   indexOfFirstPost,
  //   indexOfLastPost
  // );
  // console.log(currentData, "currentData");
  // alert(currentData)

  const pageNumbers = [];

  for (
    let i = 1;
    i <=
    Math.ceil(portfolio?.length / dataPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }
//   const setPage = (pageNum) => {
//     setCurrentPage(pageNum);
//   };
/* The `customStringSortMemo` constant is a custom sort function that is used to sort the data in a
specific column of a table. It is created using the `useMemo` hook to memoize the function and avoid
unnecessary re-renders. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';
        return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
    }
}, []);

/* The `dealDateSort` constant is a custom sort function that is used to sort the data in the "Deal
Date" column of the table. It is created using the `useMemo` hook to memoize the function and avoid
unnecessary re-renders. */
const dealDateSort = useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
  }
}, [])
const peMatch  =matchPath(`${routePathSegments.pe}/*`,location.pathname);

const addCommasToNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
  /* The above code is defining an array of columns for a table. Each column has properties such as id,
  Header (column header text), accessor (data accessor for the column), className (CSS classes for
  the column), Cell (rendering component for the cell), and sortType (custom sort function for the
  column). These columns are used to render a table with data. */
  const columns = useMemo(() => [
    {
      id: 'acquirerName',
      Header: "Acquirer Name",
      accessor: "acquirerName",
      className: "clickable  text-left lg:relative sticky left-0  bg-white",
      headerClassName: "w-[19%] text-left  lg:relative sticky left-0   w-[3rem] bg-[#EDEDED] ",
      // headerClassName:"w-[10%]",
      Cell: ({ value }) => {
        return <div className=" leading-[1.8rem] lg:leading-[2rem] font-sans_book_body items-center justify-center">
          {value?.logo_path=="default.png" || value?.logo_path=="/default.png" || !value?.logo_path?
                <RandomLogo name={value.name} page="company page"/>
                :
                <CompanyLogo name={value.name} page="company page" logo={value?.logo_path}/>           
            }

        <OverflowToolTip  className="w-[10rem] lg:w-auto" component={<Link className=' text-start lg:inline flex hover:text-[#BC8B20]' state={{ cameFrom: location.state?.prevPath }} to={routeNames.maAcquiresById(value?.id)}>
          {value.name ?? "--"}
        </Link> } />
        </div>
      },
      sortType: customStringSortMemo,
    },
    {
      id:"dealDate",
      Header: "Deal Date",
      accessor: "dealDate",
      className:"w-[10%]",
     
    Cell: (cell) => {
      return <Link state={{ cameFrom: location.state?.prevPath ?? "" }} 
      onClick={(e) => {
          if (isDirectoryUser) {
              e.preventDefault();
              e.stopPropagation();
              onBlurColumnClick();
          }
        }}
      to={peMatch != null ?routeNames.peCompanyInvestmentDealById(cell.value.id,cell.value.dealId)
        :routeNames.vcCompanyInvestmentDealById(cell.value.id,cell.value.dealId)
     }>
          {cell?.value?.date !='' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"}

      </Link>
  },
    sortType:dealDateSort
    },
    {
      Header: "Deal Amount (US$ M)",
      accessor: "dealAmountInUS",
      className:"px-0 text-right pr-[2.75rem] w-[0%]",
      headerspanClassName:"ml-auto",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    },
    {
      Header: "Company Name",
      accessor: "companyName",
      className:"w-[10%]",
      Cell: ({ value }) => {
        return <OverflowToolTip className="w-[14rem] lg;w-auto" component={<Link className='hover:text-[#BC8B20]'
          state={{ cameFrom: location.state?.prevPath }}
          to={peMatch!=null?routeNames.peCompanyFundById(value?.id)
            :routeNames.vcCompanyFundById(value?.id) }>
          {value.name ?? "--"}
        </Link>} />
      },
      sortType: customStringSortMemo,
    },
    {
      Header: "Post Money Valuation (INR Cr)",
      accessor: "postMoney",
      className:"px-0 text-right pr-[2.75rem] w-[15%]",
      headerspanClassName:"ml-auto",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    },
    {
      Header: "Revenue Multiple",
      accessor: "revenueMultiple",
      className:"px-0 text-right pr-[2.75rem] w-[15%]",
      headerClassName:"w-[15%]",
       headerspanClassName:"ml-auto",
       Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
    }
  ], []);
  
  /* The `rowData` constant is using the `useMemo` hook to memoize the data transformation logic. It
  takes the `props.toAquirer` array as input and iterates over each element to create a new array of
  objects. Each object in the new array represents a row in the data table and contains specific
  properties extracted from the original data. */
  const rowData = useMemo(() => {
    const rows = [];

    props.toAquirer?.forEach(company => {
      console.log(company,'advisorCompanyDetailAcquire');
      rows.push({
        acquirerName: { name: company?.Acquirer_Name, id: company?.AcquirerId, logo_path:company?.logo_path},
        dealDate: {date:company?.Deal_Date, dealId: company?.DealId, id: company?.CompanyId},
        dealAmountInUS: company?.Deal_Amount,
        companyName: { name: company?.Companyname, id: company?.CompanyId },
        postMoney: company?.Post_Money_Valuation > 0 ? company?.Post_Money_Valuation :'',
        revenueMultiple: company?.Revenue_Multiple > 0 ? company?.Revenue_Multiple :'',
      })
    });
    setshowXlDownload(rows.length!=0)
    return rows;
  }, [props.toAquirer])
  const { pathname, state } = useLocation();

    const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, state?.prevPath ?? "")
  /* The `initialSort` variable is used to specify the initial sorting configuration for the data
  table. It is an array containing an object with two properties: `id` and `desc`. */
    const initialSort = useMemo(() => [
      {
          id:"dealDate",
          desc: true
      }
  ], []);

  const [alert, setalert] = useState({ message: "", show: false });

  const onBlurColumnClick = () => {
          setalert({ show: true, message: Constants.maSubscriptionMsg, isAlertOnly: true, closeBtnName: "OK" })
  }

  const onConfirmation = () => {
          setalert({ show: false, message: "" })
  }

  return (
    <>
      {toAcquirersDataFound ? (
        <div className="px-4 bg-[#EDEDED] w-[100%]">
          <div className="bg-white border overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className=" pt-4 px-5 border-b border-b-[#F1F1F1]  ">
              <div className="flex justify-between mb-3">
                <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:leading-[22px] font-semibold lg:text-[17px]">
                  To Acquirers
                </h3>
                {!isDirectoryUser ? (isStudent == 'false'  && isTrialExport !='false' && showXlDownload)&&<ExcelDownloader
                                url={exportExcel}
                                fileName={Constants.exportNames.to_acuirers(props.advisorName)}
                                data={{
                                  "type":primaryMasterId,
                                  "export_from": "detail",
                                  "module_type": "advisors",
                                  "advisor_type":"L",
                                  "section": "to_acquirers",
                                  "advisor_id": [advisorProfileId]
                              }} /> : ""} 
              </div>
            </div>
            <div className="space-y-6">
              <div className="bg-white pb-2 rounded-[6px]">
                <DataTable columns={columns} data={rowData} sortBy={initialSort}/>
              </div>
            </div>       
          </div>
        </div>
       ) : "" }
       {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </>
  );
};

export default AdvisorTo_Acquirer;
