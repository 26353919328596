import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import Constants from '../../Constants';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { BiRightArrowAlt } from 'react-icons/bi';
import { Tooltip } from 'antd';
import { PeFilterContext } from '../../Contexts/PeFilterContext';

const getYears = (startDate, endDate) => {
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    let diff = toDate.diff(fromDate, 'year');
    let range = [];
    for (let i = 0; i <= diff; i++) {
        range.push(moment(startDate).add(i, 'year'));
    }
    return range;
};

const monthList = moment.monthsShort().map(month => ({ id: month, name: month, isChecked: false }));


// const yearsList = getYears(moment().subtract(25, 'years'), moment()).map(date => ({ id: date.year(), name: date.year(), isChecked: false }));

const isFutureDate = (date) => {
    return date?.isAfter(moment(), 'month') ?? true;
};

const MonthRangePlaceholder = ({ fromDate, toDate }) => {
    const formattedFromDate = moment(fromDate).format('MMM YY');
    const formattedToDate = moment(toDate).format('MMM YY');
    return <>
        <div className='text-[13px] whitespace-nowrap'>{`${formattedFromDate} - ${formattedToDate}`}</div>
        <div className='ml-[2px]'><FaRegCalendarAlt size={20} color='#d2d2d2' /></div>
    </>;
};

function MonthRangePicker({ value = Constants.initialMonthRange, onChange }) {
    const location = useLocation();
    const yearsList = location.pathname.includes('/re/') || location.pathname.includes('/ma/') 
        ? getYears(moment().subtract(20, 'years'), moment()).map(date => ({ id: date.year(), name: date.year(), isChecked: false }))
        : getYears(moment().subtract(25, 'years'), moment()).map(date => ({ id: date.year(), name: date.year(), isChecked: false }));
    const [open, setOpen] = useState(false);
    const [FromDate, setFromDate] = useState(moment());
    const [ToDate, setToDate] = useState(moment());
    const [tempFromDate, setTempFromDate] = useState(moment());
    const [tempToDate, setTempToDate] = useState(moment());
    const { setShowMontnPicker } = useContext(PeFilterContext);

    useEffect(() => {
        setFromDate(moment(value.at(0)));
        setToDate(moment(value.at(1)));
        setTempFromDate(moment(value.at(0)));
        setTempToDate(moment(value.at(1)));
    }, [value, open]);

    const handleMonth = (e) => {
        const { name, value } = e.target;
        const selectedMonth = moment().month(value).format('M');

        if (name === "from") {
            const temp = moment(tempFromDate);
            temp.set('month', parseInt(selectedMonth) - 1);
            setTempFromDate(temp);
        } else {
            const temp = moment(tempToDate);
            temp.set('month', parseInt(selectedMonth) - 1);
            setTempToDate(temp);
        }
    };

    const handleYear = (e) => {
        const { name, value } = e.target;

        if (name === "from") {
            const temp = moment(tempFromDate);
            temp.set('year', parseInt(value));
            setTempFromDate(temp);
        } else {
            const temp = moment(tempToDate);
            temp.set('year', parseInt(value));
            setTempToDate(temp);
        }
    };

    const onSubmit = () => {
        if (isFutureDate(tempFromDate)) {
            toast.warning('From date cannot be greater than current date');
            return;
        }

        if (tempFromDate.isAfter(tempToDate)) {
            toast.warning('From date cannot be greater than to date');
            return;
        }

        if (isFutureDate(tempToDate)) {
            toast.warning('To date cannot be greater than current date');
            return;
        }

        if (tempToDate.isBefore(tempFromDate)) {
            toast.warning('To date cannot be less than from date');
            return;
        }

        if (onChange != null) {
            onChange([tempFromDate.format('YYYY-MM-DD'), tempToDate.format('YYYY-MM-DD')]);
            setOpen(false);
            setShowMontnPicker(0);
        }
    };

    useEffect(() => {
        setShowMontnPicker(0);
    }, []);

    return (
        <>
            {open
                ? null
                : <div onClick={(e) => { setOpen(true); setShowMontnPicker(1); }} className='cursor-pointer py-2 px-1 select-none rounded-[5px] flex items-center justify-evenly border border-[#d2d2d2] w-[130px]'>
                    <MonthRangePlaceholder fromDate={value.at(0)} toDate={value.at(1)} />
                </div>}
            {!open
                ? null
                : <div className='flex items-center border border-[#d2d2d2] shadow-md rounded-md'>
                    <div className='flex flex-grow items-center py-2 pl-2 pr-1 justify-evenly'>
                        <select value={tempFromDate?.format('MMM')} name="from" id="" onChange={handleMonth}
                            className="border border-[#D9BD7F] mr-1 rounded focus:outline-none bg-[#FFF9EC] w-[50px] text-[12px]">
                            {monthList?.map(month => (
                                <option key={month.id} value={month.id}>{month.name}</option>
                            ))}
                        </select>
                        <select value={tempFromDate?.year()} name="from" id="" onChange={handleYear}
                            className="border border-[#D9BD7F] rounded focus:outline-none bg-[#FFF9EC] w-[55px] text-[12px]">
                            {yearsList?.slice(0).reverse().map(year => (
                                <option key={year.id} value={year.id}>{year.name}</option>
                            ))}
                        </select>
                        <p className="text-[12px] mx-2">To</p>
                        <select value={tempToDate?.format('MMM')} name="to" id="" onChange={handleMonth}
                            className="border border-[#D9BD7F] mr-1 rounded focus:outline-none bg-[#FFF9EC] w-[50px] text-[12px]">
                            {monthList?.map(month => (
                                <option key={month.id} value={month.id}>{month.name}</option>
                            ))}
                        </select>
                        <select value={tempToDate?.year()} name="to" id="to" onChange={handleYear}
                            className="border border-[#D9BD7F] rounded focus:outline-none bg-[#FFF9EC] w-[55px] text-[12px]">
                            {yearsList?.slice(0).reverse().map(year => (
                                <option key={year.id} value={year.id}>{year.name}</option>
                            ))}
                        </select>
                        <Tooltip title="Search">
                            <BiRightArrowAlt onClick={onSubmit} className='cursor-pointer' size={24} color='#BC8B20' />
                        </Tooltip>
                    </div>
                </div>}
        </>
    );
}

export default MonthRangePicker;
