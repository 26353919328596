import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { filterLocationUrl, filterSectorByIndutry } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { TableHeaderContext } from '../../Contexts/TableHeaderContext';
import { UserContext } from '../../Contexts/UserContext';
import AcquirerDealRangeFilter from '../RefineSearchComponents/AcquirerDealRangeFilter';
import CityFilter from '../RefineSearchComponents/CityFilter';
import IndustryFilter from '../RefineSearchComponents/IndustryFilter';
import SectorFilter from '../RefineSearchComponents/SectorFilter';
import { TableDataContext } from '../../Contexts/TableDataContext';
import Constants from '../../Constants';
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import TargetCompany from '../RefineSearchComponents/TargetCompany';
import Acquirers from '../RefineSearchComponents/Acquirers';
import LAdvisor from '../RefineSearchComponents/lAdvisor';
import TAdvisor from '../RefineSearchComponents/tAdvisor';
import {filterTargetCompany ,filterAcquirers,filterlAdvisors,filtertAdvisors} from "../../Config/config"
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";

/**
 * The `MaAdvisorRefineSearch` function is a React component that renders a form for refining search
 * filters in a mergers and acquisitions advisor application.
 * @returns The function `MaAdvisorRefineSearch` returns a JSX element.
 */
function MaAdvisorRefineSearch({ customPathname, adviosrType }) {
    const location = useLocation();
    console.log(location.pathname ,"huhuhkhg" , customPathname)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[customPathname]?.globalSearch?.length > 0 ? true : false
    const { setrefineSearchFilters,SwitchState,setSwitchState, onErrorHandler, refineSearchFilters,dropdown,Sectors, setSectors, MinAcquirerDealRange, MaxAcquirerDealRange,setDbDropdown,dbDropdown, setMonthPickerValue,TargetCompanyOptions,setTargetCompanyOptions , setAcquirersOptions ,AcquirersOptions,setlAdvisorsOptions , lAdvisorsOptions , tAdvisorsOptions ,settAdvisorsOptions } = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const { getToken } = useContext(UserContext);
    const [select, setSelected] = useState(0);

    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [MinDealRangeOptions, setMinDealRangeOptions] = useState([])
    const [MaxDealRangeOptions, setMaxDealRangeOptions] = useState([])


   /**
    * The function `applyFilter` updates the refine search filters based on the selected industries,
    * city, sectors, and deal size options.
    */
    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedSectors = Sectors.filter(option => option.isChecked);
        const minDealRange = MinDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const maxDealRange = MaxDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedOperators = SwitchState;
        const selectedAcquirers = AcquirersOptions?.filter(option => option.isChecked); 
        const selectedlAdvisors = lAdvisorsOptions?.filter(option => option.isChecked);
        const selectedTargetCompany = TargetCompanyOptions?.filter(option => option.isChecked); 
        const selectedtAdvisors = tAdvisorsOptions?.filter(option => option.isChecked);


        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                acquirers: selectedAcquirers,
                ladvisors: selectedlAdvisors,
                tadvisors: selectedtAdvisors,
                target_company:selectedTargetCompany,
                city: selectedCity,
                operator: selectedOperators,
                dealSize: minDealRange != null && maxDealRange != null ? [minDealRange, maxDealRange] : [],
            },
        }))
    }

  /**
   * The function `resetFilter` resets a specific filter in the `refineSearchFilters` object by setting
   * its value to an empty object.
   */
    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
        setTargetCompanyOptions([]);
        setAcquirersOptions([]);
        setlAdvisorsOptions([]);
        setSectors([]);
        settAdvisorsOptions([]);
    }


    // Submitting Refine Search Form 
   /**
    * The function `refineSearchFilter` updates search filters, resets month picker value, sets current
    * page to 1, applies filters, selects the first option, and navigates to a custom pathname if the
    * current pathname is different.
    * @param event - The event parameter is an object that represents the event that triggered the
    * function. In this case, it is used to prevent the default behavior of the event (e.g., preventing
    * a form submission) by calling the `preventDefault()` method on the event object.
    */
    const refineSearchFilter = (event) => {
        event.preventDefault();
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[]}
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
        setMonthPickerValue(Constants.initialMonthRange)
        }
        setCurrentPage(1);
        applyFilter();
        setSelected(0)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }

    }

   /**
    * The function `onResetClick` resets the current page, filters, and navigates to a custom pathname
    * if it is not already the current location.
    * @param event - The event parameter is an object that represents the event that triggered the
    * function. In this case, it is likely a click event on a reset button.
    */
    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

   /**
    * The function `accordionClick` sets the selected value to either 0 or the provided value.
    * @param val - The `val` parameter is a value that is passed to the `accordionClick` function. It
    * is used to determine whether the accordion should be selected or deselected.
    */
    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    // useEffect(() => {
    //     const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

    //     if (ids.length > 0) {
    //         const sectorsIds = SectorOptions.filter(sector => sector.isChecked)?.map(option => option.id)
    //         axios.post(filterSectorByIndutry, {
    //             "industry": ids,
    //             category: "investments"
    //         }, {
    //             headers: {
    //                 "Authorization": getToken()
    //             }
    //         }).then(response => {
    //             if (response.status === 200) {
    //                 setSectors(response.data?.data?.map(option => ({ ...option, isChecked: sectorsIds.includes(option.id) })))
    //             } else {
    //                 setSectors([])
    //             }
    //         }).catch(onErrorHandler)
    //     } else {
    //         setSectors([])
    //     }

    // }, [IndustriesOptions])

    // useEffect(()=>{
    //     fetch(filterTargetCompany, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({ search_key: "initial" })
    //         })
    //         .then(response => {
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }
    //         return response.json();
    //         })
    //         .then(data => {
    //         setTargetCompanyOptions(data?.results);
    //         console.log(TargetCompanyOptions,"vbvb")
    //         })
    //         .catch(error => {
    //         console.error('Error fetching data:', error);
    //         });
    //     },[])

    //     useEffect(()=>{
    //         fetch(filterAcquirers, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({ search_key: "initial" })
    //             })
    //             .then(response => {
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             return response.json();
    //             })
    //             .then(data => {
    //                 setAcquirersOptions(data?.results);
    //             })
    //             .catch(error => {
    //             console.error('Error fetching data:', error);
    //             });
    //         },[])

    //         useEffect(()=>{
    //             fetch(filterlAdvisors, {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify({ search_key: "initial" })
    //                 })
    //                 .then(response => {
    //                 if (!response.ok) {
    //                     throw new Error('Network response was not ok');
    //                 }
    //                 return response.json();
    //                 })
    //                 .then(data => {
    //                     setlAdvisorsOptions(data?.results);
    //                 })
    //                 .catch(error => {
    //                 console.error('Error fetching data:', error);
    //                 });
    //             },[])

    //             useEffect(()=>{
    //                 fetch(filtertAdvisors, {
    //                     method: 'POST',
    //                     headers: {
    //                         'Content-Type': 'application/json'
    //                     },
    //                     body: JSON.stringify({ search_key: "initial" })
    //                     })
    //                     .then(response => {
    //                     if (!response.ok) {
    //                         throw new Error('Network response was not ok');
    //                     }
    //                     return response.json();
    //                     })
    //                     .then(data => {
    //                         settAdvisorsOptions(data?.results);
    //                     })
    //                     .catch(error => {
    //                     console.error('Error fetching data:', error);
    //                     });
    //                 },[])


    useEffect(() => {

        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];

        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCityOptions(response.data.city?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters])

    const toggleSwitchState = () => {
        setSwitchState(prevState => prevState === "and" ? "or" : "and");
    };

    const handleDbToggle = () => {
        setDbDropdown(!dbDropdown);
    };

    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter">
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333]  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                label="Industry"
                                open={select === 1} />
                            <SectorFilter
                                setOptions={setSectors}
                                options={Sectors}
                                label="Sector"
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(2)}
                                open={select === 2}
                            />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(6)}
                                label="City(of Target Company)"
                                open={select === 6} />
                            <AcquirerDealRangeFilter
                                dataPath="dealSize"
                                customPathname={customPathname}
                                MinDealOptions={MinDealRangeOptions}
                                setMinDealOptions={setMinDealRangeOptions}
                                MaxDealOptions={MaxDealRangeOptions}
                                MinDealRange={MinAcquirerDealRange}
                                MaxDealrange={MaxAcquirerDealRange}
                                label="Deal Size ($M)"
                                setMaxDealOptions={setMaxDealRangeOptions} />
                        </ul>
                        <ul  className='flex pt-3 text-[16px] pl-1 border-t-2 border-[#e8e6e1] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer ' onClick={handleDbToggle}>
                            <span className="mt-[2px]">
                                {dbDropdown? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            </span>
                            <h1 className='font-bold text-[16px] ml-1'>{customPathname.includes("investors") ? "SHOW INVESTORS BY" : customPathname.includes("companies") ? "SHOW DEALS BY" : "SHOW ADVISORS BY"}</h1>
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dbDropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] mb-3  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]`}>

                            <TargetCompany 
                                options={TargetCompanyOptions}
                                setOptions={setTargetCompanyOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(16)}
                                label="Target Company"
                                open={select === 16}
                            />

                            <Acquirers 
                                options={AcquirersOptions}
                                setOptions={setAcquirersOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(17)}
                                label="Acquirers"
                                open={select === 17 }
                            />

                            {customPathname !== "/ma/transaction-advisors" ? (
                                <LAdvisor 
                                    options={lAdvisorsOptions}
                                    setOptions={setlAdvisorsOptions}
                                    customPathname={customPathname}
                                    label="Legal Advisors"
                                    accordionClick={() => accordionClick(18)}
                                    open={select === 18}
                                />
                            ) : (
                                <TAdvisor 
                                    options={tAdvisorsOptions}
                                    setOptions={settAdvisorsOptions}
                                    label="Transaction Advisors"
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(19)}
                                    open={select === 19}
                                />
                            )}

                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
        </div>
    )
}

export default MaAdvisorRefineSearch
