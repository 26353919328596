import React from "react";
import { useState } from "react";
import PE_VC from "../../../images/noun-investment-2180740_4/png/noun-investment-2180740.png";
import TF_US from "../../../images/Shape_4/png/Shape.png";
import TF_INR from "../../../images/noun-investment-2180740_2/png/noun-investment-2180740.png";
import Company from "../../../images/noun-investment-2180740_5/png/noun-investment-2180740.png";
import { useParams } from "react-router-dom";
import { cfsTopCardUrl } from "../../../Config/config";
import axios from "axios";
import useModule from "../../../hooks/useModule";
import { useContext } from "react";
import { UserContext } from "../../../Contexts/UserContext";
import { useEffect } from "react";
import { TableDataContext } from "../../../Contexts/TableDataContext";
import { CurrencyContext } from "../../../Contexts/CurrencyContext";
import Loader from "../../Loader";
import CAGRarrow from "./CAGRarrow";
import { calculateGrowth, parseFinancials } from "./useGetCAGR";

/**
 * The `Card` component is a functional component in JavaScript that fetches and displays card details
 * based on certain parameters.
 * @returns The Card component is returning a JSX element, which represents the UI of the component.
 */
const Card = () => {
  const [detailCard, setDetailCard] = useState([]);
  const { companyId } = useParams();
  const { data } = useContext(TableDataContext);
  const [isLoading, setisLoading] = useState(data?.length === 0);
  const { getToken } = useContext(UserContext);
  const { currency } = useContext(TableDataContext);
  const { financialType } = useContext(CurrencyContext);

  const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    setDetailCard(
      calculateGrowth(parseFinancials(data?.profit_loss), financialType)
    );
  }, [data, financialType]);

  return (
    <div className="bg-[#EDEDED]">
      <div className="sm:full mt-[4.25rem] md:mt-[3rem] h-full  bg-[#F2F2F2]">
        {/* <div> */}

        {isLoading || (detailCard?.length ?? 0) < 0 ? (
          <div className="w-full h-[72px] flex items-center justify-center">
            <Loader sizeClassName="w-6 h-6" />
          </div>
        ) : (
          <div className="overflow-x-auto lg:grid grid-cols-3 pl-1 pr-2 gap-2 py-[0.7rem] flex xl:overflow-hidden">
            {/* ----card1----- */}
            {detailCard.year?.map((detail) => {
              return (
                <section className="flex-shrink-0 ">
                  <div className="bg-white rounded-md  border border-gray-200 overflow-hidden card-animate">
                    <div className=" text-[13pt]  leading-[17pt] lg:text-[13px] lg:leading-[17px] font-sans_bold_body font-bold  text-white tracking-[-0.3px] text-center bg-gray-500 py-1">
                      {detail?.y} YEAR GROWTH
                    </div>
                    <div className="grid xl:grid-cols-3 sm:grid-cols-7 gap-1 bg-[#F2F2F2]">
                      <div className="xl:col-span-1 lg:col-span-3 bg-white space-y-1 py-1">
                        <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] flex space-x-1 justify-center items-center">
                          <label className="whitespace-nowrap font-semibold">
                            TOTAL INCOME
                          </label>
                          {detail?.totalIncomeGrowth != 0 && detail.last_actual_total_income !== 0 ? (
                            <>
                              <CAGRarrow
                                arrow_indication={
                                  detail?.current_actual_total_income <
                                  detail.last_actual_total_income
                                }
                              />
                              <span
                                className={
                                  detail?.current_actual_total_income <
                                  detail.last_actual_total_income
                                    ? "text-red-500"
                                    : "text-green-900"
                                }
                              >
                                {detail?.totalIncomeGrowth
                                  ? detail?.totalIncomeGrowth + "%"
                                  : ""}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                          {detail?.current_actual_total_income != 0
                            ? "₹ "+addCommasToNumber(detail?.current_total_income) + " Cr"
                            : "--"}
                        </div>
                        <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                          {detail?.last_actual_total_income != 0
                            ? "₹ "+addCommasToNumber(detail?.last_total_income) + " Cr"
                            : "--"}
                        </div>
                      </div>

                      <div className="xl:col-span-1 lg:col-span-2  bg-white space-y-1 py-1">
                        <div className=" text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] flex space-x-1 justify-center items-center">
                          <label className="font-semibold">EBITDA</label>
                          {detail?.ebitdaGrowth != 0 && detail?.last_actual_ebitda !== 0 ? (
                            <>
                              <CAGRarrow
                                arrow_indication={
                                  detail?.current_actual_ebitda < detail.last_actual_ebitda
                                }
                              />
                              <span
                                className={
                                  detail?.current_actual_ebitda < detail.last_actual_ebitda
                                    ? "text-red-500"
                                    : "text-green-900"
                                }
                              >
                                {detail?.ebitdaGrowth
                                  ? detail?.ebitdaGrowth + "%"
                                  : ""}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                          {detail?.current_actual_ebitda != 0
                            ? "₹ "+addCommasToNumber(detail?.current_ebitda) + " Cr"
                            : "--"}
                        </div>
                        <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                          {detail?.last_actual_ebitda != 0
                            ? "₹ "+addCommasToNumber(detail?.last_ebitda) + " Cr"
                            : "--"}
                        </div>
                      </div>

                      <div className="xl:col-span-1 lg:col-span-2  bg-white space-y-1 py-1">
                        <div className=" text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] flex space-x-1 justify-center items-center">
                          <label className="font-semibold">PAT</label>
                          {detail?.patGrowth != 0 && detail.last_actual_pat !== 0 ? (
                            <>
                              <CAGRarrow
                                arrow_indication={detail?.current_actual_pat < detail.last_actual_pat}
                              />
                              <span
                                className={
                                  detail?.current_actual_pat < detail.last_actual_pat
                                    ? "text-red-500"
                                    : "text-green-900"
                                }
                              >
                                {detail?.patGrowth
                                  ? detail?.patGrowth + "%"
                                  : ""}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                          {detail?.current_actual_pat != 0
                            ? "₹ "+addCommasToNumber(detail?.current_pat) + " Cr"
                            : "--"}
                        </div>
                        <div className="text-[11pt]  leading-[13pt] lg:text-[10px] 2xl:text-[11px] lg:leading-[13px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] text-center">
                          {detail?.last_actual_pat != 0
                            ? "₹ "+addCommasToNumber(detail?.last_pat) + " Cr"
                            : "--"}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              );
            })}
          </div>
        )}
        <div className="border-b-2 border-[#e5e5e5]"></div>
      </div>
    </div>
  );
};

export default Card;
