import { React, useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import { getFilingsApi } from "../../../../Config/config";
import downloadIcon from "../../../../images/ReportsImages/download.png";
import routePathSegments, { routeNames } from "../../../../routeSegments";
import { NavLink, useLocation, useParams } from "react-router-dom";
import useModule from "../../../../hooks/useModule";
import Loader from "../../../Loader";
import { RaiseRequestContext } from "../../../../Contexts/RaiseRequestContext";
import { UserContext } from "../../../../Contexts/UserContext";

/**
 * The component  displays filing data for a company, with the
 * option to show all filings or only the first five.
 * The FilingsFinancialTable component contains a table of
 * filings data. The table is conditionally rendered based on the isLoading and fillingsData variables.
 * If isLoading is true, a loader component is displayed. If fillingsData is empty or the companyName
 * is null or empty, a message is displayed indicating that the data is not found. Otherwise, the table
 * is rendered 
 */
const Consolidated = ({ companyName, showAll = false }) => {
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [startAfterKey , setStartAfterKey] = useState(null);
  const [loadMore, setLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation();
  const { companyId } = useParams();
  let companyProfileId = parseInt(companyId);
  const { primaryMasterId } = useModule();
  const { openRequestPopup } = useContext(RaiseRequestContext);
  const {getToken} = useContext(UserContext);


  useEffect(() => {

    if (companyName != null && companyName !== "") {
      setIsLoading(true)
      axios(`${getFilingsApi}`, {
        method: "POST",
        data: {
          company_name: companyName,
          type: primaryMasterId,
          "startAfterKey": startAfterKey
        },
        headers: {
          "Content-Type": "application/json",
          "Authorization": getToken()
        },
      })
        .then((response) => {
          setIsLoading(false);
          if (response?.data?.message === "Success") {
            setDatas(response?.data?.data)
            setData(prevData => [...prevData, ...response?.data?.data]);
            setStartAfterKey(response?.data?.startAfterKey)
            // console.log(response?.data,"jgfjhgiygu" , startAfterKey)
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        });
    }
  }, [companyName, primaryMasterId,loadMore]);

  const fillingsData = useMemo(() => {

    let fillingData = []

    fillingData = Object.keys(data)?.map(key => (data[key]))

    if (showAll) {
      return fillingData;
    }
    return fillingData?.slice(0, 5);
  }, [data, showAll])

  const handleLoadMore = ()=>{
    setLoadMore(prevLoadMore => !prevLoadMore)
  }


  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 mb-4 align-middle md:px-6 lg:px-8">
            <div className="overflow-x md:overflow-hidden ">
              {
                isLoading
                  ? <div className="bg-white w-full flex items-center justify-center h-[10rem]">
                    <Loader />
                  </div>
                  : fillingsData.length === 0 || companyName === null || companyName === ""
                    ? <div className="px-5 bg-white w-full flex items-center justify-center h-[5rem]">
                      <p className="text-[14px]">Data not found. Please <button className='text-[#A5711A] hover:underline' onClick={() => openRequestPopup('financial')}>Click Here</button> to alert Venture Intelligence about this. Thanks</p>
                    </div>
                    : <tbody className="divide-y divide-gray-200 bg-white">
                        <div className="grid grid-cols-6 gap-4">
                          <div className="col-start-1 col-end-3">
                            {
                              (() => {
                                // Flatten the entries of all objects
                                const entries = fillingsData.flatMap(Object.entries);

                                // Helper function to extract the year from the report name
                                const extractYear = (key) => {
                                  const match = key.match(/\d{4}/);
                                  return match ? parseInt(match[0], 10) : 0;
                                };

                                // Filter and sort "Annual Report" entries in descending order by year
                                const annualReportEntries = entries
                                  .filter(([key]) => key.includes("Annual Report") && !key.includes("Consolidated"))
                                  .sort(([keyA], [keyB]) => extractYear(keyB) - extractYear(keyA));

                                // Filter and sort "Consolidated Annual Report" entries in descending order by year
                                const consolidatedAnnualReportEntries = entries
                                  .filter(([key]) => key.includes("Consolidated Annual Report"))
                                  .sort(([keyA], [keyB]) => extractYear(keyB) - extractYear(keyA));

                                // Filter and sort other entries in ascending order
                                const otherEntries = entries
                                  .filter(([key]) => !key.includes("Annual Report") && !key.includes("Consolidated Annual Report"))
                                  .sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

                                // Concatenate the sorted arrays
                                const sortedEntries = [...annualReportEntries, ...consolidatedAnnualReportEntries, ...otherEntries];

                                // Map over the sorted entries
                                return sortedEntries.map(([key, value], i) => (
                                  <a
                                    key={i} // Ensure each element has a unique key
                                    className="pl-3 flex whitespace-nowrap py-3 text-[12pt] leading-[17pt] lg:text-[13px] lg:leading-[17px] font-sans_book_body text-[#2B2A29] tracking-[-0.3px] sm:pl-6"
                                    href={value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      alt=""
                                      src={downloadIcon}
                                      className="h-4 w-4 flex mt-1 lg:mt-0 mr-3"
                                      aria-hidden="true"
                                    />
                                    {key}
                                  </a>
                                ));
                              })()
                            }
                          </div>
                        </div>
                        {/* {console.log(datas.length  ,"vfjhjhgjhgvuj")} */}
                        {datas.length > 997 && showAll ?
                          <button
                            type="button"
                            onClick={handleLoadMore}
                            className="underline text-[13pt]  leading-[17pt] lg:text-[13px] lg:leading-[17px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] hover:text-[#BC8B20] border-none"
                          >
                            Load More
                          </button> : null
                         }
                    </tbody>
              }
              {/* {openTab === 5 ? */}
              {data.length === 0 || showAll
                ? null
                : <div className="flex justify-start py-1">
                  <button type="button" >
                    <NavLink
                      target="_blank"
                      state={{ prevPath: location.pathname }}
                      to={{
                        pathname: routeNames.companyFillingById(
                          companyProfileId,
                          routePathSegments.fillings
                        ),
                        search: `type=${primaryMasterId}`
                      }}
                    >
                      {fillingsData.length >0 &&
                      <button
                        type="button"

                        className="underline text-[13pt]  leading-[17pt] lg:text-[13px] lg:leading-[17px] font-sans_book_body  text-[#2B2A29] tracking-[-0.3px] hover:text-[#BC8B20]"
                      >
                        View All
                      </button>}
                    </NavLink>
                  </button>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Consolidated;
