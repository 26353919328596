import { useState, useMemo } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { sortRows, filterRows, paginateRows } from '../helpers'
import { Pagination } from '../Pagination_old'
import { rowsPerPageData } from '../../Config/config'
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import addNoted from "../../images/AtomFavourite_1/png/AtomFavourite.png";
import addNotedActive from '../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import AddNote from "../../Pages/addNote";
import '../TableView.css'


/**
 * The code defines a React component called "Table" that displays a table with sortable columns,
 * pagination, and the ability to add notes to rows.
 * @returns The `Table` component is returning a JSX fragment that contains a table with columns and
 * rows, as well as pagination functionality.
 */
const Table = ({ columns, rows }) => {
    const [activePage, setActivePage] = useState(1)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({ order: 'asc', orderBy: 'id' })
    const [showId, setShowId] = useState(null);
    const [ showNote, setShowNote ] = useState(false)

    /**
     * The addNote function toggles the visibility of a note and sets the id of the note to be shown.
     * @param id - The `id` parameter is the identifier of the note that needs to be added.
     */
    const addNote = (id) => {
      setShowNote(!showNote)
        setShowId(id)
    }
    const rowsPerPage = rowsPerPageData
  
    const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
    const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
    const calculatedRows = paginateRows(sortedRows, activePage, rowsPerPage)
    console.log(calculatedRows)
  
    const count = filteredRows.length
    const totalPages = Math.ceil(count / rowsPerPage)

  
    /**
     * The handleSort function updates the sort order and order by properties based on the provided
     * accessor.
     * @param accessor - The `accessor` parameter is a value that represents the property or key of an
     * object that you want to sort by. It is used to determine which property to sort the data by.
     */
    const handleSort = (accessor) => {
      setActivePage(1)
      setSort((prevSort) => ({
        order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
        orderBy: accessor,
      }))
    }

    return (
        <>
        <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
        <div className="scrollbar ">
        <div className="overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
        <table className="table w-full p-1 bg-grey-50 divide-y">
          <thead className="text-[11pt] leading-[16pt] bg-[#EBEBEB] lg:text-[12px]  font-sans_semi_bold lg:leading-[15px]  text-[#666666] text-left">
            <tr className='text-left'>
              {columns.map((column) => {
                const sortIcon = () => {
                  if (column.accessor === sort.orderBy) {
                    if (sort.order === 'asc') {
                      return <SortAscendingIcon className="pl-2 block h-[15px]"/>
                    }
                    return <SortDescendingIcon className="pl-2 block h-[15px]"/>
                  } else {
                    return <SortIcon className="pl-2 block h-[15px] "/>
                  }
                }
                return (
                  <th className="whitespace-nowrap px-5  py-2" key={column.accessor}>
                    {/* <span>{column.label}</span>
                    <button onClick={() => handleSort(column.accessor)}>{sortIcon()}</button> */}
                     <span className="flex">
                    <a className="tableTd" title={column.label?column.label:"--"}>{column.label?column.label:"--"}</a>
                    {column.accessor == "add"?"":
                    <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                  }</span>
                  </th>
                )
              })}
            </tr>
          </thead>
          {count>0?
          <tbody className="text-[11pt] leading-[16pt]  lg:text-[14px] text-[#333333] lg:leading-[16px] font-sans_book_body text-left">
            {calculatedRows.map((row) => {
              return (
               
                <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" key={row.id}>
                  {columns.map((column,i) => {
                    if(i==columns.length-1)
                    return <td className="title whitespace-nowrap  px-3 py-3 font-[Squel Sans] text-center " ><a title={column.accessor}></a>
                      <span><img className="mx-3 w-[21px] h-[21px] cursor-pointer" src={showId === row.id && showNote ?addNotedActive:addNoted} onClick={()=>addNote(row.id)} />{showId === row.id && showNote?<AddNote id={row.id} />:""}</span></td> 
                    else if (i==columns.length-2)
                    return <td className=" whitespace-nowrap  px-5 py-3 font-[Squel Sans] text-center" ><a title={column.accessor}>{row[column.accessor]}</a>
                            </td>
                  else if (i==columns.length-3)
                    return <td className=" whitespace-nowrap  px-5 py-3 font-[Squel Sans] text-center " ><a title={column.accessor}>{row[column.accessor]}</a>
                            </td>
                            
                    else
                    return <td className=" whitespace-nowrap  px-5 py-3 font-[Squel Sans]  ">
                      <NavLink to={"/reinvestor/"+row.id} 
                        title={row[column.accessor]}>  {row[column.accessor]}
                      </NavLink>
                    </td>
                  })}
                </tr>
              )
            })}
          </tbody>
          :
          <tbody className=" h-[10rem] w-full rounded-lg">
            <div role="status" className="  h-[16rem]">
              <svg className="relative top-[7rem] left-[30rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
            </div>
        </tbody>
        }
        </table>
        </div>
        </div>
        </div>
        </div>
  
        {count > 0 ? (
          <Pagination
            activePage={activePage}
            count={count}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        ) : (
          // <p>Loading ...</p>
          ""
        )}
  
        {/* <div>
          <p>
            <button onClick={clearAll}>Clear all</button>
          </p>
        </div> */}
      </>
    )
}

export default Table
