import React, { useState } from 'react'

const ViewMoreViewLess = ({children, maxChars=500}) => {
    const [viewMore, setViewMore] = useState(true);

    if(children.length <= maxChars) return <p>{children}</p>

    let text = viewMore ? children.substring(0, maxChars) : children;

  return (
    <>
        <span className='mt-3 whitespace-pre-line text-[11pt] leading-[18pt] py-1  lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body'>
            {text}
        </span>
        <button 
            className='text-[#bc8b20] lg:text-[13px]'
            onClick={() => setViewMore(!viewMore)}
        >
            {viewMore ? "...View More" : "View Less"}
        </button>
    </>
  )
}

export default ViewMoreViewLess;