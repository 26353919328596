import { React, useContext, useEffect, useState ,useMemo} from 'react'
import InvestorProfileHighlights from './InvestorProfileHighlight'
import InvestorFunds from './InvestorFunds'
import InvestorNewsActivity from './InvestorNewsActivity'
import ComparePopUp from '../../../Pages/ComparePopUp'
import Constants from '../../../Constants'
import axios from 'axios'
import useModule from '../../../hooks/useModule'
import { useParams, useSearchParams, useLocation, NavLink } from 'react-router-dom'
import { Tooltip } from 'antd'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { UserContext } from '../../../Contexts/UserContext'
import { TableDataContext } from '../../../Contexts/TableDataContext'
import Tab from '../../shared/Tab'
import RandomLogo from '../../RandomLogo'
import CompanyLogo from '../../CompanyLogo'
import AlertPopUp from '../../DetailPage/AlertPopUp'
import AddNotePopover from '../../shared/AddNotePopover'
import PENotesPopup from '../../../Components/NotesPopup/PENotesPopup'
import SendDealPopUp from '../../SendDealPopUp'
import { reInvestorProfileUrl, exportPdf} from '../../../Config/config';
import { routeNames } from '../../../routeSegments';
import XLSPopup from "../../../Components/DetailPage/DownloadXLS";
import PdfDownloader from "../../shared/PdfDownloader";
import ReadMoreReadLess from '../../shared/ReadMoreReadLess';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { DataFoundContext } from '../../../Contexts/DataFoundContext'
 
 
/**
 * The function `InverstorCompanyDetail` is a React component that displays the details of an investor
 * company, including its overview, funding information, and recent news and activity.
 * @param menu - The `menu` parameter is not used in the code snippet provided. It is likely a
 * placeholder for a menu component or navigation menu options.
 * @param props - The `props` parameter is an object that contains any additional properties passed to
 * the `InverstorCompanyDetail` component. These properties can be accessed within the component using
 * dot notation, such as `props.page` or `props.activeTab`.
 * @returns The function `InverstorCompanyDetail` returns a JSX element.
 */
const InverstorCompanyDetail = (menu, props ) => { 

    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' });; }
    const funds = () => { document.getElementById("funds").scrollIntoView({ behavior: 'smooth' });; }
    const news = () => { document.getElementById("news").scrollIntoView({ behavior: 'smooth' }); }

    const [showModal, setShowModal] = useState(false);

    const { getToken, logout, isStudent } = useContext(UserContext);
    const [URLSearchParams, SetURLSearchParams] = useSearchParams({ section: Constants.primaryMasterSubIds.re_investor_investment_id });
    const { primaryMasterId } = useModule();
    const [isLoading, setIsLoading] = useState(true);
    const { reinvestorId } = useParams()
    const location = useLocation() 
    const [profileData, setProfileData] = useState({})
    const [activetabIndex, setActivetabIndex] = useState(1)
    const [skipScroll, setSkipScroll] = useState(false)
    const { newsAndActivityDataFound } = useContext(DataFoundContext);

    const [overviewEl, isOverviewVisible, overviewEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [fundingEl, isfundingVisible, fundingEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, newsEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })


    const section = URLSearchParams.get('section');

    /* The above code is a React useEffect hook that makes an API call using axios. It sends a POST
    request to the specified URL with the provided data and headers. It also includes an abort
    controller to cancel the request if needed. */
    useEffect(() => {
        const controller = new AbortController()
        setIsLoading(true);
        axios(reInvestorProfileUrl, {
            method: "POST",
            data: {
                investor_id: reinvestorId,
                type: primaryMasterId
            },
            signal: controller.signal,
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                setIsLoading(false);
                if (response?.data?.success === true) {
                    console.log("setProfileData", response.data);
                    setProfileData(response.data?.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                if (error?.response?.status === Constants.sessionTerminatedStatus) {
                    logout()
                }
                console.log("API not working", error);
            });
        return () => controller.abort()
    }, [getToken, logout, primaryMasterId, reinvestorId])

    /**
     * The function `onTabClick` is used to handle tab clicks and perform different actions based on
     * the active tab.
     * @param active - The `active` parameter is a number that represents the index of the tab that was
     * clicked. It is used to determine which tab should be activated and which function should be
     * called based on the index.
     */
    const onTabClick = (active) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (active === 1) {
            overview()
        } else if (active === 2) {
            funds()
        } else {
            news()
        }
        setActivetabIndex(active);


    }

    /* The above code is defining a function called `setIsActiveOnScroll` using the `useMemo` hook.
    This function uses the `debounce` function to delay the execution of the `setActivetabIndex`
    function by 100 milliseconds. The `setActivetabIndex` function is passed an `index` parameter,
    which is used to set the active tab index. The `useMemo` hook is used to memoize the function so
    that it is only re-computed when its dependencies change. The empty dependency array `[]`
    indicates that the function does not have any dependencies and */
    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setActivetabIndex(index), 100)
    }, [])

    /* The above code is using the useEffect hook in React to set the value of the isActiveOnScroll
    state variable based on the visibility of different sections on the page. It checks the values
    of isOverviewVisible, isfundingVisible, and isnewsVisible variables and sets the
    isActiveOnScroll variable accordingly. */
    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } else if (isfundingVisible) {
            setIsActiveOnScroll(2);
        
        } else if (isnewsVisible) {
            setIsActiveOnScroll(3);
        }
    }, [isOverviewVisible, , isfundingVisible, isnewsVisible, setIsActiveOnScroll])


    const category = parseInt(section) === Constants.primaryMasterSubIds.re_investor_investment_id
        ? "re-investments"
        : parseInt(section) === Constants.primaryMasterSubIds.re_investor_exits_id
            ? "re-exits"
            : "re-ipo"


            /**
             * The function `subTypeMasterId` returns a sub category ID based on the value of the
             * `section` parameter.
             * @returns The function `subTypeMasterId` returns the sub category ID based on the value
             * of the `section` parameter.
             */
            const subTypeMasterId =() =>{
       
                if(section == Constants.primaryMasterSubIds.re_investor_investment_id){
                  return Constants.subCategoryIds.re_investor_investment_id
                }
                else if(section == Constants.primaryMasterSubIds.re_investor_exits_id){
                  return Constants.subCategoryIds.re_investor_exits_id
                }
                
                else if(section == Constants.primaryMasterSubIds.re_investor_ipo_id){
                  return Constants.subCategoryIds.re_investor_ipo_id
                }
               
                
              } 

    const { tableInvestorId: invId } = useContext(TableDataContext);

    const currentIndex = invId?.indexOf(Number(reinvestorId));

    document.title = `${profileData?.topProfile?.investor_name ? profileData?.topProfile?.investor_name + " - " : ""}RE Investors - Venture Intelligence`;

    return (
        <>
            <div className="bg-[#FFFFFF] relative">
                <nav className="flex p-1 px-4 lg:px-1 border-b " aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            <div className='flex items-center'> 
                              
                                <NavLink state={location.state?.prevPath} to={location.state?.prevPath != null && location.state?.prevPath !=""
                                    ? location.state?.prevPath
                                   
                                        : routeNames.re_investors_re_investments} 
                                        className="ml-2 text-[11pt] lg:text-[12px]  leading-[14px] font-sans_book_body text-[#666666]">
                                        Investors</NavLink>
                            </div>
                        </li>
                        <li className='flex items-center'>
                            <div className="flex items-center">
                                <span className=" text-[12px] text-[#666666] mr-1">/</span>
                                <span className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold whitespace-nowrap"
                                >{profileData?.topProfile?.investor_name}</span>
                            </div>
                        </li>
                    </ol>
                </nav>
                {invId &&
                    <>
                        <Tooltip title="Prev">
                            <NavLink
                                className="absolute top-2 right-8"
                                to={currentIndex === 0 ? null : routeNames.reInvestorById(invId[currentIndex - 1])}
                                >
                                {invId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next">
                            <NavLink
                                className="absolute top-2 right-4"
                                to={
                                    invId.length - 1 === currentIndex
                                    ? null
                                : routeNames.reInvestorById(invId[currentIndex + 1])}
                                >
                                {invId.length - 1 === currentIndex ? null : (
                                    <FaChevronRight size={15} color='grey'/>
                                )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
                <div className="px-4 ">
                    <div className="lg:hidden mt-4  grid  md:grid-cols-8  grid-cols-10  items-center mb-4">
                        <div className='col-span-5 mt-2'></div>
                        {/* <div className="lg:col-span-6 col-span-8"> */}
                            {/* // <Tooltip title="Compare Company" >
                            //     <button
                            //         className={
                            //             showModal
                            //                 ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                            //                 : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                            //         }
                            //         type="button"
                            //         onClick={() => {
                            //             setShowModal(!showModal)
                            //             setCompanyDetail([])
                            //             setCurrentPage("re-investor")
                            //         }}>
                            //         <img className={
                            //             showModal
                            //                 ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                            //                 : "w-[20px] h-[18px] pr-1 blur-btn"
                            //         }
                            //             src={compare}
                            //             alt="" />
                            //         <span>Compare</span>
                            //     </button>
                            // </Tooltip> */}
                        {/* </div> */}
                        <div className='flex md:col-span-2 col-span-5 space-x-3'>
                        <div className="col-span-1 ">
                            <AlertPopUp cid={reinvestorId} type_id={Constants.moduleIds.investorsId} page="investor"/>
                        </div>
                        <div className="col-span-1 ">
                            <AddNotePopover
                                title="Save List"
                                companyId={reinvestorId}
                                companyName={profileData?.topProfile?.investor_name}
                                type_id={Constants.moduleIds.investorsId} 
                                IsDetailed={true}
                                sub_type_master_id={subTypeMasterId()}
                                />
                        </div>
                        <div className="col-span-1 ">
                            <PENotesPopup
                                // note={note}
                                cid={reinvestorId}
                                type_id={Constants.moduleIds.investorsId}
                            />
                        </div>
                        <div className="col-span-1">
                            <SendDealPopUp data={profileData?.topProfile?.investor_name} />
                        </div>
                        {isStudent == 'true' ? <></> :
                                    <div className="col-span-1">
                                        {
                                            props?.page == "redealPage" ? (
                                                <XLSPopup activeTab={props?.activeTab} />
                                            ) : (
                                                <PdfDownloader
                                                    url={exportPdf}
                                                    fileName={profileData?.topProfile?.investor_name?.replaceAll(
                                                        /\s/g,
                                                        ""
                                                    )}
                                                    // data={{

                                                    data={{
                                                        "module_type": "RE",
                                                        "entity_id": reinvestorId,
                                                        "type_id": Constants.moduleIds.investorsId,
                                                        "type": 3,
                                                        "is_detailed": true,

                                                    }}


                                                />
                                            )
                                        }

                                    </div>
                                }
                        </div>

                        
                    </div>
                    <div className=" py-2 mt-4 bg-[#FFFFFF]">
                        <ul >
                            <li className="w-full flex">
                                <div className=" rounded-md">
                                    {
                                        profileData?.topProfile?.icon_path == "default.png" || profileData?.topProfile?.icon_path == "/default.png" || !profileData?.topProfile?.icon_path
                                            ? <RandomLogo name={profileData?.topProfile?.investor_name} page="detail page" />
                                            : <CompanyLogo name={profileData?.topProfile?.investor_name} id={reinvestorId} page="detail page" logo={profileData?.topProfile?.icon_path} />
                                    }
                                </div>

                                <div className="ml-3 flex flex-1 flex-col">
                                    <span className="text-[#333333] text-[24px] font-semibold leading-[29px]"> {profileData?.topProfile?.investor_name} </span>
                                    <span className=" text-[12pt] leading-[16pt] text-[#333333]  lg:text-[14px] lg:leading-[17px] font-sans_roman_body ">{profileData?.topProfile?.firm_type}</span>
                                </div>
                                <div className="hidden lg:grid grid-cols-5 gap-3 items-center ">
                                    {/* <div className="col-span-3">
                                        <Tooltip title="Compare Company" >
                                            <button
                                                className={
                                                    showModal
                                                        ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                                                        : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                                                }
                                                type="button"
                                                onClick={() => {
                                                    setShowModal(!showModal)
                                                    setCompanyDetail([])
                                                    setCurrentPage("re-investor")
                                                }}>
                                                <img className={
                                                    showModal
                                                        ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                                        : "w-[20px] h-[18px] pr-1 blur-btn"
                                                }
                                                    src={compare}
                                                    alt="" />
                                                <span>Compare</span>
                                            </button>
                                        </Tooltip>
                                    </div> */}
                                    <div className="col-span-1 ">
                                        <AlertPopUp cid={reinvestorId} type_id={Constants.moduleIds.investorsId} page="investor"/>
                                    </div>
                                    <div className="col-span-1 ">
                                        <AddNotePopover
                                            title="Save List"
                                            companyId={reinvestorId}
                                            companyName={profileData?.topProfile?.investor_name}
                                            type_id={Constants.moduleIds.investorsId} IsDetailed={true}
                                            sub_type_master_id={subTypeMasterId()}/>
                                    </div>
                                    <div className="col-span-1 mt-1">
                                        <PENotesPopup
                                            // note={note}
                                            cid={reinvestorId}
                                            type_id={Constants.moduleIds.investorsId}
                                        />
                                    </div>
                                    <div className="col-span-1">
                                        <SendDealPopUp data={profileData?.topProfile?.investor_name} />
                                    </div>
                                {isStudent == 'true' ? <></> :
                                    <div className="col-span-1">
                                        {
                                            props?.page == "redealPage" ? (
                                                <XLSPopup activeTab={props?.activeTab} />
                                            ) : (
                                                <PdfDownloader
                                                    url={exportPdf}
                                                    fileName={profileData?.topProfile?.investor_name?.replaceAll(
                                                        /\s/g,
                                                        ""
                                                    )}
                                                    // data={{

                                                    data={{
                                                        "module_type": "RE",
                                                        "entity_id": reinvestorId,
                                                        "type_id": Constants.moduleIds.investorsId,
                                                        "type": 3,
                                                        "is_detailed": true,

                                                    }}


                                                />
                                            )
                                        }

                                    </div>
                                }
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- tags--> */}
                    <div className="bg-[#FFFFFF]">
                        {profileData?.topProfile?.sector != null && profileData?.topProfile?.sector !== ""
                            ? <p className=" lg:block text-[#333333] font-sans_book_body text-[11pt] leading-[17pt] lg:leading-[17px] py-2 lg:text-[14px]  mr-3">
                                Sector : {profileData?.topProfile?.sector}
                            </p>
                            : null}

                        {(profileData?.topProfile?.description?.length ?? 0) > 0
                            ? <ReadMoreReadLess
                                className="whitespace-pre-line text-[11pt] leading-[17pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px]  font-sans_book_body"
                                text={profileData?.topProfile?.description}
                            />
                            : null}


                    </div>
                    {/* <a className="text-[#BC8B20] text-[13px] leading-[16px]" href="#">... View More</a> */}

                    {/* <ul className="flex flex-wrap   " >
                        <li className="">
                            <a className=" lg:block leading-[16px]  py-1 text-[#333333] font-sans_book_body text-[11pt] leading-[17pt] lg:leading-[17px] py-2 lg:text-[13px]  mr-3 mt-3"
                            >Industries (Existing Investments): Healthcare & Life Sciences, IT & ITES </a>
                        </li>

                    </ul> */}
                </div>

            </div>
            <div className='bg-[#EDEDED]'>
                <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index">
                    <Tab active={activetabIndex === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                    <Tab active={activetabIndex === 2} label={"Funding"} onClick={() => onTabClick(2)} />
                    {newsAndActivityDataFound ? <Tab active={activetabIndex === 3} label={"Recent News & Activity"} onClick={() => onTabClick(3)} /> : ""}
                </div>
                <div className="bg-[#EDEDED] ">
                    <section ref={overviewEl}  className="scroll-mt-14" id="profile"><InvestorProfileHighlights profileHighlights={profileData?.profile} isLoading={isLoading} /></section>
                    <section ref={fundingEl} className="scroll-mt-14" id="funds"><InvestorFunds category={category} InvestorName={profileData?.topProfile?.investor_name} /></section>
                    <section  ref={newsEl} id="news" className='mt-4 scroll-mt-14'><InvestorNewsActivity /></section>
                </div>
            </div>
            {showModal ? (<ComparePopUp onClose={(e) => setShowModal(false)} />) : null}
        </>
    )

}

export default InverstorCompanyDetail