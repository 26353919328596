import { React, useState, useMemo, useEffect, useContext } from 'react'
import { matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client'
import axios from 'axios'
import moment from 'moment'
// import { useSearchParams, useLocation } from "react-router-dom";
import { useSearchParams, Routes, Route, useParams } from 'react-router-dom';
import { descriptionCharLimit } from "../../../Config/config"
// import DetailInformation from '../DealPage/M_ACompanyDetailInformation';
import DealInfo from './M_ADealInfo'
import ValuationInfo from './M_AValuationInfo'
import InvestorInfo from './M_AInvestorInfo'
import OtherDeals from './M_AOtherDeals';
import AdvisorInfo from './M_AAdvisorInfo'
import Target from './M_ATarget';
import dataJson from './DealData.json'
import DetailInformation from '../../../Components/DetailPage/CompanyDetailInformation';
import {
    dealPageAngel,madealdetail,
    getMaRoundHierarchy, madealPageProfile
} from "./../../../Config/config";
import routePathSegments, { routeNames } from '../../../routeSegments';
import { TableDataContext } from '../../../Contexts/TableDataContext'
import { UserContext } from '../../../Contexts/UserContext';
import useModule from '../../../hooks/useModule';
import Constants from '../../../Constants';
import { RaiseRequestContext } from '../../../Contexts/RaiseRequestContext';


/* The above code is a React component written in JavaScript. It is a part of a larger application and
is responsible for rendering the details of a company deal. */
const M_ACompanyDealDetail = ({ menu }) => {
    const {setCname} = useContext(RaiseRequestContext);


    const { activeTabDeal, setActiveTabDeal, setDealIdTab } = useContext(TableDataContext);
    const [companyProfile, setCompanyProfile] = useState({});
    const [companyProfileTab, setCompanyProfileTab] = useState({});
    const [companyDealDetail, setCompanyDealDetail] = useState(dataJson.rounds);
    const [companyDealDetailTab, setCompanyDealDetailTab] = useState();
    const [companyExitDealDetailTab, setCompanyExitDealDetailTab] = useState()
    const [companyDealDetailShareholding, setCompanyDealDetailShareholding] = useState({})
    const [companyDealDetailAngelInvestment, setCompanyDealDetailAngelInvestment] = useState({})
    const [companyDealDetailIncubation, setCompanyDealDetailIncubation] = useState({})
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const [showAdd, setShowAdd] = useState(false)
    const [showPopupPDF, setShowPopupPDF] = useState(false);
    const [showPopupXLS, setShowPopupXLS] = useState(false);
    const [companyName, setCompanyName] = useState();
    const [roundsDetail, setRoundsDetail] = useState([]);
    const [selectedRoundType, setSelectedRoundType] = useState("");
    let longText = " BarRaiser, a Bengaluru ,based.AI powered smart Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat culpa unde nostrum vel nihil amet minus laboriosam voluptas placeat natus laudantium eligendi quam earum tempora temporibus, sapiente fuga Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat culpa unde nostrum vel nihil amet minus."
    const charLength = descriptionCharLimit;
    const [searchParams] = useSearchParams();
    const { companyId, dealId } = useParams();
    const [currentDealId, setCurrentDealId] = useState(dealId);
    const [currentDeal, setCurrentdeal] = useState("")
    const [cardData, setCardData] = useState({})
    const [dealInfoDetail, setDealInfoDetail] = useState()
    const [invstorInfoDetail, setInvestorInfoDetail] = useState()
    const [isProfileLoading, setIsProfileLoading] = useState(true);
    const { getToken } = useContext(UserContext);
    const { primaryMasterId } = useModule();

    const location = useLocation();
    let companyProfileId = parseInt(companyId)
    const navigate = useNavigate();



    /**
     * The function fetchProfileData makes a POST request to a specified API endpoint with certain data
     * and headers, and then sets the response data to the state variable cardData.
     */
    const fetchProfileData = () => {
        console.log(dealId, "yy")
        setCardData({})
        axios(`${madealPageProfile}`, {
            method: "POST",
            data: {

                "company_id": companyProfileId,
                "deal_id": dealId
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                if (response.status == "200") {
                    console.log(response.data.company_name, "dealCarddata ");
                    setCardData(response.data)
                    setCname(response?.data?.company_name)
                }
            })
            .catch((error) => {
                console.log("API not working - dealCarddata", error);
            });
    }


    /**
     * The function `fetchTableData` makes a POST request to a specified endpoint with certain data and
     * headers, and then sets the state with the response data if the request is successful.
     */
    const fetchTableData = (activeTabDeal, currentDealId ,dealId) => {
        axios(`${madealdetail}`, {
            method: "POST",
            data: {
                
                "company_id": companyProfileId,
                "deal_id": currentDealId
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                // console.log(response.data, "dealCarddata ");
                if (response.status == "200") {
                    // console.log(response.data, "dealCarddata ");
                    setCompanyDealDetailTab(response.data)
                }
            })
            .catch((error) => {
                console.log("API not working - dealCarddata", error);
            });
    }

   


    const companyProfileTabDataDetail = useMemo(() => companyDealDetailTab, [companyDealDetailTab]);


    const companyProfileData = useMemo(() => companyProfile, [companyProfile]);


    /**
     * The function `getCompanyName` sets the company name in the state using data from the
     * `companyProfileData` object.
     */
    const getCompanyName = () => {
        setCompanyName(companyProfileData?.companyname)
    };

    useEffect(() => {
        getCompanyName();
        // fetchProfileData()
        getRoundHierarchyData(companyProfileId, currentDealId ,dealId);

    }, []);


    /**
     * The function `getRoundHierarchyData` makes a POST request to retrieve round hierarchy data for a
     * specific company and deal, and then updates various state variables based on the response.
     */
    const getRoundHierarchyData = (companyProfileId, dealId) => {
        setCardData({})
        setSelectedRoundType( )
        axios(`${getMaRoundHierarchy}`, {
            method: "POST",
            data: {

                "company_id": companyProfileId,
                "deal_id": dealId
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                if (response.status == "200") {
                    console.log(response.data, "getRoundHierarchy");
                    setRoundsDetail(response?.data)
                    setSelectedRoundType(response?.data?.active_tab)
                    setActiveTabDeal(response?.data?.active_tab)
                    setDealIdTab(dealId)
                    fetchProfileData(response?.data?.active_tab, dealId)
                    fetchTableData(response?.data?.active_tab, dealId)

                }
            })
            .catch((error) => {
                console.log("API not working - getRoundHierarchy", error);
            });
    };


    let currentBreadCrumbLabel = "Deals"
    const MaMatch = matchPath(`${routeNames.maCompanyDealById(companyId, dealId)}`, location.pathname)
    // if (MaMatch != null) {
    //     currentBreadCrumbLabel = "Deals";
    
    // }
    // else {
    //     currentBreadCrumbLabel = "Deals";
    // }


   /* The above code is a JavaScript React code snippet. It defines a constant variable called
   `exportFileName` using the `useMemo` hook. */
    const exportFileName = useMemo(() => {
        const pathSegs = location.pathname.split('/')
        let exportFileName = " ";

        if (pathSegs.includes(routePathSegments.ma_dealpage)) {
            exportFileName = `${cardData?.company_name}-${companyDealDetailTab?.deal_info?.DealDate}`;

        } else {
            exportFileName = `${cardData?.company_name}-ma`;
        }
        return exportFileName;
    }, [companyDealDetailTab, location.pathname]);






    return (
        <>
            <div className="bg-[#FFFFFF]">

                <nav className="flex p-1 px-3 lg:px-2 border-b overflow-x-auto" aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            <div className='flex items-center'>
                                <NavLink to={
                                    location.state?.prevPath != null
                                        ? location.state?.prevPath
                                        : routeNames.ma_companies} className="text-[11pt] ml-2 lg:text-[12px] font-sans_book_body text-[#666666]">
                                    Companies</NavLink>
                            </div> 
                        </li>
                        <li className='flex items-center'>
                            <div className="flex items-center">
                                <span className=" text-[11pt] lg:text-[12px] text-[#666666] mr-1">/</span>
                                <div className='pt-[0.1rem] flex items-center whitespace-nowrap'>
                                    <NavLink state={{ prevPath: location.state?.prevPath }} to={

                                        routeNames.maCompanyById(companyId)} className="mr-1 text-[11pt] lg:text-[12px] text-[#666666]">
                                        {cardData?.company_name}</NavLink>
                                </div>
                                <span className="text-[11pt] lg:text-[12px] text-[#666666] mr-1">/</span>
                                <span className="text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                                    aria-current="page">{currentBreadCrumbLabel}</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                {/* <!-- BarRaiser--> */}
                {/* <DetailInformation data={companyProfileData}/> */}
                <DetailInformation activeTab="ma-investments" menu={menu} data={cardData} id={companyId} currentDealId={currentDealId} page="madealPage" desc={companyProfileTabDataDetail} exportFileName={exportFileName} />


                <div className=" flex flex-row mt-[0.7rem] overflow-x-auto scrollbar space-x-6 bg-white px-6 border-b border-[#D7D7D7] cursor-pointer">

                    {Object.keys(roundsDetail).length > 0 && Object.entries(roundsDetail).map(([key, value]) => {
                        return (
                            value != "Success" && key != "active_tab" && value?.length > 0 && value?.map((round, i) => {
                                console.log(key, "getroundhier")
                                console.log(value, "getroundhiervalue");
                                return (
                                    Object.entries(round).map(([key1, value1]) => {
                                        console.log(key1, "getroundhier1")
                                        console.log(value1, "value1");
                                        return (
                                            <a className={"py-2 whitespace-nowrap text-[12pt] leading-[17pt] text-[#333333] lg:text-[13px] lg:leading-[17px] font-semibold" +
                                                (value1?.hash_current_deal ?
                                                    " border-b-[3px] border-b-[#F1CA75]   group-hover:text-[#F1CA75]"
                                                    :
                                                    " opacity-[0.4]"
                                                )
                                            }
                                                onClick={() => {
                                                    getRoundHierarchyData(companyProfileId, value1?.hash_current_deal_id)
                                                    setCurrentDealId(value1?.hash_current_deal_id);
                                                    setIsActive(i + 1);
                                                    setCompanyDealDetailTab("")
                                                    setRoundsDetail(companyProfileId, value1?.hash_current_deal_id)

                                                    // console.log(companyProfileId, value1?.hash_current_deal_id,"eee")
                                                }}
                                            >
                                                {key1}
                                            </a>
                                        )
                                    })
                                )
                            })
                        )
                    })}
                </div>

            </div>
            <div className="bg-[#EDEDED] h-full">
                <div className=" flex flex-row overflow-x-auto scrollbar bg-[#EDEDED] space-x-6 py-4 px-6 pt-4 cursor-pointer ">
                    {Object.keys(roundsDetail).length > 0 && Object.entries(roundsDetail).map(([key, value]) => {
                        return (
                            value != "Success" && key != "active_tab" && value?.length > 0 && value?.map((round, i) => {
                                console.log(round, "val?.hash_current_deal_id")
                                return (
                                    Object.entries(round).map(([key1, val]) => {
                                        console.log(val, "valll")
                                        return (
                                            val?.hash_current_deal &&
                                            Object.entries(val).map(([key2, vall]) => {
                                                console.log(vall?.deal_id, 'dealIdddss')
                                                return (
                                                    vall?.deal_date_parsed &&
                                                    <a className={
                                                        "inline-block whitespace-nowrap px-3 py-1 text-[13px] cursor-pointer leading-[16px]  rounded-[13.5px] " +
                                                        (vall?.selected ?
                                                            "text-[#FFFFFF] bg-[#BC8B20] border border-[#BC8B20] pointer-events-none"
                                                            :
                                                            "text-[#BC8B20] border border-[#BC8B20]"
                                                        )
                                                    }
                                                        onClick={() => {
                                                            navigate(routeNames.maCompanyDealById(companyProfileId, vall?.deal_id))
                                                            setCurrentDealId(vall?.deal_id);
                                                            // getRoundHierarchyData(companyProfileId, vall?.deal_id)
                                                            setIsActive(i + 1);
                                                            setCompanyDealDetailTab("")
                                                            // console.log(companyProfileId, vall?.deal_id ,"fg")
                                                        }}
                                                    >
                                                        {moment(vall?.deal_date_parsed, "MMM YYYY").format("MMM YYYY")}
                                                    </a>
                                                )
                                            })


                                        )

                                    })

                                )
                            })
                        )
                    })
                    }

                </div>


                <div className="w-full lg:grid lg:grid-cols-8 px-4 gap-4 pt-0 bg-[#EDEDED]">
                    <div className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded lg:col-span-4  overflow-x-hidden">
                        <div className="w-full lg:grid lg:grid-rows-8 gap-5">
                            <div className="row-span-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px] overflow-x-auto">
                                <DealInfo  data={companyProfileTabDataDetail?.deal_info}/>
                            </div>
                           
                            <div className=" mb-3 row-span-2 py-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px] overflow-x-auto">
                                <ValuationInfo data={companyProfileTabDataDetail?.valuation_info}/>
                            </div>

                        </div>
                    </div>
                    <div className="lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar">
                        <div className="lg:grid lg:grid-rows-9 gap-5">
                            <div
                                className="hidden lg:grid py-4 overflow-x-auto row-span-5 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                <Target data={companyProfileTabDataDetail?.target_and_acquirer_info}/>
                            </div>

                            <div className="row-span-2 overflow-x-auto  py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                <AdvisorInfo data={companyProfileTabDataDetail?.advisor_info}/>
                            </div>

                            <div className="mt-5 lg:mt-0 row-span-2 overflow-x-auto  px-6 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px] mb-3">
                                <OtherDeals data={companyProfileTabDataDetail?.same_acquirer_deals}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )

}

export default M_ACompanyDealDetail