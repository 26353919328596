import { React, useState, useEffect, useContext } from "react";
import { RaiseRequestContext } from "../../../Contexts/RaiseRequestContext";
import HeaderButton from "./HeaderButton";
import lock from "../../../images/lock-icon.png";
import { CurrencyContext } from "../../../Contexts/CurrencyContext";
import Constants from "../../../Constants";
import { UserContext } from "../../../Contexts/UserContext";
import LockIcon from "mdi-react/LockIcon";
import ConfirmPopup from "../../../Utils/ConfirmPopup";
import { Tooltip } from "antd";


function Ratio({
  data,
  isDetails,
  setIsDetail,
  companyLabel,
  cfsFinKeys,
}) {
  const [selected, setSelected] = useState(1);
  const [alert, setalert] = useState({ message: "", show: false });
  const { openRequestPopup } = useContext(RaiseRequestContext);

  const { setFinancialType } = useContext(CurrencyContext);
  const { isDirectoryUser } = useContext(UserContext);

  /**
   * The function `onButtonSelect` updates the selected and financial type based on the selected
   * type.
   */
  const onButtonSelect = (selectedType) => {
    setSelected(selectedType);
    setFinancialType(selectedType === 1 ? "Consolidated" : "Standalone");
  };

  useEffect(() => {
        if (data?.Consolidated?.fy_years?.length == 0) {
            onButtonSelect(2);
        } else if (data?.Consolidated?.fy_years?.length != 0) {
            onButtonSelect(1);
        }
    }, [])

  const onBlurColumnClick = () => {
    setalert({
      show: true,
      message: Constants.cfsSubscriptionMsg,
      isAlertOnly: true,
      closeBtnName: "OK",
    });
  };

  const onConfirmation = (flag) => {
    setalert({ show: false, message: "" });
  };

  return (
    <div>
      <div className="flex items-center justify-between px-3 md:px-5">
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-2 md:space-x-3">
            {data?.Consolidated?.fy_years?.length != 0 &&
            data?.Consolidated?.fy_years != null ? (
              <div className="">
                <HeaderButton
                  isSelected={selected === 1}
                  onClick={(e) => onButtonSelect(1)}
                >
                  Consolidated
                </HeaderButton>
              </div>
            ) : (
              <></>
            )}
            {data?.Standalone?.fy_years?.length != 0 &&
            data?.Standalone?.fy_years != null ? (
              <div className="py-4">
                <HeaderButton
                  isSelected={selected === 2}
                  onClick={(e) => onButtonSelect(2)}
                >
                  Standalone
                </HeaderButton>
              </div>
            ) : (
              <></>
            )}
          </div>
          {companyLabel &&
            data[`${selected === 1 ? "Consolidated" : "Standalone"}`]?.length !=
              0 &&
            data[`${selected === 1 ? "Consolidated" : "Standalone"}`] !=
              null && (
              <div className="rounded-full bg-purple-100 px-2.5 py-[0.1rem] text-[9px] leading-[12px] font-sans_book_body text-[#333333]">
                XBRL
              </div>
            )}
        </div>
      </div>

      {data[`${selected === 1 ? "Consolidated" : "Standalone"}`]?.fy_years
        ?.length != 0 &&
      data[`${selected === 1 ? "Consolidated" : "Standalone"}`]
        ?.fy_years != null ? (
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] font-bold text-[#666666] py-4 px-0 text-left">
                <tr>
                    <th
                        width="10%"
                        className="whitespace-nowrap px-5 py-3.5 text-left sticky left-0 bg-[#EDEDED]"
                    >
                        Financials
                    </th>
                    {data[`${selected === 1 ? 'Consolidated' : 'Standalone'}`]?.fy_years?.map((year, index) => {
                        return (
                            <th
                                key={`year_${year}`}
                                width={`${
                                    data[`${selected === 1 ? 'Consolidated' : 'Standalone'}`]?.fy_years.length -
                                    1 ===
                                    index
                                        ? '5'
                                        : '5%'
                                }`}
                                className={`whitespace-nowrap px-5 py-3.5 ${data[`${selected === 1 ? 'Consolidated' : 'Standalone'}`]?.fy_years.length -
                                    1 ===
                                    index
                                        ? 'ml-2 w-12':'text-right'}`}
                            >
                              <div className="w-16 text-right">
                                {`FY ${year}`}
                              </div>
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody className="text-[12pt] leading-[15pt] lg:text-[12px] lg:leading-[15px] text-[#666666] py-4 px-0 text-left">
                {cfsFinKeys.map(({ accessor, label, tooltip }) => (
                    <tr key={label} className="border-b font-sans_book_body text-[#333333] leading-[24px]  text-[14px]">
                        <td className="md:whitespace-nowrap font-sans_book_body px-5 py-3 text-left sticky left-0  bg-white text-[#333333] w-[3rem]">
                          <Tooltip title={tooltip} placement="right" color="white" overlayInnerStyle={{color:'#333333',fontWeight:'500', whiteSpace:'nowrap', width:'fit-content'}} zIndex={99}>
                            {label}
                          </Tooltip>
                        </td>
                        {data[`${selected === 1 ? 'Consolidated' : 'Standalone'}`][accessor]?.map(e => 
                          isDirectoryUser ? (
                            <td
                              onClick={onBlurColumnClick}
                              className="relative whitespace-nowrap cursor-pointer"
                            >
                              <div className="blur-sm bg-[#DCDCDC] select-none h-full px-5 py-4 w-20">
                                {Constants.restrictedPlaceholder}
                              </div>
                            </td>
                          ) : (
                            <td className="md:whitespace-nowrap font-sans_book_body px-5 py-3 text-left bg-white text-[#333333] w-[3rem]">
                              <div className="w-16 text-right">
                                {e}
                              </div>
                            </td>)
                          )
                        }
                    </tr>
                ))}
            </tbody>
        </table>

        </div>
      ) : (
        <div className="h-28 w-full">
          <div className="w-full h-full flex items-center justify-center">
            
            <p className="px-5 text-[14px]">
              Data not found. Please{" "}
              <button
                className="text-[#A5711A] hover:underline"
                onClick={() => openRequestPopup("financial", "CFS")}
              >
                Click Here
              </button>{" "}
              to alert Venture Intelligence about this. Thanks
            </p>
          </div>
        </div>
      )}
      {data[`${selected === 1 ? "Consolidated" : "Standalone"}`]?.fy_years?.length === 0 || isDetails ? <></> : 
        <div className="flex">
                  <button
                      onClick={(e) => {
                          e.preventDefault();
                          setIsDetail(true)
                      }}
                      className=" py-1 px-2 border rounded-[5px] border-[#D2D2D2] inline-flex items-center float-right mx-3 my-3"
                  >
                      <img src={lock} alt='' />
                      <span className="pl-2 pr-1 text-[#333333] text-[13px]">
                          Detailed
                      </span>
                  </button>
            </div>
        }
      {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </div>
  );
}

export default Ratio;
