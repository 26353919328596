import Layout from '../Components/Layout'
import { NavLink, useSearchParams} from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import RandomLogo from '../Components/RandomLogo';
import CompanyLogo from '../Components/CompanyLogo';
import OverflowToolTip from '../Components/shared/OverflowToolTip';
import Loader from '../Components/Loader';
import { Pagination } from 'antd';
import {TableDataContext} from '../Contexts/TableDataContext';
import { cubejsApi } from '..';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import { routeNames } from '../routeSegments';
import FilterTags from '../Components/QueryBuilder/Components/FilterTags';
import xls from "../images/xls/png/Group 47.png";
import Constants from '../Constants';
import { exportAdvisorExcel } from '../Config/config';
import { transactionAdvisorProfileExportData } from '../Components/ExportPopup/ExportData';
import line from "../images/line.png";
import FileIcon from "../images/file.png";
import FileIconBlur from "../images/fileblur.png";
import SavedFilterContent from '../Components/SavedFilter';
import { PeFilterContext } from '../Contexts/PeFilterContext';
import ExportPopup from '../Components/ExportPopup';
import MonthPickerComponent from '../Utils/MonthPickerComponent';
import Card from '../Components/Advisor/AdvisorPageCard/Card';


function TransactionAdvisorsQueryBuilder() {
    const { data, setData, setIsLoad, setIsDataLoad, advancedSearchCurrentPage,setAdvancedSearchCurrentPage, tableAdvisorId, setTableAdvisorId, allDealIds, setAllDealIds} = useContext(TableDataContext)
    const {save, setSave} = useContext(PeFilterContext);
    // const [isLoading, setIsLoading] = useState(false)
    const [companyData, setCompanyData] = useState();
    const [totalAdvisorCount, setTotalAdvisorCount] = useState(1)
    const [sort, setSort] = useState({ order: "asc", orderBy: "advisor_cias.cianame" })
    const [searchParams, setSearchParams] = useSearchParams()
    const id = searchParams.get('id')
    const [showPopup, setShowPopup] = useState(false);
    const [isAdvanceSearch, setIsAdvanceSearch] = useState(true);
    
    const [PEVCTableHeader, setPEVCTableHeader] = useState(
        [
            { accessor: 'advisor_cias.cianame', label: 'Advisor' },
            { accessor: 'advisor_cias.country', label: 'Country' },
            { accessor: 'advisor_cias.city', label: 'City' },
            { accessor: 'advisor_cias.designation', label: 'Designation' },
            { accessor: 'advisor_cias.email', label: 'Email' }
        ]
    );
  
    /**
     * The function `currentPageTable` sets the current page value and performs a search with the
     * updated value.
     * @param value - The value parameter is the current page number that is being set.
     */
    const currentPageTable = (value) => {
      setAdvancedSearchCurrentPage(value)
      serachResult(value, sort.orderBy, sort.order)
    }
  
    useEffect (() => {
      findTotalCount()
      serachResult("1", sort.orderBy, sort.order)
      getAdvisorIdCubeQuery(sort.orderBy, sort.order);
      console.log(totalAdvisorCount, "totalAdvisorCount");
    },[])

    const savedList = () => {
      setSave(!save);
    };
  
    const topCardValues = () => {
      const cubeQuery1 = {
          timeDimensions: [],
            measures: ["advToCompaniesFilterFlat.dealcount"],
            order: {
              "advisor_cias.cianame": "asc",
            },
          filters: JSON.parse(localStorage.getItem(id)),
          segments:[],
        };    
      const cubeQuery2 = {
          timeDimensions: [],
            measures: ["advToExitsFilterFlat.dealcount"],
            order: {
              "advisor_cias.cianame": "asc",
            },
          filters: JSON.parse(localStorage.getItem(id)),
          segments:[],
        };    
      const cubeQuery3 = {
          timeDimensions: [],
            measures: ["advToInvestorsFilterFlat.dealcount"],
            order: {
              "advisor_cias.cianame": "asc",
            },
          filters: JSON.parse(localStorage.getItem(id)),
          segments:[],
        };    
      const cubeQuery4 = {
          timeDimensions: [],
            measures: ["adv_to_acquirers_filter_flat.dealcount"],
            order: {
              "advisor_cias.cianame": "asc",
            },
          filters: JSON.parse(localStorage.getItem(id)),
          segments:[],
        };    
        cubeQuery1.segments = JSON.parse(localStorage.getItem("TASegmentsList"))
        cubeQuery2.segments = JSON.parse(localStorage.getItem("TASegmentsList"))
        cubeQuery3.segments = JSON.parse(localStorage.getItem("TASegmentsList"))
        cubeQuery4.segments = JSON.parse(localStorage.getItem("TASegmentsList"))
      return [cubeQuery1, cubeQuery2, cubeQuery3, cubeQuery4];
    }
  
/**
 * The function `findTotalCount` retrieves the total count of companies from a data source using
 * Cube.js and sets the value in the state variable `totalAdvisorCount`.
 */
    const findTotalCount = async () => {
      const transformedData = {
        num_to_company_investments: 0,
        num_to_company_exits: 0,
        num_to_investors: 0,
        num_to_acquirers: 0,
      };
      
      try {
        // Load the total count
        const query0 = totalCount();
        const result0 = await cubejsApi.load(query0);
        setTotalAdvisorCount(result0.loadResponse.results[0].data[0]["advisor_cias.count_of_advisor"]);

        // Load the top card values
        const queries = topCardValues();
        const results = await Promise.all(queries.map(q => cubejsApi.load(q)));

        transformedData.num_to_company_investments = results[0].loadResponse.results[0].data[0]["advToCompaniesFilterFlat.dealcount"];
        transformedData.num_to_company_exits = results[1].loadResponse.results[0].data[0]["advToExitsFilterFlat.dealcount"];
        transformedData.num_to_investors = results[2].loadResponse.results[0].data[0]["advToInvestorsFilterFlat.dealcount"];
        transformedData.num_to_acquirers = results[3].loadResponse.results[0].data[0]["adv_to_acquirers_filter_flat.dealcount"];

        setIsLoad(false);
        setIsDataLoad(false);
        console.log(transformedData, "trans");
        setData(transformedData);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    }

    /**
     * The function `searchResult` takes in three parameters (page, accessor, order) and generates a
     * cube query using these parameters to fetch data from an API.
     * @param page - The "page" parameter is used to specify the page number for pagination. It
     * determines which page of search results to retrieve.
     * @param accessor - The `accessor` parameter is used to specify the dimension or measure that you
     * want to search for in your data. It could be the name of a column in your database table or a
     * cube.js dimension or measure.
     * @param order - The "order" parameter is used to specify the order in which the search results
     * should be sorted. It can be either "asc" for ascending order or "desc" for descending order.
     */
    const serachResult = (page, accessor, order) => {
      // setrefineSearchFilters(1)
      setCompanyData()
      // setIsLoading(true);
      const query0 = generateCubeQuery(page, accessor, order);
  
      // setIsLoading(true);
      cubejsApi.load(query0
      )
        .then((result) => {
          // console.log('result', result.loadResponse.results[0].data);
          setCompanyData(result.loadResponse.results[0].data);
          // setIsLoading(false)
        })  
  
    }

    /**
     * The function `totalCount()` returns a cube query object with measures, filters, and segments
     * based on data stored in local storage.
     * @returns The function `totalCount` returns the `cubeQuery` object.
     */
    function totalCount() {
        const cubeQuery = {
          timeDimensions: [],
            measures: ["advisor_cias.count_of_advisor"],
            order: {
              "advisor_cias.cianame": "asc",
            },
          filters: JSON.parse(localStorage.getItem(id)),
          segments:[],
        };    
        cubeQuery.segments = JSON.parse(localStorage.getItem("TASegmentsList"))
        return cubeQuery;
      }
  
/**
 * The function generates a cube query object with specified parameters for pagination, sorting,
 * filtering, and dimensions.
 * @param page - The page parameter is used to determine which page of data to retrieve. It is
 * typically a number that represents the page number.
 * @param orderBy - The `orderBy` parameter is used to specify the field by which the data should be
 * ordered. It can be any of the dimensions mentioned in the `dimensions` array.
 * @param order - The "order" parameter specifies the order in which the results should be sorted. It
 * can have two possible values: "asc" for ascending order and "desc" for descending order.
 * @returns The function `generateCubeQuery` returns the `cubeQuery` object.
 */
    function generateCubeQuery(page, orderBy, order) {
      const cubeQuery = {
        timeDimensions: [],
        order: {
            [orderBy]: order,
        },
        segments:[],
        dimensions: [
          "advisor_cias.cianame",
          "advisor_cias.country",
          "advisor_cias.city",
          "advisor_cias.designation",
          "advisor_cias.email",
          "advisor_cias.CIAId"
        ],
        filters: JSON.parse(localStorage.getItem(id)),
        // limit: 20
        limit: 10,
        offset: page=="1"?0:10*(page-1)
      };
      cubeQuery.segments = JSON.parse(localStorage.getItem("TASegmentsList"))
  
      return cubeQuery;
    }

    const getAdvisorId = (orderBy, order) => {
      const cubeQuery = {
        timeDimensions: [],
        order: {
            [orderBy]: order,
        },
        dimensions: [
          "advisor_cias.CIAId",
          "DealFlat.DealId",
          ],
        filters: JSON.parse(localStorage.getItem(id)),
        segments:[],
        limit: 50000,
        offset: 0,
      };
      cubeQuery.segments = JSON.parse(localStorage.getItem("TASegmentsList"))
        
      return cubeQuery;
    }

    const getAdvisorIdCubeQuery = (accessor, order) =>{
      setTableAdvisorId([]);
      setAllDealIds([]);

      const advsiorIdSet = new Set([]);
      const dealIdSet = new Set([]);

      const query0 = getAdvisorId(accessor, order);

      console.log(query0, 'qury0')

      cubejsApi.load(query0)
        .then((result) => {
          const advisorData = result.loadResponse.results[0].data;
          if (advisorData) {
            advisorData.forEach(adv => {
              if(adv["advisor_cias.CIAId"] !== null){
                advsiorIdSet.add(adv["advisor_cias.CIAId"]);
              }
              if(adv['advisor_cias.CIAId'] !== null){
                dealIdSet.add(adv['DealFlat.DealId']);
              }
            });
            setTableAdvisorId(Array.from(advsiorIdSet));
            setAllDealIds(Array.from(dealIdSet));
          }
        })
        .catch(error => {
          console.error('Error fetching investor IDs:', error);
        });
        console.log(tableAdvisorId, 'tableInvestorId', allDealIds)
    }

    const filtersFromLocalStorage = JSON.parse(localStorage.getItem(id));

      /**
       * The handleSort function updates the sorting order and column to be sorted based on the given
       * accessor and order.
       * @param accessor - The `accessor` parameter is a value that represents the property or key of
       * the object that you want to sort by. It is used to identify the specific property that you
       * want to sort the search results by.
       * @param order - The "order" parameter is used to determine the sorting order. It can have two
       * possible values: 'asc' for ascending order and 'desc' for descending order.
       */
      const handleSort = (accessor, order) => {
        setSort((prevSort) => ({
            order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
            orderBy: accessor,
          }))
        serachResult(advancedSearchCurrentPage, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc')
      }

      const dateChangeHandler = (date) =>{
        const dateFilterIndex = filtersFromLocalStorage.findIndex(filter => filter.member === "DealFlat.date");

        if (dateFilterIndex !== -1) {
            // Update existing filter
            filtersFromLocalStorage[dateFilterIndex].values = date;
            filtersFromLocalStorage[dateFilterIndex].operator = "inDateRange";
        } else {
            // Add new filter
            filtersFromLocalStorage.push({
                member: "DealFlat.date",
                operator: "inDateRange",
                values: date
            });
        }

        localStorage.setItem(`${id}`, JSON.stringify(filtersFromLocalStorage));
        findTotalCount()
        serachResult("1", sort.orderBy, sort.order)
        getAdvisorIdCubeQuery(sort.orderBy, sort.order)
      }

    const onClose = (value) => {
      console.log(value);
      const updatedData = filtersFromLocalStorage
        .map((item) => {
        if (item.values && Array.isArray(item.values)) {
          item.values = item.values.filter((val) => val !== value);

          if (item.values.length === 0) {
            return null;
          }
        }

        return item;
      })
      .filter((item) => item !== null);
    console.log(updatedData);

    localStorage.setItem(`${id}`, JSON.stringify(updatedData));
    serachResult("1", sort.orderBy, sort.order);
    findTotalCount();
  };
  
  const deleteFilterByMember = (member) => {
    const updatedData = filtersFromLocalStorage.filter((item) => {
      return item.member !== member || (item.values && item.values.length <= 3);
    });

    console.log(updatedData, member);

    localStorage.setItem(`${id}`, JSON.stringify(updatedData));

    serachResult("1", sort.orderBy, sort.order);
    getAdvisorIdCubeQuery(sort.orderBy, sort.order);
    findTotalCount();
  };
  
    return (
        <div>
            <Layout>
              <div className="hidden lg:block">
                <Card />
              </div>
                <div className="">
                    {/* <QueryBuilderTabs /> */}
                    {/* <TableTabTable filter={location.state.filter}/> */}
                  <div className='mx-8 flex justify-between items-center'>
                    <FilterTags
                      filtersFromLocalStorage={filtersFromLocalStorage}
                      onClose={onClose}
                      deleteFilterByMember={deleteFilterByMember}
                    />
                    <div className='flex flex-row gap-2 items-center'>
                      <MonthPickerComponent id={id} filters={filtersFromLocalStorage} onDateChange={dateChangeHandler}/>
                      <div className='flex items-center gap-4'>
                        <img
                          src={xls}
                          className="max-h-[24px] mt-1 max-w-[21px]"
                          alt=""
                          onClick={() => setShowPopup(!showPopup)}
                        />
                        <img
                          className="h-[24px] max-w-[2px] mt-1 "
                          src={line}
                        />
                        <div className="group cursor-pointer">
                          <div
                            className="cursor-pointer"
                            onClick={savedList}
                          >
                            <img
                              src={save ? FileIcon : FileIconBlur}
                              className="max-h-[24px] mt-1 max-w-[21px]"
                              alt=""
                              />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {showPopup && 
                    <ExportPopup
                      modules="advisor"
                      isAdvanceSearch={isAdvanceSearch}
                      module="transaction-advisor"
                      url={exportAdvisorExcel}
                      fileName={Constants.exportNames.pe_t_advisor_investment}
                      onClose={() => setShowPopup(false)}
                      exportOptions={transactionAdvisorProfileExportData}
                      type="pe_vc_investment" module_type="pe" advisor_type="T" 
                    />
                  }
                  {save && 
                    <SavedFilterContent primaryTypeMasterId={Constants.primaryMasterIds.peId}
                      subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_advisors_id}
                      typeId={Constants.moduleIds.t_advisor_id}
                      show={save}
                      isAdvanceSearch={isAdvanceSearch}
                      filters={filtersFromLocalStorage}
                    />
                  }
      {/* <h2> Table Rendering </h2> */}
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] m-3 " + (companyData?.length > 5 ? '' : 'pr-2')}>

              <table className="md:pl-5 md:pr-5 pl-5 pr-[16rem]  listPage table w-full p-1 bg-grey-50 divide-y" width="50%">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]  text-left"
                >
                    {PEVCTableHeader.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (
                        <th className={"md:pl-5 md:pr-5 px-[1rem] py-2 pr-[11rem] "} key={i}>
                          <span className='flex'><OverflowToolTip component={<a className='cursor-text hover:text-[#000000]'>{column.label ? column.label : "--"}</a>} />
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                         </span>
                        </th>
                      )
                    })}
                </thead>
                <tbody className='text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body'>
                  {companyData ?
                 companyData.length > 0 ? (
                  companyData?.map((row, i) => {
                    return (
                      <tr key={i} className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white " >
                         <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                         {/* {row['CfsFlat.logopath'] == '' ? 'empty' : 'No empty'} */}
                         {row["peadvisor_logo.icon_path"] == "default.png" || row["peadvisor_logo.icon_path"] == "/default.png" || !row["peadvisor_logo.icon_path"] ?
                                    <RandomLogo name={row['advisor_cias.cianame']} />
                                    :
                                    <CompanyLogo name={row['advisor_cias.cianame']} page="company page" logo={row["peadvisor_logo.icon_path"]} />
                                  }
                                 <OverflowToolTip 
                                  className="max-w-[15rem] lg:max-w-[20rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis" 
                                  component={<NavLink className='hover:text-[#BC8B20] text-[#333333]' to={routeNames.peAdvisorById(row['advisor_cias.CIAId'])}>{row['advisor_cias.cianame'] ? row['advisor_cias.cianame'] : "--"}</NavLink>}
                                   />
                          </td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          <OverflowToolTip 
                                  className="max-w-[15rem] lg:max-w-[20rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis" 
                                  component={<a className='hover:text-[#000000] cursor-text '>{row['advisor_cias.country'] ? row['advisor_cias.country'] : "--"}</a>}
                                   />
                          </td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          <p className='cursor-text'>{row['advisor_cias.city']?row['advisor_cias.city']:"--"}</p></td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {/* {row['profile_flat.sector']?row['profile_flat.sector']:"--"} */}
                          <OverflowToolTip 
                                  className="max-w-[15rem] lg:max-w-[20rem] 2xl:max-w-[30rem] overflow-x-clip overflow-ellipsis" 
                                  component={<a className='hover:text-[#000000] cursor-text '>{row['advisor_cias.designation'] ? row['advisor_cias.designation'] : "--"}</a>}
                                   />
                          </td>
                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          <p className='cursor-text'>{row['advisor_cias.email']?row['advisor_cias.email']:"--"}</p></td>
                          {/* <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center"
                          >{row['Pecompanies.region']?row['Pecompanies.region']:"--"}</td> */}
                           {/* <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {row['DealFlat.amountM']?row['DealFlat.amountM']:"--"}</td>

                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {row['PecompanyTagCrosstabVw.tags']?row['PecompanyTagCrosstabVw.tags']:"--"}</td>

                          <td className="md:w-[20%] px-5 py-3 leading-[2rem] font-sans_book_body items-center justify-center">
                          {row['DealFlat.SPV']?row['DealFlat.SPV']:"--"}</td> */}

                          
                      </tr>
                    )
                  }))
                  
                   : (
                    <tr>
                      <td colSpan="5" className="py-3 text-center">No Records Found</td>
                    </tr>
                  )
                  :
                  <div className='sticky left-[50%] py-[2rem]'><Loader /></div>}
                </tbody>
              </table>


            </div>
          </div>
        </div>
      </div>
      <center className='my-[1rem]'><Pagination defaultCurrent={1} total={totalAdvisorCount} onChange={currentPageTable}/></center>

                </div>
            </Layout>
        </div>
    )
}

export default TransactionAdvisorsQueryBuilder
