import { React,useContext, useEffect} from "react";
import { filterLabels, PeFilterContext } from "../Contexts/PeFilterContext";
import { useLocation } from "react-router-dom";
import { TableDataContext } from '../Contexts/TableDataContext'
import moment from 'moment'
import { TableHeaderContext } from "../Contexts/TableHeaderContext";
import FilterTag from './FilterTag';
import CloseButton from "../images/CloseBtn.svg";
import { Popover } from "antd";
import ExpandedTagList from "./FilterTag/ExpandedTagList";
import Constants from "../Constants";
import useModule from "../hooks/useModule";
import routePathSegments from "../routeSegments";

/**
 * The `Tag` function is a React component that renders a list of tags based on the filters applied in
 * the application.
 * @param props - The `props` parameter is an object that contains the properties passed to the `Tag`
 * component. These properties can be accessed using dot notation, such as `props.page` to access the
 * value of the `page` property.
 * @returns The component is returning a JSX element, which is list of tag
 * components. The content of the tag is determined by the values of the refineSearchFilters and
 * tagList variables.
 */
const Tag = (props) => {
  const {
    monthPickerValue, setMonthPickerValue,
    refineSearchFilters, removeFilter, cfsRemoveFilter, FinancialBasedSelected, RatioBasedSelected, GrowthBasedSelected, groupId ,setGroupId  
  } = useContext(PeFilterContext);
  const { setCurrentPage} = useContext(TableDataContext);
  const { setSelectedPage } = useContext(TableHeaderContext)
  const location = useLocation();  
  let tagList = [];

  const { pathname } = useLocation();
  const { primaryMasterId } = useModule();
  const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

  console.log(refineSearchFilters , "gjygj");



  const closeIconTag = (id, key) => {
    if (key == "monthRange") {
      setMonthPickerValue(Constants.initialMonthRange);
      setCurrentPage(1)
      setSelectedPage(1)

    }  
  };

  const removeGroup =(id,key)=>{
    if (key == "myList") {
      setGroupId({})
      setMonthPickerValue(Constants.initialMonthRange);
      setCurrentPage(1)
      setSelectedPage(1)
    }
  }
  useEffect(()=>{
    if(groupId?.myList?.length > 0)  {
      if(groupId?.myList[0]?.group_id !=null){
      setMonthPickerValue(['1998-01-01', moment().endOf("month").format("YYYY-MM-DD")])
      }
    }
    else{      
      // setMonthPickerValue(Constants.initialMonthRange)}}
    }
  },[groupId])
  console.log("Vandhutandaaa" , groupId?.myList)
  // console.log("GrowthBasedSelected", GrowthBasedSelected);
  return (
    <div className={props?.page==="inv_lp"?"px-4 py-3 ":"px-4 py-3 mt-[1rem] lg:mt-0"}>
      <div className="flex flex-wrap gap-2">
        {primaryMasterId != 5 && !location.pathname.includes(routePathSegments.limited_partners) ?
          <label
            htmlFor=""
            className="text-[10px] leading-[16px] font-sans_book_body whitespace-nowrap text-[#333333] px-3 py-0.5 flex rounded-md ring-1 ring-gray-400 items-center tag-animate"
          >
            <p className="p-[2px]">{moment(monthPickerValue[0]).format("MMM YY") + " - " + moment(monthPickerValue[1]).format("MMM YY")}</p>
            {isFromGlobalSearch() || groupId?.myList?.length >0 ?<></> :
            <img alt="" src={CloseButton} className="h-3 w-3 ml-3 cursor-pointer" onClick={() => closeIconTag(0, "monthRange")} key={"monthRange"} />}
          </label>
          :null
        }
        {groupId?.myList?.length >0 ? 
           (groupId?.myList?.map((tags) => {
            return (<FilterTag title="My List" subTitle={tags?.name} onRemove={() => removeGroup(tags?.id, "myList")} />)
           }))
           : null
        }
        
        {Object.keys(refineSearchFilters[location.pathname] ?? {}).map((filter) => {
          tagList = refineSearchFilters[location.pathname][filter];
           console.log(filter,'tagList');
          if (filter === "financialFilterRequest" || filter === "ratiosFilterRequest" || filter === "growthFilterRequest") {
            return <>
              {tagList && (filter === "financialFilterRequest" ? FinancialBasedSelected : filter === "growthFilterRequest" ? GrowthBasedSelected : RatioBasedSelected).map((option, i) => {
                console.log(option, 'tagList[option]');
                return (
                  <>
                    {filter === 'growthFilterRequest' ?
                      (tagList[option]) && (
                        <FilterTag title={filterLabels[filter]} subTitle={`${option}(${tagList[option] ?? ''} %)`} onRemove={() => cfsRemoveFilter(filter, option)} />)
                      :
                      <FilterTag key={i} title={filterLabels[filter]} subTitle={`${option}(${tagList[option + '_min'] ?? ''} - ${tagList[option + '_max'] ?? ''})`} onRemove={() => cfsRemoveFilter(filter, option)} />
                    }
                  </>
                )
              })
              }
              {(tagList?.unit) &&
                <FilterTag title={filterLabels[filter]} subTitle={`In Crores`} onRemove={() => cfsRemoveFilter(filter, 'unit')} />
              }
              {(tagList?.year_match_type) &&
                <FilterTag title={filterLabels[filter]} subTitle={`${tagList?.year_match_type == 'any' ? 'Any Of' : 'Across All'}`} onRemove={() => cfsRemoveFilter(filter, 'year_match_type')} />
              }
              {(tagList?.cagr) &&
                <FilterTag title={filterLabels[filter]} subTitle={`CAGR`} onRemove={() => cfsRemoveFilter(filter, 'cagr')} />
              }

            </>

          }
          if (tagList?.length <= 3 || filter === "growthPercentage" || filter === "ratioPercentage" || filter === "financialPercentage") {
            console.log(filter , "igkhjkhgk")
            if ((tagList?.length ?? 0) === 0) return <></>
            if (filter === "yearFounded") {
              return <FilterTag title={filterLabels[filter]} subTitle={`${tagList[0]?.name} - ${tagList[1]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "operator" || filter === "deals_operator") {
                return null; 
            }
            if (filter === "dealRange" || filter === "dealSize") {
              return <FilterTag title={filterLabels[filter]} subTitle={`$${tagList[0]?.name} - $${tagList[1]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "fundRange" || filter === "fundSize") {
              return <FilterTag title={filterLabels[filter]} subTitle={`$${tagList[0]?.name} - $${tagList[1]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "dealInvestorRange") {
              return <FilterTag title={filterLabels[filter]} subTitle={`$${tagList[0]?.name} - $${tagList[1]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "returnMultiple") {
              return <FilterTag title={filterLabels[filter]} subTitle={`${tagList[0]?.name} - ${tagList[1]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "investorSaleInipo") {
              return <FilterTag subTitle={`${filterLabels[filter]} - ${tagList[0]?.name}`} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "chargeDate" || filter === "chargeAmount") {
              return <FilterTag title={filterLabels[filter]} subTitle={tagList[0]?.name} onRemove={() => removeFilter(filter, 0, true)} />
            }
            if (filter === "growthPercentage") {
              return tagList?.map((tags) => {
                return (<FilterTag title="Growth based" subTitle={tags?.name} onRemove={() => removeFilter(filter, tags?.id, false)} />);
              });
            }
            if (filter === "ratioPercentage") {
              return tagList?.map((tags) => {
                return (<FilterTag title="Ratio based" subTitle={tags?.name} onRemove={() => removeFilter(filter, tags?.id, false)} />);
              });
            } if (filter === "financialPercentage") {
              return tagList?.map((tags) => {
                return (<FilterTag title="Financial based" subTitle={tags?.name} onRemove={() => removeFilter(filter, tags?.id, false)} />);
              });
            }
            
            return tagList?.map((tags) => {
              if (tags.hide === true) {
                return <></>
              }
              return (<FilterTag title={tags?.title} subTitle={tags?.name} onRemove={() => removeFilter(filter, tags?.id, false)} />);
            });
          }
          else {
            if ((tagList?.length ?? 0) === 0) return <></>
            console.log(tagList , filterLabels[filter] ,tagList[0]?.name , "tagList" );
            return (
              <Popover placement="right" title={filterLabels[filter]} content={<ExpandedTagList tagList={tagList} onRemove={(id) => removeFilter(filter, id, false)} />}>
                <span><FilterTag title={filterLabels[filter]} subTitle={`${tagList[0]?.name} +${tagList?.length - 1} more`} onRemove={() => removeFilter(filter, 0, true)} /></span>
              </Popover>              
            )
          }
        })}        
      </div>
    </div>
  );
};

export default Tag;
