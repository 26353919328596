import React, { useEffect, useState, useContext } from "react";
// import Select from "react-select";
import { Select } from "antd"
import Switch from "./AndOrToggleSwtich";
import BasedFilterWrapper from './BasedFilterWrapper';
import ToggleButton from "./ToggleAndOrSwitch";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import useScrollIntoView from './useScrollIntoView';

const options = [
  { value: "revenue", label: "Revenue", moreThan: "", years: 2 },
  { value: "ebitda", label: "EBITDA", moreThan: "", years: 2 },
  { value: "net_profit", label: "Net profit", moreThan: "", years: 2 }
];
const MaxYears = [2, 3, 4, 5]

/**
 * The RatiosBasedFilter component is a JavaScript function that renders a filter component for
 * growth-based ratios in a user interface.
 * @returns The RatiosBasedFilter component is being returned.
 */
const RatiosBasedFilter = ({ open,
  accordionClick,
  customPathname,
  label = "GROWTH BASED",
  GrowthYear,
  setGrowthYear,
  GrowthOptions,
  setGrowthOptions,
  IsCAGR,
  setIsCAGR,
  AndOr,
  setAndOr,
}) => {
  const { refineSearchFilters } = useContext(PeFilterContext);
  const listItemRef = useScrollIntoView(open);


  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
  `useEffect` hook is being used to update the state variables `growthYear`, `growthOptions`,
  `cagr`, and `andOr` based on the values stored in the `refineSearchFilters` object. */
  useEffect(() => {
    const growthYear = refineSearchFilters[customPathname]?.growthYear?.at(0)?.value;
    const growthOptions = refineSearchFilters[customPathname]?.growthPercentage?.map(option => option.value);
    const cagr = refineSearchFilters[customPathname]?.growthCAGR?.at(0)?.value;
    const andOr = refineSearchFilters[customPathname]?.growthOperator?.at(0)?.value ?? 'and'
    setGrowthYear(growthYear ?? "any")
    setGrowthOptions(growthOptions ?? []);
    setIsCAGR(cagr ?? false);
    setAndOr(andOr);
  }, [customPathname, refineSearchFilters, setAndOr, setGrowthOptions, setGrowthYear, setIsCAGR])


  //new event listeners
  /**
   * The function `onGrowthYearChange` sets the value of `growthYear` to the provided `value`.
   * @param value - The value parameter is the new value for the growth year.
   */
  const onGrowthYearChange = (value) => {
    // setGrowthYear(prev => prev === value ? null : value)
    setGrowthYear(value)
    // setGrowthOptions([])
  }

  /**
   * The function `onGrowthOptionChange` logs the selected growth options and sets the growth options
   * state.
   * @param e - The parameter "e" is an event object that represents the event that triggered the
   * change. It can be used to access information about the event, such as the target element that
   * triggered the change.
   * @param selectedGrowths - The `selectedGrowths` parameter is an array that contains the selected
   * growth options.
   */
  const onGrowthOptionChange = (e, selectedGrowths) => {
    console.log('onGrowthOptionChange', selectedGrowths);
    setGrowthOptions(selectedGrowths)
  }


  /**
   * The function `onOptionsYearChange` is used to update the growth options based on the selected
   * year.
   * @param index - The index parameter represents the index of the growth option in the growthOptions
   * array. It is used to identify which growth option needs to be updated.
   * @param optionKey - The `optionKey` parameter is a string that represents the key of the option
   * being changed. In this code snippet, it can have two possible values: "moreThan" or "years".
   * @param value - The `value` parameter in the `onOptionsYearChange` function represents the new
   * value that is being passed for a specific option. It could be a number, a string, or any other
   * valid data type depending on the context of the code.
   */
  const onOptionsYearChange = (index, optionKey, value) => {

    setGrowthOptions(prev => {
      let temp = [...prev];
      if (optionKey === "moreThan") {
        const validNumber = new RegExp(/^\d*\.?\d*$/);
        let processedValue = "";
        if (validNumber.test(value)) {
          processedValue = value;
        } else {
          processedValue = temp[index].moreThan;
        }
        temp[index].moreThan = processedValue
      } else if (optionKey === "years") {
        temp[index].years = value
      }
      console.log('selectedGrowths', index, optionKey, value, temp);
      return temp;
    })
  }

  return (

    <div className="border-b-[3px] border-[#E8E6E1] space-y-3">
      <li className={
        open
          ? "bg-white relative  py-1"
          : "relative  py-1"
      }
      ref={listItemRef}
      >
        <BasedFilterWrapper
          isSelect={true}
          label={label}
          onClick={accordionClick}
          open={open}
        />
        <div
          className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto px-3 scrollbar-none"
          style={{ maxHeight: open ? "10rem" : "" }}
        >
          {open === true ?
            <>
              <div className="flex justify-between items-center my-2">
                <div className="flex space-x-3">
                  <label className="font-semibold text-[13px] text-[#333333]">Year :</label>
                  <div className="flex mr-1">
                    <input
                      className="cursor-pointer mr-1"
                      value="all"
                      type="radio"
                      key="all"
                      id="all"
                      checked={GrowthYear === "all"}
                      onClick={() => onGrowthYearChange('all')} />

                    <label htmlFor="all" className="text-xs cursor-pointer whitespace-nowrap"> All</label>
                  </div>
                  <div className="flex  mr-1">
                    <input
                      type="radio"
                      className="cursor-pointer mr-1"
                      value="any"
                      key="any"
                      id="any"
                      checked={GrowthYear === "any"}
                      onClick={() => onGrowthYearChange('any')}
                    />

                    <label htmlFor="any" className="text-xs cursor-pointer whitespace-nowrap">Any</label>
                  </div>
                </div>
                <div className="flex ">
                  <input
                    type="checkbox"
                    id="cagr"
                    key="cagr"
                    className="cursor-pointer mr-1"
                    checked={IsCAGR}
                    onChange={() => setIsCAGR(prev => !prev)}
                  />
                  <label htmlFor="cagr" className="text-xs cursor-pointer whitespace-nowrap">CAGR</label>
                </div>
              </div>
              <div className="py-2">
                <Select
                  mode="multiple"
                  showSearch
                  options={options}
                  optionFilterProp='label'
                  value={GrowthOptions}
                  onChange={onGrowthOptionChange}
                  style={{
                    width: '100%',
                    border: '1px solid #BC8B20',
                    borderRadius: '4px',
                    background: '#ffffff',
                    maxHeight: '60px',
                    overflowY: "auto"
                  }}
                  bordered={false}
                />
                <div className="">
                  {GrowthOptions.length > 0 &&
                    <div className="flex justify-between my-3 gap-3">
                      <label className="whitespace-nowrap font-semibold text-[13px] text-[#333333]">Fields</label>
                      <label className="whitespace-nowrap font-semibold text-[13px] text-[#333333] ml-3">More than(%)</label>
                      <label className="whitespace-nowrap font-semibold text-[13px] text-[#333333]"># of Yrs</label>
                    </div>
                  }
                  {GrowthOptions.map((option, i) => {
                    console.log('GrowthOptions-12', option);
                    return (
                      <>
                        <div key={option.value} className="grid grid-cols-3 space-y-2 flex items-center break-words">
                          <div className=" text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{option.label}</div>
                          <div className="px-1">
                            <input
                              className="border border-gray-300 rounded p-1 w-full"
                              type="text"
                              autoComplete="off"
                              max={100}
                              required
                              value={option.moreThan}
                              onChange={(e) => onOptionsYearChange(i, 'moreThan', e.target.value)}
                            />
                          </div>
                          <div className="px-1">
                            <select value={option.years} name="" id="" onChange={(e) => onOptionsYearChange(i, 'years', e.target.value)}
                              className=" border border-gray-300 rounded focus:outline-none bg-[#FFFFFF] w-full p-1">

                              {MaxYears.map((value, index) => (
                                <option key={index} value={value}>{value}</option>
                              ))
                              }

                            </select>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <hr className="mt-2"></hr>
                <div className="mt-2">
                  <ToggleButton value={AndOr === "or"} onLabel="AND" offLabel="OR" onToggle={(value) => setAndOr(value)} />
                </div>
              </div>
            </> :

            <></>}

        </div>

      </li>
      {/* <pre>  {JSON.stringify(fields, null, 2)} </pre>          */}
      {/* <pre>{JSON.stringify(financials, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}


    </div>
  );
};
export default RatiosBasedFilter;

