import React from 'react';
import { useMatch } from 'react-router-dom';
import routePathSegments, { routeNames } from '../../routeSegments'
import NavbarItems from './NavbarItems'
import NavbarWrapper from './NavbarWrapper'


/** This component renders a header for a `PE` section of a
website. It uses the `useMatch` hook from the `react-router-dom` library to determine if the current
URL matches certain paths. */
function PEHeader() {
    const isCompanyMatch = useMatch({ path: `/${routePathSegments.pe}/${routePathSegments.company}/*` })
    const isInvestorMatch = useMatch({ path: `/${routePathSegments.pe}/${routePathSegments.investor}/*` })
    const isAdvisorMatch = useMatch({ path: `/${routePathSegments.pe}/${routePathSegments.advisor}/*` })
    const isTransactionAdvisorMatch = useMatch({ path: `/${routePathSegments.pe}/${routePathSegments.transaction_advisor}/*` })
    // const isQueryBuilderMatch = useMatch({ path: `/${routePathSegments.query_builder}/*` })
    const isActiveInvestorMatch = useMatch({ path: `/${routePathSegments.pe}/${routePathSegments.activeInvestors}/*` })
    const isNewInvestorMatch = useMatch({ path: `/${routePathSegments.pe}/${routePathSegments.newInvestors}/*` })
    const companyMatch = useMatch({ path: `/${routePathSegments.company}/*` })

    return (
        <NavbarWrapper>
            <NavbarItems
                title="Companies"
                to={routeNames.PE_company}
                isLinkActive={isCompanyMatch != null || companyMatch != null}
                pageTitle="PE Companies PE-VC Investments - Venture Intelligence"
            />
            <NavbarItems
                title="Investors"
                to={routeNames.PE_investor}
                isLinkActive={isInvestorMatch != null || isNewInvestorMatch != null || isActiveInvestorMatch != null}
                pageTitle="PE Investors PE-VC Investments - Venture Intelligence"
            />
            <NavbarItems
                title="Legal Advisors"
                to={routeNames.PE_advisor}
                isLinkActive={isAdvisorMatch != null}
                pageTitle="PE Advisors PE-VC Investments - Venture Intelligence"
            />
            <NavbarItems
                title="Transaction Advisors"
                to={routeNames.PE_transaction_advisor}
                isLinkActive={isTransactionAdvisorMatch != null}
                pageTitle="PE Transaction Advisors PE-VC Investments - Venture Intelligence"
            />            
        </NavbarWrapper>
    )
}

export default PEHeader