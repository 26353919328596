import React, { useState, useEffect, useContext } from "react"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import MonthPickerComponent from '../../../Utils/MonthPickerComponent'
import { TableDownloadContext } from '../../../Contexts/TableDownloadContext'
import moment from "moment"
import line from "../../../images/line.png"
import xls from "../../../images/xls/png/Group 47.png"
import FileIcon from "../../../images/file.png"
import FileIconBlur from "../../../images/fileblur.png"
import ExcelPopup from "../../../Components/ExcelPopup";
// import '../MainMenu.css'
import { useLocation } from "react-router";
import { TableDataContext } from '../../../Contexts/TableDataContext'
import SavedFilterContent from "../../../Components/SavedFilter";
import SavedFilterICON from "../../../Components/SavedFilter/SavedFilterICON";
import ExportPopup from "../../../Components/ExportPopup/FundsExcel";
import { data ,fundsExportData } from "../../../Components/ExportPopup/ExportData"
import { exportFundsExcel } from "../../../Config/config";
import Constants from "../../../Constants";
import { PeFilterContext } from "../../../Contexts/PeFilterContext";
import { UserContext } from "../../../Contexts/UserContext";
function MainMenu(props) {

    // const [save,setSave]=useState(false)
    const { save, setSave, showMonthPicker ,refineSearchFilters,groupId} = useContext(PeFilterContext);
    const { handleDownloadExcel } = useContext(TableDownloadContext);
    const {  data, setData} = useContext(TableDataContext);
    const [openTab, setOpenTab] = React.useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [date, setDate] = useState([
        moment().startOf("month").subtract(12, "months").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ]);
    const { isStudent,isTrialExport} = useContext(UserContext)

    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])

  /**
   * The function `savedList` toggles the value of the `save` variable.
   */
          const savedList = () => {
    setSave(!save)
  }


    /**
     * The `download` function calls the `handleDownloadExcel` function.
     */
    const download = () => { 
        handleDownloadExcel()
    }
    const { pathname } = useLocation(); 
/**
 * The function `isFromGlobalSearch` checks if there are any global search filters applied based on the
 * value of `refineSearchFilters[pathname]?.globalSearch`.
 */
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
console.log(data,"khgjhbkj")
    return (
        <div className="pt-0 pl-3 pr-4">

            <div className="scrollbar  rounded">
                <div className=" py-2 rounded-b-none bg-white block lg:flex flex-row justify-end shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                    
                    

            {groupId?.myList?.length > 0 ?"":

<div className={"justify-between items-center px-4 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
{
  data?.aggCount && data?.agg_raised_amt_usd ? (
    <h1 className="text-[#bc8b20] font-normal text-lg">
    Total No. of Funds <span className="font-semibold">{data?.aggCount}</span>; Amount (US$M) <span className="font-semibold">{data?.agg_raised_amt_usd}</span>
  </h1>
  ) : null
}
  
<label htmlFor="" className="text-[#666666]">
    <div className={showMonthPicker!=0 ?"":'flex'}>
        <MonthPickerComponent />
    </div>
</label>
<div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>  
  {isTrialExport =='false' || isStudent == 'true' ? <></> :
        <button>
      <img
        src={xls}
        className="h-[24px] mt-1 w-[21px]"
        alt=""
        onClick={() => setShowPopup(!showPopup)}
      />
      {showPopup && (
        <ExportPopup 
        modules="funds"
        module_type="pe"
        // category="funds"
        fileName={Constants.exportNames.pe_funds}
        url={exportFundsExcel}
        onClose={() => setShowPopup(false)} 
        exportOptions={fundsExportData} 
         />
        // <div>
        //   <ExcelPopup
        //     menu="investments"
        //     subMenu="pevc"
        //     showPop={showPopup}
        //     setShowPop={setShowPopup}
        //   />{" "}
        // </div>
      )}
    </button>  
  }                         
    {/* <a> 
        <img className="h-[24px] w-[2px] mt-1 " src={line}/>
    </a>
     <SavedFilterICON  
  save={save} 
  FileIcon={FileIcon} 
  FileIconBlur={FileIconBlur}   
  savedList={savedList}  />  */}

</div>

</div>
            }        
               
                </div>
                {save ? <SavedFilterContent data={save} /> : ''}
            </div>
        </div>
    )
}

export default MainMenu