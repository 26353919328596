import React from 'react'
import ReactReadMoreReadLess from "react-read-more-read-less";
import { descriptionCharLimit } from "../../Config/config";

/**
 * The `MoreInfo` function is a React component that renders additional information based on the props
 * passed to it. It includes conditional rendering based on the screen width and the page type. It also
 * uses the `ReactReadMoreReadLess` component to limit the character length of the text and provide a
 * "View More" and "View Less" functionality.
 * @param props - The `props` parameter is an object that contains the data and configuration passed to
 * the `MoreInfo` component. It is used to customize the rendering of the component based on the
 * provided data and settings.
 * @returns The MoreInfo component is returning a JSX element.
 */
const MoreInfo = (props) => {

    // let longText = "  PAG-backed Edelweiss Wealth Management (EWM) has launched Edelweiss Crossover Yield Opportunities Fund (ECYOF), a venture debt fund, to raise INR 3,000 crore (around USD 361 million) including the green shoe option. The close-ended Category II alternative investment fund (AIF) offers to invest in emerging startups that offer equity-like returns with debt-like risks and regular cash flows. ECYOF will invest across growth stages starting from Series A and will be sector-agnostic. The fund is being managed by Pranav Parikh, Managing Partner and Head-Private Equity and Gaurav Gairola, Senior Investment Director."
    const screenWidth= window.innerWidth;
    const charLength = props?.page =="fundDetail" && screenWidth == 1213 ? '575'
    :props?.page =="fundDetail" && screenWidth > 1250 ? '665'
    :props?.page =="fundDetail" && screenWidth >= 1400 ? '700'
    :props?.page =="fundDetail" && screenWidth >= 1600 ? '830'
    : descriptionCharLimit;
    return (
        <div
            className="">
            <div className="px-5">
                <p
                    className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px]">
                    More Info</p>
            </div>
            <div>
             {Object.keys(props?.data).length > 0 && Object.entries(props?.data).map(([key, value]) => {
                    return(
                     value != "The fund detail has been viewed successfully" && key != "status" && value?.length>0 && value?.map((round, i) => {
                        // console.log(key,"getroundhier")
                        // console.log(value,"getroundhiervalue");
                        return(
                            Object.entries(round).map(([key1, value1]) => {
                                // console.log(key1,"getroundhier1")
                                // console.log(value1,"value1");
                                return(
                         <div className="whitespace-pre-line">
                        
                     {value1?.selected== true?
                         <p key={value1?.deal_id} className="text-[11pt] px-5 leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body  "
                            readonly>  
                    <ReactReadMoreReadLess
                      charLimit={charLength}
                      readMoreText={"View More "}
                      readLessText={"View Less "}
                      readMoreClassName="read-more-less--more lg:text-[13px]"
                      readLessClassName="read-more-less--less lg:text-[13px]"
                    >
                       {value1?.more_info?value1?.more_info:""}
                    </ReactReadMoreReadLess>
                  
                </p>
                        : 
                        " " 
                    } 
 
                     </div>
                            )})
                        )
                    })
                    )
                    })}
                {/* <p className="text-[11pt] px-5 leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                  
                    <ReactReadMoreReadLess
                      charLimit={charLength}
                      readMoreText={"View More "}
                      readLessText={"View Less "}
                      readMoreClassName="read-more-less--more lg:text-[13px]"
                      readLessClassName="read-more-less--less lg:text-[13px]"
                    >
                      {longText}
                    </ReactReadMoreReadLess>
                  
                </p> */}
              
                {/* {props.data&&
                <p className="text-[11pt] leading-[13pt] whitespace-nowrap px-2 py-2 mt-2 font-sans_book_body lg:text-[12px] lg:leading-[16px] text-[#777777]">More Info : {props?.data?.exitinvestment?.MoreInfor?props?.data?.exitinvestment?.MoreInfor:"--"}</p>
                } */}
                </div>
            </div>
   
    )
}

export default MoreInfo
