import React from "react";
import Loader from "../../Loader";
import linkedin from "../../../Utils/linkedin.png"
import { routeNames } from "../../../routeSegments";


/**
 * The code defines a React functional component called InvestorProfileHighlights that renders a
 * profile highlights section with dynamic data and loading state.
 * @returns The code is returning a JSX element, which represents the structure and content of the
 * InvestorProfileHighlights component.
 */
const InvestorProfileHighlights = ({ profileHighlights, isLoading }) => {

  return (
    <div>
      <div className="lg:mt-4 px-4 w-[100%]">
        <div className="">
          <div
            className="lg:bg-white rounded-[6px]"
            x-show="tab === 1"
          >
            <div className="w-full lg:grid lg:grid-cols-6  shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
              <div className=" mt-3 lg:mt-0 rounded-lg lg:rounded lg:col-span-6 lg:border-r bg-white ">
                <div className="px-5 py-4 ">
                  <p className="flex text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                    Profile {
                      profileHighlights?.filter(pro => pro?.label === "LinkedIn").length > 0 && // Check if LinkedIn Profile exists
                      profileHighlights?.filter(pro => pro?.label === "LinkedIn")[0]?.value ?  // Check if LinkedIn Profile value exists
                      <a href={`${profileHighlights?.filter(pro => pro.label === "LinkedIn")[0]?.value}`} target="_blank" className="mt-[0.20rem] ml-2"><img src={linkedin} width={16} alt="linkedin logo" /></a>
                      : ""
                    }
                  </p>
                  <div className="w-full bg-[#FFFFFF]">
                    {!isLoading ? (
                      <div className="grid grid-cols-2 md:grid-cols-4">
                        {profileHighlights?.map((highlight, i) => (
                          <div key={i} className={`profiletable ${i < 16 ? 'border-b' : ''}`}>
                            <div className="leading-[21px] font-sans_book_body">
                              <p className="py-1 text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto" readOnly>
                                {highlight.label === "LinkedIn" ? "" : highlight.label}
                              </p>
                              <p className="mb-2 mr-3 text-[11pt] break-words leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold">
                                {highlight.value && highlight.label === "Website" ? (
                                  <a target="_blank" href={highlight.value}>
                                    {highlight.value}
                                  </a>
                                ) : highlight.label === "LinkedIn" ? (
                                  ""
                                ) : highlight.label === "Limited Partners" && Array.isArray(highlight.value) ? (
                                  highlight.value.map((partner, index) => 
                                    partner.id !== "" ? (
                                      <span key={partner.id}>
                                        <a
                                          target="_blank"
                                          href={routeNames.investorsLimitedPartnerById(partner.id)}
                                          className={"underline text-[#bc8b20]"}
                                        >
                                          {partner.name}
                                        </a>
                                        {index < highlight.value.length - 1 && ", "}
                                      </span>
                                    ) : (
                                      <span key={partner.id} className="cursor-default">
                                        {partner.name}
                                        {index < highlight.value.length - 1 && ", "}
                                      </span>
                                  ))) : highlight?.value ? (
                                  highlight.value === "" ? "--" : highlight.value
                                ) : null}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="h-[10rem] w-full bg-[#FFFFFF] flex items-center justify-center">
                        <Loader />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <!--first half end--> */}

              {/* <!--end--> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorProfileHighlights;