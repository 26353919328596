import React from "react";
import OverflowToolTip from '../../shared/OverflowToolTip';
import { Popover } from "antd";
import linkedin from "../../../Utils/linkedin.png"

/**
 * The `ProfileHighlights` function is a React component that renders a profile section and a
 * highlights section with dynamic data.
 * @returns The code is returning a JSX element that represents a component called "ProfileHighlights".
 * This component renders a div with multiple nested divs and other elements inside. The content of the
 * component depends on the values of the "profile", "highlights", and "shareholdingPattern" props.
 */

const ProfileHighlights = ({ profile, highlights, shareholdingPattern }) => {
const filteredData = profile?.filter(item => item.label !== "Linkedin" );

  return (
    <div>
      <div className="space-y-6 px-4 bg-[#EDEDED] w-[100%]">
        <div className="lg:border ">
          <div
            className="lg:mt-4 lg:bg-white  rounded-[6px]"
            x-show="tab === 1"
          >
            <div className="w-full lg:grid lg:grid-cols-5 lg:rounded-[6px] lg:shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] ">
              <div className=" mt-3 lg:mt-0 rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] lg:shadow-none lg:rounded lg:col-span-3 lg:border-r bg-white overflow-x-auto">
                <div className="mx-5 py-4 lg:overflow-hidden overflow-x-auto">
                    <p className=" text-[14pt] flex leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                      Profile {
                        profile?.filter(pro => pro?.label === "Linkedin").length > 0 && // Check if LinkedIn profile exists
                        profile?.filter(pro => pro?.label === "Linkedin")[0]?.value ?  // Check if LinkedIn profile value exists
                        <a href={`${profile?.filter(pro => pro.label === "Linkedin")[0]?.value}`} target="_blank" className="mt-[0.20rem] ml-2"><img src={linkedin} width={16} alt="linkedin logo" /></a>
                        : ""
                      }
                    </p>
                  <div className=" mt-2">
                    {(profile?.length ?? 0) <= 0 ? (
                      <div className="overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:overflow-y-hidden">
                        <table className="h-[10rem] w-full bg-[#FFFFFF] ">
                          <center>
                            <div role="status" className="bg-white  h-[16rem]">
                              <svg
                                className="relative top-[6rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            </div>
                          </center>
                        </table>
                      </div>
                    ) : (
                      <div className="overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:overflow-y-hidden">
                        <div className="  w-full bg-[#FFFFFF] ">
                          <div className="grid grid-cols-3 break-words ">
                            {filteredData?.map((filteredData, i) => {
                              return (
                                <div className={i > 5 ? '' : 'border-b'} >
                                  <div className=" leading-[21px] font-sans_book_body ">
                                    <OverflowToolTip component={
                                      <a className=" py-1 text-[11pt] whitespace-nowrap hover:text-[#000000]  leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto"
                                        readOnly >
                                        {filteredData.label}
                                      </a>
                                    } />                          
                                    <p className="w-[91%] mb-2 text-[11pt] break-words leading-[18pt] lg:text-[13px] text-[#333333] font-semibold "
                                      readOnly >

                                      {filteredData.value ?  filteredData.label === "Linkedin" ? "" :  filteredData.label == "Website" ? <a className="w-[68%] md:w-0 cursor-pointer" target="_blank" href={filteredData.value}>{filteredData.value}</a>
                                        : filteredData.value ? filteredData.label == "News" ?
                                          <Popover
                                            placement="topLeft"
                                            className=' overflow text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold hover:underline '
                                            content={
                                              <a
                                                className=" overflow text-[11pt] hover:text-[#A5711A] leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto font-semibold hover:underline "
                                                href={filteredData.value} target="_blank" rel='noreferrer'>
                                                {filteredData.value}
                                              </a>
                                            }>
                                            <a
                                              className=" overflow text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold hover:underline "
                                              href={filteredData.value} target="_blank" rel='noreferrer'>
                                              Link 1
                                            </a>
                                          </Popover>
                                          // <OverflowToolTip className="whitespace-nowrap " component = { <a href={filteredData.value} target="_blank" className="hover:text-[#000000] cursor-pointer" rel="noreferrer">
                                          //   {/* <span className="word-break">{filteredData.value }</span></a>} */}
                                          //   Link 1</a>}
                                          //   />

                                          : filteredData.value ? filteredData.label == "Top Management" ? <p className="w-[90%]">{filteredData.value}</p>
                                            : filteredData.value ? filteredData.label == "Email" ? <p className="">{filteredData.value}</p> : filteredData.value
                                              : filteredData.value : filteredData.value : "--" : "--"}

                                    </p>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <!--first half end--> */}
              <div className="w-full lg:col-span-2 bg-white mt-5 lg:mt-0 overflow-x-auto space-x-10 rounded-lg scrollbar lg:shadow-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="lg:grid lg:grid-rows-2 lg:grid-flow-col gap-3">
                  <div className="row-span-2 px-5 lg:pl-8 pt-4 pb-2">
                    <div className="overflow-x-auto">
                      <p className=" mb-2 text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold lg:leading-[22px]">
                        Highlights
                      </p>
                      {(highlights?.length ?? 0) <= 0 ? (
                        <table className="h-[4rem] w-full bg-[#FFFFFF] ">
                          <center>
                            <div role="status" className="bg-white  h-[6rem]">
                              <svg
                                className="relative top-[2rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            </div>
                          </center>
                        </table>
                      ) : (
                        <div className="  w-full bg-[#FFFFFF] ">
                          <div className="grid grid-cols-2 ">
                            {highlights?.map((highlight, i) => {

                              return (
                                <div className={i < 2 ? 'border-b' : ""} >
                                  <div className=" leading-[21px] font-sans_book_body ">
                                    <OverflowToolTip component={
                                      <p className=" py-1 text-[11pt]   leading-[18pt] lg:text-[13px] text-[#333333]  cursor-auto"
                                        readOnly >
                                        {highlight.label}
                                      </p>
                                    } />
                                    <p className=" mb-2 text-[11pt] break-all leading-[18pt] lg:text-[13px] text-[#333333]  font-semibold "
                                      readOnly >

                                      {highlight.value != "$ 0 M" && highlight.value !=0 && highlight.value !=null? highlight.value : "--"}
                                    </p>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                </div>
              </div>
              {/* <!--end--> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHighlights;
