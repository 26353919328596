import React, { useState, useMemo, useContext, useEffect, useCallback } from 'react'
import Loader from '../../../../Components/Loader'
import NoDataPlaceholder from '../../../../Components/shared/NoDataPlaceholder'
import Pagination from '../../../../Components/Pagination'
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import OverflowToolTip from '../../../../Components/shared/OverflowToolTip'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { UserContext } from '../../../../Contexts/UserContext'
import Constants from '../../../../Constants'
import moment from 'moment';
import { reInvestorListUrl,getPEGlobalSearchCompaniesApi } from '../../../../Config/config'
import axios from 'axios'
import routePathSegments, { routeNames } from '../../../../routeSegments'
import AddNotePopover from '../../../../Components/shared/AddNotePopover'
import { TableDataContext } from '../../../../Contexts/TableDataContext'
import { PeFilterContext } from '../../../../Contexts/PeFilterContext'
import { useRef } from 'react'
import { getSection } from '../../../../Utils/utils'
import RandomLogo from '../../../../Components/RandomLogo'
import CompanyLogo from '../../../../Components/CompanyLogo'
import '../../../../Components/TableView.css'
import '../../../../Utils/Common.css'
import DeleteItemsFromGroup from '../../../../Components/shared/DeleteItemsFromGroup';
import { toast } from 'react-toastify'

/** The above code is a JavaScript function called "Table" that takes in several parameters. It is a
component that renders a table with data fetched from an API. */
function Table({ columns, category , orderBy, order,sub_type_master_id}) {
  const { rows, setRows, setAllDealIds, setTableInvestorId, data, setData, setIsDataLoad, currentPage, setCurrentPage,setCurrentFilter, globalSearchResult} = useContext(TableDataContext);
  const { monthPickerValue, refineSearchFilters ,groupId , resetDealsByFilter} = useContext(PeFilterContext);
  const [sort, setSort] = useState({ order: order, orderBy: orderBy })
  const abortControllerRef = useRef();
  const [activePage, setActivePage] = useState(currentPage)
  const location = useLocation();
  useEffect(()=>{
    console.log("jjjhhk inv" , location?.key)
    resetDealsByFilter();
  },[location?.key])
  const { getToken, logout } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false

  const[reload, setReload] = useState(false)

/**
 * The function `findActivePage` sets the current page, renders a table with specified parameters, and
 * sets the loading state to true.
 * @param page - The page parameter is used to specify the current page number.
 */
  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order ,groupId)
    setIsLoading(true)
    
  }

  const dealCategory = category == "re-investments"
      ? "RE_INV"
      : category == "re-exits"
        ? "RE_EXIT"
        : category == "re-ipo"
          ? "RE_IPO"
          :""

  const sortBy = dealCategory == "RE_INV"
      ? "latest_inv_deal_date_num"
      : dealCategory == "RE_EXIT"
        ? "investor_sort"
        : dealCategory == "RE_IPO"
          ? "investor_sort"          
          :""

          const globalSearchCategory = category.replace("-", "_")+"_ids";


/* The above code is a JavaScript function that uses the axios library to make an HTTP POST request to
an API endpoint. The function takes several parameters including page, month, column, order, and
groupId. It then constructs the request payload based on the values of these parameters and other
variables. The function also includes headers with an authorization token. */
  const tableRender = useCallback((page, month, column, order ,groupId) => {
    axios(reInvestorListUrl, {
      method: "POST",
      signal: abortControllerRef.current.signal,
      data:{ 
        "category": category,
        from : isFromGlobalSearch() ? 'gs' : '',
        date_range: isFromGlobalSearch()
            ? {
                from_date: globalSearchResult?.date_range?.from_date,
                to_date: globalSearchResult?.date_range?.to_date,
              }
            : {
                from_date: moment(month[0]).format("YYYY-MM-DD"),
                to_date: moment(month[1]).format("YYYY-MM-DD"),
              },
        "order_by": {
          "column": column,
          "direction": order 
        },
        "page":page,
        // operator : refineSearchFilters[location.pathname]?.deals_operator,
        company_ids : refineSearchFilters[location.pathname]?.target_company?.map(company => company.id),
        ladvisor_ids : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.ladvisors?.map(acq => acq.id)  ,
        tadvisor_ids : category === "angel" || category === "incubation" ? [] : refineSearchFilters[location.pathname]?.tadvisors?.map(acq => acq.id)  ,
        investor_ids: isFromGlobalSearch() ? globalSearchResult?.investor[globalSearchCategory]?.length === 0 ? [0] 
          : globalSearchResult?.investor[globalSearchCategory] : refineSearchFilters[location.pathname]?.investor_id?.map(acq => acq.id),
        "city": refineSearchFilters[location.pathname]?.city?.map(city => city.id),
        "deal_range": refineSearchFilters[location.pathname]?.dealRange?.at(0) && {
          "from": refineSearchFilters[location.pathname]?.dealRange[0].name,
          "to": refineSearchFilters[location.pathname]?.dealRange[1].name
        },
        "investor_type": refineSearchFilters[location.pathname]?.investorType?.map(type => type.id),
        "type": refineSearchFilters[location.pathname]?.fundSector?.map(sector => sector.id),
        "sub_sector": refineSearchFilters[location.pathname]?.subSector?.map(sector => sector.id)
        ,
        group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
    }, 

      }, 
        
      headers: {
        "Authorization": getToken()
      }
    }).then(response => {
      if (response?.data?.message == "Success") {
        setCurrentFilter(response.config.data)
        setIsLoading(false)
        setIsDataLoad(false)
        setRows(response?.data?.investors)
        setTableInvestorId(response?.data?.all_investor_ids)
        setAllDealIds(response?.data?.all_deal_ids)
        setData(response?.data)
        // console.log(response?.data?.companies,"api")
      }
    })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
          logout();
        }
        console.log("API not working", error);
      });
  }, [monthPickerValue, refineSearchFilters]);


  useEffect(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setIsLoading(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage)
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order,groupId)
    return () => abortController.abort();
  }, [monthPickerValue, refineSearchFilters ,groupId ,reload]);


  const rowsMemo = useMemo(() => rows, [rows])
  const count = rowsMemo?.length

/**
 * The function `handleSort` is used to handle sorting of data in a table based on a given accessor and
 * order.
 * @param accessor - The `accessor` parameter is a value that represents the property or key of the
 * object that you want to sort by. It is used to identify the specific property or key in the object
 * that you want to sort on.
 * @param order - The "order" parameter is used to specify the sorting order. It can have two possible
 * values: 'asc' for ascending order and 'desc' for descending order.
 */
  const handleSort = (accessor, order) => {
    setCurrentPage(currentPage)
    setRows([])
    setIsLoading(true)
    setIsDataLoad(true)
    setSort((prevSort) => ({
      order: prevSort.order === 'asc' && prevSort.orderBy === accessor ? 'desc' : 'asc',
      orderBy: accessor,
    }))
    tableRender(currentPage, monthPickerValue, accessor, sort.order === 'asc' && sort.orderBy === accessor ? 'desc' : 'asc',groupId)
  } 

 /**
  * The function `addCommasToNumber` takes a number as input and returns a string representation of the
  * number with commas added for thousands separators.
  * @param number - The `number` parameter is the number to which commas need to be added.
  * @returns The function `addCommasToNumber` returns a string with commas added to the number.
  */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <div className={`pl-3 ${rows?.length > 5 ? 'pr-2':'pr-4'}`}>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] "+(rows?.length > 5 ? '':'')}>
              <table className="w-full p-1 bg-grey-50 divide-y">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-sans_semi_bold text-[#666666]  text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (
                        <th className="title  px-5 py-2  font-sans_book_body" key={i}>
                          
                          <span className={`flex ${column.className}`}><OverflowToolTip component={<a className='cursor-text hover:text-[#000000]'>{column.accessor == "add" && groupId?.myList?.length > 0 ? "Delete": column.label ? column.label : "--"}</a>} />
                            {column.accessor == "add" ? "" :
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                            }</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoading ?
                  count > 0 ?
                    <tbody className="text-[13pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body">
                      {rowsMemo.length > 0 ?
                        rowsMemo?.map((row, i) => {
                          if (category === "re-investments") {
                            return (
                              <tr key={row.InvestorId} className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text">
                                <td className='px-5 py-3 leading-[2rem] font-sans_book_body font-medium items-center justify-center card-animate' width="20%">
                                  {row?.icon_path == "default.png" || row?.icon_path == "/default.png" || !row?.icon_path ?
                                    <RandomLogo name={row.Investor} />
                                    :
                                    <CompanyLogo id={row.InvestorId} name={row.Investor} page="company page" logo={row?.icon_path} />
                                  }
                                  <OverflowToolTip component={<NavLink className='hover:text-[#BC8B20]'
                                    state={{ prevPath: location.pathname }}
                                    to={{
                                      pathname: routeNames.reInvestorById(row.InvestorId),
                                      search: getSection(Constants.primaryMasterSubIds.re_investor_investment_id)
                                    }}  >
                                    {row?.Investor ? row.Investor : "--"}
                                  </NavLink>} />
                                </td>
                                <td width="10%" className='whitespace-nowrap pr-[2.8rem] py-3 font-[Squel Sans] text-right'>{row?.project_count !=0 ? addCommasToNumber(row.project_count) :"--"}</td>
                                <td width="20%" className='whitespace-nowrap font-medium px-5 py-3 font-[Squel Sans] '>
                                  {row?.top_project_companies_detail?.length >0 ? <OverflowToolTip className="sm:max-w-[17rem] 2xl:max-w-[30rem]" component={
                                    row?.top_project_companies_detail?.map((detail, index) => (<Link className='hover:text-primaryColor'
                                      to={{
                                        pathname: routeNames.reCompanyById(detail?.company_id),
                                        search: getSection(Constants.primaryMasterSubIds.re_investor_investment_id)
                                      }}>
                                      {detail?.company_name + (index === (row?.top_project_companies_detail?.length - 1) ? "" : ", ")}</Link>))
                                  } />
                                    : "--"
                                  }
                                </td>
                                <td width="20%" className='whitespace-nowrap font-medium px-5 py-3 font-[Squel Sans] '>
                                  {
                                    row?.last_invested_in
                                      ? <OverflowToolTip component={
                                        <Link className='hover:text-primaryColor'
                                          to={{
                                            pathname: routeNames.reCompanyById(row?.last_invested_in?.company_id),
                                            search: getSection(Constants.primaryMasterSubIds.re_investor_investment_id)
                                          }}>
                                          {row?.last_invested_in?.company_name}
                                        </Link>
                                      } />
                                      : "--"
                                  }
                                </td>
                                <td width="15%" className='whitespace-nowrap font-medium px-5 py-3 font-[Squel Sans] '>

                                <OverflowToolTip component={<Link
                                            className='hover:text-[#BC8B20]'
                                            
                                            to={{
                                              pathname: routeNames.reCompanyInvestmentDealById(row?.last_invested_in?.company_id, row?.last_investment_at_id)
                                            }}
                                          >{row?.last_investment_at? row.last_investment_at: "--"}</Link>} />
                                  
                                  </td>
                                <td width="5%" className='whitespace-nowrap  px-5 py-3 font-[Squel Sans]'>
                                {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.investorsId} setReload={setReload}/>
                                 :  <AddNotePopover key={row.InvestorId} companyId={row.InvestorId} companyName={row.Investor} type_id={Constants.moduleIds.investorsId} sub_type_master_id={sub_type_master_id}/>
                          }</td>
                              </tr>
                            )
                          } else if (category === "re-exits") {
                            return (
                              <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                <td className='px-5 font-medium py-3 leading-[2rem] font-sans_book_body items-center justify-center card-animate w-[20%]'>
                                  {row?.icon_path == "default.png" || row?.icon_path == "/default.png" || !row?.icon_path ?
                                    <RandomLogo name={row.Investor} />
                                    :
                                    <CompanyLogo id={row.InvestorId} name={row.Investor} page="company page" logo={row?.icon_path} />
                                  }
                                  <OverflowToolTip component={<Link className='hover:text-[#BC8B20]'
                                    state={{ prevPath: location.pathname }}
                                    to={{
                                      pathname: routeNames.reInvestorById(row.InvestorId),
                                      search: getSection(Constants.primaryMasterSubIds.re_investor_exits_id)
                                    }}  >
                                    {row?.Investor ? row.Investor : "--"}
                                  </Link>} />
                                </td>
                                <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[20%] text-right pr-[3.2rem]'>{row?.exited_project_count !=0 ? addCommasToNumber(row.exited_project_count) :"--"}</td>
                                <td className='whitespace-nowrap font-medium px-5 py-3 font-[Squel Sans] w-[25%]'>
                                  {(row?.top_exits_detail?.length ?? 0) > 0
                                    ? <OverflowToolTip className="sm:max-w-[17rem] 2xl:max-w-[30rem]" component={
                                      row?.top_exits_detail?.map((detail, index) => (<Link className='hover:text-primaryColor'
                                        to={{
                                          pathname: routeNames.reCompanyById(detail?.company_id),
                                          search: getSection(Constants.primaryMasterSubIds.re_investor_investment_id)
                                        }}>
                                        {detail?.company_name + (index === (row?.top_exits_detail?.length - 1) ? "" : ", ")}</Link>))
                                    } />
                                    : "--"
                                  }
                                </td>
                                <td className='whitespace-nowrap font-medium px-5 py-3 font-[Squel Sans] w-[25%]'>
                                  {
                                    row?.last_exited_in
                                      ? <OverflowToolTip component={
                                        <Link className='hover:text-primaryColor'
                                          to={{
                                            pathname: routeNames.reCompanyById(row?.last_exited_in?.company_id),
                                            search: getSection(Constants.primaryMasterSubIds.re_investor_exits_id)
                                          }}>
                                          {row?.last_exited_in?.company_name}
                                        </Link>
                                      } />
                                      : "--"
                                  }
                                  {/* {row?.last_exited_in ? <OverflowToolTip component={row.last_exited_in} /> : "--"} */}
                                </td>
                                <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[5%]'>
                                {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.investorsId} setReload={setReload}/>
                                 :  <AddNotePopover key={row.InvestorId} companyId={row.InvestorId} companyName={row.Investor} type_id={Constants.moduleIds.investorsId} sub_type_master_id={sub_type_master_id}/>
                                 } </td>
                              </tr>
                            )
                          } else {
                            return (
                              <tr className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]  border-b bg-white text" key={i}>
                                <td className='px-5 py-3 leading-[2rem] font-medium font-sans_book_body items-center justify-center card-animate w-[20%]'>
                                  {row?.icon_path == "default.png" || row?.icon_path == "/default.png" || !row?.icon_path ?
                                    <RandomLogo name={row.Investor} />
                                    :
                                    <CompanyLogo id={row.InvestorId} name={row.Investor} page="company page" logo={row?.icon_path} />
                                  }
                                  <OverflowToolTip component={<Link className='hover:text-[#BC8B20]'
                                    state={{ prevPath: location.pathname }}
                                    to={{
                                      pathname: routeNames.reInvestorById(row.InvestorId),
                                      search: getSection(Constants.primaryMasterSubIds.re_investor_ipo_id)
                                    }}  >
                                    {row?.Investor ? row.Investor : "--"}
                                  </Link>} />
                                </td>
                                <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] w-[15%] pl-10'>{row.ipos !=0 ? addCommasToNumber(row.ipos) :"--"}</td>
                                <td className='whitespace-nowrap font-medium px-5 py-3 font-[Squel Sans] w-[25%]'>
                                  {
                                    row?.latest_ipo
                                      ? <OverflowToolTip component={
                                        <Link className='hover:text-primaryColor'
                                          to={{
                                            pathname: routeNames.reCompanyById(row?.latest_ipo?.company_id),
                                            search: getSection(Constants.primaryMasterSubIds.re_investor_ipo_id)
                                          }}>
                                          {row?.latest_ipo?.company_name}
                                        </Link>
                                      } />
                                      : "--"
                                  }
                                </td>
                                <td className='whitespace-nowrap  px-5 py-3 font-[Squel Sans] text-center lg:pl-[9%] xl:pl-[8.5%] 2xl:pl-[7%] w-[25%]'>{row?.latest_ipo_size !=0 || row.latest_ipo_size !=0.00 ? addCommasToNumber(row.latest_ipo_size) : "--"}</td>
                                <td className='whitespace-nowrap font-medium px-5 py-3 font-[Squel Sans] w-[5%]'>
                                {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.investorsId} setReload={setReload}/>
                                 :  <AddNotePopover key={row.InvestorId} companyId={row.InvestorId} companyName={row.Investor} type_id={Constants.moduleIds.investorsId} sub_type_master_id={sub_type_master_id}/>
                          }</td>
                              </tr>
                            )
                          }
                        })
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                      }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>
              {!isLoading ?
                count > 0 ?
                  <></>
                  : 
                    <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                      <NoDataPlaceholder />
                    </div>                  
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

    <div className={`${isFromGlobalSearch() ? 'lg:pb-[3rem]':'pb-[9.25rem] lg:pb-[7.25rem]'}`}>{data?.num_investors && rows.length > 0 ? (
        <Pagination
          className="pagination-bar "
          currentPage={currentPage}
          totalCount={data?.num_investors}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />

      ) : (
        ""
      )}
      </div>  
    </div>
  )
} 

export default Table