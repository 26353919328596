import React, { useState, useMemo, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";
import DataTable from "../../Components/DataTables/DataTable";
import axios from "axios";
import { getRecentNewsUrl } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import useModule from "../../hooks/useModule";
import { DataFoundContext } from "../../Contexts/DataFoundContext";

/**
 * The `NewsActivity` function is a React component that fetches recent news and activity data for a
 * specific company and displays it in a table format.
 * @returns The NewsActivity component is being returned.
 */
const NewsActivity = ({news}) => {

  const { newsAndActivityDataFound } = useContext(DataFoundContext);

  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? "--" : "A";
      return (rowA?.values[columnId]?.news ?? defaultVal).localeCompare(
        rowB?.values[columnId]?.news ?? defaultVal
      );
    };
  }, []);
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment
        .utc(rowA?.values[columnId].date)
        .diff(moment.utc(rowB?.values[columnId].date));
    };
  }, []);
  const columns = useMemo(
    () => [
      {
        id: "news",
        Header: "News",
        accessor: "news",
        className:
          "text-[#333333] text-[12pt]  py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
        headerClassName: "lg:w-[15%]",
        Cell: ({ value }) => {
          return (
            <a href={value.link} target="_blank" rel="noreferrer">
              {value.news}
            </a>
          );
        },
        sortType: customStringSortMemo,
      },
      {
        id: "releaseDate",
        Header: "Release Date",
        accessor: "releaseDate",
        className:
          "text-[#333333] pr-[3.2rem] md:pr-[3.5rem] py-4 float-center lg:float-right whitespace-nowrap lg:text-[14px] lg:leading-[16px] text-right",
        headerClassName: "text-center float-right flex lg:px-10 px-8 py-3.5",
        Cell: ({ value }) => {
          const date = value?.date.trim();
          return moment(date).format("MMM DD, YYYY");
        },
        sortType: dealDateSort,
      },
    ],
    []
  );

  const rowData = useMemo(() => {
    const rows = [];

    if (news?.length !== 0) {
      news?.forEach((data) => {
        rows.push({
          news: { news: data?.title, link: data?.news_link },
          releaseDate: { date: data?.published_at, news: data?.title },
        });
      });
    }

    return rows;
  }, [news]);
  const initialSort = useMemo(
    () => [
      {
        id: "releaseDate",
        desc: true,
      },
    ],
    []
  );
  return (
    <>
      {newsAndActivityDataFound ? (
        <div className="mx-4 ">
          <div className="bg-white border mb-[-1.75rem] overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <div className="pt-4  px-5">
              <p className="text-[14pt] leading-[19pt] lg:text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px] mb-3 ">
                Recent News & Activity
              </p>
            </div>
            <DataTable columns={columns} data={rowData} sortBy={initialSort} />
          </div>
          <br />
          <br />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default NewsActivity;
