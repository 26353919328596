import { useContext, useEffect, useState } from "react";
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import { PeFilterContext } from "../../Contexts/PeFilterContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

//toggle switch component
export default function Example({ customPathname, SwitchState, setSwitchState }) {
  const { refineSearchFilters } = useContext(PeFilterContext);

  useEffect(() => {
    const ope = refineSearchFilters[customPathname]?.operator == null ? false : refineSearchFilters[customPathname]?.operator === 1
    setSwitchState(ope)
  }, [customPathname,  setSwitchState])


  return (
    <div className="py-1">
      <ToggleButton value={SwitchState} onLabel="AND" offLabel="OR" onToggle={(value) => setSwitchState(prev => !prev)} />
    </div>
  );
}
