import React from "react";
import CAGRcalculationCard from "../../Components/CFS/DetailsPage/NewCAGRCard";
import CompanyDetail from "../../Components/CFS/DetailsPage/NewCompanyDetails";
import RefineSearch from "../../Components/RefineSearchComponent";
import Layout from "../../Components/CfsLayout";
import { COMPANY_PROFILE, DEATAILPAGE_CARD } from "../../Queries/Pe";
import CurrencyContextProvider from "../../Contexts/CurrencyContext";
import CFSRefineSearch from "../../Components/RefineSearch/CFSRefineSearch";
import { routeNames } from "../../routeSegments";

function NewCFSDetailPage(props) {
  return (
    <CurrencyContextProvider>
      <div>
        <Layout>
          <div className="flex min-h-screen h-screen flex-col">
            <CAGRcalculationCard />
            <div className="flex min-h-0 flex-1 overflow-hidden">
              <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex">
                <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                  <CompanyDetail menu="cfs" COMPANY_PROFILE={COMPANY_PROFILE} />
                </section>
                <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                  <div className="relative flex h-full  flex-col border-r border-gray-200 bg-[#FAF5EA]">
                    <RefineSearch>
                      <CFSRefineSearch customPathname={routeNames.cfs} />
                    </RefineSearch>
                  </div>
                </aside>
              </main>
            </div>
            <br />
          </div>
        </Layout>
      </div>
    </CurrencyContextProvider>
  );
}

export default NewCFSDetailPage;
