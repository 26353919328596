import { React, useState, useRef, useContext, useEffect, Fragment } from 'react'
import { useLocation } from "react-router-dom";
import PropTypes from 'prop-types'
import axios from "axios";
import { savedFilterApi } from '../Config/config'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TableDataContext } from '../Contexts/TableDataContext';
import { filterLabels, PeFilterContext } from '../Contexts/PeFilterContext';
import Constants from '../Constants';
import { UserContext } from '../Contexts/UserContext';
import { Dialog, Transition } from '@headlessui/react'
import closeIcon from '../images/search_close_button.png'
import useModule from '../hooks/useModule';

/**
 * The `SavedFilterContent` is a component that allows users to save filter requests
 * and displays a dialog box for entering the filter label and saving the request.
 * @returns The SavedFilterContent component is being returned.
 */
const SavedFilterContent = (props) => {

    const [editable, setEditable] = useState(false);
    const [currentFilterRequest, setCurrentFilterRequest] = useState(Constants.defaultSaveFilterLabel);
    const inputEl = useRef(null);
    const { currentFilter } = useContext(TableDataContext);
    const { save, setSave, refineSearchFilters } = useContext(PeFilterContext);
    const location = useLocation()
    const { getToken } = useContext(UserContext);
    const [isSaving, setIsSaving] = useState(false)
    const {isMA} = useModule()
    const editContent = (event) => {
        setEditable(true)
        setCurrentFilterRequest(event.target.value)
    }
    // const setFocus = () => {
    //     setEditable(true)
    //     inputEl.current.focus();

    // }

    useEffect(() => {
        let filters = [];
        const refineSearchValues = refineSearchFilters[location.pathname]??{}
        Object.keys(refineSearchValues).filter(key => {
            const data = refineSearchValues[key];
            return data?.length > 0 && key !== "yearFounded" && key !== "dealRange";
        }).forEach((key, index) => {
            if (index < 3) {
                filters.push({ key: key, value: refineSearchValues[key].at(0) })
            }
        })

        let label = "";

        filters.forEach((filter, index) => {
            console.log(filter,'abcniebeo');
            label = `${label} ${filterLabels[filter.key]}-${filter.value.name} ${index === filters.length - 1 ? "" : "&"}`
        })
        if (label.trim().length === 0) {
            setCurrentFilterRequest(Constants.defaultSaveFilterLabel)
        } else {
            setCurrentFilterRequest(label)
        }
    }, [location.pathname, refineSearchFilters])


    const savedFilter = () => {
        if (currentFilterRequest.length === 0) {
            toast.warning("Please enter name")
            return;
        }
        let request_json;

        if (props.isAdvanceSearch) {
        request_json = {};
        request_json.advance_search = props.filters;
        } else {
            request_json = JSON.parse(currentFilter);
        }
        request_json.filterData = refineSearchFilters[location.pathname]

        console.log(request_json, 'request_json')

        setIsSaving(true)
        axios(`${savedFilterApi}`, {
            method: "POST",
            data: {
                // "user_id": 1,
                "type_id": props.typeId,
                "name": currentFilterRequest,
                "notes": "Default",
                "request_json": JSON.stringify(request_json),
                'primary_type_master_id': props.primaryTypeMasterId,  // pe / VC 
                'sub_type_master_id': isMA ? null : props.subTypeMasterId // 2 // investments / exits / incubation / Angel 
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                setIsSaving(false)
                console.log(response, 'saved list response');
                if (response?.data?.success == true) {
                    toast.success(response?.data?.message)
                    setSave(!save)
                }
                else if(response?.data?.status==400){
                    toast.warning(response?.data?.message)
                }
                else {
                    toast.error(response?.data?.message)
                }
            })
            .catch((error) => {
                setIsSaving(false)
                console.log("API not working", error);
            });

    }

    const closePopup = ()=>{
        setSave(false); 
        setEditable(false)
    }

    return (
        <div>
            <Transition.Root show={save} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closePopup}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="items-center flex min-h-full  justify-center p-4 text-center ">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="absolute top-0 right-0  pt-4 pr-4 ">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none  "
                                            onClick={closePopup}
                                        >
                                            <span className="sr-only">Close</span>
                                            <img alt='' src={closeIcon} className="w-8 h-8 " />
                                        </button>
                                    </div>
                                    <div className='text-left px-5 md:px-0 md:text-center font-[Sequel Sans] font-Medium'>
                                        Save Filter Request
                                    </div>
                                    <div className="">
                                        <div className="mt-3  lg:mt-0 lg:ml-4 lg:text-left">
                                            <div className="flex w-full items-center py-2">
                                                <input placeholder='Enter filter label' className={" text-[#BC8B20] text-[14px] font-sans_RomanBody leading-[17px] border-b border-b-[#BC8B20] p-2 focus:outline-none  w-[85%]"} onChange={editContent} value={currentFilterRequest} ref={inputEl} />
                                            </div>
                                        </div>
                                        <center>
                                            <button disabled={isSaving} className="rounded-[4px] px-4 py-1 text-[13px] text-[#FFFFFF] bg-[#BC8B20]" onClick={savedFilter}>{isSaving?"Saving...":"Save"}</button>
                                        </center>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    )
}

SavedFilterContent.propTypes = {
    show: PropTypes.bool,
    typeId: PropTypes.string,
    primaryTypeMasterId: PropTypes.string,
    subTypeMasterId: PropTypes.string,
}

export default SavedFilterContent