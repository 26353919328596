import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';
import useScrollIntoView from './useScrollIntoView';

/**
 * The `VcFundedFilter` function is a React component that renders a dropdown filter for VC funded
 * options, allowing users to select a specific option.
 * @returns a JSX element.
 */
function VcFundedFilter({ open, accordionClick, heading, VCFundedOptions, setVCFundedOptions, customPathname }) {
    const { VcFunded, refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);

    const handleChange = (e) => {
        const value = e.target.value
        // const values = companyType.filter(companyType => companyType.id === e.target.value);
        setVCFundedOptions(VCFundedOptions?.map(type => ({ ...type, isChecked: value === "" ? false : type.value === value })))

        // const result = VcFunded.filter(vcFund => vcFund.value === e.target.value);
        // setSelectedVcFunded(result[0].value === "" ? null : result[0])
    };

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.vcFunded?.map(value => value.value) ?? [];
        setVCFundedOptions(VcFunded?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.value) })))
    }, [VcFunded, customPathname, refineSearchFilters, setVCFundedOptions])

    const selected = VCFundedOptions?.filter(option => option.isChecked)?.at(0)

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
                ref={listItemRef}
            >
                <FilterWrapper
                    isSelect={true}
                    label={selected?.name ? `${heading} - ` + selected?.name : heading}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    <select name="" id="" value={selected?.value ?? ""} onChange={handleChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {VCFundedOptions && VCFundedOptions.length > 0 ? (
                            VCFundedOptions.map((vcFund, index) => (
                                <option key={vcFund.name} value={vcFund.value}>{vcFund.name}</option>
                            )))
                            :
                            (
                                <h1>No results found!</h1>
                            )}
                    </select>
                </div>

            </li>

        </div>
    )
}

export default VcFundedFilter