import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity';
import FilterWrapper from './FilterWrapper';
import useScrollIntoView from './useScrollIntoView';

/**
 * The CityFilter function is a component in JavaScript that renders a filter for selecting cities,
 * with options to select all cities or individual cities.
 * @returns a JSX element.
 */
function CityFilter({ open, accordionClick, CityOptions, setCityOptions, customPathname, filterPathkey = "city", label = "City", innerCity }) {
    const { refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);
    const [CitySearchText, setCitySearchText] = React.useState("");

    useEffect(() => {
        const ids = refineSearchFilters[customPathname]?.[filterPathkey]?.map(value => value.id) ?? [];
        setCityOptions(CityOptions?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
    }, [customPathname, refineSearchFilters, filterPathkey])


    const handleCityChange = ((e) => {
        const { name, checked, id } = e.target;
        if (name === "allSelect") {
            let currentCities = CityOptions.map((city) =>
                ({ ...city, isChecked: checked })
            );
            setCityOptions(currentCities);
        } else {
            let currentCities = CityOptions.map((city) =>
                city.id === parseInt(id) ? { ...city, isChecked: checked } : city
            );
            setCityOptions(currentCities);
        }
    });
    const limitedCityOptions = CityOptions?.slice(0, 850);
    var primaryCityOptions = CitySearchText?.length > 2 ? CityOptions : limitedCityOptions;
    const len = CityOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className={`${filterPathkey === "chargeCity" ? "" : "border-b-[3px] border-[#E8E6E1]"}`}>
            <li
                className={
                    open
                        ? `bg-white relative px-${!innerCity ? '3' : '0'} py-1`
                        : `relative px-${!innerCity ? '3' : '0'} py-1`
                }
                ref={listItemRef}
            >
                <FilterWrapper
                    isSelect={false}
                    label={label}
                    count={len}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleCityChange}
                        primaryEntity={primaryCityOptions}
                        setCitySearchText={setCitySearchText}
                    /> : <></>}
                </div>

            </li>

        </div>
    )
}

export default CityFilter