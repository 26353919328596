import{React, useState, createContext, useEffect} from 'react'
import { useLocation} from 'react-router-dom';

export const RaiseRequestContext = createContext('');

/**
 * The code defines a React context provider component called RaiseRequestContextProvider that manages
 * state related to raising requests, including the open status of a request popup, the default
 * messages for different types of requests, the email ID to send the request to, and the author ID.
 * @returns The `RaiseRequestContextProvider` component is being returned.
 */
const RaiseRequestContextProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const [date,setDate] = useState();
    const [cname , setCname] = useState();
    const [openTable, setOpenTable] = useState(false);
    const [DefaultMessage, setDefaultMessage] = useState({
      financial : "Request for financials linking",
      updateFinancials : "Update financials",
      moreInfoInvestment  : "Request for more deal data-PE Investment",
      moreInfoAngel : "Request for more deal data-Ang Inv",
      moreInfoIncubation  : "Request for more deal data",
      MandA : "Check for M&A Activity",
      valuationInfo: `${cname} ${date} Request for more deal data-PE Investment`,
      coInvestor: "Investor - Investment",
      moreDealVc  : "Request for more deal data-VC Investment",
      mandaDealAdvisorInfo :"Request for more deal data-MandA",
      mandaDealValuationInfo:"Request for more deal data",
      faq :"Query from FAQ section",
      subscription : "Upgrade Subscription - Enquiry"
    })
    const [product, setProduct] = useState("");
    console.log(cname , date , "Card checking")
    const [subjectData, setSubjectData] = useState("")
    const location = useLocation()
    useEffect(() => {
      let investmentType = ""; // Default
      if (location.pathname.includes("/company/pe-vc-investments") || location.pathname.includes("/vc-investments/") ) {
        investmentType = "Investment";
      } else if (location.pathname.includes("/exits/")) {
        investmentType = "Exits";
      } else if (location.pathname.includes("/backed-ipos/")) {
        investmentType = "Backed IPO";
      } else if (location.pathname.includes("/angel/")) {
        investmentType = "Angel Investment";
      } else if (location.pathname.includes("/incubation/")) {
        investmentType = "Incubation";
      }

      const pathVerify = location.pathname.includes("/pe/") || location.pathname.includes("/vc") ;
      
      setDefaultMessage(prev => ({
        ...prev,
        valuationInfo:pathVerify ? `${investmentType } - ${cname} ${date}` : `${cname}`,
        moreDealVc  : pathVerify ? `${investmentType } - ${cname} ${date}` : `${cname}`,
        moreInfoInvestment : pathVerify ? `${investmentType } - ${cname} ${date}` : `${cname}`, 
        moreInfoIncubation:pathVerify ? `${investmentType } - ${cname} ${date}` : `${cname}`,
        moreInfoAngel:pathVerify ? `${investmentType } - ${cname} ${date}` : `${cname}`,
        mandaDealAdvisorInfo:pathVerify ? `${investmentType } - ${cname} ${date}` : `${cname}`,
        mandaDealValuationInfo:pathVerify ? `${investmentType } - ${cname} ${date}` : `${cname}`,
        MandA: `${cname} - Check for M&A Activity`,
        coInvestor:`${cname} - Investor - Investment`,
        financial:`${cname} - Request for financials linking`,
        updateFinancials: `${cname} - Update Financials`
      }));
    }, [location.pathname, cname, date]);
    const [requestMailID, setRequestMailId] = useState({
      commonEmailId : location.pathname=='/setting' ? "sales@ventureintelligence.com" :"research@ventureintelligence.com"
    })

    const [authorId, setAuthorId] = useState("vijayakumar.k@praniontech.com")

    const openRequestPopup = (from, product) => {
      if (product === undefined) {
          product = from;
          from = "";
      }
  
      if (from === "") {
          setSubjectData("");
      } else {
          setSubjectData(DefaultMessage[from] ?? "");
      }
  
      setProduct(product);
      setOpen(true);
  };

    const Detail = { open, setOpen,openTable,cname , setCname ,date,setDate , setOpenTable, DefaultMessage, requestMailID, authorId,subjectData, setSubjectData,openRequestPopup, product, setProduct}

    return (
      <RaiseRequestContext.Provider value={Detail}>
        {children}
      </RaiseRequestContext.Provider>
    )
}

export default RaiseRequestContextProvider