import React, { useState, useMemo, useContext } from "react";
import { useParams, Link, useLocation, matchPath } from 'react-router-dom';
import moment from "moment";
import { routeNames } from "../../../routeSegments";
import DataTable from '../../../Components/DataTables/DataTable';
import CompanyLogo from "../../CompanyLogo";
import routePathSegments from "../../../routeSegments";
import ExcelDownloader from "../../shared/ExcelDownloader";
import { reExportExcel } from "../../../Config/config";
import OverflowToolTip from '../../shared/OverflowToolTip';
import Constants from "../../../Constants";
import RandomLogo from "../../RandomLogo";
import { UserContext } from "../../../Contexts/UserContext";

/**
 * The `AdvisorTo_Investors` function is a React component that renders a table of investor data for a
 * specific advisor.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `AdvisorTo_Investors` component. These properties can be accessed using dot notation, such as
 * `props.companyId` or `props.advisorName`.
 * @returns The `AdvisorTo_Investors` component is returning a JSX element that represents a table of
 * data. The table displays information about investors and their investments in various companies. The
 * data is fetched from the `props.toInvestor` array and transformed into a format that can be used by
 * the `DataTable` component. The table has columns for investors, company names, deal dates, and deal
 * amounts.
 */
const AdvisorTo_Investors = (props) => {

  const [showXlDownload, setshowXlDownload] = useState(false);
  const { companyId } = useParams();
  let advisorProfileId = parseInt(companyId)
  const location = useLocation();
  const {isTrialExport,isStudent} = useContext(UserContext)

  const reMatch = matchPath(`${routePathSegments.re}/*`, location.pathname);

  
  // const customStringSortMemo = useMemo(() => {
  //   return (rowA, rowB, columnId, desc) => {
  //     const row1 = rowA?.values[columnId].map(inv => inv.Investor).join(" ");
  //     const row2 = rowA?.values[columnId].map(inv => inv.Investor).join(" ");
  //     const defaultVal = desc ? '--' : 'A';
  //     return (row1 ?? defaultVal).localeCompare(row2 ?? defaultVal);
  //   }
  // }, []);
 /* The `customNameSortMemo` is a custom sorting function that is used in the DataTable component. It
 is created using the `useMemo` hook to memoize the function and prevent unnecessary re-renders. */
  const customNameSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
    }
  }, []);

 /* The `dealDateSort` variable is a custom sorting function that is used in the DataTable component.
 It is created using the `useMemo` hook to memoize the function and prevent unnecessary re-renders. */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
    }
  }, [])
  // const decimalNumberSort = useMemo(() => {
  //   return (rowA, rowB, columnId, desc) => {
  //     const rowAValue = Number(rowA.values[columnId])
  //     const rowBValue = Number(rowB.values[columnId])
  //     if (rowAValue > rowBValue) return 1;
  //     if (rowBValue > rowAValue) return -1;
  //     return 0;
  //   }
  // }, [])
  
/* The `investorSort` variable is a custom sorting function that is used in the DataTable component. It
is created using the `useMemo` hook to memoize the function and prevent unnecessary re-renders. */
  const investorSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const defaultVal = desc ? '--' : 'A';

        const valueA = rowA?.values[columnId].map(data => data.investor_name).join("")
        const valueB = rowB?.values[columnId].map(data => data.investor_name).join("")

        return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
    }
}, [])


/**
 * The addCommasToNumber function takes a number as input and returns a string representation of the
 * number with commas added for thousands separators.
 * @param number - The `number` parameter is the number that you want to add commas to.
 * @returns The function `addCommasToNumber` returns a string representation of the input number with
 * commas added as thousands separators.
 */
const addCommasToNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/* The `columns` variable is an array of objects that define the columns for the DataTable component.
Each object represents a column and contains properties such as `Header` (the column header text),
`accessor` (the key to access the data for that column), `className` (the CSS class for the column),
and `Cell` (a custom component to render the cell content). */
  const columns = useMemo(() => [
    {
      id: "investors",
      Header: "Investors",
      accessor: "investors",
      // headerClassName: "w-[14%]",
      className: " w-[25%]",
      Cell: ({ value }) => {
        return <div className="leading-[2rem] font-sans_book_body items-center justify-center">
          {value?.logo_path == "default.png" || value?.logo_path == "/default.png" || !value?.logo_path ?
            <RandomLogo name={value?.investor_name} type= {value.investor_type} page="company page" />
            :
            <CompanyLogo name={value?.investor_name} type= {value.investor_type} page="company page" logo={value?.logo_path} />
          }<OverflowToolTip className=" " component={
            value.map((inv, index) => (<Link className='hover:text-[#BC8B20]'
              state={{ cameFrom: location.state?.prevPath }} to={reMatch != null ? routeNames.reInvestorById(inv.investor_id)
                : ""}>
              {`${inv?.investor_name}${index === value.length - 1 ? "" : ", "}`}
              {/* {inv?.Investor_name} */}
            </Link>))

          } />
        </div>
      },
      sortType: investorSort,
      
    },
    {
      Header: "Company Name",
      accessor: "companyName",
      className: "w-[22%]",
      Cell: ({ value }) => {
        return <OverflowToolTip className=" " component={<Link className='hover:text-[#BC8B20]'
          state={{ cameFrom: location.state?.prevPath }}
          to={reMatch != null ? routeNames.reCompanyFundById(value?.id)
            : ""}>
          {value.name ?? "--"}
        </Link>} />

      },
      sortType: customNameSortMemo,
    },
    {
      id: "dealDate",
      Header: "Deal Date",
      accessor: "dealDate",
      className: "w-[12%]",
      Cell: (cell) => {
        return <Link state={{ cameFrom: location.state?.prevPath ?? "" }}
          to={reMatch != null ? routeNames.reCompanyInvestmentDealById(cell.value.id, cell.value.dealId)
            : ""}>
          {/* {cell?.value?.Deal_Date!='' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"} */}
          {cell?.value?.date != '' ? moment(cell?.value?.date , 'MMM YYYY').format('MMM YYYY') : "--"}

        </Link>
      },
      sortType: dealDateSort
    },
    {
      Header: "Deal Amount (US$ M)",
      accessor: "dealAmountInUS",
      className: "pl-[8rem] lg:pl-[110px] w-[50%]",
      Cell: (cell) => {
        return addCommasToNumber(cell?.value)
    },
      // headerspanClassName: "ml-auto"
    },


  ], []);

 /* The `rowData` variable is a memoized function that transforms the `props.toInvestor` data into a
 format that can be used by the DataTable component. */
  const rowData = useMemo(() => {
    const rows = [];

    props.toInvestor?.forEach(company => {
      rows.push({
        investors: company?.investors,
        dealDate: { date: company?.deal_date, dealId: company?.deal_id, id: company?.company_id },
        dealAmountInUS: company?.deal_amount >0 ? company?.deal_amount:"",
        companyName: { name: company?.company_name, id: company?.company_id },
      })
    });
    setshowXlDownload(rows.length !== 0)

    return rows;
  }, [props.toInvestor])


 /* The `initialSort` variable is using the `useMemo` hook to memoize an array of objects. Each object
 represents a column in the DataTable component and specifies the initial sorting configuration for
 that column. In this case, the `dealDate` column is being sorted in descending order (`desc: true`)
 as the initial sort configuration. */
  const initialSort = useMemo(() => [
    {
      id: "dealDate",
      desc: true
    }
  ], []);
  return (
    <>
      <div className=" px-4 bg-[#EDEDED] w-[100%]">
        <div className="bg-white border  overflow-hidden sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
          <div className=" pt-4 px-5 border-b border-b-[#F1F1F1] ">
            <div className="flex justify-between mb-2">
              <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] lg:leading-[22px] font-semibold mb-2">
                To Investors
              </h3>
              {(isTrialExport != 'false' && isStudent == 'false'  && showXlDownload) && <ExcelDownloader
                url={reExportExcel}
                fileName={Constants.exportNames.to_investors(props.advisorName)}
                data={{

                  "advisor_type": "L",
                  "section": "to_investors",
                  "advisor_id": advisorProfileId
                }} />}
            </div>
          </div>
          <div className="space-y-6">
            <div className="bg-white pb-2 rounded-[6px]">
              <DataTable columns={columns} data={rowData} sortBy={initialSort} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvisorTo_Investors;
