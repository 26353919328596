import { React, useContext, useEffect, useMemo, useState } from 'react'
import { useParams, NavLink, useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Tooltip } from "antd";
import { getProfileUrl } from "../../../Config/config"
import ProfileHighlight from '../../DetailPage/ProfileHighlights'
import Funding from '../../DetailPage/Funding'
import MergerAcquisition from '../../DetailPage/MergerAcquisition' 
import Financials from '../../DetailPage/Financials'
import CapTable from '../../DetailPage/CapTable'
import SimilarCompanies from '../../DetailPage/SimilarCompanies'
import NewsActivity from '../../DetailPage/NewsActivity'
import ComparePopUp from '../../../Pages/ComparePopUp'
import Constants from '../../../Constants';
import { UserContext } from '../../../Contexts/UserContext';
import axios from 'axios';
import CompanyDetailInformation from '../../DetailPage/CompanyDetailInformation';
import { routeNames } from '../../../routeSegments';
import Tab from '../../shared/Tab';
import FundingDataProvider from '../../../Contexts/FundingDataProvider';
import CurrencyContextProvider from '../../../Contexts/CurrencyContext';
import { PeFilterContext } from '../../../Contexts/PeFilterContext';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { TableDataContext } from '../../../Contexts/TableDataContext';
import { DataFoundContext } from '../../../Contexts/DataFoundContext';
import { RaiseRequestContext } from '../../../Contexts/RaiseRequestContext';

/**
 * The MACompanyDetail function is a React component that displays detailed information about a
 * company, including its overview, mergers and acquisitions, funding, financials, cap table, similar
 * companies, and recent news and activity.
 */
const MACompanyDetail = () => {
    const {setCname} = useContext(RaiseRequestContext);
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' }); }
    const funding = () => { document.getElementById("fund").scrollIntoView({ behavior: 'smooth' }); }
    const merger = () => { document.getElementById("merge").scrollIntoView({ behavior: 'smooth' }); }
    const financial = () => { document.getElementById("financial").scrollIntoView({ behavior: 'smooth' }); }
    const capTable = () => { document.getElementById("cap").scrollIntoView({ behavior: 'smooth' }); }
    const similarCompanies = () => { document.getElementById("similar").scrollIntoView({ behavior: 'smooth' }); }
    const newsActivity = () => { document.getElementById("news").scrollIntoView({ behavior: 'smooth' }); }
    const [showModal, setShowModal] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const [profileData, setProfileData] = useState({});
    const { getToken, logout, getAllowedModules } = useContext(UserContext);
    const {loadPreviousStateRef} = useContext(PeFilterContext);
    const location = useLocation();
    const { companyId } = useParams();
    const [skipScroll, setSkipScroll] = useState(false)
    const { capTableDataFound, similarCompaniesDataFound, newsAndActivityDataFound} = useContext(DataFoundContext);

    const [overviewEl, isOverviewVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [fundingEl, isfundingVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [mergerEl, ismergerVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [financialEl, isfinancialVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [captableEl, iscaptableVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [similarCompaniesEl, issimilarCompaniesVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, ] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
   

    useEffect(() => {
        const abortController = new AbortController()

        axios(`${getProfileUrl}`, {
            method: "POST",
            data: {
                company_id: [companyId],
                type: Constants.primaryMasterIds.mANDaId
            },
            signal: abortController.signal,
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                if (response?.data?.success == true) {
                    // console.log("setProfileData", response.data.data.topProfile.company_name);
                    setProfileData(response.data?.data);
                    setCname(response?.data?.data?.topProfile?.company_name)
                }
            })
            .catch((error) => {
                if (error?.response?.status == Constants.sessionTerminatedStatus) {
                    logout()
                }
                console.log("API not working", error);
            });

        return () => abortController.abort()
    }, [companyId, getToken, logout])

    /**
     * The function `onTabClick` is used to handle tab clicks and perform different actions based on
     * the selected tab index.
     */
    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview();
        } else if (tabIndex === 2) {
            merger();
        } else if (tabIndex === 3) {
           funding();
        } else if (tabIndex === 4) {
            financial();
        } else if (tabIndex === 5) {
            capTable()
        } else if (tabIndex === 6) {
            similarCompanies()
        } else if (tabIndex === 7) {
            newsActivity();
        }

        setIsActive(tabIndex);
    }

    /* The above code is using the `useMemo` hook in React to memoize the result of a function called
    `showSimilarCompanies`. */
    const showSimilarCompanies = useMemo(() => {
        const allowedModules = getAllowedModules();
        return allowedModules.includes('pe') || allowedModules.includes('vc')
    }, [getAllowedModules])


    /**
     * The function sets a flag indicating that the previous state should be loaded when a root
     * breadcrumb is clicked.
     */
    const onRootBreadCrumbClick = ()=>{
        loadPreviousStateRef.current = true;
    }

    /* The above code is using the `useMemo` hook in React to memoize a function called
    `setIsActiveOnScroll`. This function is created using the `debounce` function, which is likely
    imported from a library. The `debounce` function takes two arguments - a callback function
    (`index => setIsActive(index)`) and a delay time (100 milliseconds in this case). */
    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])
    


    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } else if (ismergerVisible) {
            setIsActiveOnScroll(2);
        } else if  (isfundingVisible){
            setIsActiveOnScroll(3);
        } else if (isfinancialVisible) {
            setIsActiveOnScroll(4);
        } else if (iscaptableVisible) {
            setIsActiveOnScroll(5);
        } else if (issimilarCompaniesVisible) {
            setIsActiveOnScroll(6);
        } else if (isnewsVisible) {
            setIsActiveOnScroll(7);
        }
    }, [isOverviewVisible, iscaptableVisible, isfinancialVisible, isfundingVisible, ismergerVisible, isnewsVisible, issimilarCompaniesVisible, setIsActiveOnScroll])

    const { tableCompanyId: cmpId } = useContext(TableDataContext);

    const currentIndex = cmpId?.indexOf(Number(companyId));

    document.title = `${profileData?.topProfile?.company_name ? profileData?.topProfile?.company_name + " - " : ""}M&A Companies - Venture Intelligence`;

 
    return (
        <CurrencyContextProvider>
          <div className='overflow-y-auto details-screen'> 
           <div className="bg-[#FFFFFF]  profile-detail relative">
                <nav className="flex p-1 border-b " aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            <div className='flex items-center'>
                                <NavLink
                                onClick={onRootBreadCrumbClick}
                                state={location.state?.prevPath }
                                 to={location.state?.prevPath != null && location.state?.prevPath == ""
                                    ? location.state?.prevPath
                                    : routeNames.ma_companies} 
                                    className="ml-2 text-[11pt] lg:text-[12px] text-[#666666] font-sans_book_body">
                                    Companies
                                </NavLink>
                            </div>
                        </li>
                        <li className="flex items-center"><div className="flex items-center"><span className=" text-[12px] text-[#666666] mr-1">/</span><span className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold" aria-current="page">{profileData?.topProfile?.company_name}</span></div></li>
                    </ol>
                </nav>
                {cmpId &&
                    <>
                        <Tooltip title="Prev">
                            <NavLink
                            className="absolute top-2 right-8"
                            to={routeNames.maCompanyById(cmpId[currentIndex - 1])}
                            >
                            {cmpId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next">
                            <NavLink
                            className="absolute top-2 right-4"
                            to={routeNames.maCompanyById(cmpId[currentIndex + 1])}
                            >
                            {cmpId.length - 1 === currentIndex ? null : (
                                <FaChevronRight size={15} color='grey'/>
                            )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
                <CompanyDetailInformation data={profileData?.topProfile} id={companyId} page="companyPage" cname={profileData?.topProfile?.company_name} type="ma" />

                <div className="bg-[#EDEDED]">
                    <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index scrollbar-remove">
                        <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                        <Tab active={isActive === 2} label={"Mergers & Acquisitions"} onClick={() => onTabClick(2)} />
                        <Tab active={isActive === 3} label={"Funding"} onClick={() => onTabClick(3)} />
                        <Tab active={isActive === 4} label={"Financials"} onClick={() => onTabClick(4)} />
                        {capTableDataFound ? <Tab active={isActive === 5} label={"Cap Table"} onClick={() => onTabClick(5)} />:""}
                        {similarCompaniesDataFound ? showSimilarCompanies ? <Tab active={isActive === 6} label={"Similar Companies"} onClick={() => onTabClick(6)} /> : null : ''}
                        {newsAndActivityDataFound ? <Tab active={isActive === 7} label={"Recent News & Activity"} onClick={() => onTabClick(7)} /> : ""}
                    </div>
                    <div className="bg-[#EDEDED] h-full space-y-5 details-page">
                        <section ref={overviewEl} className='scroll-mt-14' id="profile">
                            <ProfileHighlight menu="ma"
                                profile={profileData?.profile}
                                highlights={profileData?.highlights}
                                shareholdingPattern={profileData?.shareholdingPattern} />
                        </section>
                        <section  ref={mergerEl}  className='scroll-mt-14' id="merge"><MergerAcquisition companyName={profileData?.topProfile?.company_name} /></section>
                        <section ref={fundingEl}  className='scroll-mt-14' id="fund"><FundingDataProvider><Funding menu="ma" companyName={profileData?.topProfile?.company_name} /></FundingDataProvider></section>
                        <section ref={financialEl}  className='scroll-mt-14' id="financial"><Financials companyName={profileData?.topProfile?.company_name} fcompanyName={profileData?.topProfile?.fcompanyName} menu="ma" profileData={profileData}/></section>
                        <section  ref={captableEl}  className='scroll-mt-14' id="cap"><CapTable companyName={profileData?.topProfile?.company_name} /></section>
                        {showSimilarCompanies ? <section ref={similarCompaniesEl}  className='scroll-mt-14' id="similar"><SimilarCompanies companyName={profileData?.topProfile?.company_name} /></section> : null}
                        <section ref={newsEl}  className='scroll-mt-14' id="news"><NewsActivity /></section>
                    </div>
                </div>
                {showModal ? (<ComparePopUp id={companyId} onClose={(e) => setShowModal(false)} />) : null}
            </div>
            </div>
        </CurrencyContextProvider>

    )

}

export default MACompanyDetail