import React, { useContext, useState, useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { toast } from 'react-toastify';

function InvestorDealRangeFilter({ customPathname, DealRange, setDealRange }) {
    const { refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const from = refineSearchFilters[customPathname]?.dealRange?.at(0)?.name ?? "";
        const to = refineSearchFilters[customPathname]?.dealRange?.at(1)?.name ?? "";
        setDealRange({ from, to })
    }, [customPathname, refineSearchFilters, setDealRange]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        const formatedValue = value.replace(/[^0-9]/g, '')
        // const re = /^[0-9\b]+$/;
        // if (formatedValue === '' || re.test(formatedValue)) {
        setDealRange(prev => ({ ...prev, [name]: formatedValue }))
        // }
    };
    
    
    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    "relative px-3 py-2"
                }
            >
                <p className="text-[14px] tracking-[-.3px]">Deal Range (US$ M)</p>
                <div className="flex mt-2">
                    <input type="number" min={0} name="from" id="" onChange={handleChange}
                        className=" border border-[#BC8B20] mr-3 rounded focus:outline-none bg-[#FFFFFF] lg:w-[5rem] w-[9.5rem] md:w-[16rem] pl-2"
                        value={DealRange?.from ?? ""} placeholder="" autoComplete='off' />
                    <p className="text-sm mr-3">To</p>
                    <input type="number" min={DealRange?.from ?? "1"} name="to" id="" onChange={handleChange}
                        className="border border-[#BC8B20] rounded focus:outline-none bg-[#FFFFFF] lg:w-[5rem] w-[9.5rem] md:w-[16rem] pl-2"
                        value={DealRange?.to ?? ""} placeholder="" autoComplete='off' />
                </div>
            </li>
        </div>
    );
}

export default InvestorDealRangeFilter;
